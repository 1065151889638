import {Injectable} from "@angular/core";
import {SearchService} from "./search.service";
import {MockService} from "./mock.service";
import {RootShareService} from "./root.shared.service";
import {Http} from "@angular/http";
import {ApiService} from "./api.service";
import {Broadcaster} from "./broadcaster";
import {AuthService} from "./auth.service";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {ReferralForm} from "../models/forms/referral/referral.form";
import {Observable} from "rxjs/Observable";

@Injectable()
export class CustomFormsService implements Resolve<Array<ReferralForm>> {
    constructor (private _broadcaster: Broadcaster, public mockService: MockService,
                 private _authService: AuthService, private _apiService: ApiService,
                 private _rootShare: RootShareService, private _http: Http, private _searchService: SearchService) {}

    saveForm(form) {
        return new Promise((resolve, reject) => {
            this._apiService.post('customdata/save?collectionName=customForms', form).then((response) => {
                resolve(response);
            });
        });
    }
    deleteForm(id) {
        return new Promise((resolve, reject) => {
            this._apiService.post('customdata/delete?collectionName=customForms', {_id: id}).then((response) => {
                resolve(response);
            });
        });
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<ReferralForm>> | Promise<Array<ReferralForm>> | Array<ReferralForm> {
        return new Promise((resolve, reject) => {
            let filter = {
                patientId: route.params["id"]
            };
            this._apiService.post('customdata/filter?collectionName=customForms', filter).then((response) => {
                console.log(response, route.params["id"]);
                resolve(response);
            });
            /*
            //this.getPatientData(route.params["id"]).then((success) => {
            this.getById(route.params["id"]).then((success) => {
                console.log(success);
                this.getVitals(route.params['id']);
                resolve(success);
            }, (fail) => {
                reject(false);
            })
            */
        })
    }
}

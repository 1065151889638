import {Component, EventEmitter, Input, Output} from '@angular/core';
import { UserThumbnailComponent } from "../user-thumbnail/user-thumbnail.component";
import  { UsersService} from "../../services/user.service";
import {Conversation, Message, MessageService, MessageStatus} from "../../services/messages.service";
import {PatientService} from "../../services/patient.service";
import {Moment} from "moment";
import {ActivatedRouteSnapshot, Router} from "@angular/router";
import moment = require("moment");
import {DocumentsService} from "../../services/documents.service";
import {RootShareService} from "../../services/root.shared.service";
import {Medications} from "../../models/forms/referral/medications";
import {MockService} from "../../services/mock.service";
import * as $ from "jquery";
import * as viewerjs from "viewerjs";
import {ApiService} from "../../services/api.service";
import {UploadState} from "../referral-image/referral-image.element.component";
import {ReferralForm} from "../../models/forms/referral/referral.form";
import {CustomFormsService} from "../../services/custom.forms.service";
import {AuthService} from "../../services/auth.service";
import {MainService} from "../../services/main.service";

export enum TimelineElementType {
    vitals,
    diagnosis,
    prescriptions1,
    prescriptions2,
    careTeam,
    clinicalImage,
    visualField,
    examination
}

@Component({
    selector: 'patient-timeline-element',
    templateUrl: './patient-timeline-element.component.html'
})
export class PatientTimelineElementComponent {
    @Input() type: TimelineElementType = TimelineElementType.vitals;
    @Input() title: string = "Prescriptions";
    @Input() firstIcon: string = "";
    @Input() secondIcon: string = "";

    @Input() userThumbnail: boolean = false;
    @Input() showDateBalloon: boolean = false;
    @Input() userThumbnailName: string = "John Doe";
    @Input() userThumbnailTitle: string = "5 hours ago";
    @Input() userThumbnailImage: string = "../../assets/img/users_photo/male/christopher.png";

    //Clinical Image
    @Input() patientDocuments: any;
    @Input() date: Moment;
    @Input() patientID: string = '1111111111';

    @Input() innerText: string;
    @Input() parent: any;

    @Output() updateDiagnoseElements: EventEmitter<any> = new EventEmitter();

    public JSON = JSON;

    public expandComments: boolean = false;
    private _commentText: string = '';
    private _editComment: boolean = false;
    private _editCommentText: string = '';

    private _responseState: UploadState = UploadState.empty;

    private openConversation: boolean = false;
    @Input() set conversationId (conID: any) {
        if (conID) {
            let conDetails = this.messageService.findConversation(conID);
            this.usersModal = true;
            conDetails['userConversation'] = [
                {
                    'patientID': conDetails.patientID,
                    'newMessage': 0,
                    'convId': conDetails.id
                }
            ];
            this.openConversation = true;
            this.chatDetails = conDetails;
            this.chatWithUser = this.usersService.getUser(conDetails.userId);

            setTimeout(()=>{
                document.getElementById('modal').style.top = 70+'px';
            },1);
        }
    }

    @Output() firstIconAction = new EventEmitter();
    @Output() secondIconAction = new EventEmitter();

    TimelineElementType = TimelineElementType;


    usersModal: boolean = false;
    chatDetails: any;

    offset = [0,0];
    isDown = false;

    careTeam: Array<any>;
    chatWithUser: any;

    constructor(private usersService: UsersService, private messageService: MessageService,
                public patientService: PatientService, private _router: Router, private mainService: MainService,
                public documentsService: DocumentsService,private _rootShared: RootShareService,
                private _mockService: MockService, private _apiService: ApiService, private _customFormsService: CustomFormsService) {
        this.careTeam = this.usersService.getCareTeam();
    }

    ngOnInit() {
        if (this.patientDocuments && this.patientDocuments.startDate)
            this.date = this.patientDocuments.startDate;

        if(this.type == TimelineElementType.careTeam)
            this.careTeam = this.usersService.getCareTeam();

        this.assignAvatarsToComments();

        //If there are comments, expand comment box by default
        if (this.patientDocuments && this.patientDocuments.comments && this.patientDocuments.comments.length >= 1)
            this.expandComments = true;
    }

    public open(event, pageDocument, fullsreen, iframeView=false) {
        if(pageDocument.imgLoadSuccess) {
            if (fullsreen) {
                if (pageDocument && pageDocument.imageUrl) {
                    this.fullScreen(pageDocument.imageUrl);
                }
            } else {
                if (pageDocument && pageDocument.imageUrl) {
                    if (iframeView) {
                        // let ratio = window.screen.availWidth / window.screen.availHeight;
                        // let contentDiv = $("#" + pageDocument.id);
                        // let divW = contentDiv.width();
                        // contentDiv.empty();
                        // let iframe = $('<iframe frameborder="0" scrolling="no"></iframe>');
                        // iframe.attr('id', pageDocument.id);
                        // iframe.attr('src', pageDocument.imageUrl);
                        // iframe.height(divW / ratio);
                        // contentDiv.append(iframe);
                        let then = this;
                        let divContent = $(".content");
                        let divIFrameContent = $(".iframe-content");
                        let iFrameEl = $('<iframe id="main-iframe" frameborder="0" scrolling="no"></iframe>');
                        iFrameEl.attr('src', pageDocument.imageUrl);
                        divIFrameContent.css("display","block");
                        divContent.css("display","none");
                        $(".iframe-close").css("display", "block");
                        divIFrameContent.append(iFrameEl);
                        setTimeout(() => {
                            $('[itag="closebrowsertab"]').click(function(){
                                then.mainService.closeIFrame();
                            });

                            let some = $("#reviewToolbar");
                            let a = 1;
                        }, 50000);

                    } else {
                        window.open(pageDocument.imageUrl);
                    }
                }
            }
        }
    }

    public onErrorLoadImg(event, pageDocument){
        $("img#"+pageDocument.id).attr('src','../../../assets/img/sample-document-404.PNG');
        pageDocument.imgLoadSuccess = false;
    }

    public onLoadImg(event, pageDocument){
        pageDocument.imgLoadSuccess = true;
    }

    public openURL(url) {
        window.open(url);
    }

    private fullScreen(src) {
        // noinspection all
        let el = $("<img/>").attr('src', src);
        // noinspection all
        let viewer = new viewerjs.default(el[0], {
            zIndex: 1000000,
            navbar: false,
            title: false,
            toolbar: {
                zoomIn: true,
                zoomOut: true,
                oneToOne: true,
                reset: true,
                rotateLeft: true,
                rotateRight: true,
                flipHorizontal: true,
                flipVertical: true
            },
            hidden: () => {
                viewer.destroy();
            }
        });
        viewer.show();
    }

    getClasses(icon) {
        let classes: Array<string> = [];

        classes.push("unity-icon");
        classes.push("timeline-icon");

        if (icon == 'first')
            classes.push(this.firstIcon);
        else if (icon == 'second')
            classes.push(this.secondIcon);

        return classes;
    }

    showOcularFull = false;
    getMedicationsOcularFull() {
        let a1 = this.getMedicationsOcular();
        let a2 = [];
        if (this.showOcularFull) {
            a2 = this.getMedicationsOcular(true);
        }
        let a3 = [];
        a3 = a3.concat(a1);
        a3 = a3.concat(a2);
        return a3;
    }

    showSystemicFull = false;
    getMedicationsSystemicFull() {
        let a1 = this.getMedicationsSystemic();
        let a2 = [];
        if (this.showSystemicFull) {
            a2 = this.getMedicationsSystemic(true);
        }
        let a3 = [];
        a3 = a3.concat(a1);
        a3 = a3.concat(a2);
        return a3;
    }

    // noinspection all
    getMedicationsOcular(reverse=false) : Array<Medications> {
        if (this.parent) {
            return this.parent.getMedicationsOcular(reverse);
        }
        return [];
    }
    // noinspection all
    getMedicationsSystemic(reverse=false) : Array<Medications> {
        if (this.parent) {
            return this.parent.getMedicationsSystemic(reverse);
        }
        return [];
    }

    checkLoadedImage(event) {
        console.log(event);
    }

    goToTime(date) {
        if (date) {
            let n = moment(date).format("YYYYMMDD");
            let f = $("div[data-when='" + n + "']");
            if (f.length > 0) {
                let where = f.first();
                let o = where.offset();
                let pScroll = $('.patient-timeline-scroll.scroll-right');
                let st = pScroll.scrollTop();
                let nu = o.top + st - 195;
                nu = nu < 0 ? 0 : nu;
                pScroll.animate({ scrollTop: nu }, 500);
            }
        }
    }

    openModal(user){
        let id = this.patientService.currentPatientId;
        console.log(id);
        let conDetails = this.messageService.findChatDetailsFor(user.id, id);
        this._rootShared.unreadNotifications.messages -= conDetails.newMessage;
        if (!this.usersModal)
        this.usersModal = true;
        conDetails['userConversation'] = [
            {
                'patientID': conDetails.patientID,
                'newMessage': 0,
                'convId': conDetails.id
            }
        ];
        this.chatDetails = conDetails;
        this.chatWithUser = this.usersService.getUser(conDetails.userId);

        setTimeout(()=>{
            // document.getElementById('modal').style.top = document.getElementById('care-team-element').offsetTop+'px';
            document.getElementById('modal').style.top = '200px';
        },1);
    }

    modalMouseDown(e) {
        this.isDown = true;
        let div = document.getElementById('modal');
        this.offset = [
            div.offsetLeft - e.clientX,
            div.offsetTop - e.clientY
        ];
    }

    modalMouseMove(event){
        event.preventDefault();
        let div = document.getElementById('modal');
        if (this.isDown) {
            let mousePosition = {

                x : event.clientX,
                y : event.clientY

            };
            div.style.left = (mousePosition.x + this.offset[0]) + 'px';
            div.style.top  = (mousePosition.y + this.offset[1]) + 'px';
        }
    }

    modalMouseUp(event) {
        this.isDown = false;
    }

    closeModal() {
        if (this.openConversation) {
            localStorage.setItem('chatUserID', this.chatDetails.userId);
            localStorage.setItem('chatPatientID', this.chatDetails.patientID);
            this._router.navigate(['messages']);
        }
        this.usersModal = false;
    }

    firstIconClick(e) {
        this.firstIconAction.emit(e);

        this.expandComments = !this.expandComments;
    }

    secondIconClick(e){
        this.secondIconAction.emit(e);
    }

    getClass(r){
        if(r == 1)
            return 'middle';
        if(r == 2)
            return 'high';
    }

    userThumbnailDate() {
        //if date doesn't exist, create fake date
        if (!this.date)
            this.date = moment(new Date(2016, 8, 15));

        let day = this.date.format('DD');
        let month = this.date.format('MMM');
        let year = this.date.format('Y');

        return day + " " + month + " " + year;
    }

    getMonth() {
        let month = this.date.format('MMM').toUpperCase();
        return month;
    }

    getYear() {
        let year = this.date.format('Y');
        return year;
    }

    submitComment() {
        if (this._commentText.length > 2) {
            this.documentsService.addDocumentComment(this.patientID, this.patientDocuments.id, this._commentText)
                .then((response) => {
                        console.log('Comment added successfully');
                        this.reloadDocument();
                    })

            //clear commentText
            this._commentText = '';
        } else {
            //TODO Comment too short notification
            alert("Comment is too short");
        }
    }

    submitEditComment(commentID: string) {
        if (this._editCommentText.length > 2) {
            this.documentsService.editDocumentComment(this.patientID, this.patientDocuments.id, commentID, this._editCommentText)
                .then((response) => {
                        console.log('Comment edited successfully');
                        this.reloadDocument();
                    })

            //clear commentText
            this._editCommentText = '';
            this._editComment = false;
        } else {
            //TODO Comment too short notification
            alert("Comment is too short");
        }
    }

    formatDate2(d) {
        if (d) {
            return moment(d).format('DD/MM/YYYY');
        }
    }
    formatDate(d) {
        let date = moment(d);
        let day = date.format('DD');
        let month = date.format('MMM');
        let year = date.format('YYYY');

        //check if today
        let today = moment();

        if (moment(today).isSame(date, 'day'))
            return "Today " + date.format('hh') + ":" + date.format('mm') + " " + date.format('A');

        return day + " " + month + " " + year;
    }

    reloadDocument() {
        this.documentsService.getDocumentByID(this.patientID, this.patientDocuments.id).then(
            (response) => {
                this.patientDocuments = response;
                this.assignAvatarsToComments();
                this._responseState = UploadState.empty;
            }
        )
    }

    assignAvatarsToComments() {
        if (this.patientDocuments && this.patientDocuments.comments && this.patientDocuments.comments.length > 0) {
            for (let i = 0; i < this.patientDocuments.comments.length; i++) {
                this.getCommentAvatar(this.patientDocuments.comments[i].userId, i);
            }
        }
    }

    getCommentAvatar(userID, element) {
        this._mockService.getData("currentUserMissingFields", userID).then(
            (mockData) => {
                this.patientDocuments.comments[element].commentAvatar = '../../' + mockData.photo;
            }, (err) => {}
        );
    }

    newMessage(newMsg){
        let message = new Message(newMsg,new Date(),MessageStatus.send, this.usersService.getCurrentUser().id);
        this._commentText = message.text;
    }

    approveDiagnose(value, document) {
        this._responseState = UploadState.uploading;

        if ((document.metadata.DIAGNOSE == "true" && value == true) || (document.metadata.DIAGNOSE == "false" && value == false)) {
            //Add new referral
            let referralForm: ReferralForm = new ReferralForm();
            referralForm.patientId = this.patientID;
            referralForm.userId = this.usersService.getCurrentUser().id;
            referralForm.carePathway.isIgnored = true;
            //referralForm.diagnose.isConfirmed = value;
            referralForm.when = document.startDate;
            referralForm.status = "save";
            referralForm.documentId = document.id;
            referralForm.documentThumbnail = document.thumbPage1;

            if (value) {
                referralForm.diagnose.isConfirmed = true;
            } else {
                referralForm.diagnose.isIgnored = true;
            }

            if (document.metadata.DIAGNOSE == "true") {
                referralForm.diagnose.watsonDiagnosisEvidence = "Watson Image Analysis have detected probable Retinopathy.";
                referralForm.diagnose.watsonDifferentialDiagnosis  = "Retinopathy detected. Grading required";
                referralForm.diagnose.diagnosis = "Retinopathy detected. Grading required";
            } else {
                referralForm.diagnose.watsonDifferentialDiagnosis  = "Retinopathy Grade 0, No Changes.";
                referralForm.diagnose.watsonDiagnosisEvidence = "Watson Image Analysis have not detected probable Retinopathy.";
                referralForm.diagnose.diagnosis = "Retinopathy Grade 0, No Changes.";
            }

            this._customFormsService.saveForm(referralForm).then(
                (response)=> {
                    this.updateDiagnoseElements.emit('referrals');
                }, (err) => {
                    console.log(err);
                }
            );
        }

        //Approve diagnose in Document
        let data: any = {};
        data['accept'] = value;

        this._apiService.post('patients/' + this.patientID + '/documents/' + document.id + '/acceptDiagnose', data).then(
            (response) => {
                this.updateDiagnoseElements.emit('document');
            }, (err) => {
                this._responseState = UploadState.uploadFailed;
            }
        );
    }
}

import {Injectable} from "@angular/core";
import {Observable} from "rxjs/index";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {trigger} from "@angular/animations";
import _ from 'lodash';
import {MockService} from "./mock.service";
import {UsersService} from "./user.service";
/**
 * Created by Magdalena on 09.05.2018.
 */

export class Conversation {

    messages: Array<Message>;
    conversationId: string;

    static fromJSON<T>(json: any): any{
        let basicObj = Object.create(this.prototype);
        return Object.assign(basicObj, json);
    }
}

export enum MessageStatus {
    send,
    readed
}
export class Message {
    text: string;
    data: Date;
    status: MessageStatus;
    authorID: string;

    constructor(text,data, status, author) {
        this.text = text;
        this.data = data;
        this.status = status;
        this.authorID = author;
    }
}

@Injectable()
export class MessageService {

    getMessagesForChat(chatId: string) {
        let selectedConversation;

        for (let i = 0; i< this.chats.length; i++) {
            if (chatId ==  this.chats[i].conversationId) {
                selectedConversation = this.chats[i];
            }
        }

        return new Promise((resolve, reject) => {
            let j = Conversation.fromJSON(selectedConversation);
            resolve(j)
        })
    }

    public chats: Array<any>;

    constructor(public mockService:MockService, private userService: UsersService) {
        this.mockService.getData("chatsList", '1').then(
            (chats) => {
                console.log(chats);
                this.conversationList = chats['chatsList'];
                this.countNewMessage();

            },
            (error) => {

            }
        );
        this.mockService.getData("chats", '1').then(
            (chats) => {
                console.log(chats);
                this.chats = chats['chats'];
            });
    }

    findConversation(conId){

        for (let i = 0 ; i < this.conversationList.length; i++) {
            if(conId == this.conversationList[i].id)
                return this.conversationList[i];
        }
    }

    resetNewMessageFor(conId) {
        let con = this.findConversation(conId);
        con.newMessage = 0;
    }

    findChatDetailsFor(idUser, patientID) {
        let conversationId;
        for(let i = 0 ; i < this.conversationList.length; i++){
            if (this.conversationList[i].userId == idUser && this.conversationList[i].patientID == patientID) {
                conversationId = this.conversationList[i].id;
            }
        }
        if(conversationId)
            return this.findConversation(conversationId);
        else
            return this.createNewConversation(idUser,patientID);
    }

    createNewConversation(idUser,patientID) {
        let newChatId = this.conversationList.length;
        let newConDetails = {
            'id': newChatId,
            'userId': idUser,
            'patientID': patientID,
            'newMessage': 0,
            'isGroupChat': false,
            'usersInGroup': [],
            'countAllNewMessage': '0',
            'userConversation' : [
                {
                    'patientID': patientID,
                    'newMessage': 0,
                    'convId': newChatId
                }
            ]
        };
        this.conversationList.push(newConDetails);

        let newChart = {
            "conversationId": newChatId,
            "messages": []
        };
        this.chats.push(newChart);
        return newConDetails;
    }
    createNewConversationGroup(idUsers: Array<any>,patientID) {
        let newChatId = this.conversationList.length;
        console.log(newChatId);
        let newConDetails = {
            'id': newChatId,
            'userId': '5acb514214969132a0fad927',
            'patientID': patientID,
            'newMessage': 0,
            'isGroupChat': true,
            'usersInGroup': idUsers,
            'countAllNewMessage': '0',
            'userConversation' : [
                {
                    'patientID': patientID,
                    'newMessage': 0,
                    'convId': newChatId
                }
            ]
        };
        this.conversationList.push(newConDetails);

        let newChart = {
            "conversationId": newChatId,
            "messages": []
        };
        this.chats.push(newChart);
        return newConDetails;
    }

    addMessage(message: any, id) {
        let selectedConversation;

        console.log(this.chats,id);
        selectedConversation = this.chats[id].messages;
        selectedConversation.push(message);
    }

    public  conversationList: Array<any> = [];

    public newMessageForAllChats = 0;
    countNewMessage(){
        for(let i = 0; i < this.conversationList.length; i++) {
            this.newMessageForAllChats += this.conversationList[i].newMessage;
        }
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Array<any>> {

        return new Promise((resolve, reject) => {
            resolve(this.conversationList);

        })

    }
}
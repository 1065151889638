import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ConversationDetailsComponent} from "../../components/user-chat/user-chat.component";
import {User, UsersService} from "../../services/user.service";
import * as _ from 'lodash';
import {PatientData} from "../../models/patient.model";
import {PatientService} from "../../services/patient.service";
import {MessageService} from "../../services/messages.service";
import {RootShareService} from "../../services/root.shared.service";

@Component({
  templateUrl: './messages.module.html'
})
export class MessagesModule {

  private chatTitle: string = 'Group Chat';
  private _chatsDetailsList: Array<any>;
  private _chatsDetailsAllList: Array<any>;
  private chatDetailsToOpen: any;
  private chatWith: User;
  private groupChatWith: Array<User>;
  viewByGroupChat: boolean = false;

  usersModal: boolean = false;
  allUsers: Array<User>;

  private patient: any;

  offset = [0,0];
  isDown = false;

  constructor(private _route: ActivatedRoute, private usersService: UsersService,private _router: Router,
              private messageService: MessageService, private _rootShared: RootShareService) {}

  selectUserConversation(conversationList: Array<any>){
    let tests: Array<any> = [];
    let testSets:any = {};
    conversationList.forEach((convesation)=>{
      let set =  _.uniqueId('setId');
      if(convesation.hasOwnProperty('userId') && convesation.hasOwnProperty('isGroupChat')){
        set = convesation.userId+convesation.isGroupChat;
      }
      if(!testSets.hasOwnProperty(set)){
        testSets[set] = [];
      }
      testSets[set].push(convesation);
    });
    Object.keys(testSets).forEach((testSet) => {
      let resultTest = {};
      _.extend(resultTest,testSets[testSet][0]);

      resultTest['userConversation'] = [];
      resultTest['countAllNewMessage'] = 0;

      testSets[testSet].forEach((oneTest) => {
        resultTest['countAllNewMessage'] += oneTest['newMessage'];
        resultTest['userConversation'].push({
          patientID: oneTest['patientID'],
          newMessage: oneTest['newMessage'],
          convId: oneTest['id']
        });
      });
      tests.push(resultTest);
    });
    console.log(tests);
    return tests;
  }

  ngOnInit() {
    this._route.data.subscribe(
        data => {
          this._chatsDetailsList = this.selectUserConversation(data['messageList']);
          this._chatsDetailsAllList = this.selectUserConversation(data['messageList']);

          if ( localStorage.getItem('chatUserID')) {
            let chat = localStorage.getItem('chatUserID');

            for (let i = 0; i < this._chatsDetailsList.length; i++) {
              if (this._chatsDetailsList[i].userId == chat && !this._chatsDetailsList[i].isGroupChat ) {
                localStorage.removeItem('chatUserID');
                this.openNewChat(this._chatsDetailsList[i]);
                ConversationDetailsComponent.openChatDetails = this._chatsDetailsList[i];
                break;
              }
            }

          } else {
            for (let i = 0; i < this._chatsDetailsList.length; i++) {
              if (this._chatsDetailsList[i].countAllNewMessage == 0 && !this._chatsDetailsList[i].viewByGroupChat) {
                this.openNewChat(this._chatsDetailsList[i]);
                ConversationDetailsComponent.openChatDetails = this._chatsDetailsList[i];
                break;
              }
            }
          }

          //assign unread messages to rootSharedService
        },
        error => {
        }
    );
  }

  openNewChat(chatDetails) {

    this.closeModal();
    this.chatDetailsToOpen = chatDetails;
    this.groupChatWith = [];

    if (this.chatDetailsToOpen.isGroupChat) {
      this.chatWith = this.usersService.getUser(chatDetails.userId);
      this.chatTitle = 'Group Chat';

      if(chatDetails.usersInGroup) {
        this.groupChatWith = this.usersService.getUsers(chatDetails.usersInGroup);
        console.log(this.groupChatWith);
      }

    } else {
      this.chatWith = this.usersService.getUser(chatDetails.userId);
      this.chatTitle = 'Chatting with ' + this.chatWith.name;
    }
  }


  createAGroup() {
    let allUsers = this.usersService.getAllUsers();
    let userToAdd: Array<User> = [];
    for (let i = 0; i <allUsers.length; i++) {
      if (!this.containsObject(allUsers[i], this.groupChatWith) &&
          allUsers[i].id != this.chatWith.id && allUsers[i].id != '20') {
        userToAdd.push(allUsers[i]);
      }
    }
    this.allUsers = userToAdd;
    this.usersModal = !this.usersModal;
  }


  containsObject(obj, arr) {
    let i;
      for (i = 0; i < arr.length; i++) {
        if (arr[i].id == obj.id) {
          return true;
        }
      }
    return false;
  }


  modalMouseDown(e) {
    this.isDown = true;
    let div = document.getElementById('modal');
    this.offset = [
      div.offsetLeft - e.clientX,
      div.offsetTop - e.clientY
    ];
  }

  modalMouseMove(event){
    event.preventDefault();
    let div = document.getElementById('modal');
    if (this.isDown) {
      let mousePosition = {

        x : event.clientX,
        y : event.clientY

      };
      div.style.left = (mousePosition.x + this.offset[0]) + 'px';
      div.style.top  = (mousePosition.y + this.offset[1]) + 'px';
    }
  }

  modalMouseUp(event) {
    this.isDown = false;
  }

  createNewGroupWith(user) {
    if (this.chatDetailsToOpen.isGroupChat) {
      let index = this.allUsers.indexOf(user);
      this.allUsers.splice(index,1);
      this.groupChatWith.push(user);
    } else {
      this.viewByGroupChat = true;
      let newGroup = this.messageService.createNewConversationGroup([user.id,this.chatWith.id],this.patient.__id);
      this._chatsDetailsList.push(newGroup);
      ConversationDetailsComponent.openChatDetails = newGroup;
      this.openNewChat(newGroup);
    }
  }

  closeModal() {
    this.usersModal = false;
  }

  searchUser(searchText) {

    this._chatsDetailsList = this._chatsDetailsAllList;

    if (searchText != '') {

      let rex = new RegExp(searchText,'gi');

      this._chatsDetailsList = _.filter(this._chatsDetailsList, (chatDetalis) => {
        let user = this.usersService.getUser(chatDetalis.userId);
          if (user.name.match(rex)) {
            return true;
          }
          else {
            return false;
          }
        });

    }
  }

  openPatient(e){
    let convID = 0;
    console.log(this.chatDetailsToOpen);
    for(let i = 0 ; i < this.chatDetailsToOpen.userConversation.length; i++) {
      if (Number(this.chatDetailsToOpen.userConversation[i].patientID) == this.patient.__id) {
        convID = this.chatDetailsToOpen.userConversation[i].convId;
        console.log(convID);
      }
    }
    //console.log(this.patient);
    this._router.navigate(['patient',this.patient.__id,'timeline'], { queryParams: { con: convID } });

  }

  selectNewPatient(patient) {
     this.patient = patient;
  }
}

import {JsonProperty} from "./mapUtil";

export class DashboardData {
    news: Array<string>;
    @JsonProperty('latest-patients')
    latestPatients: number;
    referrals: number;
    @JsonProperty('new-messages')
    newMessages: number;
    tasks: number;

    public constructor(){
        this.news = undefined;
        this.latestPatients = undefined;
        this.referrals = undefined;
        this.newMessages = undefined;
        this.tasks = undefined;
    }
}
import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {AuditDetailsType} from "../../../../models/forms/referral/audit.details.type";

@Component({
    selector: 'audit-component',
    templateUrl: './audit.html'
})
export class AuditComponent {
    @Input() audit: AuditDetailsType;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

import {PatientData} from "./patient.model";

export class TaskDescription {
    workItemID: string;
    assignee: string;
    clinicCode: any;
    assigneeName: string;
    startTime: string;
    startTimeEpoch: number;
    nodeTitle: string;
    nodeDescription: string;
    workflowId: string;
    workflowTitle: string;
    workflowDesc: string;
    formData: FormData;
    patientID: string;
    formURL: string;
    priority: string;
    patient: PatientData;
    expand: boolean = false;
    isClaimed: boolean;
    claimable: boolean;
    dueTime: string;

    static fromJson(json: any) : any {
        Object.assign(this, json);
        return this;
    }
}

export class FormData {
    PatientDetails: any;
}

export class MyGroups {
    name: string;
    userName: string;
    groups: Array<Group>;
    countTask: number = 0;
    isOpen: boolean = true;

    fromJson(json: any) : any {
        Object.assign(this, json);
        return this;
    }
}


export class Group {
    groupName: string;
    groupID: string;
    membersCount: number;
    taskCount: number;
    isSelect: boolean = false;
    members: Array<UserInGroup>;
}

export class UserInGroup {
    userID: string;
    userName: string;
    groups: any;

}

export class TaskRenderRequest  {
    workflowItemID:string;
    urlOnly:boolean;
}
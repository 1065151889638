export class OcularMotilityAndMuscleBalance {
    testName = "OcularMotilityAndMuscleBalance";

    extraocularMovementsOD: string;
    extraocularMovementsOS: string;

    public constructor() {
        this.extraocularMovementsOD = null;
        this.extraocularMovementsOS = null;
    }
}

import {Component, EventEmitter, Input, Output} from "@angular/core";
import * as moment from "moment";
import * as $ from 'jquery';
import {NoteForm} from "../../models/forms/note/note.form";
import {Http} from "@angular/http";
import {ApiService} from "../../services/api.service";

export enum UploadState {
    empty,
    uploading,
    uploadFailed,
    uploadSuccess
}

@Component({
    selector: 'referral-image-component',
    templateUrl: './referral-image.element.component.html',
    styleUrls: ['./referral-image.element.component.scss']
})
export class ReferralImageComponent {
    @Input() title: string = "Retinopathy";
    @Input() firstIcon: string = "";
    @Input() secondIcon: string = "";

    @Input() userThumbnail: boolean = false;
    @Input() showDateBalloon: boolean = false;
    @Input() userThumbnailName: string = "John Doe";
    @Input() userThumbnailTitle: string = "5 hours ago";
    @Input() userThumbnailImage: string = "../../assets/img/users_photo/male/christopher.png";

    @Input() parent: any;

    @Input() patient: any;
    @Input() patientDocument: any;

    @Input() noteForm: NoteForm = new NoteForm();

    @Output() firstIconAction = new EventEmitter();
    @Output() secondIconAction = new EventEmitter();
    @Output() cancelImageUpload = new EventEmitter();

    selectedDocType = "Other";
    selectedFiles = null;
    selectedDate: Date;

    uploadStateEnum = UploadState;
    uploadState: UploadState = UploadState.empty;

    constructor(private _http: Http, private _apiService: ApiService) {}

    getClasses(icon) {
        let classes: Array<string> = [];

        classes.push("unity-icon");
        classes.push("timeline-icon");

        if (icon == 'first')
            classes.push(this.firstIcon);
        else if (icon == 'second')
            classes.push(this.secondIcon);

        return classes;
    }

    userThumbnailDate() {
        let d = moment();
        let day = d.format('DD');
        let month = d.format('MMM');
        let year = d.format('Y');

        return day + " " + month + " " + year;
    }

    getMonth() {
        let month = moment().format('MMM').toUpperCase();
        return month;
    }

    getYear() {
        let year = moment().format('Y');
        return year;
    }

    // cancelNote() {
    //     if (this.parent) {
    //         this.parent.removeNote(this.noteForm);
    //     }
    // }

    selectFile(e) {
        this.selectedFiles = e.target.files;
    }

    cancelUpload() {
        $("#selectFileToUpload").val('');
        this.selectedFiles = null;
        this.cancelImageUpload.emit();
    }

    uploadDocument() {
        if (this.selectedFiles && this.selectedFiles.length > 0) {
            // do upload
            this.uploadState = UploadState.uploading;
            let formData: FormData = new FormData();
            formData.append("repositoryId", "SRC_DCTM");
            formData.append("hospitalNumber", this.patient.hospitalNumber);
            formData.append("startDate", this.selectedDate.toString());
            formData.append("docType", this.selectedDocType);
            formData.append("diagnose", "true");
            for (let file of this.selectedFiles) {
                formData.append('file', file, file.name);
            }

            let that = this;

            let apiUrl:[boolean,string] = this._apiService.buildUrl('documents');
            this._http.post(apiUrl[1], formData, {withCredentials: true})
                .map((res) => [res.status, res.json()])
                .subscribe(
                    data => {
                        let response = data[1];
                        console.log("Document uploaded");
                        this.uploadState = UploadState.uploadSuccess;

                        //that.cancelUpload();
                        // clear this file!
                        this.selectedFiles = null;
                        $("#selectFileToUpload").val('');
                    },
                    err => {
                        console.log("Document upload failed");
                        this.uploadState = UploadState.uploadFailed;
                    }
                );
        }
    }

    refreshPage() {
        window.location.reload();
    }
}
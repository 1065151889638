import { Injectable } from "@angular/core";
import * as $ from 'jquery';

@Injectable()
export class MainService {
    constructor(){
        $("#app-loading").hide();
    }

    public showLoading(){
        $("#app-loading").show();
    }

    public hideLoading(){
        $("#app-loading").hide();
    }

    public closeIFrame(){
        $("#main-iframe").remove();
        $(".content").css("display", "block");
        $(".iframe-content").css("display", "none");
        $(".iframe-close").css("display", "none");
    }
}
import {Injectable} from "@angular/core";
import {EnvironmentClass, mandatoryEvnKeys} from "../environment.class";
import {Http} from "@angular/http";
import {LoggerLevel} from "./logger.interface";
import {ApiService} from "./api.service";
import {RootShareService} from "./root.shared.service";

@Injectable()
export class EnvironmentProviderService {
    private _environment: EnvironmentClass;

    constructor(private http: Http, private _apiService: ApiService, private _rootShareService: RootShareService) {
        this._environment = new EnvironmentClass();
    }

    get environment() {
        return this._environment;
    }

    private static parseLogLevel(levelName:string):LoggerLevel{
        switch(levelName){
            case 'ERROR': {
                return LoggerLevel.ERROR;
            }
            case 'WARNING': {
                return LoggerLevel.WARNING;
            }
            case 'INFO': {
                return LoggerLevel.INFO;
            }
            case 'DEBUG': {
                return LoggerLevel.DEBUG;
            }
            case 'ALL': {
                return LoggerLevel.ALL;
            }
            default: {
                console.log('no set log level in config, return default: LoggerLevel.ALL');
                return LoggerLevel.ALL
            }
        }
    }

    private static parseEnvironmentObject(inputObject:any):[boolean, EnvironmentClass]{
        if(inputObject.hasOwnProperty('logLevel')){
            inputObject.logLevel = EnvironmentProviderService.parseLogLevel(inputObject.logLevel);
        }
        // if(inputObject.hasOwnProperty('loginAuth')){
        //     inputObject.loginAuth = EnvironmentProviderService.parseAuthType(inputObject.loginAuth);
        // }
        let env:EnvironmentClass = new EnvironmentClass();
        let parseResult:boolean = true;
        for(let key in env){
            if(inputObject.hasOwnProperty(key)){
                if(typeof env[key] != typeof inputObject[key]){
                    parseResult = false;
                    console.error('Incorrect config type in key: ' + key);
                } else {
                    env[key] = inputObject[key];
                }
            } else {
                if(key in mandatoryEvnKeys) {
                    parseResult = false;
                    console.error('Incorrect config, missing a mandatory key: ' + key);
                }
                console.log('Config:' + key + ' set to default : ' + env[key]);
            }
        }
        return [parseResult, env]
    }

    public getEnvironmentData() : Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let v = Date.now();
                //this.http.get('config/config.json?v=' + v)
                this.http.get('../src/config/config.json')
                    .map(res => res.json())
                    .subscribe(
                        data => {
                            try {
                                let parseEnv: [boolean, EnvironmentClass] = EnvironmentProviderService.parseEnvironmentObject(data);
                                if (parseEnv[0]) {
                                    this._environment = parseEnv[1];
                                    this._apiService.initEnvironment(this._environment);
                                    this._rootShareService.initEnvironment(this._environment);
                                    //this._logger.initLevel(this._environment);
                                    resolve(true);
                                }
                            } catch (err) {
                                console.log(err);
                            }
                        },
                        err => {
                            console.log(err);
                        },
                        () => {
                        }
                    );
            }
            catch (e) {
                console.log('Exception:' + (<Error>e).message)
            }
        });
    }
}
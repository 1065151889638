import {Address} from "./address.model";
import {JsonProperty} from "./mapUtil";
import {GpData} from "./gp.model";
import {RootShareService} from "../services/root.shared.service";
import DateTimeFormat = Intl.DateTimeFormat;
import {Phone} from "./phone.model";

export enum Gender {
    male, female
}

export class PatientData {
    @JsonProperty('__id')
    _id: string;
    title: string;
    firstName: string;
    middleNames: string;
    familyName: string;
    emplacement: string;
    gender: Gender;
    homePhone: string;
    emailAddress: string;
    photo: string;
    dob: string;
    nhsNumber: string;
    hospitalNumber: string;

    @JsonProperty('phoneNumbers')
    phoneNumbers: Array<Phone>;
    @JsonProperty('address')
    addresses: Array<Address>;
    @JsonProperty('gp')
    gp: GpData;

    public constructor(){
        this._id = "";
        this.title = "";
        this.firstName = "";
        this.familyName = "";
        this.gender = undefined;
        this.phoneNumbers = undefined;
        this.homePhone = "";
        this.emailAddress = "";
        this.photo = "";
        this.addresses = undefined;
        this.dob = "";
        this.gp = undefined;
        this.nhsNumber = undefined;
        this.hospitalNumber = undefined;
    }

    static fromJSON2<T>(json: any, rootShareService: RootShareService): any{
        let basicObj = Object.create(this.prototype);
        //basicObj._dateFormat = rootShareService.environment.dateFormat;
        return Object.assign(basicObj, json);
    }

    get fullName(): string {
        return this.firstName + ", " + this.familyName + " (" + this.title + ")";
    }

    get fullAddress(): string {
        //return this.address.firstLine + ", " + this.address.secondLine + ", " + ", " + this.address.city + ", " + this.address.postal;
        return this.addresses[0].addressLines[0] + ", " + this.addresses[0].addressLines[1] + ", " + this.addresses[0].postalCode;
    }

    get id(): string {
        let id = this.id;
        id = id.replace(/\//g, "_$_");
        id = id.replace(/\\/g, "_$$_");
        return id;
    }

    get parsedDOB(): string {
        let year = this.dob.substr(0,4);
        let month = this.dob.substr(5,2);
        let day = this.dob.substr(8,2);

        return day + "/" + month + "/" + year;
    }

    get actualAge(): number {
        let today = new Date();
        let birthDate = new Date(this.dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
}
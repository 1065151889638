import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../../services/auth.service";
import {RootShareService} from "../../../../../services/root.shared.service";
import {VisualAcuity} from "../../../../../models/forms/referral/visual.acuity";

@Component({
    selector: 'visual-acuity-component',
    templateUrl: './visual.acuity.html'
})
export class VisualAcuityComponent {
    @Input() visualAcuity: VisualAcuity;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

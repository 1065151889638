export class GpAction {
    // Patient asked to telephone/visit GP
    // Patient sent to Eye Casualty
    // Advise Referral to Eye Dept (URGENT)
    // Advise Referral to Eye Dept (Routine)
    //
    PatientAskedTelephone: boolean;
    PatientSentEyeCasualty: boolean;
    AdviseReferralEyeDeptURGENT: boolean;
    AdviseReferralEyeDeptRoutine: boolean;

    public constructor(){
        this.PatientAskedTelephone = false;
        this.PatientSentEyeCasualty = false;
        this.AdviseReferralEyeDeptURGENT = false;
        this.AdviseReferralEyeDeptRoutine = false;
    }
}

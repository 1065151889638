import {ApiService} from "../../services/api.service";
/**
 * Created by mstolarczyk on 2016-12-15.
 */
export class BaseClass {
    __id: string;
    __href:string = "";
    __version: string = "";
    __type:string = "";

    constructor(private _apiService:ApiService){}

    /**
     * This methods allows to create object from JSON
     * @param json JSON object - this is not a string but you should use something like JSON.parse(data)
     * @returns {Dictionary} Returns newly created dictionary
     */
    static fromJSON<T>(json: any): any{
        let basicObj = Object.create(this.prototype);
        return Object.assign(basicObj, json);
    }

    static convert<T>(object: any): any{
        return Object.create(this.prototype, object);
    }

    /**
     * This method allows to convert json formatted dictionary into this object
     * @returns {Dictionary} Returns this allowing for chaining.
     */
    fromJson(json: any) : any {
        Object.assign(this, json);
        return this;
    }

    fromHref(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if(this.__href.length){
                this._apiService.get(this.__href).then((resObject)=>{
                    if(resObject){
                        Object.assign(this, resObject);
                        resolve(this);
                    } else {
                        reject('No content');
                    }
                }, (error)=>{
                    reject(error);
                })
            } else {
                reject('object not exist')
            }
        });
    }
}


import {Injectable} from '@angular/core';
import {EnvironmentClass} from "../environment.class";

export class UnreadNotifications {
    clinics: number;
    referrals: number;
    messages: number;
    tasks: number;
}

@Injectable()
export class RootShareService {
    private _indicatorLostConnection: boolean = false;
    private _environment: EnvironmentClass;
    public unreadNotifications: UnreadNotifications = new UnreadNotifications();

    set indicatorLostConnection(showLostConnection: boolean) {
        this._indicatorLostConnection = showLostConnection;
    }

    get indicatorLostConnection() {
        return this._indicatorLostConnection;
    }

    showIndicatorLostConnection() {
        this._indicatorLostConnection = true;
    }

    hideIndicatorLostConnection() {
        this._indicatorLostConnection = false;
    }

    initEnvironment(env:EnvironmentClass){
        this._environment = env;
    }
}
export class FundasExam {
    testName = "FundasExam";

    descriptionOD: string;
    descriptionOS: string;
    discOD: string;
    maculaOD: string;
    vesselsOD: string;
    peripheryOD: string;
    discOS: string;
    maculaOS: string;
    vesselsOS: string;
    peripheryOS: string;

    public constructor(){
        this.descriptionOD = null;
        this.descriptionOS = null;
        this.discOD = null;
        this.maculaOD = null;
        this.vesselsOD = null;
        this.peripheryOD = null;
        this.discOS = null;
        this.maculaOS = null;
        this.vesselsOS = null;
        this.peripheryOS = null;
    }
}

import {Injectable} from "@angular/core";
import * as _ from "lodash";
import {Dictionary, DictionaryInterface, DictionaryItem} from "../utils/definitions/Dictionary";
import {LoggerLevel} from "./logger.interface";
import {ApiService} from "./api.service";
import {Consultant} from "../utils/definitions/Consultant";
import {GP} from "../utils/definitions/GP";
import {Clinic} from "../utils/definitions/Clinic";
import {Ward} from "../utils/definitions/Ward";
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";
import {RootShareService} from "./root.shared.service";
import {Specialty} from "../utils/definitions/Specialty";
import {InvestigationDepartment} from "../utils/definitions/InvestigationDepatrment";
import {MockService} from "./mock.service";
import {dictionaryFilter, DictResponse, DictItem, DictRequestOptions} from "../models/dict.utils.model";

/**
 * Service for working with dictionaries and elements accessible for filters
 */
@Injectable()
export class DictionaryService {
    private _filter:string = "";
    constructor(private apiService: ApiService, private _router:Router,
                private _mockService: MockService) {}

    fetchTabs(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.get("tabs").then(
                (response) => {
                    if (response && response.__data) {
                        resolve(response.__data);
                    }
                },
                (failed) => {
                    if(failed.status == 401) {
                        console.log("Response_Error_401");
                        this._router.navigate([''])
                    }
                }
            )
        });
    }

    getClinic(id:string):Promise<DictionaryItem>{
        return new Promise((resolve, reject) => {
            this.apiService.get("clinics/" + id).then(
                (response) => {
                    let result = DictionaryItem.createWithData(response.description, response.clinicCode, response);
                    resolve(result);
                },
                (failed) => {
                    if(failed.status == 401) {
                        console.log("Response_Error_401");
                        this._router.navigate([''])
                    }
                });
        })
    }

    getConsult(id:string):Promise<DictionaryItem>{
        return new Promise((resolve, reject) => {
            this.apiService.get("consultants/" + id).then(
                (response) => {
                    let result = DictionaryItem.createWithData(response.familyName + " "+response.firstName, response.consultantCode? response.consultantCode : response.__id, response);
                    resolve(result);
                },
                (failed) => {
                    if(failed.status == 401) {
                        console.log("Response_Error_401");
                        this._router.navigate([''])
                    }
                });
        })
    }

    getWards(id:string, type: number):Promise<DictionaryItem>{
        if(type == 0){
            return new Promise((resolve, reject) => {
                this.apiService.get("wards/"+ id).then(
                    (response) => {
                        let result;
                        if (response != null) {
                            result = DictionaryItem.createWithData(response.wardName, response.wardCode ? response.wardCode : response.__id, response);
                        }
                        resolve(result);
                    },
                    (failed) => {
                        if(failed.status == 401) {
                            console.log("Response_Error_401");
                            this._router.navigate([''])
                        }
                    });
            })
        } else {
            return new Promise((resolve, reject) => {
                let s = "_id eq '"+id+"'";
                let f = encodeURI(s);
                this.apiService.get("$dict/Specialties?$filter=" + f).then(
                    (response) => {
                        let specialty = response.__data[0];
                        let result = DictionaryItem.createWithData(specialty.__description, specialty.__code? specialty.__code: specialty.__id, specialty);
                        resolve(result);
                    },
                    (failed) => {
                        if(failed.status == 401) {
                            console.log("Response_Error_401");
                            this._router.navigate([''])
                        }
                    });
            })
        }

    }

    findClinicsByConsultant(filter: dictionaryFilter):Promise<DictResponse>{

        let data: any = {};
        data['consultantId']=filter.id;
        data['dateFrom'] = filter.dataFrom;
        data['dateTo'] = "2019-01-01";
        //data['clinicCode'] = null;
        //"clinicCode":"0LUCPH3P"

        return new Promise((resolve, reject) => {
            this.apiService.post("clinics/findClinicsByConsultant", data).then(
                (response) => {
                   // console.log(response);
                    let result = new DictResponse();
                    result.data = [];
                    _.forEach(response.__data, (itemJson) => {
                        result.data.push(new DictItem(itemJson['id'], itemJson['code'], itemJson['description']));
                    });
                    result.page = 1;
                    result.pageSize = result.data.length;
                    result.pageCount = 1;
                    result.itemsCount = result.data.length;

                  resolve(result);
                },
                (failed) => {
                    if(failed.status == 401) {
                        console.log("Response_Error_401");
                        this._router.navigate([''])
                    }
                });
        })
    }

    findConsultantsByClinic(filter: dictionaryFilter):Promise<DictResponse>{

        let data: any = {};
        data['clinicCode']=filter.id;
        data['dateFrom'] = filter.dataFrom;
        data['dateTo'] = "2019-01-01";
        //data['clinicCode'] = null;
        //"clinicCode":"0LUCPH3P"

        return new Promise((resolve, reject) => {
            this.apiService.post("consultants/findConsultantsByClinic?$sort=familyName,firstName", data).then(
                (response) => {
                    console.log('cons',response);
                    let result = new DictResponse();
                    result.data = [];
                    _.forEach(response.__data, (itemJson) => {
                       // let consultant = new Consultant(this.apiService);
                       // result.data.push(consultant.fromJson(itemJson));
                        result.data.push(new DictItem(itemJson['id'], itemJson['code'], itemJson['familyName']));
                    });
                    result.page = 1;
                    result.pageSize = result.data.length;
                    result.pageCount = 1;
                    result.itemsCount = result.data.length;

                    resolve(result);
                },
                (failed) => {
                    if(failed.status == 401) {
                        console.log("Response_Error_401");
                        this._router.navigate([''])
                    }
                });
        })
    }

    fetchPageDictionary(name:string, options?:DictRequestOptions, searchFilter?:string): Promise<DictResponse>{
        if(!options){
            options = new DictRequestOptions();
        }
        switch(name){
            case "Consultants": {
                if(searchFilter && searchFilter.length > 0){
                    let searchParts:Array<string> = searchFilter.trim().split(" ");
                    switch(searchParts.length) {
                        case 2:
                            options.$filter = _.replace(Consultant.getFullNameFilterString(), new RegExp("###1", "g"), searchParts[0]);
                            options.$filter = _.replace(options.$filter, new RegExp("###2", "g"), searchParts[1]);
                            break;
                        case 3:
                            options.$filter = _.replace(Consultant.getFullNameAndCodeFilterString(), new RegExp("###1", "g"), searchParts[0]);
                            options.$filter = _.replace(options.$filter, new RegExp("###2", "g"), searchParts[1]);
                            options.$filter = _.replace(options.$filter, new RegExp("###3", "g"), searchParts[2]);
                            break;
                        default:
                            options.$filter = _.replace(Consultant.getFilterString(), new RegExp("###", "g"), searchFilter.trim());
                            break;
                    }
                }else {
                    options.$filter = Consultant.getInactiveFilter();
                }
                options.$sort = Consultant.getDefaultSort();
                return this.fetchConsultants(options);
            }
            case "GP": {
                if(searchFilter && searchFilter.length > 0){
                    options.$filter = _.replace(GP.getFilterString(),new RegExp("###","g"),searchFilter);
                }
                return this.fetchGPs(options);
            }
            case "Clinics": {
                if(searchFilter && searchFilter.length > 0){
                    options.$filter = _.replace(Clinic.getFilterString(),new RegExp("###","g"),searchFilter);
                }
                return this.fetchClinics(options);
            }
            case "Wards": {
                if(searchFilter && searchFilter.length > 0){
                    options.$filter = _.replace(Ward.getFilterString(),new RegExp("###","g"),searchFilter);
                }
                return this.fetchWards(options);
            }
            default: {
                if(searchFilter && searchFilter.length > 0){
                    options.$filter = _.replace(Dictionary.getFilterString(),new RegExp("###","g"),searchFilter);
                }
                return new Promise((resolve, reject) => {
                    this.apiService.get("$dict/" + name, options).then(
                        (response) => {
                            let result = new DictResponse();
                            result.data = [];
                            if(response) {
                                result.page = response['__pageNumber'];
                                result.pageSize = response['__pageSize'];
                                result.pageCount = response['__pageCount'];
                                result.itemsCount = response['__size'];
                                _.forEach(response.__data, (itemJson) => {
                                    result.data.push(new DictItem(itemJson['__id'], itemJson['__code'], itemJson['__description']));
                                });
                            } else {
                                result.page = 1;
                                result.pageSize = 1;
                                result.pageCount = 1;
                                result.itemsCount =0;
                            }
                            resolve(result);
                        },
                        (failed) => {
                            if(failed.status == 401) {
                                console.log("Response_Error_401");
                                this._router.navigate([''])
                            }
                        }
                    )
                });
            }
        }
    }
    //deprecated
    fetchWholeDictionary(name: string): Promise<DictResponse> {
        switch(name){
            case "Consultants": {
                return this.fetchConsultants();
            }
            case "GP": {
                return this.fetchGPs();
            }
            case "Clinics": {
                return this.fetchClinics();
            }
            case "InvestigationDepartments": {
                console.debug("Use specific method to get that dictonary");
                return null;
            }
            default: {
                return new Promise((resolve, reject) => {
                    this.apiService.get("$dict/" + name).then(
                        (response) => {
                            let result = new DictResponse();
                            result.data = [];
                            if(response) {
                                result.page = response['__pageNumber'];
                                result.pageSize = response['__pageSize'];
                                result.pageCount = response['__pageCount'];
                                result.itemsCount = response['__size'];
                                _.forEach(response.__data, (itemJson) => {
                                    result.data.push(new DictItem(itemJson['__id'], itemJson['__code'], itemJson['__description']));
                                });
                            } else {
                                result.page = 1;
                                result.pageSize = 1;
                                result.pageCount = 1;
                                result.itemsCount =0;
                            }
                            resolve(result);
                        },
                        (failed) => {
                            if(failed.status == 401) {
                                console.log("Response_Error_401");
                                this._router.navigate([''])
                            }
                        }
                    )
                });
            }
        }
    }

    fetchConsultants(options?:DictRequestOptions): Promise<DictResponse> {
        if(!options) {
            options = new DictRequestOptions();
        }
        return new Promise((resolve, reject) => {
            this.apiService.get("consultants", options).then(
                (response) => {
                    let result = new DictResponse();
                    if(response) {
                        result.page = response['__pageNumber'];
                        result.pageSize = response['__pageSize'];
                        result.pageCount = response['__pageCount'];
                        result.itemsCount = response['__size'];
                        result.data = [];
                        _.forEach(response.__data, (itemConsultantJson) => {
                            let consultant = new Consultant(this.apiService);
                            result.data.push(consultant.fromJson(itemConsultantJson));
                        });
                    } else {
                        result.page = 1;
                        result.pageSize = 0;
                        result.pageCount = 1;
                        result.itemsCount = 0;
                        result.data = [];
                    }
                    resolve(result);
                },
                (failed) => {
                    if(failed.status == 401) {
                        console.log("Response_Error_401");
                        this._router.navigate([''])
                    }
                }
            )
        });
    }

    fetchGPs(options?:DictRequestOptions): Promise<DictResponse> {
        if(!options) {
            options = new DictRequestOptions();
        }
        return new Promise((resolve, reject) => {
            this.apiService.get("gp", options).then(
                (response) => {
                    let result = new DictResponse();
                    if(response) {
                        result.page = response['__pageNumber'];
                        result.pageSize = response['__pageSize'];
                        result.pageCount = response['__pageCount'];
                        result.itemsCount = response['__size'];
                        result.data = [];
                        _.forEach(response.__data, (itemGPJson) => {
                            let gp = new GP(this.apiService);
                            result.data.push(gp.fromJson(itemGPJson));
                        });
                    } else {
                        result.page = 1;
                        result.pageSize = 0;
                        result.pageCount = 1;
                        result.itemsCount = 0;
                        result.data = [];
                    }
                    resolve(result);
                },
                (failed) => {
                    if(failed.status == 401) {
                        console.log("Response_Error_401");
                        this._router.navigate([''])
                    }
                }
            )
        });
    }

    fetchClinics(options?:DictRequestOptions): Promise<DictResponse> {
        if(!options) {
            options = new DictRequestOptions();
        }
        return new Promise((resolve, reject) => {
            this.apiService.get("clinics", options).then(
                (response) => {
                    let result = new DictResponse();
                    if(response) {
                        result.page = response['__pageNumber'];
                        result.pageSize = response['__pageSize'];
                        result.pageCount = response['__pageCount'];
                        result.itemsCount = response['__size'];
                        result.data = [];
                        _.forEach(response.__data, (itemGPJson) => {
                            let clinic = new Clinic(this.apiService);
                            result.data.push(clinic.fromJson(itemGPJson));
                        });
                    } else {
                        result.page = 1;
                        result.pageSize = 0;
                        result.pageCount = 1;
                        result.itemsCount = 0;
                        result.data = [];
                    }
                    resolve(result);
                },
                (failed) => {
                    if(failed.status == 401) {
                        console.log("Response_Error_401");
                        this._router.navigate([''])
                    }
                }
            )
        });
    }

    fetchWards(options?:DictRequestOptions): Promise<DictResponse> {
        if(!options) {
            options = new DictRequestOptions();
        }
        return new Promise((resolve, reject) => {
            this.apiService.get("wards", options).then(
                (response) => {
                    let result = new DictResponse();
                    if(response) {
                        result.page = response['__pageNumber'];
                        result.pageSize = response['__pageSize'];
                        result.pageCount = response['__pageCount'];
                        result.itemsCount = response['__size'];
                        result.data = [];
                        _.forEach(response.__data, (itemWardJson) => {
                            let ward = new Ward(this.apiService);
                            ward.fromJson(itemWardJson);
                            //TODO: to be resolved by server mapping
                            if(!ward.wardCode){
                                ward.wardCode = ward.__id;
                            }
                            result.data.push(ward);
                        });
                    } else {
                        result.page = 1;
                        result.pageSize = 0;
                        result.pageCount = 1;
                        result.itemsCount = 0;
                        result.data = [];
                    }
                    resolve(result);
                },
                (failed) => {
                    if(failed.status == 401) {
                        console.log("Response_Error_401");
                        this._router.navigate([''])
                    }
                }
            )
        });
    }

    fetchInvestigationDepartments(): Promise<Array<InvestigationDepartment>> {
        return new Promise((resolve) => {
            this.apiService.get("$dict/InvestigationDepartments").then(
                (response) => {
                    let result: Array<InvestigationDepartment> = [];
                    _.forEach(response.__data, (itemJson) => {
                        result.push(new InvestigationDepartment(itemJson));
                    });
                    resolve(result);
                },
                (failed) => {
                    if (failed.status == 401) {
                        this._router.navigate([''])
                    }
                });
        });
    }

    fetchMockDictionaryByName(name:string): Promise<any>{
        return new Promise((resolve,reject) => {
            this._mockService.getData("getDictionary", name).then(
                (dict) => {
                    resolve(dict);
                }
            )
        });
    }

    resolve(){
        return this.fetchInvestigationDepartments();
    }
}
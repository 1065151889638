import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions, RequestMethod, URLSearchParams} from '@angular/http';
import { RootShareService } from "./root.shared.service";
import {EnvironmentClass} from "../environment.class";

@Injectable()
export class ApiService {
    private _header: RequestOptions;
    private _regexp: any = /^[a-zA-Z0-9-_/]+$/;
    private _url: string = "https://demo.fortrus.com/unity/rest/API/1.1/";
    // private _url: string = "http://ucr02.scandoc.local:8080/unity/rest/API/1.1/";
    private _retry: number = 5;
    private _retryDelay: number = 10;
    private _requestPOSTTimeout: number = 4000;
    private _requestGETTimeout: number = 4000;
    private _requestPUTTimeout: number = 4000;

    constructor(private _http: Http, private _rootService: RootShareService) {
        let header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Accept', '*/*');
        this._header = new RequestOptions({headers: header});
    }

    get url() {
        return this._url;
    }

    initEnvironment(environment:EnvironmentClass){
        if(environment.hasOwnProperty('apiUrl')) {
            this._url = environment.apiUrl + '/';
        } else {
            this._url = "http://ucr.fortrus.com/unitydev/rest/API/1.1/";
            //this._logger.error('No defined Api URL in config!')
        }
        if(environment.hasOwnProperty('connectionRetry')){
            this._retry = environment.connectionRetry;
        } else {
            //this._logger.warning('No "connectionRetry" parameter in config, set to default 5');
            this._retry = 5;
        }
        if(environment.hasOwnProperty('connectionRetryDelay')){
            this._retryDelay = environment.connectionRetryDelay;
        } else {
            //this._logger.warning('No "connectionRetryDelay" parameter in config, set to default 5');
            this._retryDelay = 5;
        }
        if(environment.hasOwnProperty('requestPOSTTimeout')){
            this._requestPOSTTimeout = environment.requestPOSTTimeout;
        } else {
            //this._logger.warning('No "requestPOSTTimeout" parameter in config, set to default 4s');
            this._requestPOSTTimeout = 4000;
        }
        if(environment.hasOwnProperty('requestGETTimeout')){
            this._requestGETTimeout = environment.requestGETTimeout;
        } else {
            //this._logger.warning('No "requestGETTimeout" parameter in config, set to default 4s');
            this._requestGETTimeout = 4000;
        }
        if(environment.hasOwnProperty('requestPUTTimeout')){
            this._requestPUTTimeout = environment.requestPUTTimeout;
        } else {
            //this._logger.warning('No "requestPUTTimeout" parameter in config, set to default 4s');
            this._requestPUTTimeout = 4000;
        }
    }

    get(collectionPath: string, data?:any): Promise<any> {
        return new Promise((resolve, reject) => {
            let apiUrl:[boolean,string] = this.buildUrl(collectionPath);
            let params = new URLSearchParams();
            if(data) {
                for (let key in data) {
                    if(data[key] && data[key] != null && data[key].toString().length > 0) {
                        params.set(key, data[key]);
                    }
                }
            }
            let options = new RequestOptions({
                search: params,
                withCredentials: true
            });
            if (apiUrl[0]) {
                this._http.get(apiUrl[1], options)
                    .retry(this._retry)
                    .delay(this._retryDelay)
                    .map((res) => [ res.status, res.json() ])
                    .subscribe(data => {
                        this._rootService.hideIndicatorLostConnection();
                        if (data[ 0 ] == 204) {
                            //this._logger.info('No Content', 204);
                        }
                        resolve(data[ 1 ]);
                    }, err => {
                        //this._logger.error(err);
                        if (err.status == 0) {
                            // lock app
                            this._rootService.showIndicatorLostConnection();
                            // handle lost connection situation
                            // this._handleLostConnectionForGet(apiUrl[1], options)
                            //     .then((res) => {
                            //         resolve(res);
                            //     }, (err) => {
                            //         resolve(err);
                            //     });
                        }
                        if (err.status == 401) {
                            //Forbbiden - move to login
                            reject(err);
                        }
                        if (err.status == 403) {
                            reject(err);
                        }
                        if (err.status == 500) {
                            reject(err);
                        }
                    }, () => {
                        //this._logger.debug('Api GET(' + apiUrl[ 0 ] + ') finished');
                    });
            } else {
                reject('Incorrect collection path : ' + collectionPath);
            }
        });
    }

    post(collectionPath:string, data?:any): Promise<any> {
        if(!data){
            data = {};
        }
        return new Promise((resolve, reject) => {
            let apiUrl:[boolean,string] = this.buildUrl(collectionPath);
            if (!apiUrl[0]) {
                reject(`Incorrect collection path : ${collectionPath}`);
            } else {
                this._http.post(apiUrl[1], data, {withCredentials: true})
                    .retry(this._retry)
                    // .timeout(this._requestPOSTTimeout)
                    .delay(this._retryDelay)
                    .map((res) => [res.status, res.json()])
                    .subscribe(data => {
                        //this._rootService.renewCounter();
                        if(data[0] == 204){
                            //this._logger.info('No Content', 204);
                        }
                        resolve(data[1]);
                    }, err => {
                        //this._logger.error(err);
                        if (err.status == 0) {
                            // lock app
                            this._rootService.showIndicatorLostConnection();
                            // handle lost connection situation
                            // this._handleLostConnectionForPost(apiUrl[1], data)
                            //     .then((res) => {
                            //         resolve(res);
                            //     }, (err) => {
                            //         resolve(err);
                            //     });
                        }
                        if (err.status == 400 || err.status == 401 || err.status == 403 || err.status == 404 || err.status == 500) {
                            reject(err);
                        }
                    }, () => {
                        //this._logger.debug('Api POST(' + apiUrl[0] + ') finished');
                    })
            }

        })
    }

    /**
     * Method builds collection path for API, and verify collection path
     * @param collectionPath - path to api endpoint
     * @returns [boolean, api_path] - tuple, verification result and api url
     */
    public buildUrl(collectionPath:string):[boolean,string]{
        if(this.verifyCoolectionPath(collectionPath)){
            return [true,this._url + collectionPath];
        } else {
            return [false,''];
        }
    }

    /**
     * Method checks collection path to endpoint
     * @param collectionPath - path to api endpoint
     * @returns {boolean} - verification result
     */
    private verifyCoolectionPath(collectionPath:string):boolean{
        if (collectionPath && collectionPath.length > 0 &&
            (collectionPath.search(this._regexp) >= -1) &&
            (collectionPath.indexOf('/') > 0 || collectionPath.indexOf('/') == -1 )) {
            return true;
        } else {
            return false;
        }
    }

    postAuth(collectionPath:string, data?:any): Promise<any> {
        if(!data){
            data = {};
        }
        return new Promise((resolve, reject) => {
            let apiUrl:[boolean,string] = this.buildUrl(collectionPath);
            if (!apiUrl[0]) {
                reject(`Incorrect collection path : ${collectionPath}`);
            } else {
                this._http.post(apiUrl[1], data, {withCredentials: true})
                    .retry(this._retry)
                    .delay(this._retryDelay)
                    .map((res) => [res.status, res.json()])
                    .subscribe(data => {
                        //this._rootService.renewCounter();
                        if(data[0] == 204){
                            console.log('No Content');
                        }
                        resolve(data[1]);
                    }, err => {
                        reject(err);
                    }, () => {
                        //this._logger.debug('Api POST(' + apiUrl[0] + ') finished');
                    })
            }
        })
    }

    delete(collectionPath: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let apiUrl:[boolean,string] = this.buildUrl(collectionPath);
            if(apiUrl[0]){
                this._http.delete(apiUrl[1], {withCredentials: true})
                    .retry(this._retry)
                    .timeout(4000)
                    .delay(this._retryDelay)
                    .subscribe(data => {
                        resolve(data);
                    }, err => {
                        this._rootService.showIndicatorLostConnection();
                        reject(err);
                    }, () => {
                        //
                    })
            } else {
                reject('Incorrect collection path : ' + collectionPath);
            }
        });
    }
}
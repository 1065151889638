export class Diagnose {
    isConfirmed: boolean;
    isIgnored: boolean; // ????

    // Diagnosis
    //
    // Watson Diagnosis
    // SNOMED - Watson Diagnosis
    // Watson Differential Diagnosis
    // Watson Diagnosis Evidence

    diagnosis: string;
    watsonDiagnosis: string;
    snomedWatsonDiagnosis: string;
    watsonDifferentialDiagnosis: string;
    watsonDiagnosisEvidence: string;

    public constructor(){
        this.isConfirmed = false;
        this.isIgnored = false;

        this.diagnosis = "";
        this.watsonDiagnosis = "";
        this.snomedWatsonDiagnosis = "";
        this.watsonDifferentialDiagnosis = "";
        this.watsonDiagnosisEvidence = "";
    }
}

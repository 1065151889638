import {Injectable} from "@angular/core";
import {MockService} from "./mock.service";
import {PatientData} from "../models/patient.model";
import {MapUtils} from "../models/mapUtil";
import {AuthService} from "./auth.service";
import {Broadcaster} from "./broadcaster";
import {environment} from "../../environments/environment";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {ApiService} from "./api.service";
import {RootShareService} from "./root.shared.service";
import {Http} from "@angular/http";
import {reject} from "q";
import moment = require("moment");
import {trigger} from "@angular/animations";
import * as _ from "lodash"
import {SearchResult, SearchService} from "./search.service";
import {EnvironmentClass} from "../environment.class";
import {GP} from "../utils/definitions/GP";
import {Patient,Place} from "../utils/definitions/Patient";
import {ReferralForm} from "../models/forms/referral/referral.form";

/**
 * Created by Bartek on 15.05.2018.
 */

@Injectable()
export class PatientService implements Resolve<Patient> {

    private _baseCollectionPath: string = 'patients';
    private _patientsAvatars: any;
    private _environment: EnvironmentClass;

    public currentPatientId;

    public vitalsAreReady: boolean = false;

    constructor(private _broadcaster: Broadcaster, public mockService: MockService,
                private _authService: AuthService, private _apiService: ApiService,
                private _rootShare: RootShareService, private _http: Http,
                private _searchService: SearchService) {
    }

    getPatients(ids: Array<number>) {
        let patients = [];
        for (let i = 0; i < ids.length; i++) {
            this.getById(ids[i]).then(
                (patient) => {
                    patients.push(patient);
                },
                (error) => {
                    console.log(error);
                }
            )
        }
        return patients;
    }

    getPatientData(id: string): Promise<PatientData> {
        return new Promise((resolve, reject) => {
            if (environment.mockUser) {
                this.mockService.getData("getPatientData", id).then(
                    (patientData) => {
                        let p: PatientData = MapUtils.deserialize(PatientData, patientData);
                        console.log(p);
                        resolve(p);
                    },
                    (error) => {
                        reject(error);
                    }
                )
            } else {
                console.log('get patient data from API');
                resolve(new PatientData());
            }
        });
    }

    getById(patientId: number): Promise<Patient> {
        console.log("get by id");
        return new Promise((resolve, reject) => {
            this._apiService.get(this._baseCollectionPath + "/" + patientId).then(
                (response) => {
                    if (response) {
                        let patient = Patient.fromJSON2(response);
                        console.log(response);
                        resolve(patient);
                    }
                },
                (failed) => {
                    let result: string;
                    reject([result, failed.status]);
                }
            )
        })
    }

    public getPatientAvatarByEmail(email: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getPatientsAvatars().then(
                (results) => {
                    if (results[email])
                        resolve(results[email]);
                    else
                        resolve("nophoto");
                }, (err) => {
                    reject(err);
                }
            )
        })
    }

    public getPatientsAvatars(): Promise<any> {
        let fileName = "mock/emailAvatars.json";
        return new Promise((resolve, reject) => {
            try {
                this._http.get(fileName)
                    .subscribe(
                        data => {
                            if (data && data["_body"]) {
                                let resObj: any = {};
                                try {
                                    resObj = JSON.parse(data['_body']);
                                    resolve(resObj);
                                } catch (ex) {
                                    console.log("JSON parse error in file " + fileName);
                                    reject(ex);
                                }
                            }
                        },
                        err => {
                            console.log(err);
                            reject(err);
                        },
                        () => {
                        }
                    );
            }
            catch (e) {
                console.log('Exception:' + (<Error>e).message)
            }
        });
    }


    __fetchSingleInvestigationTests(patientId, testname, dateFrom?, dateTo?): Promise<any> {
        return new Promise((resolve, reject) => {

            let data: any = {};
            data['testName'] = testname;
            data['dateFrom'] = dateFrom ? dateFrom.format("YYYY-MM-DD") : null;
            data['dateTo'] = dateTo ? dateTo.format("YYYY-MM-DD") : null;


            this._apiService.post("patients/" + patientId + "/investigations/getTestHistory?$sort=observationDate", data).then(
                (response) => {
                    if (response && response.__data) {
                        let tests: Array<any> = [];
                        let testSets: any = {};
                        response.__data.forEach((test) => {
                            let set = _.uniqueId('setId');
                            if (test.hasOwnProperty('observationDate')) {
                                set = moment(test.observationDate).format("YYYY-MM-DD");
                            }
                            if (!testSets.hasOwnProperty(set)) {
                                testSets[set] = [];
                            }
                            testSets[set].push(test);
                        });
                        Object.keys(testSets).forEach((testSet) => {
                            let resultTest = {};
                            _.extend(resultTest, testSets[testSet][0]);
                            resultTest['value'] = [];
                            testSets[testSet].forEach((oneTest) => {

                                let test;
                                if (oneTest['value'] != 'No Result') {
                                    test = {
                                        noResult: false,
                                        range: this.getRange(),
                                        value: oneTest['value'],
                                        day: oneTest['observationDate']
                                    }
                                } else {
                                    test = {
                                        noResult: true,
                                        range: 0,
                                        value: oneTest['value'],
                                        day: oneTest['observationDate']
                                    }
                                }
                                resultTest['value'].push(test);
                            });
                            tests.push(resultTest);
                        });
                        resolve(tests);
                    }
                    else
                        resolve(response);
                },
                (failed) => {

                    reject(failed);
                }
            )
        });
    }

    getRange() {
        return Math.floor((Math.random() * 5));
    }

    // TODO: !!!!!!
    // noinspection all
    get MEDICATIONS(): any { return this.getMedications(); }
    // noinspection all
    get PROCEDURES(): any { return this.getProcedures(); }

    // noinspection all
    get VAOD(): any { return this.getTestValues("VAOD"); }
    // noinspection all
    get VAOS(): any { return this.getTestValues("VAOS"); }
    // noinspection all
    get CDROD(): any { return this.getTestValues("CDROD"); }
    // noinspection all
    get CDROS(): any { return this.getTestValues("CDROS"); }
    // noinspection all
    get IOPOD(): any { return this.getTestValues("IOPOD"); }
    // noinspection all
    get IOPOS(): any { return this.getTestValues("IOPOS"); }
    // noinspection all
    get VFOD(): any { return this.getTestValues("VFOD"); }
    // noinspection all
    get VFOS(): any { return this.getTestValues("VFOS"); }
    // noinspection all
    get REF(): any { return this.getTestValues("REF"); }

    // noinspection all
    get REFOD(): any { return this.getTestValues("REFOD"); }
    // noinspection all
    get REFOS(): any { return this.getTestValues("REFOS"); }

    // public VAOS;
    // public VAOD;
    // public IOPOS;
    // public IOPOD;
    // public VFOS;
    // public VFOD;
    // public REF;

    getMedications() {
        let meds = [];
        let forms = _.cloneDeep(this.testForms);
        _.remove(forms, (fe) => {
            return !(fe.formName === 'referral' && fe.mode === "medications");
        });

        _.forEach(forms, (f) => {
            _.forEach(f.medicationsOcular, (fm) => {
                fm.when = f.when;
                meds.push(fm);
            });
            _.forEach(f.medicationsSystemic, (fm) => {
                fm.when = f.when;
                meds.push(fm);
            });
        });

        return meds;
    }

    getProcedures() {
        let forms = _.cloneDeep(this.testForms);
        _.remove(forms, (fe) => {
            return fe.formName !== 'procedure-surgery';
        });
        return forms;
    }

    // noinspection all
    getTestValues(testname) {
        let vs = [];

        // let forms = [];
        // forms.concat(this.testForms);
        // forms.concat(this.eye);

        // noinspection all
        let forms = _.cloneDeep(this.testForms);

        _.remove(forms, (fe) => {
            return fe.formName !== 'test' && fe.formName !== 'eye-exam';
        });

        // lets connect them
        // noinspection all
        forms = _.sortBy(forms, [(o) => {
            return moment(o.when).toDate();
        }]);
        _.forEach(forms, (tf: ReferralForm) => {
            tf.whenString = moment(tf.when).format("YYYYMMDD");
        });

        let newForms = [];
        _.forEach(forms, (tf: ReferralForm) => {
            let founded;
            _.forEach(newForms, (tfs: ReferralForm) => {
                if (tfs.whenString === tf.whenString) {
                    founded = tfs;
                }
            });
            if (founded) {
                // noinspection all
                founded.tests = founded.tests.concat(tf.tests);
            } else {
                newForms.push(tf);
            }
        });
        forms = newForms;

        let convertUnits = (v) => {
            let value = 0;
            switch (v) {
                case "6/60":
                    value = 1.0;
                    break;
                case "6/48":
                    value = 0.9;
                    break;
                case "6/38":
                    value = 0.8;
                    break;
                case "6/30":
                    value = 0.7;
                    break;
                case "6/24":
                    value = 0.6;
                    break;
                case "6/19":
                    value = 0.5;
                    break;
                case "6/15":
                    value = 0.4;
                    break;
                case "6/12":
                    value = 0.3;
                    break;
                case "6/9.5":
                    value = 0.2;
                    break;
                case "6/7.5":
                    value = 0.1;
                    break;
                case "6/6":
                    value = 0.0;
                    break;
                case "6/5":
                    value = -0.1;
                    break;
                case "6/4":
                    value = -0.2;
                    break;
                case "6/3":
                    value = -0.3;
                    break;
            }
            return value;
        };

        if (forms && forms.length > 0) {
            let that = this;
            _.forEach(forms, (tf: ReferralForm) => {
                let val = undefined;
                let displayVal = '';
                _.forEach(tf.tests, (test) => {
                    switch (testname) {
                        case "CDROD":
                            if (test.testName === "FundusImageInterpretation") {
                                val = eval(test.CupToDiscRatioOD);
                                displayVal = test.CupToDiscRatioOD;
                            }
                            break;
                        case "CDROS":
                            if (test.testName === "FundusImageInterpretation") {
                                val = eval(test.CupToDiscRatioOS);
                                displayVal = test.CupToDiscRatioOS;
                            }
                            break;
                        case "VAOD":
                            if (test.testName === "VisualAcuity") {
                                /*
                                if (test.visualAcuityOD && test.visualAcuityOD.startsWith('20/')) {
                                    val = 'OD 20/' + test.visualAcuityOD.substr(3);
                                } else
                                    val = 'OD ' + test.visualAcuityOD;
                                */
                                val = test.visualAcuityOD;
                                displayVal = test.visualAcuityOD;
                                val = convertUnits(test.visualAcuityOD);
                            }
                            break;
                        case "VAOS":
                            if (test.testName === "VisualAcuity") {
                                /*
                                if (test.visualAcuityOS && test.visualAcuityOS.startsWith('20/')) {
                                    val = 'OS 20/' + test.visualAcuityOS.substr(3);
                                } else
                                    val = 'OS ' + test.visualAcuityOS;
                                */
                                val = test.visualAcuityOS;
                                displayVal = test.visualAcuityOS;
                                val = convertUnits(test.visualAcuityOS);
                            }
                            break;
                        case "IOPOD":
                            if (test.testName === "IOP") {
                                val = test.IOPOD;
                                displayVal = test.IOPOD;
                            }
                            break;
                        case "IOPOS":
                            if (test.testName === "IOP") {
                                val = test.IOPOS;
                                displayVal = test.IOPOS;
                            }
                            break;
                        case "VFOD":
                            if (test.testName === "VisualField" && test.verticalCupToDiscRatioOD) {
                                val = test.verticalCupToDiscRatioOD;
                                displayVal = test.verticalCupToDiscRatioOD;
                            }
                            if (test.testName === "FundusImageInterpretation" && test.CupToDiscRatioOD) {
                                val = test.CupToDiscRatioOD;
                                displayVal = test.CupToDiscRatioOD;
                            }
                            break;
                        case "VFOS":
                            if (test.testName === "VisualField" && test.verticalCupToDiscRatioOS) {
                                val = test.verticalCupToDiscRatioOS;
                                displayVal = test.verticalCupToDiscRatioOS;
                            }
                            if (test.testName === "FundusImageInterpretation" && test.CupToDiscRatioOS) {
                                val = test.CupToDiscRatioOS;
                                displayVal = test.CupToDiscRatioOS;
                            }
                            break;
                        case "REFOD":
                            if (test.testName === "Refraction") {
                                // sph -0.50  cyl -0.25  axis 70 Prism 0.5  Add +2.00
                                val = "Sph " + test.sphOD + " Cyl " + test.cylOD + " Axis " + test.axisOD + " Prism " + test.prismOD + " Add " + test.addOD;
                                val = (test.sphOD ? test.sphOD : '') + "/" + (test.cylOD ? test.cylOD : '') + " " + (test.axisOD ? test.axisOD : '');
                                displayVal = val;
                            }
                            break;
                        case "REFOS":
                            if (test.testName === "Refraction") {
                                // val = "Sph " + test.sphOS + " Cyl " + test.cylOS + " Axis " + test.axisOS + " Prism " + test.prismOS + " Add " + test.addOS;
                                val = (test.sphOS ? test.sphOS : '') + "/" + (test.cylOS ? test.cylOS : '') + " " + (test.axisOS ? test.axisOS : '');
                                displayVal = val;
                            }
                            break;
                        case "CTDROD":
                            if (test.testName === "VisualField") {
                                val = test.verticalCupToDiscRatioOD;
                                displayVal = test.verticalCupToDiscRatioOD;
                            }
                            break;
                        case "CTDROS":
                            if (test.testName === "VisualField") {
                                val = test.verticalCupToDiscRatioOS;
                                displayVal = test.verticalCupToDiscRatioOS;
                            }
                            break;

                        case "GHTOD":
                            break;
                        case "GHTOS":
                            break;

                    }

                });
                if (val) {
                    vs.push({
                        "name": testname,
                        "value": [
                            {
                                "noResult": false,
                                "range": 0,
                                "value": val,
                                "displayValue": displayVal,
                                "day": moment(tf.when)
                            }
                        ],
                        "range": "120.0-160.0",
                        "unit": "",
                        "outOfRange": false,
                        "observationDate": moment(tf.when),
                        "setId": 1,
                        "investigationId": "0",
                        "examSampleNo": "0",
                        "status": "Final",
                        "rangeFrom": 120,
                        "rangeTo": 160,
                        "rangeOperator": null
                    });
                } else {
                    vs.push({
                        "name": testname,
                        "value": [
                            {
                                "noResult": true,
                                "range": 0,
                                "value": "No Result",
                                "day": moment(tf.when)
                            }
                        ],
                        "range": "120.0-160.0",
                        "unit": "",
                        "outOfRange": false,
                        "observationDate": moment(tf.when),
                        "setId": 1,
                        "investigationId": "0",
                        "examSampleNo": "0",
                        "status": "Final",
                        "rangeFrom": 120,
                        "rangeTo": 160,
                        "rangeOperator": null
                    });
                }
            });
        }

        // noinspection all
        vs = _.sortBy(vs, [function (o) {
            return o.observationDate.valueOf();
        }]);

        _.reverse(vs);

        return vs;
    }

    testForms: Array<ReferralForm> = null;
    // noinspection all
    fetchSingleInvestigationTests(patientId, testname, dateFrom?, dateTo?): Promise<any> {
        // console.log('fetchSingleInvestigationTests', testname);
        return new Promise((resolve, reject) => {
            resolve(this.getTestValues(testname));

            /*
            resolve([{
                "name": "VAOS",
                "value": [
                    {
                        "noResult": true,
                        "range": 0,
                        "value": "No Result",
                        "day": "2017-12-10T18:25:00.000+0000"
                    }
                ],
                "range": "120.0-160.0",
                "unit": "g/L",
                "outOfRange": false,
                "observationDate": "2017-12-10T18:25:00.000+0000",
                "setId": 1,
                "investigationId": "NHS3212221124ted12t434ttrg21",
                "examSampleNo": "T90878",
                "status": "Final",
                "rangeFrom": 120,
                "rangeTo": 160,
                "rangeOperator": null
            }]);
            */
        });
    }

    getVitals(id) {
        let that = this;
        let filter = {
            patientId: id,
            $or: [{formName: 'test'}, {formName: 'eye-exam'}, {formName: 'referral'}, {formName: 'procedure-surgery'}]
        };
        this._apiService.post('customdata/filter?collectionName=customForms', filter).then((response) => {
            this.testForms = response;
            that.vitalsAreReady = true;

            /*
            let promise1 = this.fetchSingleInvestigationTests(id,'VAOS');
            let promise2 = this.fetchSingleInvestigationTests(id,'VAOD');
            let promise3 = this.fetchSingleInvestigationTests(id,'IOPOS');
            let promise4 = this.fetchSingleInvestigationTests(id,'IOPOD');
            let promise5 = this.fetchSingleInvestigationTests(id,'VFOS');
            let promise6 = this.fetchSingleInvestigationTests(id,'VFOD');
            let promise7 = this.fetchSingleInvestigationTests(id,'REF');

            this.resetVitals();

            Promise.all([promise1, promise2, promise3,promise4,promise5,promise6,promise7]).then(function(values) {
                if (values[0]) {
                    that.VAOS = values[0].reverse();
                    that.VAOD = values[1].reverse();
                    that.IOPOS = values[2].reverse();
                    that.IOPOD = values[3].reverse();
                    that.VFOS = values[4].reverse();
                    that.VFOD = values[5].reverse();
                    that.REF = values[6].reverse();
                    that.vitalsAreReady = true;
                }
            });
            */
        });
    }

    resetVitals() {
        // this.VAOS = [];
        // this.VAOD = [];
        // this.IOPOS = [];
        // this.IOPOD = [];
        // this.VFOS = [];
        // this.VFOD = [];
        // this.REF = [];
        this.vitalsAreReady = false;
    }

    patientSearch(search: any, page?: number, pageSize?: number, options?: any, sort?: string): Promise<SearchResult> {

        return new Promise((resolve, reject) => {
            this._searchService.search("/" + this._baseCollectionPath, search, page, pageSize, options, sort).then(
                (sr) => {
                    if (sr && sr.data){
                        for(let i=0; i < sr.data.length; i++){
                            let patient = new Patient(this._apiService, this._rootShare);
                            patient.fromJson(sr.data[i]);
                            patient.place = new Place();

                            /**
                             * Conditions describing what to show in case of different situations:
                             - inPatient - BED ICON, bed number + ward number
                             - to have an appointment in clinic but is NOT inPatient (NOT TODAY) - date of the nearest appoitment, time + clinic number
                             - to have an appointment TODAY- CLINIC ICON + time + clinic number
                             - is inPatient and have appointment (NOT TODAY) -  in one row [BED ICON + bed number + ward number], uderneath in one row
                             [date of the nearest appointment, time, clinic number]
                             - is inPatient and have appointment TODAY-  in one row [BED ICON + bed number + ward number], uderneath in one row [time of
                             the nearest appointment, clinic number]
                             */


                                //Defining local variables and in case of they're 'null', set to empty string
                                //Because for example: if patient.inPatientInfo.bedNumber is null, UCR shows 'null' text on the table, and when we bind empty string in that case, UCR just shows nothing
                            let bedNumber, wardCode, bay, clinic : string;

                            if (!patient.inPatientInfo) {
                                bedNumber = " ";
                                wardCode = " ";
                                bay = "";
                            }
                            else {
                                if (patient.inPatientInfo.bedNumber)
                                    bedNumber = patient.inPatientInfo.bedNumber;
                                else
                                    bedNumber = " ";

                                if (patient.inPatientInfo.wardCode)
                                    wardCode = patient.inPatientInfo.wardCode;
                                else
                                    wardCode = " ";

                                if (patient.inPatientInfo.bay)
                                    bay = patient.inPatientInfo.bay;
                                else
                                    bay = "";
                            }
                            if (!patient.clinicAppointmentInfo)
                                clinic = " ";
                            else {
                                if (patient.clinicAppointmentInfo.clinic)
                                    clinic = patient.clinicAppointmentInfo.clinic;
                                else
                                    clinic = " ";
                            }

                            // //check if patient is inpatient
                            // if (patient.inPatientInfo != null){
                            //     //patient without attendances, which is inpatient
                            //     if (!patient.clinicAppointmentInfo || patient.clinicAppointmentInfo == null){
                            //         patient.place.icon = "unity-icon unity-icon-inpatients";
                            //         patient.place.description = bedNumber;
                            //         patient.place.location = wardCode;
                            //         patient.place.bay = bay;
                            //     }
                            //     //patient with attendances (not today), which is inpatient
                            //     else if ((patient.clinicAppointmentInfo && patient.clinicAppointmentInfo != null) && !moment(patient.clinicAppointmentInfo.appointmentDate).isSame(moment(), 'day')){
                            //         patient.place.icon = "unity-icon unity-icon-inpatients";
                            //         patient.place.description = bedNumber + " " + wardCode;
                            //         patient.place.location = moment(patient.clinicAppointmentInfo.appointmentDate).format(this._environment.patientTableAppointmentDate.otherDayFormat) + " " + clinic;
                            //         patient.place.bay = bay;
                            //     }
                            //     //patient with attendances (Today), which is inpatient
                            //     else if ((patient.clinicAppointmentInfo || patient.clinicAppointmentInfo != null) && moment(patient.clinicAppointmentInfo.appointmentDate).isSame(moment(), 'day')){
                            //         patient.place.icon = "unity-icon unity-icon-inpatients";
                            //         patient.place.description = bedNumber + " " + wardCode;
                            //         patient.place.location = moment(patient.clinicAppointmentInfo.appointmentDate).format(this._environment.patientTableAppointmentDate.todayFormat) + " " + clinic;
                            //         patient.place.bay = bay;
                            //     }
                            // }
                            //if patient is not inpatient
                            // else {
                            //     //patient with attendances (not today), but which is NOT inpatient
                            //     if ((patient.clinicAppointmentInfo || patient.clinicAppointmentInfo != null) && !moment(patient.clinicAppointmentInfo.appointmentDate).isSame(moment(), 'day')){
                            //         patient.place.icon = "unity-icon unity-icon-clinics";
                            //         patient.place.location = moment(patient.clinicAppointmentInfo.appointmentDate).format(this._environment.patientTableAppointmentDate.otherDayFormat);
                            //         patient.place.location = clinic;
                            //     }
                            //
                            //     //patient with attendances (Today), but which is NOT inpatient
                            //     else if ((patient.clinicAppointmentInfo || patient.clinicAppointmentInfo != null) && moment(patient.clinicAppointmentInfo.appointmentDate).isSame(moment(), 'day')){
                            //         patient.place.icon = "unity-icon unity-icon-clinics";
                            //         patient.place.location = moment(patient.clinicAppointmentInfo.appointmentDate).format(this._environment.patientTableAppointmentDate.todayFormat);
                            //         patient.place.location = clinic;
                            //     }
                            //     //if patient clinicAppointmentInfo and inPatient is not set
                            //     else {
                            //         //empty for this moment
                            //     }
                            // }
                            if(patient.patientNumbers){
                                patient.mergedNumbers = [];
                                _.forEach(patient.patientNumbers, (number) => {
                                    if(number.type == "merged") {
                                        patient.mergedNumbers.push(number.number);
                                    }
                                });
                            }
                            sr.data[i] = patient;
                        }
                    }
                    resolve(sr);
                },
                (error) => {
                    reject(error);
                }
            );
        })
    }

    getPatientMockGP(patientId: string) {
        return new Promise((resolve, reject) => {
            this.mockService.getData("patientsGP", "all").then(
                (response) => {
                    let data;
                    for (let i = 0; i < response.length; i++) {
                        if (response[i].patientId == patientId) {
                            data = response[i];
                            break;
                        }
                    }
                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            )
        });
    }

    resolve(route: ActivatedRouteSnapshot): Promise<Patient> {
        return new Promise((resolve, reject) => {
            //this.getPatientData(route.params["id"]).then((success) => {
            this.currentPatientId = route.params["id"];
            this.getById(route.params["id"]).then((success) => {
                console.log(success);
                this.getVitals(route.params['id']);
                resolve(success);
            }, (fail) => {
                reject(false);
            })
        })
    }
}
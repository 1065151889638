export class Refraction {
    testName = "Refraction";

    sphOD: string;
    cylOD: string;
    axisOD: string;
    prismOD: string;
    addOD: string;
    sphOS: string;
    cylOS: string;
    axisOS: string;
    prismOS: string;
    addOS: string;

    public constructor(){
        this.sphOD = null;
        this.cylOD = null;
        this.axisOD = null;
        this.addOD = null;
        this.sphOS = null;
        this.cylOS = null;
        this.axisOS = null;
        this.addOS = null;

        this.prismOD = null;
        this.prismOS = null;
    }
}

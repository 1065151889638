export class GPDetailsType {
    title: string;
    initials: string;
    familyName: string;
    practiceCode: string;
    address: string;
    surgeryTelephone: string;

    public constructor(){
        this.title = "";
        this.initials = "";
        this.familyName = "";
        this.practiceCode = "";
        this.address = "";
        this.surgeryTelephone = "";
    }
}


import {Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild} from "@angular/core";
import * as _ from 'lodash';
import * as $ from 'jquery';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Rx';
import {Dictionary, DictionaryItem} from "../../utils/definitions/Dictionary";
import {DictionaryService} from "../../services/dict.service";
import {SearchDictionary, dictionaryFilter, dictionaryFilterType} from "../../models/dict.utils.model";
import {ApiService} from "../../services/api.service";
import {ElementRef} from '@angular/core';
import {MockService} from "../../services/mock.service";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'ui-search-ucr',
    templateUrl: 'search.element.html',
    styleUrls : ['search.element.scss']
})
export class UiSearchUCR implements AfterViewInit{
    public inputValue:string;
    public select: any;
    public _recentSearches: Array<any> = [];
    public _storageKeyPrefix: string = "uiRecentSearches_";
    public _displayDropdown : boolean = false;
    public _showDropDown: string = "none";
    public _selectedCode: string;
    public _selectedOption: boolean;
    public _dictionaries: Array<SearchDictionary>;
    public _allItems: Array<any>;
    public _eventStream;
    public _showRecentProperty: boolean = false;
    public _inputName: string;

    @ViewChild('inputAdditional') inputAdditionalElement:ElementRef;


    constructor(private _dictionaryService: DictionaryService, private _apiService: ApiService, private _mockService: MockService) {
        this.inputValue = "";
    }

    /**
     * 'type' property stands for input field type - it can be 'normal' or 'dropdown-icon', which means
     * that it is displayed with small dropdown icon on the right
     * 'defaultText' property stands for text in placeholder (for example: Search here...), which is displayed
     * when input is empty
     * 'dictionary' array stands for dictionary values in dropdown
     * 'searchedValue' property is an emitter which can be used in other components, like gui.basic.module
     * to display searched values
     */
    @Input() type : string = "";
    @Input() name : string;
    @Input() defaultText : string;
    @Input('select-mode-only') selectModeOnly : boolean = false;
    @Input('base-input-value')
    set baseInputValue(baseInputValue:string | DictionaryItem) {
        if (typeof(baseInputValue) == "string") {
            if (baseInputValue && baseInputValue.length > 0) {
                this.inputValue = baseInputValue;
            }
        } else {
            if(baseInputValue != null) {
                this.inputValue = baseInputValue.name;
                this.select = baseInputValue;
                this._selectedCode = baseInputValue.id? baseInputValue.id : null;
                this._selectedOption = true;
            }
        }
    }

    @Input()
    set dictionaries(dictionaries : Array<SearchDictionary>) {
        _.forEach(dictionaries, (dictionary) => {
           if (dictionary.filter) {
               if(dictionary.filter.type == dictionaryFilterType.clinic) {
                   let dict = new Dictionary(this._dictionaryService, this._apiService, this._mockService);
                   dict.findConsultantsByClinic(dictionary.filter);
                   dictionary.dictionary = dict;
               }
               if (dictionary.filter.type == dictionaryFilterType.consultant) {
                   let dict = new Dictionary(this._dictionaryService, this._apiService, this._mockService);
                   dict.findClinicsByConsultant(dictionary.filter);
                   dictionary.dictionary = dict;
               }
           } else {
               let dict = new Dictionary(this._dictionaryService, this._apiService, this._mockService);
               dict.fetch(dictionary.name, 1, dictionary.sort);
               dictionary.dictionary = dict;
           }

        });
        this._dictionaries = dictionaries;
        this._allItems = dictionaries[0].dictionary.items();

    }

    @Output() searchedValue = new EventEmitter();

    _isIPad: boolean = false;
    ngOnInit(){
        if(!this.name) {
            this.name = _.uniqueId('ui_search_');
        }
        this._inputName = "input_" + this.name;

        if(/iPad/i.test(navigator.platform)) {
            this._isIPad = true;
        }
    }

    ngAfterViewInit() {
        if (this._dictionaries && this._dictionaries.length > 0) {
            let input = $("#"+this._inputName);
            if (input != null && this._dictionaries) {
                this._eventStream = Observable.fromEvent(input, 'keyup')
                    .map(() => this.inputValue)
                    .debounceTime(1000)
                    .distinctUntilChanged();

                this._eventStream.subscribe((input) => {
                    if (this._dictionaries.length > 1 || !this._dictionaries[0].filter) {
                        if (input.length > 0) {
                            _.forEach(this._dictionaries, (searchDictonary) => {
                                searchDictonary.dictionary.filter(input, 1)
                            });
                        } else {
                            _.forEach(this._dictionaries, (searchDictonary) => {
                                searchDictonary.dictionary.fetch(searchDictonary.name, 1)
                            });
                        }
                    } else {

                        this._dictionaries[0].dictionary.setItems(this._allItems);

                        if (input.length > 0) {
                            let rex = new RegExp(input, 'gi');
                            this._dictionaries[0].dictionary.setItems(_.filter(this._dictionaries[0].dictionary.items(), (item) => {
                                if (item.name.match(rex))
                                    return true;
                                else return false;
                            }));
                        }
                    }
                });
            }
        }
    }

    more: boolean = false;
    moreItem(dictionary) {
        dictionary.dictionary.addPage(this.inputValue);
        this.more = true;
        let input = $("#" + this._inputName);
        input.focus();
      //  $("#" + this.name).addClass("open");
    }

    iPadBlur(e){
        if ($(e.target).parents('.search').length <= 0) {
            if (!this._selectedOption) {
                this.inputValue = "";
                let input = $("#" + this._inputName);
                input.keyup();
                setTimeout(() => {
                    $("#" + this.name).removeClass("open")
                }, 500);
            }
        }
    }

    /**
     * @param event
     * onKeyUp event is emitting searched value, to be displayed in other component (e.g. gui.basic.module)
     */
    onKeyUp(event:any) {
        this._selectedOption = false;
        this._selectedCode = null;
        $("#"+this.name).addClass("open");
    }

    /**
     * @param event
     * onEnter event is fired when user click 'ENTER' on input, it is handling recentSearched
     * array- it can add new item and remove the old one
     */
    onEnter(event:any):void{
        if(!this.selectModeOnly) {
            if (event.target.value.length > 0) {
                //flag to set information that text in input value is selected option, not for filter dictionary
                this._selectedOption = true;
                this.searchedValue.emit(event.target.value);
                $("#"+this.name).removeClass("open");
            }
        } else {
            console.log("Please select option");
            // this._rootShare.addIndicatorNotification(
            //     new NotificationMessage(MessageType.info, this._translate.instant("Please select option"),
            //         this._rootShare.environment.defaultNotificationDuration)
            // );
        }
    }

    /**
     * onEscape void is handling event, when user hits 'ESC' on input
     */
    onEscape():void{
        this.inputValue = "";
        this._selectedOption = false;
        $("#"+this.name).removeClass("open");
    }

    onBlur():void{
        setTimeout(() => {
            if (!this.more && !this._isIPad && !this._selectedOption) {
                this.inputValue = "";
                let input = $("#" + this._inputName);
                input.keyup();

                if (this.select) {
                    this.inputValue = this.select.name;
                    this._selectedCode = this.select.id ? this.select.id : null;
                    this._selectedOption = true;
                }

            } else {
                this.more = false;
            }
        }, 500);
    }

    dictionarySearch(event, item: DictionaryItem):void{
        this.inputValue = item.name;
        this._selectedCode = item.id;
        this._selectedOption = true;
        this.searchedValue.emit(item);
    }

    removeSelectedOption(event){
        this.inputValue = "";
        this._selectedCode = null;
        this._selectedOption = false;
        this.searchedValue.emit(null);
        this.select = null;
    }

    /**
     * Function which adjust showing input-content in case of displayed code. If function won't be called and
     * screen would be small, it could cause overflowing elements.
     * Function is getting input-additional-field width and adding 38 (because it's ~38px from the right)
     * and returning it, to adjust padding-right of text inside input.
     */
    adjustInputPadding() {
        let width = this.inputAdditionalElement.nativeElement.offsetWidth + 38;
        return width;
    }
}

/**
 * Created by mstolarczyk on 2017-03-31.
 */
import {Directive, ElementRef, Input, HostListener, Output, EventEmitter} from "@angular/core";
import * as $ from "jquery";

@Directive({ selector: '[dropDownOpenClose]' })
export class DropDownDirective {
    @Input() triggerId: string;

    @HostListener('click', ['$event']) onClick(event: any) {
        if (this.triggerId == "application") {
            $(".dropdown.open").removeClass("open");
            event.stopPropagation();

            // FIXME: at one point in time we need to repair all checkboxes to not require this
            if ($(".upload-document-component").css('display') !== "block")
                event.preventDefault();
        }
        else
        {
            if (this.triggerId != "blocker") {
                let triggerHasClass: boolean = $("#" + this.triggerId).hasClass("open");
                $(".dropdown.open").removeClass("open");
                if(!triggerHasClass) {
                    $("#" + this.triggerId).addClass("open");
                }
            }
            event.stopPropagation();

            // FIXME: at one point in time we need to repair all checkboxes to not require this
            if ($(".upload-document-component").css('display') !== "block")
                event.preventDefault();
        }
    }
}

/**
 * Created by mstolarczyk on 2017-01-14.
 */

import {Injectable} from "@angular/core";
import {MockService} from "./mock.service";
import {ApiService} from "./api.service";
import * as moment from "moment";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {RootShareService} from "./root.shared.service";
import {Patient} from "../utils/definitions/Patient";
import {Ward} from "../utils/definitions/Ward";

class FindAttendancesRequest {
    attendanceTypes: Array<string>;
    startDate: moment.Moment;
    endDate: moment.Moment;
    wardCodeOrSpecialtyId: string;
    clinicCode: string;
    consultantId: string;
}

class PatientAttendanceRequest {
    patientId: string;
    numberOfPastRecords: number;

    constructor(patientId: string, numberOfLastRecords: number){
        this.patientId = patientId;
        this.numberOfPastRecords = numberOfLastRecords;
    }
}

export class FetchAttendancesFilter {
    wardCodeOrSpecialtyId: string;
    clinicCode: string;
    theaterCode: string;
    consultantId: string;

    constructor() {
        this.consultantId = null;
        this.wardCodeOrSpecialtyId = null;
        this.clinicCode = null;
        this.theaterCode = null;
    }
}

/**
 * Service for working with dictionaries and elements accessible for filters
 */
@Injectable()
export class AttendanceService implements Resolve<any>{
    constructor(private _apiService: ApiService, private _router: Router) {
    }

    fetchAllAttendance(attendanceType: string, fromMonth?: moment.Moment, toMonth?: moment.Moment, filter?: FetchAttendancesFilter): Promise<any> {
        return new Promise((resolve, reject) => {
            let requestData: FindAttendancesRequest = new FindAttendancesRequest();
            requestData.attendanceTypes = new Array<string>();
            requestData.attendanceTypes.push(attendanceType);
            requestData.startDate = fromMonth;
            requestData.endDate = toMonth;
            if (filter) {
                requestData.wardCodeOrSpecialtyId = filter.wardCodeOrSpecialtyId;
                requestData.clinicCode = filter.clinicCode;
                requestData.consultantId = filter.consultantId;
            }
            this._apiService.post("attendances/findPatientAttendances?$expand=patient,clinic,consultant,ward&$sort=startDate", requestData).then(
                (response) => {
                    if (response && response.__data) {
                        resolve(response.__data);
                    }
                    resolve([]);
                },
                (failed) => {
                    //timeout possible
                    console.log('Request timeout error');
                    reject(failed);
                }
            )
        });
    }

    public getInpatientStays(patientId: string, numberOfPastRecords?: number): Promise<any>{
        return new Promise((resolve, reject) => {
            let requestData = new PatientAttendanceRequest(patientId,
                numberOfPastRecords ? numberOfPastRecords : 10000);
            this._apiService.post("attendances/getInpatientStays?$expand=ward", requestData).then(
                (response) => {
                    if (response) {
                        resolve(response);
                    }
                    resolve({current: null, past: null});
                },
                (failed) => {
                    console.log('Request timeout error');
                    reject(failed);
                }
            )
        });
    }

    public getPatientAppointments(patientId: string, numberOfPastRecords?: number): Promise<any>{
        return new Promise((resolve, reject) => {
            let requestData = new PatientAttendanceRequest(patientId,
                numberOfPastRecords ? numberOfPastRecords : 10000);
            this._apiService.post("attendances/getPatientAppointments?$expand=clinic,consultant", requestData).then(
                (response) => {
                    if (response) {
                        resolve(response);
                    }
                    resolve({past: null, future: null});
                },
                (failed) => {
                    console.log('Request timeout error');
                    reject(failed);
                }
            )
        });
    }

    public resolve(route: ActivatedRouteSnapshot): Promise<any> {
        let result = {
            inpatient: {},
            clinics:{}
        };
        return new Promise((resolve, reject) => {
            this.getInpatientStays(route.params["id"]).then((inpatients) => {
                result.inpatient = inpatients;
                this.getPatientAppointments(route.params["id"]).then((clinics) => {
                    result.clinics = clinics;
                    resolve(result);
                })
            }, (fail) => {
                if (fail[1] == 401) {
                    //this._rootShareService.userLogout();
                } else {
                    // this._rootShareService.addIndicatorNotification(
                    //     new NotificationMessage(MessageType.error, fail[0].toString())
                    // );
                    // this._rootShareService.hideIndicatorLoading();
                }
                reject(false);
            })
        })
    }
}
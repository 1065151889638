import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {ChildClinicTypeSuggest} from "../../../../models/forms/referral/child.clinic.type.suggest";
import * as _ from 'lodash';

@Component({
    selector: 'child-clinic-type-suggest-component',
    templateUrl: './child.clinic.type.suggest.html'
})
export class ChildClinicTypeSuggestComponent {
    @Input() childClinicTypeSuggest: ChildClinicTypeSuggest;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }

    clickQ(qn) {
        if (!this.readOnly)
            this.childClinicTypeSuggest[qn] = !this.childClinicTypeSuggest[qn];
    }
}

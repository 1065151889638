import { Injectable } from "@angular/core";
import {ApiService} from "./api.service";
import * as _ from 'lodash';
import * as moment from "moment";

class PatientDocuments {
    private _patientId:string;
    private _documents: Array<any>;

    constructor(__patientId: string, __documents: Array<any>){
        this._patientId = __patientId;
        this._documents = __documents;
    }

    get patientId():string {
        return this._patientId;
    }

    get documents():Array<any> {
        return this._documents;
    }
}

@Injectable()
export class DocumentsService {
    private _patientDocuments: PatientDocuments;
    private _allDocumentCache: {
        patientId: string,
        data: any,
        dtstamp: moment.Moment
    };

    constructor (private apiService: ApiService) {}

    getAllPatientDocuments(patientId:string):Promise<any>{
        return new Promise((resolve) => {
            if(this._patientDocuments && this._patientDocuments.patientId == patientId){
                resolve(this._patientDocuments.documents)
            } else {
                this.fetchAllDocuments(patientId).then(
                    (patientDocuments) => {
                        let sortedPatientDocument = [];
                        let sortConditionArray:Array<any> = _.concat(
                            [ function (f: any) {
                                f.startDate = f.startDate == null ? null : moment(f.startDate);
                                return f.startDate == null ? moment('1900-01-01') : f.startDate;
                            } ]);
                            //this.rootService.environment.subSortDocumentsMetadataProperties, '__id');
                        let documents = _.sortBy(patientDocuments, sortConditionArray);
                        documents = documents.reverse();
                        console.log(patientDocuments);
                        _.forEach(documents, (doc) => {
                            let pDoc = {};
                            pDoc['id'] = doc['__id'];
                            pDoc['name'] = doc.hasOwnProperty('docType') ? doc['docType'].name : "";
                            pDoc['tabName'] = doc.hasOwnProperty('docType') ? doc['docType'].tab ? doc['docType'].tab.name : "" : "";
                            pDoc['startDate'] = doc.hasOwnProperty('startDate') && doc['startDate'] != null ? moment(doc['startDate']) : null;
                            pDoc['specialty'] = doc.hasOwnProperty('specialty') && doc['specialty'] != null ? doc['specialty'] : "";
                            pDoc['thumbUrl'] = this.apiService.url + doc['__href'].substr(1) + '/pages/' + 1 + '/thumbnail/image';
                            pDoc['thumbPage1'] = this.apiService.url + doc['__href'].substr(1) + '/pages/' + 1 + '/image';
                            pDoc['thumbPage2'] = this.apiService.url + doc['__href'].substr(1) + '/pages/' + 2 + '/image';
                            pDoc['requiredBreakGlass'] = doc.hasOwnProperty('requiresBreakGlass') ? doc['requiresBreakGlass'] : false;
                            pDoc['comments'] = doc.hasOwnProperty('comments') ? doc['comments'] : false;
                            pDoc['metadata'] = doc.hasOwnProperty('metadata') ? doc['metadata'] : false;
                            pDoc['contentType'] = (doc.hasOwnProperty('metadata') && doc['metadata'].hasOwnProperty("contentType")) ? doc['metadata']["contentType"] : null;
                            pDoc['imageUrl'] = (doc.hasOwnProperty('metadata') && doc['metadata'].hasOwnProperty("url")) ? doc['metadata']["url"] : null;
                            pDoc['pageCount'] = doc.hasOwnProperty('pageCount') ? doc['pageCount'] : 1;
                            if(pDoc["name"] != "ATTN") {
                                sortedPatientDocument.push(pDoc);
                            }
                        });
                        this.setPatientDocuments(patientId, sortedPatientDocument);
                        resolve(sortedPatientDocument);
                    }
                )
            }
        });
    }

    fetchAllDocuments(patientId): Promise<any> {
        return new Promise((resolve, reject) => {
            if(this._allDocumentCache && this._allDocumentCache.patientId == patientId && moment(this._allDocumentCache.dtstamp).add(3, "minutes").isAfter(moment())){
                resolve(this._allDocumentCache.data);
            } else {
                this.apiService.get("patients/" + patientId + "/documents").then(
                    (response) => {
                        if (response && response.__data) {
                            this._allDocumentCache = {
                                patientId: patientId,
                                data: response.__data,
                                dtstamp: moment()
                            };
                            resolve(this._allDocumentCache.data);
                        }
                        resolve([]);
                    },
                    (failed) => {
                        reject(failed);
                    }
                )
            }
        });
    }

    getDocumentByID(patientID: string, documentID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.get("patients/" + patientID + "/documents/" + documentID).then(
                (response) => {
                    let pDoc = {};
                    let doc = response;
                    pDoc['id'] = doc['__id'];
                    pDoc['name'] = doc.hasOwnProperty('docType') ? doc['docType'].name : "";
                    pDoc['tabName'] = doc.hasOwnProperty('docType') ? doc['docType'].tab ? doc['docType'].tab.name : "" : "";
                    pDoc['startDate'] = doc.hasOwnProperty('startDate') && doc['startDate'] != null ? moment(doc['startDate']) : null;
                    pDoc['specialty'] = doc.hasOwnProperty('specialty') && doc['specialty'] != null ? doc['specialty'] : "";
                    pDoc['thumbUrl'] = this.apiService.url + doc['__href'].substr(1) + '/pages/' + 1 + '/thumbnail/image';
                    pDoc['thumbPage1'] = this.apiService.url + doc['__href'].substr(1) + '/pages/' + 1 + '/image';
                    pDoc['thumbPage2'] = this.apiService.url + doc['__href'].substr(1) + '/pages/' + 2 + '/image';
                    pDoc['requiredBreakGlass'] = doc.hasOwnProperty('requiresBreakGlass') ? doc['requiresBreakGlass'] : false;
                    pDoc['comments'] = doc.hasOwnProperty('comments') ? doc['comments'] : false;
                    pDoc['metadata'] = doc.hasOwnProperty('metadata') ? doc['metadata'] : false;
                    pDoc['contentType'] = (doc.hasOwnProperty('metadata') && doc['metadata'].hasOwnProperty("contentType")) ? doc['metadata']["contentType"] : null;
                    pDoc['imageUrl'] = (doc.hasOwnProperty('metadata') && doc['metadata'].hasOwnProperty("url")) ? doc['metadata']["url"] : null;
                    pDoc['pageCount'] = doc.hasOwnProperty('pageCount') ? doc['pageCount'] : 1;
                    resolve(pDoc);
                }, (error) => {
                    reject(error);
                }
            )
        })
    }

    setPatientDocuments(patientId:string, documents:Array<any>){
        this._patientDocuments = new PatientDocuments(patientId, documents);
    }

    addDocumentComment(patientID: string, documentID: string, comment: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let data: any = {};
            data['text'] = comment;

            this.apiService.post("patients/" + patientID + "/documents/" + documentID + "/comments", data)
                .then(
                    (response) => {
                        resolve(true);
                    }, (error) => {
                        resolve(false);
                    }
                )
        })
    }

    editDocumentComment(patientID: string, documentID: string, commentID: string, comment: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let data: any = {};
            data['id'] = commentID;
            data['text'] = comment;

            this.apiService.post("patients/" + patientID + "/documents/" + documentID + "/comments", data)
                .then(
                    (response) => {
                        resolve(true);
                    }, (error) => {
                        resolve(false);
                    }
                )
        })
    }

    deleteDocumentComment(patientID: string, documentID: string, commentID: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apiService.delete("patients/" + patientID + "/documents/" + documentID + "/comments/" + commentID)
                .then((response) => {
                    resolve(true);
                }, (error) => {
                    resolve(false);
                })
        })
    }
}
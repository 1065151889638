export class AdultClinicTypeSuggest {
    Cataract: boolean;
    Cornea: boolean;
    DiabeticMedicalRetina: boolean;
    ExternalEyeDisease: boolean;
    Glaucoma: boolean;
    LaserYAG: boolean;
    LowVision: boolean;
    OculoplasticsOrbitsLacrimal: boolean;
    OtherMedicalRetinaIncARMD: boolean;
    SquintOcularMotility: boolean;
    Vitreoretinal: boolean;
    NotOtherwiseSpecified: boolean;

    public constructor(){
        this.Cataract = false;
        this.Cornea = false;
        this.DiabeticMedicalRetina = false;
        this.ExternalEyeDisease = false;
        this.Glaucoma = false;
        this.LaserYAG = false;
        this.LowVision = false;
        this.OculoplasticsOrbitsLacrimal = false;
        this.OtherMedicalRetinaIncARMD = false;
        this.SquintOcularMotility = false;
        this.Vitreoretinal = false;
        this.NotOtherwiseSpecified = false;
    }
}

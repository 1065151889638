import {Component, Input} from '@angular/core';
import {PatientData} from "../../models/patient.model";

@Component({
    selector: 'patient-chat-details',
    templateUrl: './patient-chat-details.component.html'
})
export class PatientChatDetails {

    @Input() patient: PatientData;
    @Input() image: string = "../../assets/img/users_photo/female/21.jpg";

}
import {Moment} from "moment";
import * as moment from "moment";

export class AuditDetailsType {
    username: string;
    fullname: string;
    printedDate: Moment;

    public constructor(){
        // image i mean user image?
        this.username = "";
        this.fullname = "";
        this.printedDate = moment();
    }
}

/**
 * Created by mstolarczyk on 2017-02-07.
 */
import {BaseClass} from "./BaseClass";
import {ApiService} from "../../services/api.service";
import {DictionaryInterface} from "./Dictionary";
import {Hospital} from "./Hospital";

export class Ward extends BaseClass implements DictionaryInterface{
    wardCode:string;
    wardName:string;
    hospital:Hospital;

    constructor(private apiService: ApiService) {
        super(apiService);
    }

    get wardId() {
        return this.__id;
    }

    getDisplayName(): string {
        return  this.wardName;
    }

    getDictId(): string {
        return this.__id;
    }

    getDictCode():string {
        return this.wardCode;
    }

    static getFilterString() {
        return "name regex '###.*' OR _id regex '###.*'";
    }

    static getDefaultSort(): string {
        return "name";
    }
}
import {Component, Input, Output, EventEmitter, SimpleChanges, OnChanges} from "@angular/core";
import * as moment from "moment";
import * as _ from "lodash";
import {DaysFilter} from "../../models/daysFilter.model";

export class CalendarDay{
    date: moment.Moment;
    displayDay: string;
    classes: Array<string>;

    constructor(day: moment.Moment, classes:Array<string>){
        this.date = day;
        this.displayDay = day.format('D');
        this.classes = classes;
    }
}

@Component({
    selector: 'ui-calendar',
    templateUrl: './calendar.element.html',
    styleUrls: ['./calendar.element.scss']
})
export class UiCalendar implements OnChanges {
    //private array of weekdays names
    public weekdays: string[];
    //private array of weekdays short names
    public weekdaysShort: string[];
    //private array of month names
    public months: string[] = moment.months();
    //private name of first day of week, default Sunday, can be change in config.json
    public firstDayOfWeek = "Monday";
    //private array of array defines weeks and days to show in calendar
    public monthWeeks: Array<Array<CalendarDay>> = [];
    //private number of month for calendar, calculated value according input monthYear string
    public _month: number;
    //private number of year for calendar, calculated value according input monthYear string
    public _year: number;

    private _selectedDay: moment.Moment;

    constructor(){
    }

    //monthYear input string value should be YYYY-MM
    @Input () monthYear:string;

    //0 means January
    @Input () month: number;

    //number of year
    @Input () year: number;

    @Input ('selected-date') selectedDate: string;  //mustBe YYYY-MM-DD format

    //array of active dates for calendar, those date determinate day indicator available for choose
    @Input ('active-dates') activeDates: Array<moment.Moment> = [];

    //Event emitter for send chosen day in calendar
    @Output ('choose-day') chooseDay = new EventEmitter();

    //Event emitter for send filter day in calendar
    @Output ('filterStart-day') filterStartDay = new EventEmitter();

    //Event emitter for send filter day in calendar
    @Output ('filterEnd-day') filterEndDay = new EventEmitter();

    //array of active dates for calendar, those date determinate day indicator available for choose
    @Input ('filtered-dates') filteredDates: DaysFilter;

    /**
     * Init function for prepare
     */
    ngOnInit(){
        let firstDayOfWeekIndex:number = 0;
        firstDayOfWeekIndex = moment.weekdays().indexOf(this.firstDayOfWeek);
        this.weekdaysShort = moment.weekdaysShort().splice(firstDayOfWeekIndex,moment.weekdaysShort().length - firstDayOfWeekIndex);
        for(let i = 0;i < firstDayOfWeekIndex;i++){
            this.weekdaysShort.push(moment.weekdaysShort()[i]);
        }
    }

    /**
     * Function watches change of monthYear input value, that change determinate redefine monthWeeks array
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) : void {
        if (changes.hasOwnProperty('monthYear')) {
            this.monthWeeks.splice(0, this.monthWeeks.length);
            let startDay = moment(changes['monthYear'].currentValue, "YYYY-MM");
            this._month = startDay.month();
            this._year = startDay.year();
            let endDay = moment(startDay).add(startDay.daysInMonth() - 1, 'days');
            let firstDateOfFirstWeek = moment(startDay);
            while (firstDateOfFirstWeek.format('dddd') != this.firstDayOfWeek) {
                firstDateOfFirstWeek.add(-1, 'days');
            }
            let lastDayOfLastWeekOfMonth = moment(endDay);
            lastDayOfLastWeekOfMonth.add(1, 'days');
            while (lastDayOfLastWeekOfMonth.format('dddd') != this.firstDayOfWeek) {
                lastDayOfLastWeekOfMonth.add(1, 'days');
            }
            lastDayOfLastWeekOfMonth.add(-1, 'days');
            let daysInCalendar = moment(firstDateOfFirstWeek).add(-1, 'days');
            while (daysInCalendar < lastDayOfLastWeekOfMonth || this.monthWeeks.length < 6) {
                let tmpWeek: Array<CalendarDay> = [];
                for (let i = 0; i < 7; i++) {
                    daysInCalendar.add(1, 'days');
                    let classes: Array<string> = [];
                    if (daysInCalendar.month() != this._month) {
                        classes.push('disabled');
                    }
                    if (daysInCalendar.isSame(moment(), 'day')) {
                        classes.push('today');
                    }
                    if (_.findIndex(this.activeDates, function (activeDate) {
                            return activeDate.isSame(daysInCalendar, 'day');
                        }) > -1) {
                        // if (daysInCalendar <= moment()) {
                        //     classes.push('past');
                        // } else {
                        //     classes.push('active');
                        // }
                        classes.push('active');
                    }
                    if (daysInCalendar.isSame(this._selectedDay, 'day')) {
                        classes.push('selected');
                    }
                    tmpWeek.push(new CalendarDay(moment(daysInCalendar), classes));
                }
                this.monthWeeks.push(tmpWeek);
            }
        }
        if (changes.hasOwnProperty('activeDates')) {
            let newActiveDays: Array<moment.Moment> = changes['activeDates'].currentValue;
            _.forEach(this.monthWeeks, (week) => {
                _.forEach(week, (day) => {
                    day.classes.splice(0, day.classes.length);
                    if (day.date.month() != this._month) {
                        day.classes.push('disabled');
                    }
                    if (day.date.isSame(moment(), 'day')) {
                        day.classes.push('today');
                    }
                    if (_.findIndex(newActiveDays, function (activeDate) {
                            return activeDate.isSame(day.date, 'day');
                        }) > -1) {
                        // if (day.date <= moment()) {
                        //     day.classes.push('past');
                        // } else {
                        //     day.classes.push('active');
                        // }
                        day.classes.push('active');
                    }
                    if(this.filteredDates){
                        if (day.date.format("YYYY-MM-DD") >= this.filteredDates.firstDay
                            && day.date.format("YYYY-MM-DD") <= this.filteredDates.lastDay) {
                            day.classes.push("filterDay");

                        }
                    }
                    if (day.date.isSame(this._selectedDay, 'day')) {
                        day.classes.push('selected');
                    }
                })
            })
        }

        if (changes.hasOwnProperty('filteredDates')) {
           _.forEach(this.monthWeeks, (week) => {
                _.forEach(week, (day) => {
                    let pickedIndex = day.classes.indexOf('filterDay');
                    if (pickedIndex > -1) day.classes.splice(pickedIndex, 1);
                    if (day.date.format("YYYY-MM-DD") >= changes['filteredDates'].currentValue.firstDay
                        && day.date.format("YYYY-MM-DD") <= changes['filteredDates'].currentValue.lastDay) {
                        day.classes.push("filterDay");

                    }
                })
            })
        }

        if (changes.hasOwnProperty('selectedDate')) {
            try {
                this._selectedDay = moment(changes['selectedDate'].currentValue, "YYYY-MM-DD");
                _.forEach(this.monthWeeks, (week) => {
                    _.forEach(week, (day) => {
                        let pickedIndex = day.classes.indexOf('selected');
                        if (pickedIndex > -1) day.classes.splice(pickedIndex, 1);
                        if (day.date.isSame(this._selectedDay, 'day')) {
                            day.classes.push("selected");

                        }
                    })
                })
            } catch(ex) {
                console.log(ex);
            }
        }
    }

    altDown = false;
    documentKeyDown(event) {
        if (event.keyCode == 18) {
            this.altDown = true;
       }
    }

    documentKeyUp(event) {
        this.altDown = false;
    }

    dayClick(event, day){
        if (day.classes.indexOf('past') - day.classes.indexOf('disabled') >= 0) {
            if (!this.altDown) {
                this.chooseDay.emit(moment({year: this._year, month: this._month, 'day': parseInt(day.displayDay)}));
            }
        }
        if (this.altDown) this.filterStartDay.emit(moment({year: this._year, month: this._month, 'day': parseInt(day.displayDay)}));
    }

    dayRightClick(event, day){
        if(this.altDown) {
            this.filterEndDay.emit(moment({year: this._year, month: this._month, 'day': parseInt(day.displayDay)}));
        }
        return false;
    }
}
import {Component, Input} from '@angular/core';

@Component({
    selector: 'ui-spinner',
    templateUrl: './spinner.element.html'
})
export class UiSpinner {
    @Input() label: string = "Loading...";
    @Input() blurBackground: boolean = false;
    @Input() center: boolean = false;
    @Input() marginTop: string = '88px';
    @Input() color: string = '#999';

    getClasses(text) {
        let classes: Array<string> = [];

        if (text == "container") {
            classes.push("loader-absolute");
            if (this.blurBackground)
                classes.push("background-blur");
        } else if (text == "loader") {
            classes.push("loader loader-primary");
            if (this.center)
                classes.push("loader-center");
        }

        return classes;
    }
}
import {Moment} from "moment";
import * as moment from "moment";

export class VteStage1 {
    // Surgical patient
    // Medical patient expected to have ongoing reduced mobility relative to normal state
    // Medical patient NOT expected to have significantly reduced mobility relative to normal state
    q1: boolean;
    q2: boolean;
    q3: boolean;

    public constructor(){
        this.q1 = false;
        this.q2 = false;
        this.q3 = false;
    }
}

import {Component, OnInit} from '@angular/core';
import {ConversationDetailsComponent} from "../../components/user-chat/user-chat.component";
import {UsersService} from "../../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardData} from "../../models/dashboardData.model";
import { AuthService } from "../../services/auth.service";
import * as _ from "lodash";
import {RootShareService} from "../../services/root.shared.service";


@Component({
  templateUrl: './dashboard.module.html'
})
export class DashboardModule implements OnInit {
    basicData: DashboardData;

    constructor(private _router: Router, private _route: ActivatedRoute,
                public userService: UsersService, public authService: AuthService,
                public rootShared: RootShareService){
    }

    ngOnInit() {
        this._route.data.subscribe(
            data => {
                this.authService.getUser().then((v) => {
                  let referrals = 0;
                  _.forEach(data.referrals, (f) => {
                    switch (f.formName) {
                      case 'referral':
                        if (f.userId === v.id && f.status === "save") {
                          referrals++;
                        }
                        break;
                    }
                  });
                  let tasks = 0;
                  _.forEach(data.tasks, (f) => {
                    switch (f.formName) {
                      case 'task':
                        if (f.taskUser === v.id && f.status !== "done") {
                          tasks++;
                        }
                        break;
                    }
                  });
                  this.basicData = new DashboardData();
                  this.basicData.news = ["IDA has prioritised the following for you"];
                  this.basicData.latestPatients = 11;
                  this.basicData.newMessages = 3;
                  this.basicData.tasks = tasks;
                  this.basicData.referrals = referrals;
                  this.rootShared.unreadNotifications.messages = 3;
                  this.rootShared.unreadNotifications.tasks = tasks;
                  this.rootShared.unreadNotifications.referrals = referrals;
                });
            },
            error => {
                console.log(error);
            }
        );
    }

    hello(): string {
        let resMsg = "Morning";
        let hr = (new Date()).getHours();
        if(hr >= 12) resMsg = "Good Afternoon";
        if(hr > 17) resMsg = "Good Evening";
        return resMsg;
    }

    goto(e, where: string) {
        e.preventDefault(); e.stopPropagation();
        this._router.navigate([where]);
    }
}

import { Component } from '@angular/core';
import { AuthService } from "./services/auth.service";
import { MainService } from "./services/main.service";
import { Router } from "@angular/router";
import {MessageService} from "./services/messages.service";
import {RootShareService} from "./services/root.shared.service";

@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.css'],
    templateUrl: './app.component.html'
})
export class AppComponent {

    constructor(public authService: AuthService, public mainService: MainService, private messageService: MessageService,
                private _rootShared: RootShareService){
    }

    ngAfterViewInit(){

        // let that = this;
        // setTimeout(function(){
        //     //console.log(that.messageService.newMessageForAllChats);
        //     that._rootShared.unreadNotifications.messages = that.messageService.newMessageForAllChats;
        // },1000);

    }
}

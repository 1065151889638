import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../../services/auth.service";
import {RootShareService} from "../../../../../services/root.shared.service";
import {FundasExam} from "../../../../../models/forms/referral/fundas.exam";

@Component({
    selector: 'fundas-exam-component',
    templateUrl: './fundas.exam.html'
})
export class FundasExamComponent {
    @Input() fundasExam: FundasExam;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

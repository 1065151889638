import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {PatientGeneralHistory} from "../../../../models/forms/referral/patient.general.history";
import {VteStage1} from "../../../../models/forms/vte/vte.stage1";
import {VteStage3} from "../../../../models/forms/vte/vte.stage3";
import * as _ from 'lodash';

@Component({
    selector: 'vte-stage3-component',
    templateUrl: './vte.stage3.html'
})
export class VteStage3Component {
    @Input() stage3: VteStage3;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }

    clickQ(qn) {
        if (!this.readOnly)
            this.stage3[qn] = !this.stage3[qn];
    }
}

export class VisualAcuity {
    testName = "VisualAcuity";

    visualAcuityOD: string;
    visualAcuityOS: string;

    public constructor(){
        this.visualAcuityOD = null;
        this.visualAcuityOS = null;
    }
}

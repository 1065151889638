import {Address} from "./address.model";
import {JsonProperty} from "./mapUtil";

export enum Gender {
    male, female
}

export class UserData {
    @JsonProperty('__id')
    id: string;
    title: string;
    @JsonProperty('first-name')
    firstName: string;
    @JsonProperty('last-name')
    lastName: string;
    familyName: string;
    _userName: string;
    emplacement: string;
    gender: Gender;
    phone: string;
    mobile: string;
    email: string;
    photo: string;
    @JsonProperty('address')
    address: Address;

    public constructor(){
        this.id = undefined;
        this.title = undefined;
        this.firstName = undefined;
        this.lastName = undefined;
        this.familyName = undefined;
        this.gender = undefined;
        this.phone = undefined;
        this.mobile = undefined;
        this.email = undefined;
        this.photo = undefined;
        this.address = undefined;
        this.emplacement = undefined;
        this._userName = "";
    }

    get username():string {
        if(this.title && this.title.length > 0){
            return this.title + " " + this._userName;
        } else {
            return this._userName;
        }
    }
}


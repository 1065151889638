import { Injectable } from "@angular/core";
import {UserData} from "../models/user.model";
import {MapUtils} from "../models/mapUtil";
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";
import {MockService} from "./mock.service";
import {DashboardData} from "../models/dashboardData.model";

@Injectable()
export class DashboardService {

    constructor (public mockService:MockService, private _authService:AuthService) {}

    getCurrentUserDashboardData(): Promise<DashboardData> {
        let that = this;
        return new Promise((resolve, reject) => {
            if(environment.mockUser){
                this.mockService.getData("getUserDashboardData", that._authService.status.userId).then(
                    (dashboardData) => {
                        let dd:DashboardData = MapUtils.deserialize(DashboardData, dashboardData);
                        resolve(dd);
                    },
                    (error) => {
                        reject(error);
                    }
                )
            } else {
                console.log('get user data from API');
                resolve(new DashboardData());
            }
        });
        // return new Promise((resolve, reject) => {
        //     if (this._authService._currentUser)
        //         resolve(this._authService._currentUser);
        //     else
        //         reject();
        // })
    }

    resolve(): Promise<DashboardData> {
        return this.getCurrentUserDashboardData();
    }

}
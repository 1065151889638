import {Dictionary, DictionaryInterface} from "../utils/definitions/Dictionary";

export class SearchDictionary {
    name: string;
    displayName: string;
    dictionary: Dictionary;
    sort: string;
    filter: dictionaryFilter;
}

export enum dictionaryFilterType {
    consultant,
    clinic
}

export class dictionaryFilter {
    public type: dictionaryFilterType;
    public id: string;
    public dataFrom: string;
    public dataTo: string;

    constructor(type, id, dataFrom, dataTo){
        this.type = type;
        this.id = id;
        this.dataFrom = dataFrom;
        this.dataTo = dataTo;
    }
}

export class DictItem implements DictionaryInterface {
    __id: string;
    __code: string;
    __description: string;

    constructor(id, code, description){
        this.__id = id;
        this.__code = code;
        this.__description = description;
    }

    getDisplayName(): string {
        return this.__description;
    }
    getDictId(): string {
        return this.__id;
    }
    getDictCode(): string {
        if(this.__code) {
            return this.__code;
        } else {
            return this.__id
        }
    }
}

export class DictResponse {
    page: number;
    pageSize: number;
    pageCount: number;
    itemsCount: number;
    data: Array<DictionaryInterface>;
}

export class DictRequestOptions {
    $page: number = 1;
    $pageSize: number = 100;
    $filter: string;
    $sort: string;
}
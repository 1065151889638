import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {RootShareService} from "../../services/root.shared.service";
import {AuthService} from "../../services/auth.service";
import {WatsonService} from "../../services/watson.service";
import {PatientTimelineModule} from "../../modules/patient-timeline/patient-timeline.module";
import * as _ from "lodash";
import * as $ from "jquery"
import {PatientHistory} from "../../models/forms/referral/patient.history";
import {PastOcularHistory} from "../../models/forms/referral/past.ocular.history";
import {Allergies} from "../../models/forms/referral/allergies";
import {IOP} from "../../models/forms/referral/iop";
import {VisualAcuity} from "../../models/forms/referral/visual.acuity";
import {PupilExam} from "../../models/forms/referral/pupil.exam";
import {OcularMotilityAndMuscleBalance} from "../../models/forms/referral/ocular.motility.and.muscle.balance";
import {VisualField} from "../../models/forms/referral/visual.field";
import {ExternalExam} from "../../models/forms/referral/external.exam";
import {SlitLamp} from "../../models/forms/referral/slit.lamp";
import {CustomFormsService} from "../../services/custom.forms.service";

const assistantType : any = "watson";
// const assistantType : any = "manual";

export class WatsonMessage {
    userId: string;
    userPhoto: string;
    color: string;
    msgDate: Date;
    message: string
}

@Component({
    selector: 'patient-timeline-watson',
    templateUrl: './patient-timeline-watson.component.html',
    styleUrls: ['./patient-timeline-watson.component.scss']

})
export class PatientTimelineWatsonComponent implements OnInit, OnDestroy {
    private _authorization:any;
    public messageText: string = undefined;
    @Input() conversation: Array<WatsonMessage>;
    @Output() closeAction = new EventEmitter();

    @Output() messageReceived = new EventEmitter();
    @Input() event;
    @Input() parent: PatientTimelineModule;

    private subscription: any = null;
    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    currentStage = 0;
    selectedNote = null;

    ngOnInit(): void {
        let that = this;
        this.currentStage = 0;
        this.subscription = this.event.subscribe(() => {
            that.conversation = [];
            if (assistantType === "manual") {
                // compose current patient history ....
                if (that.parent && that.parent.noteForms) {
                    let message = "";
                    let summary = "";
                    _.forEach(that.parent.noteForms, (noteForm) => {
                        if (/^%SUMMARY%$/gm.test(noteForm.note)) {
                            let summaryExec = /^%SUMMARY_START%$\n^([^]*)^%SUMMARY_END%$/gm.exec(noteForm.note);
                            summary = summaryExec[1];
                            summary = summary.replace(/^%.*$\n/gm, "");
                            summary = summary.replace(/\n/g, "<br />");

                            that.selectedNote = noteForm;
                        }
                    });
                    message = summary;
                    let wm = new WatsonMessage();
                    wm.userId = this._watson.watsonId;
                    wm.userPhoto = this._watson.watsonPhoto;
                    wm.color = "watson-blue";
                    wm.msgDate = new Date();
                    wm.message = message;
                    this.conversation.push(wm);

                    message = "Please confirm this summary is accurate";

                    let wm2 = new WatsonMessage();
                    wm2.userId = this._watson.watsonId;
                    wm2.userPhoto = this._watson.watsonPhoto;
                    wm2.color = "watson-blue";
                    wm2.msgDate = new Date();
                    wm2.message = message;
                    this.conversation.push(wm2);

                    this.currentStage = 1;
                }
            } else {
                that.newMessage("Hello", true);
            }
        });
    }

    constructor(private _rootShareService: RootShareService, private _customFormsService: CustomFormsService,
                private _auth: AuthService, private _watson: WatsonService) {
    }

    closeWatson(event){
        this.closeAction.emit(event);
    }

    onKeypress(event) {
        let wm = new WatsonMessage();
        wm.userId = this._auth.status.userId;
        wm.userPhoto = this._auth.currentUser.photo;
        wm.msgDate = new Date();
        wm.message = this.messageText;
        this.conversation.push(wm);
        setTimeout(()=>{
            let objDiv = document.getElementById("message-block");
            objDiv.scrollTop = objDiv.scrollHeight;
        },1);
        if (assistantType === "manual") {
            this.askManual(this.messageText);
        } else {
            this.askWatson(this.messageText, false);
        }
        this.messageText = null;
    }

    newMessage(message, ignorePush=false) {
        let wm = new WatsonMessage();
        wm.userId = this._auth.status.userId;
        wm.userPhoto = this._auth.currentUser.photo;
        wm.msgDate = new Date();
        wm.color = "normal-color";
        wm.message = message;
        if (!ignorePush)
            this.conversation.push(wm);
        setTimeout(()=>{
            let objDiv = document.getElementById("message-block");
            objDiv.scrollTop = objDiv.scrollHeight;
        },1);
        this.askWatson(message, ignorePush);
    }

    private static getValueFromText1(valuename: string, text: string) {
        let valueExec = new RegExp('^%' + valuename + '_START%$\\n^([^]*)^%' + valuename + '_END%$', 'gm').exec(text);
        return valueExec && valueExec.length > 1 ? valueExec[1] : "";
    }

    eyeExamForm = null;

    private askManual(text: string) {
        switch(this.currentStage) {
            case 1:
                let var_HISTORY = PatientTimelineWatsonComponent.getValueFromText1("HISTORY", this.selectedNote.note);
                let var_PAST_OCULAR = PatientTimelineWatsonComponent.getValueFromText1("PAST_OCULAR", this.selectedNote.note);
                let var_FAMILY_HISTORY = PatientTimelineWatsonComponent.getValueFromText1("FAMILY_HISTORY", this.selectedNote.note);
                let var_OCULAR_MED = PatientTimelineWatsonComponent.getValueFromText1("OCULAR_MED", this.selectedNote.note);
                let var_SYSTEMIC_MED = PatientTimelineWatsonComponent.getValueFromText1("SYSTEMIC_MED", this.selectedNote.note);
                let var_ALLERGIES_MED = PatientTimelineWatsonComponent.getValueFromText1("ALLERGIES_MED", this.selectedNote.note);

                this.eyeExamForm = this.parent.addEyeExam();
                this.eyeExamForm.historyOfPresentingSymptom.reasonForReferral = var_HISTORY;
                // this.eyeExamForm.historyOfPresentingSymptom.watsonPresentingComplaint = var_PAST_OCULAR;

                let pastOcularHistory = new PastOcularHistory();
                pastOcularHistory.pastOcularHistory = var_PAST_OCULAR;
                pastOcularHistory.pastFamilyOcularHistory = var_FAMILY_HISTORY;
                this.eyeExamForm.patientGeneralHistory.push(pastOcularHistory);

                // eyeExamForm.patientHistory.push(new PatientHistory().) = var_HISTORY;

                let allergie = new Allergies();
                allergie.allergy = var_ALLERGIES_MED;
                this.eyeExamForm.patientAllergy.push(allergie);

                // Please confirm this summary is accurate
                {
                    let wm2 = new WatsonMessage();
                    wm2.userId = this._watson.watsonId;
                    wm2.userPhoto = this._watson.watsonPhoto;
                    wm2.color = "watson-blue";
                    wm2.msgDate = new Date();
                    wm2.message = "Has there been any change in your medication since your last appointment 15 months ago?";
                    this.conversation.push(wm2);
                }
                this.currentStage = 2;
                break;
            case 2:
                // Has there been any change in your medication since your last appointment 15 months ago?
                {
                    let wm2 = new WatsonMessage();
                    wm2.userId = this._watson.watsonId;
                    wm2.userPhoto = this._watson.watsonPhoto;
                    wm2.color = "watson-blue";
                    wm2.msgDate = new Date();
                    wm2.message = "Lets start the eye exam. What are the Eye Exam Results?";
                    this.conversation.push(wm2);
                }
                this.currentStage = 3;
                break;
            case 3:
                let form:any;

                /*
                    Visual Acuity: OD 6/6 and OS 6/6;
                    Interocular Pressure: OD 21 and OS 23;
                    Pupil Exam: Equal, round and reactive OU;
                    Ocular Motility and Muscle Balance: Full OU;
                    Confrontational Visual Field: Full to finger counting OU;
                    External: Normal OU;
                    Lids and Lashes: Normal OU;
                    Conjunctiva/Sclera: Normal OU;
                    Cornea: Clear OU;
                    Anterior Chamber: Deep and quiet OU;
                    Iris: Normal OU;
                    Lens: 1+ nuclear sclerotic cataracts OU;
                    Anterior Vitreous: Clear OU;
                 */

                form = new VisualAcuity(); form.visualAcuityOD = "6/6"; form.visualAcuityOS = "6/6";
                this.eyeExamForm.tests.push(form);
                form = new IOP(); form.IOPOD = "21"; form.IOPOS = "23";
                this.eyeExamForm.tests.push(form);
                form = new PupilExam(); form.descriptionOD = "Equal, round and reactive OU"; form.descriptionOS = "Equal, round and reactive OU";
                this.eyeExamForm.tests.push(form);
                form = new OcularMotilityAndMuscleBalance(); form.extraocularMovementsOD = "Full OU"; form.extraocularMovementsOS = "Full OU";
                this.eyeExamForm.tests.push(form);
                form = new VisualField(); form.visualFieldAnalysisOD = "Full to finger counting OU"; form.visualFieldAnalysisOS = "Full to finger counting OU";
                this.eyeExamForm.tests.push(form);
                form = new ExternalExam(); form.descriptionOD = "Normal OU"; form.descriptionOS = "Normal OU";
                this.eyeExamForm.tests.push(form);
                form = new SlitLamp();
                form.lidsLashesOD = "Normal OU";
                form.lidsLashesOS = "Normal OU";
                form.lensOD = "1+ nuclear sclerotic cataracts OU";
                form.lensOS = "1+ nuclear sclerotic cataracts OU";
                form.anteriorChamberOD = "Deep and quiet OU";
                form.anteriorChamberOS = "Deep and quiet OU";
                form.irisOD = "Normal OU";
                form.irisOS = "Normal OU";
                form.conjunctivaScleraOD = "Normal OU";
                form.conjunctivaScleraOS = "Normal OU";
                form.corneaOD = "Clear OU";
                form.corneaOS = "Clear OU";
                form.anteriorVitreousOD = "Clear OU";
                form.anteriorVitreousOS = "Clear OU";
                this.eyeExamForm.tests.push(form);

                this.eyeExamForm.diagnose.diagnosis = "";
                this.eyeExamForm.diagnose.watsonDiagnosis = "Primary open-angle glaucoma with a confidence of 90%";
                this.eyeExamForm.diagnose.snomedWatsonDiagnosis = "77075001";
                this.eyeExamForm.diagnose.watsonDifferentialDiagnosis = "Steroid-induced glaucoma, \n" +
                    "Pigmentary glaucoma, \n" +
                    "Pseudoexfoliation glaucoma, \n" +
                    "Trauma-induced glaucoma, \n" +
                    "Intermittent angle-closure glaucoma, \n" +
                    "Optic neuropathy\n";
                this.eyeExamForm.diagnose.watsonDiagnosisEvidence = "This patient is presenting with primary open-angle glaucoma (POAG). The diagnosis is supported by evidence of increased IOP (normal is 8 to 21 mmHg), optic nerve head abnormality, open anterior chamber angle, visual field deficits and no history to suggest a secondary glaucoma (glaucoma due to an identifiable cause).";

                this.saveElement();

                // Lets start the eye exam. what are the Eye Exam Results
                {
                    let wm2 = new WatsonMessage();
                    wm2.userId = this._watson.watsonId;
                    wm2.userPhoto = this._watson.watsonPhoto;
                    wm2.color = "watson-blue";
                    wm2.msgDate = new Date();
                    wm2.message = "Thanks, I think we're done!";
                    this.conversation.push(wm2);
                }
                this.currentStage = 4;
                break;
            case 4:
                {
                    let wm2 = new WatsonMessage();
                    wm2.userId = this._watson.watsonId;
                    wm2.userPhoto = this._watson.watsonPhoto;
                    wm2.color = "watson-blue";
                    wm2.msgDate = new Date();
                    wm2.message = "That's all for now. Consult me next time please!";
                    this.conversation.push(wm2);
                }
                break;
        }
    }

    saveElement() {
        // noinspection all
        // let formToSave = _.cloneDeep(this.referralForm);
        // // FIXME: !!!!!
        // formToSave.status = "save";
        this.eyeExamForm.status = "save";

        // TODO: PUBLISH at some point !!!
        // this._customFormsService.saveForm(formToSave).then((v) => {
        //     // alert(v);
        // });
        this._customFormsService.saveForm(this.eyeExamForm).then((v:any) => {
            this.eyeExamForm._id = v._id;
            if (this.parent) {
                // this.parent.updateElements();
            }
        });
    }

    private askWatson(text: string, ignorePush) {
        this._watson.askWatson(text, ignorePush).then((answer: any)=>{
            let wm = new WatsonMessage();
            wm.userId = this._watson.watsonId;
            wm.userPhoto = this._watson.watsonPhoto;
            wm.color = "watson-blue";
            wm.msgDate = new Date();
            wm.message = answer.text;
            this.messageReceived.emit(answer);
            this.conversation.push(wm);
            setTimeout(()=>{
                let objDiv = document.getElementById("message-block");
                objDiv.scrollTop = objDiv.scrollHeight;
            },5);
        })

    }

}

import moment = require("moment");
import {ChecklistStage1} from "./checklist.stage1";
import {ChecklistStage2} from "./checklist.stage2";
import {ChecklistStage3} from "./checklist.stage3";

export class ChecklistForm {
    _id: any;
    status: string;
    workflow: string = 'open';

    version: number;
    when: Date = moment().toDate();
    expanded: boolean;

    formName: string = 'checklist';
    // TODO: !
    userId: string = null;
    patientId: string = null;

    users: Array<string>;

    stage1: ChecklistStage1;
    stage2: ChecklistStage2;
    stage3: ChecklistStage3;

    public constructor() {
        this.status = 'new';
        this.version = 1;
        this.expanded = false;

        this.users = [];

        this.stage1 = new ChecklistStage1();
        this.stage2 = new ChecklistStage2();
        this.stage3 = new ChecklistStage3();
    }
}

import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../../services/auth.service";
import {RootShareService} from "../../../../../services/root.shared.service";
import {FundasExam} from "../../../../../models/forms/referral/fundas.exam";
import {FundusImageInterpretationExam} from "../../../../../models/forms/referral/fundus.image.interpretation.exam";

@Component({
    selector: 'fundus-image-interpretation-exam-component',
    templateUrl: './fundus-image-interpretation-exam.html'
})
export class FundusImageInterpretationExamComponent {
    @Input() fundasExam: FundusImageInterpretationExam;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

import * as moment from "moment";

export class PatientFamilyHistory {
    relationship: string;
    // <!-- parent, grand parent, sibling, children -->
    problemStart: Date;
    problemDescription: string;
    isOcular: boolean;
    medication: string;
    medicationType: string;

    static relationshipChoices = ['Parent', 'Grand parent', 'Sibling', 'Children'];

    public constructor(){
        this.relationship = "";
        // <!-- parent, grand parent, sibling, children -->
        this.problemStart = moment().toDate();
        this.problemDescription = "";
        this.isOcular = false;
        this.medication = "";
        this.medicationType = "";
    }
}

import * as moment from "moment";

export class PatientSocialHistory {
    who: string;
    problemStart: Date;
    problemDescription: string;
    isOcular: boolean;
    isSymptom: boolean;
    snomedCode: string;

    // * Social History
    // * SNOMED - Social History
    // * ROS
    socialHistory: string;
    snomedSocialHistory: string;
    ros: string;

    public constructor(){
        this.who = "";
        this.problemStart = moment().toDate();
        this.problemDescription = "";
        this.isOcular = false;
        this.isSymptom = false;
        this.snomedCode = "";

        this.socialHistory = "";
        this.snomedSocialHistory = "";
        this.ros = "";
    }
}

import {Injectable} from "@angular/core";
import {Resolve} from "@angular/router";
import {MockService} from "./mock.service";
import {Mock} from "protractor/built/driverProviders";
import {UserData} from "../models/user.model";
import {MapUtils} from "../models/mapUtil";
import {AuthService} from "./auth.service";
import {Broadcaster} from "./broadcaster";
import {environment} from "../../environments/environment";

/**
 * Created by Magdalena on 09.05.2018.
 */
export class User {
    name: string;
    title: string;
    photo: string;
    adress: string;
    mail: string;
    phoneNumber: string;
    id: string;
    status: boolean = false;
}

@Injectable()
export class UsersService implements Resolve<UserData> {
    public currentUser: UserData;
    private userList: Array<any>;

    private careTeam1 : Array <any> = [
    {
        'id': '1',
        'name':'Dr Sarah McGregor',
        'title': 'General Practitioner',
        'status': true,
        'adress': 'Esterm General Hospitak Edinburgh',
        'phoneNumber': '+45 612 345 679',
        'mail': 'adam@gmail.com',
        'photo': 'sample-woman.jpg'
    },
    {
        'id': '2',
        'name':'Dr Mark Weller',
        'title': 'Consyltant Ophthalmologist',
        'status': false,
        'adress': 'Esterm General Hospitak Edinburgh',
        'phoneNumber': '+45 612 345 679',
        'mail': 'adam@gmail.com',
        'photo': 'sample-doctor.jpg'
    },
    {
        'id': '3',
        'name':'Dr Jane Foster',
        'title': 'Ophthalmologist',
        'status': true,
        'adress': 'Esterm General Hospitak Edinburgh',
        'phoneNumber': '+45 612 345 679',
        'mail': 'adam@gmail.com'
    },
        {
            'id': '6',
            'name':'Mr Jason King',
            'title': 'Optometrist',
            'status': true,
            'adress': 'Esterm General Hospitak Edinburgh',
            'phoneNumber': '+45 612 345 679',
            'mail': 'adam@gmail.com',
            'photo': 'sample-man.jpg'
        },
        {
            'id': '5',
            'firstName':'Dr Elizabeth',
            'familyName': 'Chambers',
            'name':'Dr Elizabeth Chambers ',
            'title': 'General Practitioner',
            'status': true,
            'adress': 'Esterm General Hospital Edinburgh',
            'phoneNumber': '+45 612 345 679',
            'mail': 'adam@gmail.com',
            'photo': 'user2-photo.jpg'
        },
        {
            'id': '7',
            'name':'Dr Robert Price ',
            'title': 'General Practitioner',
            'status': false,
            'adress': 'Esterm General Hospitak Edinburgh',
            'phoneNumber': '+45 612 345 679',
            'mail': 'adam@gmail.com',
            'photo': 'user4-photo.jpg'
        },
        {
            'id': '4',
            'firstName':'Dr Stephen',
            'familyName': 'Jackson',
            'name':'Dr Stephen Jackson ',
            'title': 'Ophthalmologist',
            'status': false,
            'adress': 'Esterm General Hospital Edinburgh',
            'phoneNumber': '+45 612 345 679',
            'mail': 'adam@gmail.com',
            'photo': 'sample-doctor2.jpg'
        },
        {
            'id': '8',
            'name':'Dr Melissa Parker',
            'title': 'Ophthalmologist',
            'status': false,
            'adress': 'Esterm General Hospitak Edinburgh',
            'phoneNumber': '+45 612 345 679',
            'mail': 'adam@gmail.com'
        }
    ];
    constructor (private _broadcaster: Broadcaster, public mockService:MockService, private _authService:AuthService) {
        let users: Array <any> = [
            {
                'id': '1',
                'firstName':'Sarah',
                'familyName': 'McGregor',
                'name':'Dr Sarah McGregor',
                'title': 'General Practitioner',
                'status': true,
                'adress': 'Esterm General Hospital Edinburgh',
                'phoneNumber': '+45 612 345 679',
                'mail': 'adam@gmail.com',
                'photo': 'sample-woman.jpg'
            },
            {
                'id': '2',
                'firstName':'Dr Mark',
                'familyName': 'Weller',
                'name':'Dr Mark Weller',
                'title': 'Consyltant Ophthalmologist',
                'status': false,
                'adress': 'Esterm General Hospital Edinburgh',
                'phoneNumber': '+45 612 345 679',
                'mail': 'adam@gmail.com',
                'photo': 'sample-doctor.jpg'
            },
            {
                'id': '3',
                'firstName':'Dr Jane',
                'familyName': 'Foster',
                'name':'Dr Jane Foster',
                'title': 'Ophthalmologist',
                'status': true,
                'adress': 'Esterm General Hospital Edinburgh',
                'phoneNumber': '+45 612 345 679',
                'mail': 'adam@gmail.com'
            },
            {
                'id': '4',
                'firstName':'Dr Stephen',
                'familyName': 'Jackson',
                'name':'Dr Stephen Jackson ',
                'title': 'Ophthalmologist',
                'status': false,
                'adress': 'Esterm General Hospital Edinburgh',
                'phoneNumber': '+45 612 345 679',
                'mail': 'adam@gmail.com',
                'photo': 'sample-doctor2.jpg'
            },
            {
                'id': '5',
                'firstName':'Dr Elizabeth',
                'familyName': 'Chambers',
                'name':'Dr Elizabeth Chambers ',
                'title': 'General Practitioner',
                'status': true,
                'adress': 'Esterm General Hospital Edinburgh',
                'phoneNumber': '+45 612 345 679',
                'mail': 'adam@gmail.com',
                'photo': 'user2-photo.jpg'
            },
            {
                'id': '6',
                'firstName':'Mr Jason',
                'familyName': 'King',
                'name':'Mr Jason King',
                'title': 'Ophthalmologist',
                'status': true,
                'adress': 'Esterm General Hospital Edinburgh',
                'phoneNumber': '+45 612 345 679',
                'mail': 'adam@gmail.com',
                'photo': 'sample-man.jpg'
            },
            {
                'id': '7',
                'firstName':'Dr Robert',
                'familyName': 'Price',
                'name':'Dr Robert Price ',
                'title': 'General Practitioner',
                'status': false,
                'adress': 'Esterm General Hospital Edinburgh',
                'phoneNumber': '+45 612 345 679',
                'mail': 'adam@gmail.com',
                'photo': 'user4-photo.jpg'
            },
            {
                'id': '8',
                'firstName':'Dr Melissa',
                'familyName': 'Parker',
                'name':'Dr Melissa Parker',
                'title': 'Ophthalmologist',
                'status': false,
                'adress': 'Esterm General Hospital Edinburgh',
                'phoneNumber': '+45 612 345 679',
                'mail': 'adam@gmail.com'
            },
            {
                'id': '9',
                'firstName':'Miss Katherine',
                'familyName': 'Flynn',
                'name':'Miss Katherine Flynn ',
                'title': 'Ophthalmologist',
                'status': false,
                'adress': 'Esterm General Hospital Edinburgh',
                'phoneNumber': '+45 612 345 679',
                'mail': 'adam@gmail.com',
                'photo': 'sample-woman2.jpg'
            },
            {
                'id': '10',
                'firstName':'Mr Jason',
                'familyName': 'Jackson',
                'name':'Mr Jason Jackson',
                'title': 'Ophthalmologist',
                'status': false,
                'adress': 'Esterm General Hospital Edinburgh',
                'phoneNumber': '+45 612 345 679',
                'mail': 'adam@gmail.com'
            },
            {
                'id': '11',
                'firstName':'Dr Harry',
                'familyName': 'Herman',
                'name':'Dr Harry Herman',
                'title': 'Consyltant Ophthalmologist',
                'status': false,
                'adress': 'Esterm General Hospital Edinburgh',
                'phoneNumber': '+45 612 345 679',
                'mail': 'adam@gmail.com'
            },
            {
                'id': '20',
                'firstName':'Christopher',
                'familyName': 'Ryan',
                'name':'Christopher Ryan',
                'title': 'Ophthalmologist',
                'status': true,
                'adress': 'Esterm General Hospital Edinburgh',
                'phoneNumber': '+45 612 345 679',
                'mail': 'adam@gmail.com',
                'photo': 'profile-photo.jpg'
            }
        ];

        this.userList = users;
    }

    getUser(id) {
        id = id == '5acb514214969132a0fad927'? 20: id;
        for (let i = 0 ; i < this.userList.length ; i++){
            if (this.userList[i].id == id)
                return this.userList[i];
        }
    }

    getCareTeam() {
        return this.careTeam1;
    }

    getUsers(ids: Array<string>) {
        let users:  Array<User> = [];
        for(let i=0; i <ids.length; i++) {
            users.push(this.getUser(ids[i]));
        }
        return users;
    }

    getAllUsers(): Array<any>{
        return this.userList;
    }

    getUserData(id: string): Promise<UserData> {
        return new Promise((resolve, reject) => {
            if(environment.mockUser){
                this.mockService.getData("getUserData", id).then(
                    (userData) => {
                        let u:UserData = MapUtils.deserialize(UserData, userData);
                        console.log(u);
                        resolve(u);
                    },
                    (error) => {
                        reject(error);
                    }
                )
            } else {
                console.log('get user data from API');
                resolve(new UserData());
            }
        });
    }

    getCurrentUser(): UserData {
        return this._authService.currentUser;
    }

    resolve(): Promise<UserData> {
        return this._authService.getUser();
    }
}
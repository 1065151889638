import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Http, HttpModule} from "@angular/http";
import * as $ from "jquery";
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterModule,
    Routes
} from '@angular/router';

//Styles
// import '../../node_modules/viewerjs/dist/viewer.css';

//Modules
import {AppComponent} from './app.component';
import {AuthModule} from './modules/auth/auth.module';
import {TopBarElement} from './elements/top-bar/top-bar.element';
import {DashboardModule} from './modules/dashboard/dashboard.module';
import {PatientsModule} from './modules/patients/patients.module';
import {ReferralsModule} from './modules/referrals/referrals.module';
import {MessagesModule} from './modules/messages/messages.module';
import {TasksModule} from './modules/tasks/tasks.module';
import {PatientTimelineModule} from './modules/patient-timeline/patient-timeline.module';
import {MainModule} from "./modules/main/main.module";
import {AuditsModule} from "./modules/audits/audits.module";
import {ClinicsModule} from "./modules/clinics/clinics.module";
import {AppointmentsModule} from "./modules/appointments/appointments.module";
import {NgDatepickerModule} from "ng2-datepicker";
import {AngularFontAwesomeModule} from "angular-font-awesome";
import {NgSelectModule} from "@ng-select/ng-select";

//Components
import {UserThumbnailComponent} from './components/user-thumbnail/user-thumbnail.component';
import {ConversationDetailsComponent} from "./components/user-chat/user-chat.component";
import {PatientTimelineElementComponent} from "./components/patient-timeline-element/patient-timeline-element.component";
import {PatientTimelineProfile} from "./components/patient-timeline-profile/patient-timeline-profile.component";
import {ChatComponent} from "./components/chat/chat.component";
import {PatientTimelineWatsonComponent} from "./components/patient-timeline-watson/patient-timeline-watson.component";
import {EformComponent} from "./components/eform/eform.component";
import {VitalsGraphComponent} from "./components/vitals-graph/vitals-graph.component";
import {FormElementComponent} from "./components/form-element/form.element.component";
import {PatientDetailsComponent} from "./components/form-element/sub-elements/patient-details/patient.details";
import {AdultClinicTypeSuggestComponent} from "./components/form-element/sub-elements/adult-clinic-type-suggest/adult.clinic.type.suggest";
import {AuditComponent} from "./components/form-element/sub-elements/audit/audit";
import {ChildClinicTypeSuggestComponent} from "./components/form-element/sub-elements/child-clinic-type-suggest/child.clinic.type.suggest";
import {GPComponent} from "./components/form-element/sub-elements/gp/gp";
import {OptomDetailsComponent} from "./components/form-element/sub-elements/optom-details/optom.details";
import {PatientAllergyComponent} from "./components/form-element/sub-elements/patient-allergy/patient.allergy";
import {PatientFamilyHistoryComponent} from "./components/form-element/sub-elements/patient-family-history/patient.family.history";
import {PatientGeneralHistoryComponent} from "./components/form-element/sub-elements/patient-general-history/patient.general.history";
import {PatientHistoryComponent} from "./components/form-element/sub-elements/patient-history/patient.history";
import {PatientSocialHistoryComponent} from "./components/form-element/sub-elements/patient-social-history/patient.social.history";
import {ExternalExamComponent} from "./components/form-element/sub-elements/tests/external-exam/external.exam";
import {FundasExamComponent} from "./components/form-element/sub-elements/tests/fundas-exam/fundas.exam";
import {IOPComponent} from "./components/form-element/sub-elements/tests/iop/iop";
import {OcularMotilityAndMuscleBalanceComponent} from "./components/form-element/sub-elements/tests/ocular-motility-and-muscle-balance/ocular.motility.and.muscle.balance";
import {OpticCoherenceTomographyComponent} from "./components/form-element/sub-elements/tests/optic-coherence-tomography/optic.coherence.tomography";
import {OpticNerveHeadComponent} from "./components/form-element/sub-elements/tests/optic-nerve-head/optic.nerve.head";
import {PupilExamComponent} from "./components/form-element/sub-elements/tests/pupil-exam/pupil.exam";
import {SlitLampComponent} from "./components/form-element/sub-elements/tests/slit-lamp/slit.lamp";
import {VisualAcuityComponent} from "./components/form-element/sub-elements/tests/visual-acuity/visual.acuity";
import {VisualFieldComponent} from "./components/form-element/sub-elements/tests/visual-field/visual.field";
import {CustomFormsService} from "./services/custom.forms.service";
import {ReferralFormsService} from "./services/referral.forms.service";
import {PatientChatDetails} from "./components/patient-chat-details/patient-chat-details.component";


//Elements
import {UiButton} from './elements/button/button.element';
import {UiSearch} from "./elements/search/search.element";
import {UiFilter} from "./elements/filter/filter.element";
import {UiSpinner} from "./elements/spinner/spinner.element";
import {STTElement} from "./elements/speach_to_text/speech_to_text.element";
import {UiCalendar} from "./elements/calendar/calendar.element";
import {UiSearchUCR} from "./elements/uisearch/search.element";
import {DropDownDirective} from "./elements/open.dropdown.directive";
import {DiagnosisOverlayElement} from "./elements/diagnosis-overlay/diagnosis-overlay.element";

//Services
import {MockService} from "./services/mock.service";
import {UsersService} from "./services/user.service";
import {PatientService} from "./services/patient.service";
import {MessageService} from "./services/messages.service";
import {AuthService, AuthGuard} from "./services/auth.service";
import {MainService} from "./services/main.service";
import {Broadcaster} from "./services/broadcaster";
import {DashboardService} from "./services/dashboard.service";
import {ApiService} from "./services/api.service";
import {DocumentsService} from "./services/documents.service";
import {SearchService} from "./services/search.service";
import {RootShareService} from "./services/root.shared.service";
import {EnvironmentProviderService} from "./services/environment.provider.service";
import {AttendanceService} from "./services/attendances.service";
import {WatsonService} from "./services/watson.service";
import {FormsService} from "./services/forms.service";
import {ReferalsResolveService, TaskResolveService, TasksService} from "./services/tasks.service";
import {DictionaryService} from "./services/dict.service";
import {NgxPopperModule} from "ngx-popper";
import {ClinicalNoteComponent} from "./components/form-element/clinical-note/clinical.note.component";
import {ReferralImageComponent} from "./components/referral-image/referral-image.element.component";
import {VteElementComponent} from "./components/vte-element/vte.element.component";
import {VteStage1Component} from "./components/vte-element/stages/stage1/vte.stage1";
import {VteStage3Component} from "./components/vte-element/stages/stage3/vte.stage3";
import {VteStage2Component} from "./components/vte-element/stages/stage2/vte.stage2";
import {ChecklistElementComponent} from "./components/checklist-element/checklist.element.component";
import {ChecklistStage1Component} from "./components/checklist-element/stages/stage1/checklist.stage1";
import {ChecklistStage3Component} from "./components/checklist-element/stages/stage3/checklist.stage3";
import {ChecklistStage2Component} from "./components/checklist-element/stages/stage2/checklist.stage2";
import {GpActionRequiredComponent} from "./components/form-element/sub-elements/gp-action-required/gp-action-required";
import {MedicationsOcularComponent} from "./components/form-element/sub-elements/medications-ocular/medications-ocular";
import {MedicationsSystemicComponent} from "./components/form-element/sub-elements/medications-systemic/medications-systemic";
import {TestsElementComponent} from "./components/form-element/tests.element.component";
import {ReferralPresentingSymptomComponent} from "./components/form-element/sub-elements/referral-presenting-symptom/referral.presenting.symptom";
import {HistoryOfPresentingSymptomComponent} from "./components/form-element/sub-elements/history-of-presenting-symptom/history.of.presenting.symptom";
import {DiagnoseCarePathwayComponent} from "./components/form-element/sub-elements/diagnose-care-pathway/diagnose.care.pathway";
import {DiagnoseComponent} from "./components/form-element/sub-elements/diagnose-diagnose/diagnose";
import {RefractionComponent} from "./components/form-element/sub-elements/tests/refraction/refraction.field";
import {TasksFormsService} from "./services/tasks.forms.service";
import {EyeExamElementComponent} from "./components/form-element/sub-elements/eye-exam/eye.exam.element.component";
import {VisualFieldInterpretationExamComponent} from "./components/form-element/sub-elements/tests/visual-field-interpretation-exam/visual-field-interpretation-exam";
import {FundusImageInterpretationExamComponent} from "./components/form-element/sub-elements/tests/fundus-image-interpretation-exam/fundus-image-interpretation-exam";
import {ProcedureNoteComponent} from "./components/form-element/procedure-note/procedure.note.component";

const routes: Routes = [
    {
        path: '',
        component: MainModule,
        resolve: {
            user: UsersService
        },
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                component: DashboardModule,
                resolve: {
                    referrals: ReferralFormsService,
                    tasks: TasksFormsService
                },
            },
            {
                path: 'clinics',
                component: ClinicsModule
            },
            {
                path: 'patients',
                component: PatientsModule
            },
            {
                path: 'patient/:id/timeline',
                component: PatientTimelineModule,
                resolve: {
                    patient: PatientService,
                    forms: CustomFormsService
                }
            },
            {
                path: 'timeline',
                component: PatientTimelineModule
            },
            {
                path: 'appointments/:id/:kind',
                component: AppointmentsModule,
                resolve: {
                    patient: PatientService
                }
            },
            /*
            {
                path: 'referrals',
                component: ReferralsModule,
                resolve: {
                    taskList: ReferalsResolveService
                }
            },
            */
            {
                path: 'referrals',
                component: ReferralsModule,
                resolve: {
                    forms: ReferralFormsService
                }
            },
            {
                path: 'messages',
                component: MessagesModule,
                resolve: {
                    messageList: MessageService
                }
            },
            {
                path: 'tasks',
                component: TasksModule,
                resolve: {
                    forms: TasksFormsService
                }
            },
            {
                path: 'audits',
                component: AuditsModule
            }
        ]
    }
];

@NgModule({
    declarations: [
        //Modules
        AppComponent,
        MainModule,
        AuthModule,
        TopBarElement,
        DashboardModule,
        PatientsModule,
        ReferralsModule,
        MessagesModule,
        TasksModule,
        PatientTimelineModule,
        AuditsModule,
        ClinicsModule,
        AppointmentsModule,
        DiagnosisOverlayElement,

        //Components
        UserThumbnailComponent,
        ConversationDetailsComponent,
        PatientTimelineElementComponent,
        FormElementComponent,
        EyeExamElementComponent,
        TestsElementComponent,
        ClinicalNoteComponent,
        ReferralImageComponent,
        ProcedureNoteComponent,
        VteElementComponent,

        VteStage1Component,
        VteStage2Component,
        VteStage3Component,

        ChecklistElementComponent,
        ChecklistStage1Component,
        ChecklistStage2Component,
        ChecklistStage3Component,

        PatientDetailsComponent,
        AdultClinicTypeSuggestComponent,
        AuditComponent,
        ChildClinicTypeSuggestComponent,
        GpActionRequiredComponent,
        MedicationsOcularComponent,
        MedicationsSystemicComponent,
        GPComponent,
        OptomDetailsComponent,
        PatientAllergyComponent,
        PatientFamilyHistoryComponent,
        PatientGeneralHistoryComponent,
        PatientHistoryComponent,
        PatientSocialHistoryComponent,
        ReferralPresentingSymptomComponent,
        HistoryOfPresentingSymptomComponent,
        DiagnoseCarePathwayComponent,
        DiagnoseComponent,
        ExternalExamComponent,
        FundasExamComponent,
        VisualFieldInterpretationExamComponent,
        FundusImageInterpretationExamComponent,
        IOPComponent,
        OcularMotilityAndMuscleBalanceComponent,
        OpticCoherenceTomographyComponent,
        OpticNerveHeadComponent,
        PupilExamComponent,
        SlitLampComponent,
        VisualAcuityComponent,
        VisualFieldComponent,
        RefractionComponent,
        PatientChatDetails,

        PatientTimelineProfile,
        ChatComponent,
        VitalsGraphComponent,
        PatientTimelineWatsonComponent,

        //EformComponent,

        //Elements
        UiButton,
        UiSearch,
        UiFilter,
        UiSpinner,
        UiCalendar,
        STTElement,
        UiSearchUCR,

        DropDownDirective
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(routes, {useHash: true}),
        FormsModule,
        HttpModule,
        NgDatepickerModule,
        AngularFontAwesomeModule,
        NgSelectModule,
        NgxPopperModule
    ],
    providers: [
        EnvironmentProviderService,
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: (env: EnvironmentProviderService) => () => env.getEnvironmentData(),
        //     deps: [EnvironmentProviderService, Http],
        //     multi: true
        // },
        MainService,
        AuthService,
        MockService,
        UsersService,
        PatientService,
        CustomFormsService,
        ReferralFormsService,
        TasksFormsService,
        MessageService,
        DashboardService,
        FormsService,
        TasksService,
        ReferalsResolveService,
        TaskResolveService,
        ApiService,
        SearchService,
        DocumentsService,
        RootShareService,
        AuthGuard,
        Broadcaster,
        WatsonService,
        AttendanceService,
        DictionaryService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private _router: Router, private _mainService: MainService, private _authService: AuthService) {
        this._router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                this._mainService.closeIFrame();
                this._mainService.showLoading();
            } else if (val instanceof NavigationEnd) {
                this._mainService.hideLoading();
            } else if (val instanceof NavigationCancel) {
                this._mainService.hideLoading();
            } else if (val instanceof NavigationError) {
                if (val.error.status && val.error.status == 401) {
                    this._authService.logout().then((success) => {
                        this._router.navigate([""]);
                    });
                }
                this._mainService.hideLoading();
            }
        });
    }
}



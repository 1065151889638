import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {Moment} from "moment";
import * as moment from "moment";
import * as _ from "lodash";
import * as $ from "jquery";
import {TimelineElementType} from "../../../patient-timeline-element/patient-timeline-element.component";
import {ReferralForm} from "../../../../models/forms/referral/referral.form";
import {PatientHistory} from "../../../../models/forms/referral/patient.history";
import {PatientGeneralHistory} from "../../../../models/forms/referral/patient.general.history";
import {PatientFamilyHistory} from "../../../../models/forms/referral/patient.family.history";
import {PatientSocialHistory} from "../../../../models/forms/referral/patient.social.history";
import {PatientAllergy} from "../../../../models/forms/referral/patient.allergy";
import {ExternalExam} from "../../../../models/forms/referral/external.exam";
import {FundasExam} from "../../../../models/forms/referral/fundas.exam";
import {IOP} from "../../../../models/forms/referral/iop";
import {OcularMotilityAndMuscleBalance} from "../../../../models/forms/referral/ocular.motility.and.muscle.balance";
import {OpticCoherenceTomography} from "../../../../models/forms/referral/optic.coherence.tomography";
import {OpticNerveHead} from "../../../../models/forms/referral/optic.nerve.head";
import {PupilExam} from "../../../../models/forms/referral/pupil.exam";
import {SlitLamp} from "../../../../models/forms/referral/slit.lamp";
import {VisualAcuity} from "../../../../models/forms/referral/visual.acuity";
import {VisualField} from "../../../../models/forms/referral/visual.field";
import {PatientService} from "../../../../services/patient.service";
import {CustomFormsService} from "../../../../services/custom.forms.service";
import {Medications} from "../../../../models/forms/referral/medications";
import {Allergies} from "../../../../models/forms/referral/allergies";
import {PastOcularHistory} from "../../../../models/forms/referral/past.ocular.history";
import {Router} from "@angular/router";
//import any = jasmine.any;

@Component({
    selector: 'eye-exam-element-component',
    templateUrl: './eye.exam.element.component.html',
    styleUrls: ['./eye.exam.element.component.scss']
})
export class EyeExamElementComponent implements OnInit, OnDestroy {
    @Input() type: TimelineElementType = TimelineElementType.vitals;
    @Input() title: string = "Prescriptions";
    @Input() firstIcon: string = "";
    @Input() secondIcon: string = "";

    @Input() parent: any;

    @Input() userThumbnail: boolean = false;
    @Input() showDateBalloon: boolean = false;
    @Input() userThumbnailName: string = "John Doe";
    @Input() userThumbnailTitle: string = "5 hours ago";
    @Input() userThumbnailImage: string = "../../assets/img/users_photo/male/christopher.png";

    //Clinical Image
    @Input() patient: any;
    @Input() patientDocument: any;

    @Output() firstIconAction = new EventEmitter();
    @Output() secondIconAction = new EventEmitter();
    @Output() refreshVitalsGraph = new EventEmitter();

    @Input() referralForm: ReferralForm = new ReferralForm();

    expandedFlags: any = {
        _parent: null, //FormElementComponent

        _patientDetails: false,
        get patientDetails(): boolean { return this._patientDetails; },
        set patientDetails(v: boolean) { this._patientDetails = v; },

        _presentingSymptom: false,
        get presentingSymptom(): boolean { return this._presentingSymptom; },
        set presentingSymptom(v: boolean) { this._presentingSymptom = v; },
        _historyOfPresentingSymptom: false,
        get historyOfPresentingSymptom(): boolean { return this._historyOfPresentingSymptom; },
        set historyOfPresentingSymptom(v: boolean) { this._historyOfPresentingSymptom = v; },
        _diagnose: false,
        get diagnose(): boolean { return this._diagnose; },
        // get diagnose(): boolean { return this._parent.isReadOnly() || this._diagnose; },
        set diagnose(v: boolean) { this._diagnose = v; },
        _carePathway: false,
        get carePathway(): boolean { return this._carePathway; },
        // get carePathway(): boolean { return this._parent.isReadOnly() || this._carePathway; },
        set carePathway(v: boolean) { this._carePathway = v; },

        _adultsDetails: false,
        // get adultsDetails(): boolean { return this._parent.isReadOnly() || this._adultsDetails; },
        get adultsDetails(): boolean { return this._adultsDetails; },
        set adultsDetails(v: boolean) { this._adultsDetails = v; },
        _childrenDetails: false,
        get childrenDetails(): boolean { return this._childrenDetails; },
        set childrenDetails(v: boolean) { this._childrenDetails = v; },
        _gpActionRequired: false,
        get gpActionRequired(): boolean { return this._gpActionRequired; },
        set gpActionRequired(v: boolean) { this._gpActionRequired = v; },
        _patientGeneralHistory: false,
        get patientGeneralHistory(): boolean { return this._patientGeneralHistory; },
        set patientGeneralHistory(v: boolean) { this._patientGeneralHistory = v; },
        _patientHistory: false,
        get patientHistory(): boolean { return this._patientHistory; },
        set patientHistory(v: boolean) { this._patientHistory = v; },
        _patientFamilyHistory: false,
        get patientFamilyHistory(): boolean { return this._patientFamilyHistory; },
        set patientFamilyHistory(v: boolean) { this._patientFamilyHistory = v; },
        _patientSocialHistory: false,
        get patientSocialHistory(): boolean { return this._patientSocialHistory; },
        set patientSocialHistory(v: boolean) { this._patientSocialHistory = v; },
        _medicationsOcular: false,
        get medicationsOcular(): boolean { return this._medicationsOcular; },
        set medicationsOcular(v: boolean) { this._medicationsOcular = v; },
        _medicationsSystemic: false,
        get medicationsSystemic(): boolean { return this._medicationsSystemic; },
        set medicationsSystemic(v: boolean) { this._medicationsSystemic = v; },
        _patientAllergy: false,
        get patientAllergy(): boolean { return this._patientAllergy; },
        set patientAllergy(v: boolean) { this._patientAllergy = v; },
        _optomDetails: false,
        get optomDetails(): boolean { return this._optomDetails; },
        set optomDetails(v: boolean) { this._optomDetails = v; },
        _gpDetails: false,
        get gpDetails(): boolean { return this._gpDetails; },
        set gpDetails(v: boolean) { this._gpDetails = v; },
        _tests: false,
        get tests(): boolean { return this._tests; },
        set tests(v: boolean) { this._tests = v; }
    };

    visibleFlags: any = {
        _parent: null, //FormElementComponent

        _patientDetails: false,
        get patientDetails(): boolean { return this._patientDetails; },
        set patientDetails(v: boolean) { this._patientDetails = v; },

        _presentingSymptom: false,
        get presentingSymptom(): boolean { return this._presentingSymptom; },
        set presentingSymptom(v: boolean) { this._presentingSymptom = v; },
        _historyOfPresentingSymptom: false,
        get historyOfPresentingSymptom(): boolean { return this._historyOfPresentingSymptom; },
        set historyOfPresentingSymptom(v: boolean) { this._historyOfPresentingSymptom = v; },
        _diagnose: false,
        get diagnose(): boolean { return this._diagnose; },
        // get diagnose(): boolean { return this._parent.isReadOnly() || this._diagnose; },
        set diagnose(v: boolean) { this._diagnose = v; },
        _carePathway: false,
        get carePathway(): boolean { return this._carePathway; },
        // get carePathway(): boolean { return this._parent.isReadOnly() || this._carePathway; },
        set carePathway(v: boolean) { this._carePathway = v; },

        _adultsDetails: false,
        // get adultsDetails(): boolean { return this._parent.isReadOnly() || this._adultsDetails; },
        get adultsDetails(): boolean { return this._adultsDetails; },
        set adultsDetails(v: boolean) { this._adultsDetails = v; },
        _childrenDetails: false,
        get childrenDetails(): boolean { return this._childrenDetails; },
        set childrenDetails(v: boolean) { this._childrenDetails = v; },
        _gpActionRequired: false,
        get gpActionRequired(): boolean { return this._gpActionRequired; },
        set gpActionRequired(v: boolean) { this._gpActionRequired = v; },
        _patientGeneralHistory: false,
        get patientGeneralHistory(): boolean { return this._patientGeneralHistory; },
        set patientGeneralHistory(v: boolean) { this._patientGeneralHistory = v; },
        _patientHistory: false,
        get patientHistory(): boolean { return this._patientHistory; },
        set patientHistory(v: boolean) { this._patientHistory = v; },
        _patientFamilyHistory: false,
        get patientFamilyHistory(): boolean { return this._patientFamilyHistory; },
        set patientFamilyHistory(v: boolean) { this._patientFamilyHistory = v; },
        _patientSocialHistory: false,
        get patientSocialHistory(): boolean { return this._patientSocialHistory; },
        set patientSocialHistory(v: boolean) { this._patientSocialHistory = v; },
        _medicationsOcular: false,
        get medicationsOcular(): boolean { return this._medicationsOcular; },
        set medicationsOcular(v: boolean) { this._medicationsOcular = v; },
        _medicationsSystemic: false,
        get medicationsSystemic(): boolean { return this._medicationsSystemic; },
        set medicationsSystemic(v: boolean) { this._medicationsSystemic = v; },
        _patientAllergy: false,
        get patientAllergy(): boolean { return this._patientAllergy; },
        set patientAllergy(v: boolean) { this._patientAllergy = v; },
        _optomDetails: false,
        get optomDetails(): boolean { return this._optomDetails; },
        set optomDetails(v: boolean) { this._optomDetails = v; },
        _gpDetails: false,
        get gpDetails(): boolean { return this._gpDetails; },
        set gpDetails(v: boolean) { this._gpDetails = v; },
        _tests: false,
        get tests(): boolean { return this._tests; },
        set tests(v: boolean) { this._tests = v; }
    };

    addMenuVisible = false;
    selectedCalendar = 'public';

    referPatient() {
        this.router.navigateByUrl('/appointments/' + this.patient.hospitalNumber + '/' + this.selectedCalendar);
    }

    constructor(private _rootShareService: RootShareService, private _customFormsService: CustomFormsService, private router: Router,
                private _auth: AuthService) {
        this.expandedFlags._parent = this;
        this.visibleFlags._parent = this;
    }

    // noinspection all
    makeValue(v) {
        v = v.replace('OD', '');
        v = v.replace('OS', '');
        v = v.replace(' ', '');
        return v;
    }
    // noinspection all
    makeValue2(v) {
        v = v.replace('OU ', '');
        return v;
    }

    fillData(message: any) {
        if (message.HPI) {
            if (this.referralForm.historyOfPresentingSymptom.reasonForReferral !== message.HPI) {
                this.referralForm.historyOfPresentingSymptom.reasonForReferral = message.HPI;
                this.expandedFlags.historyOfPresentingSymptom = true;
                this.visibleFlags.historyOfPresentingSymptom = true;
            } else {
                // this.expandedFlags.historyOfPresentingSymptom = false;
            }
        }

        // Positive_Symptoms
        if (message.Positive_Symptoms) {
            if (this.referralForm.historyOfPresentingSymptom.watsonPresentingComplaint !== message.Positive_Symptoms) {
                this.referralForm.historyOfPresentingSymptom.watsonPresentingComplaint = message.Positive_Symptoms;
                this.expandedFlags.historyOfPresentingSymptom = true;
                this.visibleFlags.historyOfPresentingSymptom = true;
            } else {
                // this.expandedFlags.historyOfPresentingSymptom = false;
            }
        }

        if (message.Ocular_History_Family) {
            if (this.referralForm.patientGeneralHistory.length === 0 || (this.referralForm.patientGeneralHistory.length === 1 && this.referralForm.patientGeneralHistory[0].pastFamilyOcularHistory !== message.Ocular_History_Family)) {
                if (this.referralForm.patientGeneralHistory.length === 0)
                    this.referralForm.patientGeneralHistory.push(new PastOcularHistory());
                this.referralForm.patientGeneralHistory[0].pastFamilyOcularHistory = message.Ocular_History_Family;
                this.expandedFlags.patientGeneralHistory = true;
                this.visibleFlags.patientGeneralHistory = true;
            } else {
                // this.expandedFlags.patientGeneralHistory = false;
            }
        }

        if (message.Ocular_History_Patient) {
            if (this.referralForm.patientGeneralHistory.length === 0 || (this.referralForm.patientGeneralHistory.length === 1 && this.referralForm.patientGeneralHistory[0].pastOcularHistory !== message.Ocular_History_Patient)) {
                if (this.referralForm.patientGeneralHistory.length === 0)
                    this.referralForm.patientGeneralHistory.push(new PastOcularHistory());
                this.referralForm.patientGeneralHistory[0].pastOcularHistory = message.Ocular_History_Patient;
                this.expandedFlags.patientGeneralHistory = true;
                this.visibleFlags.patientGeneralHistory = true;
            } else {
                // this.expandedFlags.patientGeneralHistory = false;
            }
        }

        // Ocular_Medications
        // Systemic_Medications
        // Allergies

        /*
        if (message.Ocular_Medications) {
            if (this.referralForm.medicationsOcular.length === 0 || (this.referralForm.medicationsOcular.length === 1 && this.referralForm.medicationsOcular[0].drug !== message.Ocular_Medications)) {
                if (this.referralForm.medicationsOcular.length === 0)
                    this.referralForm.medicationsOcular.push(new Medications());
                this.referralForm.medicationsOcular[0].drug = message.Ocular_Medications;
                this.expandedFlags.medicationsOcular = true;
                this.visibleFlags.medicationsOcular = true;
            } else {
                this.expandedFlags.medicationsOcular = false;
            }
        }
        */

        if (message.Allergies) {
            if (this.referralForm.patientAllergy.length === 0 || (this.referralForm.patientAllergy.length === 1 && this.referralForm.patientAllergy[0].allergy !== message.Allergies)) {
                if (this.referralForm.patientAllergy.length === 0)
                    this.referralForm.patientAllergy.push(new Allergies());
                this.referralForm.patientAllergy[0].allergy = message.Allergies;
                this.expandedFlags.patientAllergy = true;
                this.visibleFlags.patientAllergy = true;
            } else {
                // this.expandedFlags.patientAllergy = false;
            }
        }

        // IOP_OS
        // IOP_OD

        if (message.IOP_OS || message.IOP_OD) {
            let form;
            _.forEach(this.referralForm.tests, (test) => {
                if (test.testName === "IOP") {
                    form = test;
                }
            });
            if (!form) {
                form = new IOP();
                this.referralForm.tests.push(form);
            }
            let iopod = this.makeValue(message.IOP_OD);
            if (form.IOPOD !== iopod) {
                form.IOPOD = iopod;
                this.expandedFlags.tests = true;
                this.visibleFlags.tests = true;
            }
            let iopos = this.makeValue(message.IOP_OS);
            if (form.IOPOS !== iopos) {
                form.IOPOS = iopos;
                this.expandedFlags.tests = true;
                this.visibleFlags.tests = true;
            }
        }

        // VA_OS
        // VA_OD

        if (message.VA_OS || message.VA_OD) {
            let form;
            _.forEach(this.referralForm.tests, (test) => {
                if (test.testName === "VisualAcuity") {
                    form = test;
                }
            });
            if (!form) {
                form = new VisualAcuity();
                this.referralForm.tests.push(form);
            }
            let od = this.makeValue(message.VA_OD);
            if (form.visualAcuityOD !== od) {
                form.visualAcuityOD = od;
                this.expandedFlags.tests = true;
                this.visibleFlags.tests = true;
            }
            let os = this.makeValue(message.VA_OS);
            if (form.visualAcuityOS !== os) {
                form.visualAcuityOS = os;
                this.expandedFlags.tests = true;
                this.visibleFlags.tests = true;
            }
        }

        // Pupils_OS
        // Pupils_OD

        if ((message.Pupils_OS && message.Pupils_OS.length > 0) || (message.Pupils_OD && message.Pupils_OD.length > 0)) {
            let form;
            _.forEach(this.referralForm.tests, (test) => {
                if (test.testName === "PupilExam") {
                    form = test;
                }
            });
            if (!form) {
                form = new PupilExam();
                this.referralForm.tests.push(form);
            }
            let od = this.makeValue2((message.Pupils_OD && message.Pupils_OD.length > 0) ? message.Pupils_OD[0] : "");
            if (form.descriptionOD !== od) {
                form.descriptionOD = od;
                this.expandedFlags.tests = true;
                this.visibleFlags.tests = true;
            }
            let os = this.makeValue2((message.Pupils_OS && message.Pupils_OS.length > 0) ? message.Pupils_OS[0] : "");
            if (form.descriptionOS !== os) {
                form.descriptionOS = os;
                this.expandedFlags.tests = true;
                this.visibleFlags.tests = true;
            }
        }

        // Extraocular_Movements_OD
        // Extraocular_Movements_OS

        if ((message.Extraocular_Movements_OS && message.Extraocular_Movements_OS.length > 0) || (message.Extraocular_Movements_OD && message.Extraocular_Movements_OD.length > 0)) {
            let form;
            _.forEach(this.referralForm.tests, (test) => {
                if (test.testName === "OcularMotilityAndMuscleBalance") {
                    form = test;
                }
            });
            if (!form) {
                form = new OcularMotilityAndMuscleBalance();
                this.referralForm.tests.push(form);
            }
            let od = this.makeValue2((message.Extraocular_Movements_OD && message.Extraocular_Movements_OD.length > 0) ? message.Extraocular_Movements_OD[0] : "");
            if (form.extraocularMovementsOD !== od) {
                form.extraocularMovementsOD = od;
                this.expandedFlags.tests = true;
                this.visibleFlags.tests = true;
            }
            let os = this.makeValue2((message.Extraocular_Movements_OS && message.Extraocular_Movements_OS.length > 0) ? message.Extraocular_Movements_OS[0] : "");
            if (form.extraocularMovementsOS !== os) {
                form.extraocularMovementsOS = os;
                this.expandedFlags.tests = true;
                this.visibleFlags.tests = true;
            }
        }

        // CVF_OD
        // CVF_OS

        if ((message.CVF_OS && message.CVF_OS.length > 0) || (message.CVF_OD && message.CVF_OD.length > 0)) {
            let form;
            _.forEach(this.referralForm.tests, (test) => {
                if (test.testName === "VisualField") {
                    form = test;
                }
            });
            if (!form) {
                form = new VisualField();
                this.referralForm.tests.push(form);
            }
            let od = this.makeValue2((message.CVF_OD && message.CVF_OD.length > 0) ? message.CVF_OD[0] : "");
            if (form.visualFieldAnalysisOD !== od) {
                form.visualFieldAnalysisOD = od;
                this.expandedFlags.tests = true;
                this.visibleFlags.tests = true;
            }
            let os = this.makeValue2((message.CVF_OS && message.CVF_OS.length > 0) ? message.CVF_OS[0] : "");
            if (form.visualFieldAnalysisOS !== os) {
                form.visualFieldAnalysisOS = os;
                this.expandedFlags.tests = true;
                this.visibleFlags.tests = true;
            }
        }

        // External_OD
        // External_OS

        if ((message.External_OS && message.External_OS.length > 0) || (message.External_OD && message.External_OD.length > 0)) {
            let form;
            _.forEach(this.referralForm.tests, (test) => {
                if (test.testName === "ExternalExam") {
                    form = test;
                }
            });
            if (!form) {
                form = new ExternalExam();
                this.referralForm.tests.push(form);
            }
            let od = this.makeValue2((message.External_OD && message.External_OD.length > 0) ? message.External_OD[0] : "");
            if (form.descriptionOD !== od) {
                form.descriptionOD = od;
                this.expandedFlags.tests = true;
                this.visibleFlags.tests = true;
            }
            let os = this.makeValue2((message.External_OS && message.External_OS.length > 0) ? message.External_OS[0] : "");
            if (form.descriptionOS !== os) {
                form.descriptionOS = os;
                this.expandedFlags.tests = true;
                this.visibleFlags.tests = true;
            }
        }

        if ((message.Lids_Lashes_OS && message.Lids_Lashes_OS.length > 0)
            || (message.Lids_Lashes_OD && message.Lids_Lashes_OD.length > 0)
            || (message.Slit_Lamp_Lens_OS && message.Slit_Lamp_Lens_OS.length > 0)
            || (message.Slit_Lamp_Lens_OD && message.Slit_Lamp_Lens_OD.length > 0)
            || (message.Slit_Lamp_Anterior_Chamber_OS && message.Slit_Lamp_Anterior_Chamber_OS.length > 0)
            || (message.Slit_Lamp_Anterior_Chamber_OD && message.Slit_Lamp_Anterior_Chamber_OD.length > 0)
            || (message.Slit_Lamp_Iris_OS && message.Slit_Lamp_Iris_OS.length > 0)
            || (message.Slit_Lamp_Iris_OD && message.Slit_Lamp_Iris_OD.length > 0)
            || (message.Slit_Lamp_Conjunctiva_Sclera_OS && message.Slit_Lamp_Conjunctiva_Sclera_OS.length > 0)
            || (message.Slit_Lamp_Conjunctiva_Sclera_OD && message.Slit_Lamp_Conjunctiva_Sclera_OD.length > 0)
            || (message.Slit_Lamp_Cornea_OS && message.Slit_Lamp_Cornea_OS.length > 0)
            || (message.Slit_Lamp_Cornea_OD && message.Slit_Lamp_Cornea_OD.length > 0)
            || (message.Slit_Lamp_Anterior_Vitreous_OS && message.Slit_Lamp_Anterior_Vitreous_OS.length > 0)
            || (message.Slit_Lamp_Anterior_Vitreous_OD && message.Slit_Lamp_Anterior_Vitreous_OD.length > 0)
        ) {
            let form;
            _.forEach(this.referralForm.tests, (test) => {
                if (test.testName === "SlitLamp") {
                    form = test;
                }
            });
            if (!form) {
                form = new SlitLamp();
                this.referralForm.tests.push(form);
            }

            let od = this.makeValue2((message.Lids_Lashes_OD && message.Lids_Lashes_OD.length > 0) ? message.Lids_Lashes_OD[0] : "");
            if (form.lidsLashesOD !== od) {
                form.lidsLashesOD = od; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }
            let os = this.makeValue2((message.Lids_Lashes_OS && message.Lids_Lashes_OS.length > 0) ? message.Lids_Lashes_OS[0] : "");
            if (form.lidsLashesOS !== os) {
                form.lidsLashesOS = os; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }

            // Slit_Lamp_Lens_
            od = this.makeValue2((message.Slit_Lamp_Lens_OD && message.Slit_Lamp_Lens_OD.length > 0) ? message.Slit_Lamp_Lens_OD[0] : "");
            if (form.lensOD !== od) {
                form.lensOD = od; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }
            os = this.makeValue2((message.Slit_Lamp_Lens_OS && message.Slit_Lamp_Lens_OS.length > 0) ? message.Slit_Lamp_Lens_OS[0] : "");
            if (form.lensOS !== os) {
                form.lensOS = os; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }

            // Slit_Lamp_Anterior_Chamber_
            od = this.makeValue2((message.Slit_Lamp_Anterior_Chamber_OD && message.Slit_Lamp_Anterior_Chamber_OD.length > 0) ? message.Slit_Lamp_Anterior_Chamber_OD[0] : "");
            if (form.anteriorChamberOD !== od) {
                form.anteriorChamberOD = od; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }
            os = this.makeValue2((message.Slit_Lamp_Anterior_Chamber_OS && message.Slit_Lamp_Anterior_Chamber_OS.length > 0) ? message.Slit_Lamp_Anterior_Chamber_OS[0] : "");
            if (form.anteriorChamberOS !== os) {
                form.anteriorChamberOS = os; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }

            // Slit_Lamp_Iris_
            od = this.makeValue2((message.Slit_Lamp_Iris_OD && message.Slit_Lamp_Iris_OD.length > 0) ? message.Slit_Lamp_Iris_OD[0] : "");
            if (form.irisOD !== od) {
                form.irisOD = od; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }
            os = this.makeValue2((message.Slit_Lamp_Iris_OS && message.Slit_Lamp_Iris_OS.length > 0) ? message.Slit_Lamp_Iris_OS[0] : "");
            if (form.irisOS !== os) {
                form.irisOS = os; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }

            // Slit_Lamp_Conjunctiva_Sclera_
            od = this.makeValue2((message.Slit_Lamp_Conjunctiva_Sclera_OD && message.Slit_Lamp_Conjunctiva_Sclera_OD.length > 0) ? message.Slit_Lamp_Conjunctiva_Sclera_OD : "");
            if (form.conjunctivaScleraOD !== od) {
                form.conjunctivaScleraOD = od; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }
            os = this.makeValue2((message.Slit_Lamp_Conjunctiva_Sclera_OS && message.Slit_Lamp_Conjunctiva_Sclera_OS.length > 0) ? message.Slit_Lamp_Conjunctiva_Sclera_OS[0] : "");
            if (form.conjunctivaScleraOS !== os) {
                form.conjunctivaScleraOS = os; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }

            // Slit_Lamp_Cornea_
            od = this.makeValue2((message.Slit_Lamp_Cornea_OD && message.Slit_Lamp_Cornea_OD.length > 0) ? message.Slit_Lamp_Cornea_OD[0] : "");
            if (form.corneaOD !== od) {
                form.corneaOD = od; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }
            os = this.makeValue2((message.Slit_Lamp_Cornea_OS && message.Slit_Lamp_Cornea_OS.length > 0) ? message.Slit_Lamp_Cornea_OS[0] : "");
            if (form.corneaOS !== os) {
                form.corneaOS = os; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }

            // Slit_Lamp_Anterior_Vitreous_
            od = this.makeValue2((message.Slit_Lamp_Anterior_Vitreous_OD && message.Slit_Lamp_Anterior_Vitreous_OD.length > 0) ? message.Slit_Lamp_Anterior_Vitreous_OD[0] : "");
            if (form.anteriorVitreousOD !== od) {
                form.anteriorVitreousOD = od; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }
            os = this.makeValue2((message.Slit_Lamp_Anterior_Vitreous_OS && message.Slit_Lamp_Anterior_Vitreous_OS.length > 0) ? message.Slit_Lamp_Anterior_Vitreous_OS[0] : "");
            if (form.anteriorVitreousOS !== os) {
                form.anteriorVitreousOS = os; this.expandedFlags.tests = true; this.visibleFlags.tests = true;
            }
        }
    }

    subscription: any = null;
    ngOnInit() {
        this.subscription = this.parent.messageReceived$.subscribe((messageWatson: any) => {
            // console.log('internalEvent', i);
            if (this.referralForm.status !== "edit" && this.referralForm.status !== "new")
                return;

            let reason = '';
            if (messageWatson.intents && messageWatson.intents[0]) {
                reason = messageWatson.intents[0].intent;
            }

            console.log('reason', reason);

            switch (reason) {
                case "Reason_for_referral":
                    // STEP 1
                    // create eye exam
                    // this.parent.addEyeExam();

                    break;
            }

            this.fillData(messageWatson.context);

            // if (messageWatson.text && messageWatson.text.length > 0 && messageWatson.text[0] === "Please summarise the patient's ocular history") {
            if (messageWatson.text && messageWatson.text.length > 0 && messageWatson.text[0] === "Thanks, I think we're done!") {
                this.referralForm.diagnose.diagnosis = "";
                this.referralForm.diagnose.watsonDiagnosis = "Primary open-angle glaucoma with a confidence of 90%";
                this.referralForm.diagnose.snomedWatsonDiagnosis = "77075001";
                this.referralForm.diagnose.watsonDifferentialDiagnosis = "Steroid-induced glaucoma, \n" +
                    "Pigmentary glaucoma, \n" +
                    "Pseudoexfoliation glaucoma, \n" +
                    "Trauma-induced glaucoma, \n" +
                    "Intermittent angle-closure glaucoma, \n" +
                    "Optic neuropathy\n";
                this.referralForm.diagnose.watsonDiagnosisEvidence = "This patient is presenting with primary open-angle glaucoma (POAG). The diagnosis is supported by evidence of increased IOP (normal is 8 to 21 mmHg), optic nerve head abnormality, open anterior chamber angle, visual field deficits and no history to suggest a secondary glaucoma (glaucoma due to an identifiable cause).";
                this.expandedFlags.diagnose = true;
                this.visibleFlags.diagnose = true;

                this.saveElement();
            }
        });
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    getClasses(icon) {
        let classes: Array<string> = [];

        classes.push("unity-icon");
        classes.push("timeline-icon");

        if (icon == 'first')
            classes.push(this.firstIcon);
        else if (icon == 'second')
            classes.push(this.secondIcon);

        return classes;
    }

    userThumbnailDate() {
        let d = moment(this.referralForm.when);
        let day = d.format('DD');
        let month = d.format('MMM');
        let year = d.format('Y');

        return day + " " + month + " " + year;
    }

    getMonth() {
        // noinspection all
        let month = moment(this.referralForm.when).format('MMM').toUpperCase();
        return month;
    }

    getYear() {
        // noinspection all
        let year = moment(this.referralForm.when).format('Y');
        return year;
    }

    /////

    addExternalExam(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new ExternalExam());
    }
    addFundasExam(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new FundasExam());
    }
    addIOP(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new IOP());
    }
    addOcular(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new OcularMotilityAndMuscleBalance());
    }
    addOpticCoherence(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new OpticCoherenceTomography());
    }
    addOpticNerve(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new OpticNerveHead());
    }
    addPupil(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new PupilExam());
    }
    addSlit(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new SlitLamp());
    }
    addVisualAcuity(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new VisualAcuity());
    }
    addVisualField(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new VisualField());
    }

    removeTest(e, test) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.tests, (v) => { return v === test; });
    }

    /////

    addPatientHistory(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.patientHistory.push(new PatientHistory());
        this.expandedFlags.patientHistory = true;
    }
    removePatientHistory(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.patientHistory, (v) => {
            return v === element;
        });
    }

    addPatientGeneralHistory(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.patientGeneralHistory.push(new PastOcularHistory());
        this.expandedFlags.patientGeneralHistory = true;
    }
    removePatientGeneralHistory(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.patientGeneralHistory, (v) => { return v === element; });
    }

    addPatientFamilyHistory(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.patientFamilyHistory.push(new PatientFamilyHistory());
        this.expandedFlags.patientFamilyHistory = true;
    }
    removePatientFamilyHistory(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.patientFamilyHistory, (v) => { return v === element; });
    }

    addPatientSocialHistory(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.patientSocialHistory.push(new PatientSocialHistory());
        this.expandedFlags.patientSocialHistory = true;
    }
    removePatientSocialHistory(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.patientSocialHistory, (v) => { return v === element; });
    }

    addMedicationsOcular(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.medicationsOcular.push(new Medications());
        this.expandedFlags.medicationsOcular = true;
    }
    removeMedicationsOcular(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.medicationsOcular, (v) => { return v === element; });
    }
    addMedicationsSystemic(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.medicationsSystemic.push(new Medications());
        this.expandedFlags.medicationsSystemic = true;
    }
    removeMedicationsSystemic(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.medicationsSystemic, (v) => { return v === element; });
    }

    addPatientAllergy(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.patientAllergy.push(new Allergies());
        this.expandedFlags.patientAllergy = true;
    }
    removePatientAllergy(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.patientAllergy, (v) => { return v === element; });
    }

    //

    isReadOnly() {
        return !(this.referralForm.status === 'new' || this.referralForm.status === 'edit');
    }

    saveElement() {
        // noinspection all
        // let formToSave = _.cloneDeep(this.referralForm);
        // // FIXME: !!!!!
        // formToSave.status = "save";
        this.referralForm.status = "save";

        // TODO: PUBLISH at some point !!!
        // this._customFormsService.saveForm(formToSave).then((v) => {
        //     // alert(v);
        // });
        this.refreshVitalsGraph.emit(1);
        this._customFormsService.saveForm(this.referralForm).then((v:any) => {
            this.referralForm._id = v._id;
            if (this.parent) {
                // this.parent.updateElements();
            }
        });
    }

    editElement() {
        this.referralForm.status = "edit";
        if (this.parent) {
            // this.parent.updateElements();
        }
    }

    cancelElement() {
        if (this.parent) {
            this.parent.cancelElement(this.referralForm);
        }
    }

    removeElement() {
        if (this.parent) {
            this.parent.removeElement(this.referralForm);
        }
    }

    createElementDOR() {
        // noinspection all
        let formToSave = _.cloneDeep(this.referralForm);
        delete formToSave._id;
        formToSave.status = "closed_dor";
        formToSave.workflow = "disabled";

        this._customFormsService.saveForm(formToSave).then((v) => {
            if (this.parent) {
                this.parent.addElement(formToSave);
                // this.parent.updateElements();
            }
            this.referralForm.when = moment().toDate();
            this.saveElement();
        });
    }

    taskTextV: string;
    createTask() {
        let $modal = $(".modal-background");
        let $task = $(".new-task-form");
        let $body = $('body');

        $modal.css('display', 'block');
        $body.css('overflow', 'hidden');
        $task.css('display', 'block');
    }
    cancelTask() {
        let $modal = $(".modal-background");
        let $task = $(".new-task-form");
        let $body = $('body');

        $modal.css('display', 'none');
        $body.css('overflow', '');
        $task.css('display', 'none');
    }
    createNewTask() {
        let form = new ReferralForm();
        form.formName = 'task';
        form.status = 'new';
        form.taskUser = "58b9777c05825b14978d980f";
        form.taskText = this.taskTextV;
        if (this.patient) {
            form.patientId = this.patient.hospitalNumber;
            form.patientDetails.familyName = this.patient.familyName;
            form.patientDetails.firstName = this.patient.firstName;
            form.patientDetails.title = this.patient.title;
            form.patientDetails.patientID = this.patient.hospitalNumber;
            form.patientDetails.nhsNumber = this.patient.nhsNumber;
            form.patientDetails.dob = moment(this.patient.dob).toDate();
            form.patientDetails.sex = this.patient.gender;
            form.patientDetails.verificationStatus = this.patient.nhsNumberVerifiedStatus;
            form.patientDetails.homeTelephone = this.patient.homePhone;
            form.patientDetails.workTelephone = this.patient.mobilePhone;
        }

        let that = this;
        this._customFormsService.saveForm(form).then((v:any) => {
            // // this.referralForm._id = v._id;
            // // if (this.parent) {
            // //     this.parent.updateElements();
            // // }
            // _.remove(that.referralForms, (fe) => {
            //     return f === fe;
            // });
            that.cancelTask();
        });
    }

    createElementDORAndClose() {
        // FIXME: !!!!!
        this.referralForm.status = "closed";
        this.referralForm.workflow = "disabled";

        // TODO: PUBLISH at some point !!!
        this._customFormsService.saveForm(this.referralForm).then((v) => {
            if (this.parent) {
                // this.parent.updateElements();
            }
        });
    }

    //

    ignoreDiagnose() {
        this.referralForm.diagnose.isIgnored = true;
        this.saveElement();
    }
    confirmDiagnose() {
        this.referralForm.diagnose.isConfirmed = true;
        this.referralForm.diagnose.diagnosis = this.referralForm.diagnose.watsonDiagnosis;
        this.saveElement();
    }

    ignoreCP() {
        this.referralForm.carePathway.isIgnored = true;
        this.saveElement();
    }
    confirmCP() {
        this.referralForm.carePathway.isConfirmed = true;
        this.referralForm.carePathway.carePathway = this.referralForm.carePathway.watsonRecommendedTreatment;
        this.saveElement();
    }
}

import * as moment from "moment";

export class PatientHistory {
    problemStart: Date;
    problemDescription: string;
    isOcular: boolean;
    isSymptom: boolean;
    isDiagnosis: boolean;
    medication: string;
    medicationType: string;
    snomedCode: string;

    // * Past Medical History
    // * SNOMED - Medical History
    // * Surgical History
    pastMedicalHistory: string;
    snomedMedicalHistory: string;
    surgicalHistory: string;

    public constructor(){
        this.problemStart = moment().toDate();
        this.problemDescription = "";
        this.isOcular = false;
        this.isSymptom = false;
        this.isDiagnosis = false;
        this.medication = "";
        this.medicationType = "";
        this.snomedCode = "";

        this.pastMedicalHistory = "";
        this.snomedMedicalHistory = "";
        this.surgicalHistory = "";
    }
}

export class SlitLamp {
    testName = "SlitLamp";

    lidsLashesOD: string;
	lidsLashesOS: string;
	conjunctivaScleraOD: string;
	conjunctivaScleraOS: string;
	corneaOD: string;
	corneaOS: string;
	anteriorChamberOD: string;
	anteriorChamberOS: string;
	irisOD: string;
	irisOS: string;
	lensOD: string;
	lensOS: string;
	anteriorVitreousOD: string;
	anteriorVitreousOS: string;
	anteriorChamberAngleOD: string;
	anteriorChamberAngleOS: string;

    public constructor() {
        this.lidsLashesOD = null;
        this.lidsLashesOS = null;
        this.conjunctivaScleraOD = null;
        this.conjunctivaScleraOS = null;
        this.corneaOD = null;
        this.corneaOS = null;
        this.anteriorChamberOD = null;
        this.anteriorChamberOS = null;
        this.irisOD = null;
        this.irisOS = null;
        this.lensOD = null;
        this.lensOS = null;
        this.anteriorVitreousOD = null;
        this.anteriorVitreousOS = null;
        this.anteriorChamberAngleOD = null;
        this.anteriorChamberAngleOS = null;
    }
}

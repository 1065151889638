import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {AdultClinicTypeSuggest} from "../../../../models/forms/referral/adult.clinic.type.suggest";
import * as _ from 'lodash';

@Component({
    selector: 'adult-clinic-type-suggest-component',
    templateUrl: './adult.clinic.type.suggest.html'
})
export class AdultClinicTypeSuggestComponent {
    @Input() adultClinicTypeSuggest: AdultClinicTypeSuggest;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }

    clickQ(qn) {
        if (!this.readOnly)
            this.adultClinicTypeSuggest[qn] = !this.adultClinicTypeSuggest[qn];
    }
}

import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {PatientDetails} from "../../../../models/forms/referral/patient.details";

@Component({
    selector: 'patient-details-component',
    templateUrl: './patient.details.html'
})
export class PatientDetailsComponent {
    @Input() patient: any;
    @Input() patientDocument: any;

    @Input() patientDetails: PatientDetails;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }

    // noinspection all
    gjt(v) {
        return JSON.stringify(v);
    }

    ngOnInit() {
    }
}

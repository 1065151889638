import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {GPDetailsType} from "../../../../models/forms/referral/gp.details.type";

@Component({
    selector: 'gp-component',
    templateUrl: './gp.html'
})
export class GPComponent {
    @Input() gpDetailsType: GPDetailsType;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

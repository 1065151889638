import {Component, Input} from '@angular/core';
import { Router } from "@angular/router";
import {Address} from "../../models/address.model";
import {GpData} from "../../models/gp.model";
import {Phone} from "../../models/phone.model";
import {PatientService} from "../../services/patient.service";
import {Patient} from "../../utils/definitions/Patient";
import * as $ from "jquery";

@Component({
    selector: 'patient-timeline-profile',
    templateUrl: './patient-timeline-profile.component.html'
})
export class PatientTimelineProfile {
    // @Input() name: string = "Stephens, Kristi (Mrs)";
    // @Input() dob: string = "01/05/1967";
    // @Input() pid: string = "100111";
    // @Input() nhs: string = "111 111 111";
    // @Input() gender: string = "female";
    // @Input() address: Address;
    // @Input() phone: Phone;
    // @Input() email: string;
    // @Input() gp: GpData;

    @Input() patient: Patient;
    @Input() image: string = "../../assets/img/no-photo.jpg";

    private expanded: boolean = false;

    constructor(private router: Router, private _patientService: PatientService) {}

    ngOnInit() {
        this._patientService.getPatientAvatarByEmail(this.patient.email).then(
            (result) => {
                if (result != "nophoto")
                    this.image = "https://randomuser.me/api/portraits/" + result + ".jpg";
            }
        )
    }

    switchExpanded(): void {
        this.expanded = !(this.expanded);
        console.log(this.patient);
        if(this.expanded) {
            $("#left-scroll-element").height($("#left-scroll-element").height() - 366);
        } else {
            $("#left-scroll-element").height($("#left-scroll-element").height() + 366);
        }
    }

    navigateBack(): void {
        this.router.navigate(['patients']);
    }
}
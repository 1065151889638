export class IOP {
    testName = "IOP";

    IOPOD: string;
    IOPOS: string;

    public constructor(){
        this.IOPOD = null;
        this.IOPOS = null;
    }
}

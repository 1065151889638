import {GP} from "./GP";
import {Surgery} from "./Surgery";
import {Address} from "./Address";
import {Name} from "./Name";
import {Attendance} from "./Attendance";
import {Alert} from "./Alert";
import {BaseClass} from "./BaseClass";
import {ApiService} from "../../services/api.service";
import {RootShareService} from "../../services/root.shared.service";
import * as moment from "moment";
import * as _ from "lodash";
import {Phone} from "./Phone";
import {Consultant} from "./Consultant";
import {Clinic} from "./Clinic";

export class Place {
    icon: string;
    description: string;
    location: string;
    bay: string;
}
export class EWS {
    private _value: number;
    trend: number; // -1 going down, 0 stable, 1 going up
    private _color: string;

    get value():number {
        return this._value;
    }
    set value(v:number) {
        this._value = v;
        switch(v) {
            case 0:{
                this._color = "#dadada";
                break;
            }
            case 1: {
                this._color = "#ffd300";
                break;
            }
            case 2:{
                this._color = "#ffa94c";
                break;
            }
            default: {
                this._color = "#fe5d5d";
                break;
            }
        }
    }
    get color():string {
        return this._color;
    }
}

export class ClinicAppointmentInfo extends BaseClass {
    appointmentDate: Date;
    appointmentHour: string;
    appointmentDay: string;
    consultant: Consultant;
    consultantName: string;
    appointmentType: string;
    attendanceStatus: string;
    clinic: string;
    clinicObj: Clinic;

    constructor(apiService:ApiService){
        super(apiService);
    }
}
export class InPatientInfo extends BaseClass {
    bedNumber: string;
    consultantFamilyName: string;
    consultantGivenName: string;
    consultantMiddleNames: string;
    consultantNatonalCode: string;
    wardCode: string;
    wardName: string;
    bay: string;

    constructor(apiService:ApiService){
        super(apiService);
    }
}

export class RecentPatient{
    patientName: string;
    patientId: string;
    constructor(patientName:string, patientId:string){
        this.patientName = patientName;
        this.patientId = patientId;
    }
}

export class Patient extends BaseClass {
    private _dateFormat: string;
    __id: string;
    names: Array<any>;
    hospitalNumber: string;
    nhsNumber: string;
    nhsNumberVerifiedStatus: string;
    nhsVerificationStatusMarker: string;
    firstName: string;
    middleNames: string;
    familyName: string;
    title:string;
    dob: Date;
    dod: Date;
    isEstimatedDOB: boolean;
    gender: string;
    genderCode: string;
    // TODO: should address lines be an Address object type???
    addressLines: Array<string>;    // containing 4 elements
    addresses: any;
    postalCode: string;
    homePhone: string;
    workPhone: string;
    mobilePhone: string;
    emailAddress: string;
    emails: Array<any>;
    scanningStatus: string;
    gp: GP;
    surgery: Surgery;
    otherAddresses: Array<Address>;
    otherNames: Array<Name>;
    documents: Array<Document>;
    attendances: Array<Attendance> = [];
    alerts: Array<Alert>;
    phoneNumbers: Array<Phone>;
    patientNumbers: Array<any>;
    //TODO pif: PifPatient;

    //special additional elements
    place: Place;
    haveAKANames: boolean = false;
    akaNames: Array<string>;
    genderClassName: Array<string>;
    patientFullName: string;
    patientFullName2: string;
    patientStringDob: string;
    patientStringDod: string;
    clinicAppointmentInfo: ClinicAppointmentInfo;
    inPatientInfo: InPatientInfo;
    ews: EWS;
    speciality: string;
    mergedNumbers: Array<string>;

    private _otherPhones:Array<string>;


    constructor(apiService: ApiService, private rootShareService: RootShareService) {
        super(apiService);
        this._dateFormat = "DD-MMM-YYYY";
    }

    static fromJSON2<T>(json: any): any{
        let basicObj = Object.create(this.prototype);
        basicObj._dateFormat = "DD-MMM-YYYY";
        return Object.assign(basicObj, json);
    }

    /**
     * Getter for patient ID
     * @returns {number} patient ID
     */
    get patientID(): string {
        //return this.__id;
        let id = this.__id;
        id = id.replace(/\//g, "_$_");
        id = id.replace(/\\/g, "_$$_");
        return id;
    }

    /**
     * Getter for patient family name
     * @returns {string} patient family name
     */
    get patientFamilyName(): string {
        return this.familyName;
    }

    /**
     * Getter for patient first name
     * @returns {string} patient first name
     */
    get patientFirstName(): string {
        return this.firstName;
    }

    /**
     * Getter for patient middle names
     * @returns {string} patient middle names
     */
    get patientMiddleNames(): string {
        return this.middleNames;
    }

    /**
     * Getter for patient NHS number
     * @returns {string} NHS number
     */
    get patientNHSNumber(): string {
        return this.nhsNumber;
    }

    /**
     * Getter for patient Hospital number
     * @returns {string} Hospital number
     */

    get patientHospitalNumber(): string {
        return this.hospitalNumber;
    }

    /**
     * Getter for patient date of birth
     * @returns {Date} date of birth
     */
    get patientDOB(): Date {
        return this.dob;
    }

    /**
     * Getter for formatted patient date of birth
     * @returns {Date} date of birth
     */
    get patientFormattedDOB(): string {
        // if(!this.patientStringDob){
        //     this.patientStringDob = moment.utc(this.dob).format(this._dateFormat);
        // }
        // return this.patientStringDob;
        let year = this.patientDOB.toString().substr(0,4);
        let month = this.patientDOB.toString().substr(5,2);
        let day = this.patientDOB.toString().substr(8,2);

        return day + "/" + month + "/" + year;
    }

    /**
     * Getter for formatted patient date of birth
     * @returns {Date} date of birth
     */
    get patientFormattedDOD(): string {
        if(!this.patientStringDod){
            this.patientStringDod = moment.utc(this.dod).format(this._dateFormat);
        }
        return this.patientStringDod;
    }

    get phoneNumber() {
        if (this.homePhone) {
            return "(Home) " + this.homePhone;
        } else if (this.mobilePhone) {
            return "(Mobile) " + this.mobilePhone;
        } else if (this.phoneNumbers && this.phoneNumbers[0]) {
            this.phoneNumbers.forEach((phone) => {
                if (phone.numberType && phone.number)
                    return "(" + phone.numberType + ") " + phone.number;
            })
            //return fake phone
            return "(Home) 789234123456";
        } else {
            //return fake phone
            return "(Home) 789234123456";
        }

    }

    get otherPhones() : Array<string> {
        if (!this._otherPhones) {
            this._otherPhones = new Array<string>();
            if(this.phoneNumbers) {
                this.phoneNumbers.forEach((phone) => {
                    if (phone.number && phone.number != this.homePhone &&
                        phone.number != this.workPhone &&
                        phone.number != this.mobilePhone) {
                        this._otherPhones.push("(" + phone.numberType + ") " + phone.number);
                    }
                });
            }
        }
        return this._otherPhones;
    }
    /**
     *
     * @returns {Array<string>}
     */
    get patientAddress(): Array<string> {
        if (this.addressLines) {
            return this.addressLines;
        } else if (this.addresses && this.addresses[0] && this.addresses[0].addressLines) {
            return this.addresses[0].addressLines;
        } else {
            //return fake address
            return ["37 Sidcup Street", "Exeter"];
        }
    }

    get patientAddressFirstLine(): string {
        let line1: string = "";
        if(this.addressLines && this.addressLines.length > 0) {
            let max: number = Math.round(this.addressLines.length / 2);
            for(let i = 0; i < max; i++){
                line1 = line1.length > 0 ? line1 + ", " + this.addressLines[i] : line1 + this.addressLines[i];
            }
        }
        return line1;
    }

    get patientAddressLastLine(): string {
        let line2: string = "";
        if(this.addressLines && this.addressLines.length > 0) {
            let min: number = Math.round(this.addressLines.length / 2);
            if(min < this.addressLines.length - 1){
                for(let i = min; i < this.addressLines.length; i++){
                    line2 = line2.length > 0 ? line2 + ", " + this.addressLines[i] : line2 + this.addressLines[i];
                }
            }
        }
        if(this.postalCode && this.postalCode.length > 0){
            line2 = line2.length > 0 ? line2 + ", " + this.postalCode : this.postalCode;
        }
        return line2;
    }

    get getPatientPostalCode(): string {
        return this.postalCode;
    }

    // get haveAKANames(): boolean {
    //     return (this.names && this.names.length > 1);
    // }

    get getPatientAKANames(): Array<string> {
        if(!this.akaNames) {
            this.akaNames = [];
            this.haveAKANames = false;
            _.forEach(this.names, (akaName) => {
                let firstName: string = "";
                if (akaName.firstName) {
                    firstName = akaName.firstName;
                }
                let middleNames: string = "";
                if (akaName.middleNames) {
                    middleNames = akaName.middleNames;
                }
                let familyName: string = "";
                if (akaName.familyName) {
                    familyName = akaName.familyName;
                }
                let nameType: string = "missing";
                if (akaName.nameType) {
                    nameType = akaName.nameType;
                }
                this.akaNames.push(firstName + " " + middleNames + " " + familyName + " (" + nameType + ")");
                this.haveAKANames = true;
            });
        }
        return this.akaNames;
    }

    get patientFullname(): string {
        if(!this.patientFullName || this.patientFullName.length <= 0){
            let familyName:string = "";
            if(this.familyName){
                familyName = this.familyName;
            }
            let firstName:string = "";
            if(this.firstName){
                firstName = this.firstName;
            }
            let middleNames:string = "";
            if(this.middleNames){
                middleNames = this.middleNames;
            }
            this.patientFullName = familyName.replace(/\b\w/g, l => l.toUpperCase()) + ", " + firstName + " " + middleNames
                                    + (this.title ? " (" + this.title + ")" : "");
        }
        if(this.patientFullName == ", "){
            this.patientFullName = "";
        }
        return this.patientFullName;

    }

    get patientFullname2(): string {
        if(!this.patientFullName2 || this.patientFullName2.length <= 0){
            let firstName:string = "";
            if(this.firstName){
                firstName = this.firstName;
            }

            let middleNames:string = "";
            if(this.middleNames){
                middleNames = this.middleNames;
            }

            let familyName:string = "";
            if(this.familyName){
                familyName = this.familyName;
            }

            this.patientFullName2 =  (this.title ? this.title + " " : "") + firstName.replace(/\b\w/g, l => l.toUpperCase()) + " " + middleNames + " " + familyName;
        }
        if(this.patientFullName2 == "  "){
            this.patientFullName2 = "";
        }
        return this.patientFullName2;

    }

    get email(): string {
        if (this.emailAddress) {
            return this.emailAddress;
        } else if (this.emails && this.emails[0] && this.emails[0].emailAddress) {
            return this.emails[0].emailAddress;
        } else {
            //return fake email
            if (this.firstName && this.familyName)
                return this.firstName.toLowerCase() + "." + this.familyName.toLowerCase() + "@example.com";
            else
                return "Not found";
        }
    }

    get patientGenderClasses(): Array<string> {
        if(!this.genderClassName){
            this.genderClassName = [];
            switch(this.genderCode){
                case "0"://not known
                    // this.genderClassName.push("unity-icon-ugender_left");
                    // this.genderClassName.push("unity-icon-ugender_right");
                    break;
                case "1": //male
                    this.genderClassName.push("unity-icon-male");
                    break;
                case "2": //female
                    this.genderClassName.push("unity-icon-female");
                    break;
                case "9": //not specified
                    this.genderClassName.push("unity-icon-unknown-gender");
                    break;
                default: //all other values
                    // this.genderClassName.push("unity-icon-ugender_left");
                    // this.genderClassName.push("unity-icon-ugender_right");
                    break;
            }
        }
        return this.genderClassName;
    }

    get actualAge(): number {
        let today = new Date();
        let birthDate = new Date(this.dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    get spaceSaversNumber(): string {
        let result:string = "";
        this.patientNumbers.forEach((nbrObj) => {
            if(nbrObj && nbrObj.hasOwnProperty('type') && nbrObj['type'] == "SpecSaversNumber") {
                result = nbrObj.hasOwnProperty('number') ? nbrObj['number'] : "";
            }
        });
        return result == "" ? this.hospitalNumber : result;
    }
}
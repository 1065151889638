import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";


@Injectable()
export class WatsonService {
    watsonId: string = "watson";
    watsonPhoto: string = "assets/img/watson-ida.png";

    constructor(private _api: ApiService){}

    getToken():Promise<string> {
        return new Promise((resolve, reject)=>{
            this._api.get("assistant/token").then(
                (token) => {
                    if(token){
                        resolve(token);
                    }
                },
                (error) => {
                    console.log(error);
                    reject(error);
                }
            )
        })
    }

    askWatson(message:string, ignorePush):Promise<string> {
        if (!ignorePush) {
            return new Promise((resolve, reject) => {
                this._api.post("assistant/message", {text: message}).then(
                    (answer) => {
                        if (answer && answer.text)
                            resolve(answer);
                        resolve("I don't understand.");
                    }, (error) => {
                        console.log(error);
                        resolve("I don't understand.");
                    }
                )
            })
        } else {
            return new Promise((resolve, reject) => {
                this._api.post("assistant/message", {text: message, resetContext: true}).then(
                    (answer) => {
                        if (answer && answer.text)
                            resolve(answer);
                        resolve("I don't understand.");
                    }, (error) => {
                        console.log(error);
                        resolve("I don't understand.");
                    }
                )
            })
        }
    }
}
import { Http } from "@angular/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { CanActivate } from "@angular/router";
import { Broadcaster } from "./broadcaster";
import { ApiService } from "./api.service";
import { RootShareService } from "./root.shared.service";
import { UsersService } from "./user.service";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router/src/router_state";
import { Observable } from "rxjs/index";
import {UserData} from "../models/user.model";
import {MapUtils} from "../models/mapUtil";
import {MockService} from "./mock.service";

class AuthInformation
{
    private _logged: boolean;
    get logged():boolean {
        return this._logged;
    }
    private _logData: string;
    get logData(): string {
        return this._logData;
    }
    private _userId: string;
    get userId():string {
        return this._userId;
    }
    private _logDate: Date;
    get logDate(): Date {
        return this._logDate;
    }
    private _accessToken: string;
    get accessToken():string {
        return this._accessToken;
    }
    private _refreshToken: string;
    get refreshToken():string {
        return this._refreshToken;
    }
    public readonly sessionTimeout: number; //in seconds

    constructor(){
        this._logged = false;
        this._userId = undefined;
        this._logDate = undefined;
        this._refreshToken = undefined;
        this.sessionTimeout = environment.sessionTimeout;
    }

    public logUser(uid: string, at: string, rt: string, ld:string){
        this._logged = true;
        this._logData = ld;
        this._userId = uid;
        this._accessToken = at;
        this._refreshToken = rt;
        this._logDate = new Date();
    }
}

@Injectable()
export class AuthService {

    public status: AuthInformation;
    private _options: any = {};
    public currentUser: UserData = null;

    constructor (public http: Http, private _broadcaster: Broadcaster, private _apiService: ApiService,
                 private rootShare: RootShareService, private _router: Router, private _mockService: MockService) {
        this.status = new AuthInformation();
    }

    basicLogin(username, password): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._apiService.postAuth('auth', {
                "userName": username,
                "password": password
            }).then((resultLogin) => {
                if (resultLogin) {
                    localStorage.setItem('access_token', resultLogin);
                    this._options['access_token'] = resultLogin;
                    this.currentUser = null;
                    this.getUser().then((currentUser) => {

                        //fake login to get mock data on the dashboard
                        this.status.logUser(currentUser.id, "noToken", "noToken", btoa(username+"*|*"+password));

                        resolve(resultLogin);
                    }), (err) => {
                        delete this._options['access_token'];
                        localStorage.removeItem('access_token');
                        reject(err);
                    }
                } else {
                    resolve(resultLogin);
                }
            }, (loginFailed) => {
                reject(loginFailed);
            })
        })
    }

    getUser(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.currentUser != null) {
                resolve(this.currentUser);
            } else {
                this._apiService.get('users/current').then(
                    (resultUserData) => {
                        if(resultUserData) {
                            this.currentUser = MapUtils.deserialize(UserData, resultUserData);
                            if(resultUserData.firstName && resultUserData.firstName.length > 0 ){
                                this.currentUser.firstName = resultUserData.firstName;
                            }
                            if(resultUserData.familyName && resultUserData.familyName.length > 0 ){
                                this.currentUser.lastName = resultUserData.familyName;
                            }
                            if(!this.currentUser.photo){
                                this._mockService.getData("currentUserMissingFields", this.currentUser.id).then((mockData) => {
                                    Object.keys(this.currentUser).forEach((key)=> {
                                        if(!this.currentUser[key]){
                                            this.currentUser[key] = mockData[key];
                                        }
                                    });
                                    this.currentUser._userName = "";
                                    if(this.currentUser.firstName && this.currentUser.firstName.length > 0 ){
                                        this.currentUser._userName += this.currentUser.firstName.charAt(0).toUpperCase() + this.currentUser.firstName.slice(1).toLowerCase();
                                    }
                                    if(this.currentUser.lastName && this.currentUser.lastName.length > 0 ){
                                        this.currentUser._userName += " " + this.currentUser.lastName.charAt(0).toUpperCase() + this.currentUser.lastName.slice(1).toLowerCase();
                                    }
                                    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                                    resolve(this.currentUser);
                                })
                            } else {
                                this.currentUser._userName = "";
                                if(this.currentUser.firstName && this.currentUser.firstName.length > 0 ){
                                    this.currentUser._userName += this.currentUser.firstName.charAt(0).toUpperCase() + this.currentUser.firstName.slice(1).toLowerCase();
                                }
                                if(this.currentUser.lastName && this.currentUser.lastName.length > 0 ){
                                    this.currentUser._userName += " " + this.currentUser.lastName.charAt(0).toUpperCase() + this.currentUser.lastName.slice(1).toLowerCase();
                                }
                                localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                                console.log(this.currentUser);
                                resolve(this.currentUser);
                            }
                        }
                    }, (getUserFailed) => {
                        if(getUserFailed.status == 401 || getUserFailed.status == 403){
                            this.logout();
                            console.log("User response auth " + getUserFailed.status);
                        }
                        reject(getUserFailed);
                    }
                );
            }
        });
    }

    logout():Promise<boolean> {
        return new Promise((resolve) => {
            this._apiService.delete('auth').then((result) => {
                this.currentUser = null;
                localStorage.removeItem("currentUser");
                this._broadcaster.broadcast("logout");
                this.status = new AuthInformation();
                resolve(true);
            }, (error) => {
                //todo: logout error handling
                console.error(error);
                this.currentUser = null;
                localStorage.removeItem("currentUser");
                this._broadcaster.broadcast("logout");
                this.status = new AuthInformation();
                resolve(true);
            });
        })
    }
}

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private _authService: AuthService){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve) => {
            if (this._authService.status.logged) {
                resolve(true);
            } else {
                this._authService.getUser().then(
                    (response) => {
                        this._authService.status.logUser("christopher", "noToken", "noToken", btoa(response.userName+"*|*"+response.hash));
                        resolve(true);
                    }, (err) => {
                        resolve(false);
                    }
                );
            }
        });
    }
}

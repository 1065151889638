import {PatientDetails} from "./patient.details";
import {PatientHistory} from "./patient.history";
import {PatientFamilyHistory} from "./patient.family.history";
import {PatientSocialHistory} from "./patient.social.history";
import {PatientGeneralHistory} from "./patient.general.history";
import {OptomDetails} from "./optom.details";
import {ChildClinicTypeSuggest} from "./child.clinic.type.suggest";
import {AdultClinicTypeSuggest} from "./adult.clinic.type.suggest";
import {VisualAcuity} from "./visual.acuity";
import {ExternalExam} from "./external.exam";
import {OcularMotilityAndMuscleBalance} from "./ocular.motility.and.muscle.balance";
import {PupilExam} from "./pupil.exam";
import {VisualField} from "./visual.field";
import {OpticNerveHead} from "./optic.nerve.head";
import {OpticCoherenceTomography} from "./optic.coherence.tomography";
import {SlitLamp} from "./slit.lamp";
import {IOP} from "./iop";
import {FundasExam} from "./fundas.exam";
import {AuditDetailsType} from "./audit.details.type";
import {GPDetailsType} from "./gp.details.type";
import moment = require("moment");
import {Moment} from "moment";
import {GpAction} from "./gp.action";
import {Medications} from "./medications";
import {Allergies} from "./allergies";
import {PastOcularHistory} from "./past.ocular.history";
import {ReferralPresentingSymptom} from "./referral.presenting.symptom";
import {HistoryOfPresentingSymptom} from "./history.of.presenting.symptom";
import {Diagnose} from "./diagnose";
import {CarePathway} from "./care.pathway";
import {Refraction} from "./refraction";
import {FundusImageInterpretationExam} from "./fundus.image.interpretation.exam";
import {VisualFieldInterpretationExam} from "./visual.field.interpretation.exam";

enum GPActionRequired {
    INFORMATION_ONLY = 'information only',
    PATIENT_ACTION_CONTACT_GP = 'patient action contact gp',
    PATIENT_ACTION_EYE_CAS = 'patient action eye cas',
    PATIENT_REFERRED_URGENT = 'patient referred urgent',
    PATIENT_REFERRED_ROUTINE = 'patient referred routine'
}

export class ReferralForm {
    _id: any;
    status: string;
    workflow: string = 'open';

    version: number;
    when: Date = moment().toDate();
    whenString: string;

    expanded: boolean;
    isUrgent: boolean = false;

    formName: string = 'referral';
    // TODO: !
    userId: string = null;
    patientId: string = null;

    referralGroup: string = "General Referral";

    taskText: string;
    taskUser: string;

    users: Array<string>;

    auditDetailsType: AuditDetailsType;
    gpDetailsType: GPDetailsType;

    patientDetails: PatientDetails;
    patientHistory: Array<PatientHistory>;
    patientFamilyHistory: Array<PatientFamilyHistory>;
    patientSocialHistory: Array<PatientSocialHistory>;
    patientGeneralHistory: Array<PastOcularHistory>;
    patientAllergy: Array<Allergies>;

    pastOcularHistory: Array<PastOcularHistory>;
    presentingSymptom: ReferralPresentingSymptom;
    historyOfPresentingSymptom: HistoryOfPresentingSymptom;
    diagnose: Diagnose;
    carePathway: CarePathway;

    optomDetails: OptomDetails;
    adultClinicTypeSuggest: Array<AdultClinicTypeSuggest>;
    childClinicTypeSuggest: Array<ChildClinicTypeSuggest>;

    gpAction: GpAction;

    medicationsOcular: Array<Medications>;
    medicationsSystemic: Array<Medications>;

    tests: Array<VisualAcuity | ExternalExam
        | OcularMotilityAndMuscleBalance | PupilExam
        | VisualField | OpticNerveHead | OpticCoherenceTomography
        | SlitLamp | IOP | FundasExam | Refraction | FundusImageInterpretationExam | VisualFieldInterpretationExam>;

    documentId: string;
    documentThumbnail: string;

    // visualAcuity: VisualAcuity;
    // externalExam: ExternalExam;
    // ocularMotilityAndMuscleBalance: OcularMotilityAndMuscleBalance;
    // pupilExam: PupilExam;
    // visualField: VisualField;
    // opticNerveHead: OpticNerveHead;
    // opticCoherenceTomography: OpticCoherenceTomography;
    // iop: IOP;
    // slitLamp: SlitLamp;
    // fundasExam: FundasExam;

    // BLOCKS
    /*
    * Patient Details
    * Patient General History
    * Patient History
    * Patient Family History
    * Patient Social History
    *
    * Patient Allergy
    *
    * Optom Details
    * GP?
    *
    * Adult Clinic Type Suggest
    * Child Clinic Type Suggest
    *
    * Audit (just information)
    *
    * tests ....
     */

    mode: string;

    public constructor(){
        this.status = 'new';
        this.version = 1;
        this.expanded = false;

        this.mode = 'referral';

        this.users = [];

        this.auditDetailsType = new AuditDetailsType();
        this.gpDetailsType = new GPDetailsType();

        this.patientDetails = new PatientDetails();
        this.patientHistory = [];
        this.patientFamilyHistory = [];
        this.patientSocialHistory = [];
        this.patientGeneralHistory = [];
        this.patientAllergy = [];

        this.pastOcularHistory = [];
        this.presentingSymptom = new ReferralPresentingSymptom();
        this.historyOfPresentingSymptom = new HistoryOfPresentingSymptom();
        this.diagnose = new Diagnose();
        this.carePathway = new CarePathway();

        this.optomDetails = new OptomDetails();
        this.adultClinicTypeSuggest = [];
        this.childClinicTypeSuggest = [];
        this.gpAction = new GpAction();

        this.medicationsOcular = [];
        this.medicationsSystemic = [];

        this.documentId = null;
        this.documentThumbnail = null;

        // TODO: tests ... [] maybe we should consider adding tests dynamically ?

        this.tests = [];

        // this.visualAcuity = new VisualAcuity();
        // this.externalExam = new ExternalExam();
        // this.ocularMotilityAndMuscleBalance = new OcularMotilityAndMuscleBalance();
        // this.pupilExam = new PupilExam();
        // this.visualField = new VisualField();
        // this.opticNerveHead = new OpticNerveHead();
        // this.opticCoherenceTomography = new OpticCoherenceTomography();
        // this.iop = new IOP();
        // this.slitLamp = new SlitLamp();
        // this.fundasExam = new FundasExam();
    }
}

import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {PatientFamilyHistory} from "../../../../models/forms/referral/patient.family.history";

@Component({
    selector: 'patient-family-history-component',
    templateUrl: './patient.family.history.html'
})
export class PatientFamilyHistoryComponent {
    @Input() patientFamilyHistory: PatientFamilyHistory;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    items = PatientFamilyHistory.relationshipChoices;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

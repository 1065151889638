export const mandatoryEvnKeys:Array<string> = ['production','version','logLevel','apiUrl','loginAuth','firstDayOfWeek','intercomAppId'];

export class EnvironmentClass {
    production: boolean = false;
    version: string = "development";
    debugUserName: string = "gordon";
    debugUserPass: string = "demo.demo";
    logLevel: string = "ALL";
    domain: string = "http://ucr.fortrus.com";
    path: string = "/unity-auth-server/";
    cookieName: string = "JSESSIONID";
    logoutUri: string = "http://v-qa02.scandoc.local:8080/unity-auth-server/login.jsp";
    apiUrl: string = "http://ucr.fortrus.com/unity/rest/API/1.1";
    responseType: string = "token";
    clientId: string = "ucr_app";
    state: string = "SomeVeryficationState";
    connectionRetry: number = 5;
    connectionTimeout: number = 2000;
    connectionRetryDelay: number = 10;
    requestGETTimeout: number = 20000;
    requestPUTTimeout: number = 20000;
    requestPOSTTimeout: number = 10000;
    patientTableAppointmentDate: any;

    constructor() {
        this.patientTableAppointmentDate = {
            "todayFormat": "HH:mm",
            "otherDayFormat": "MM-DD HH:mm"
        };
    }
}
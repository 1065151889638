import {Component, EventEmitter, Input, Output} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {RootShareService} from "../../services/root.shared.service";
import {Moment} from "moment";
import * as moment from "moment";
import * as _ from "lodash";
import {TimelineElementType} from "../patient-timeline-element/patient-timeline-element.component";
import {CustomFormsService} from "../../services/custom.forms.service";
import {VteForm} from "../../models/forms/vte/vte.form";
//import any = jasmine.any;

@Component({
    selector: 'vte-element-component',
    templateUrl: './vte.element.component.html',
    styleUrls: ['../form-element/form.element.component.scss']
})
export class VteElementComponent {
    @Input() type: TimelineElementType = TimelineElementType.vitals;
    @Input() title: string = "VTE risk assessment";
    @Input() firstIcon: string = "";
    @Input() secondIcon: string = "";

    @Input() parent: any;

    @Input() userThumbnail: boolean = false;
    @Input() showDateBalloon: boolean = false;
    @Input() userThumbnailName: string = "John Doe";
    @Input() userThumbnailTitle: string = "5 hours ago";
    @Input() userThumbnailImage: string = "../../assets/img/users_photo/male/christopher.png";

    //Clinical Image
    @Input() patient: any;
    @Input() patientDocument: any;

    @Output() firstIconAction = new EventEmitter();
    @Output() secondIconAction = new EventEmitter();

    @Input() vteForm: VteForm = new VteForm();

    expandedFlags: any = {
        _parent: null, //FormElementComponent
        _stage1: false,
        get stage1(): boolean { return this._stage1; },
        set stage1(v: boolean) { this._stage1 = v; },
        _stage2: false,
        get stage2(): boolean { return this._stage2; },
        set stage2(v: boolean) { this._stage2 = v; },
        _stage3: false,
        get stage3(): boolean { return this._stage3; },
        set stage3(v: boolean) { this._stage3 = v; },
    };

    addMenuVisible = false;

    constructor(private _rootShareService: RootShareService, private _customFormsService: CustomFormsService, private _auth: AuthService) {
        this.expandedFlags._parent = this;
    }

    getClasses(icon) {
        let classes: Array<string> = [];

        classes.push("unity-icon");
        classes.push("timeline-icon");

        if (icon == 'first')
            classes.push(this.firstIcon);
        else if (icon == 'second')
            classes.push(this.secondIcon);

        return classes;
    }

    userThumbnailDate() {
        let d = moment(this.vteForm.when);
        let day = d.format('DD');
        let month = d.format('MMM');
        let year = d.format('Y');

        return day + " " + month + " " + year;
    }

    getMonth() {
        // noinspection all
        let month = moment(this.vteForm.when).format('MMM').toUpperCase();
        return month;
    }

    getYear() {
        // noinspection all
        let year = moment(this.vteForm.when).format('Y');
        return year;
    }

    /////

    isReadOnly() {
        return !(this.vteForm.status === 'new' || this.vteForm.status === 'edit');
    }

    saveElement() {
        // noinspection all
        // let formToSave = _.cloneDeep(this.referralForm);
        // // FIXME: !!!!!
        // formToSave.status = "save";
        this.vteForm.status = "save";

        // TODO: PUBLISH at some point !!!
        // this._customFormsService.saveForm(formToSave).then((v) => {
        //     // alert(v);
        // });
        this._customFormsService.saveForm(this.vteForm).then((v:any) => {
            this.vteForm._id = v._id;
            if (this.parent) {
                // this.parent.updateElements();
            }
        });
    }

    editElement() {
        this.vteForm.status = "edit";
        if (this.parent) {
            // this.parent.updateElements();
        }
    }

    cancelElement() {
        if (this.parent) {
            this.parent.cancelElement(this.vteForm);
        }
    }

    removeElement() {
        if (this.parent) {
            this.parent.removeVte(this.vteForm);
        }
    }

    createElementDOR() {
        // noinspection all
        let formToSave = _.cloneDeep(this.vteForm);
        delete formToSave._id;
        formToSave.status = "closed_dor";
        formToSave.workflow = "disabled";

        this._customFormsService.saveForm(formToSave).then((v) => {
            if (this.parent) {
                this.parent.addVteElement(formToSave);
                // this.parent.updateElements();
            }
            this.vteForm.when = moment().toDate();
            this.saveElement();
        });
    }

    createElementDORAndClose() {
        // FIXME: !!!!!
        this.vteForm.status = "closed";
        this.vteForm.workflow = "disabled";

        // TODO: PUBLISH at some point !!!
        this._customFormsService.saveForm(this.vteForm).then((v) => {
            if (this.parent) {
                // this.parent.updateElements();
            }
        });
    }
}

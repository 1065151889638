export class HistoryOfPresentingSymptom {
    // Hstory of Presenting Complaint (HPC) / Reason for Referral
    // Watson Presenting Complaint Summary

    reasonForReferral: string;
    watsonPresentingComplaint: string;
    public constructor(){
        this.reasonForReferral = "";
        this.watsonPresentingComplaint = "";
    }
}

import {Component, OnInit} from "@angular/core";
import {
    ActivatedRoute,
    Router
} from "@angular/router";
import {MainService} from "../../services/main.service";

@Component({
    templateUrl: './main.module.html'
})
export class MainModule implements OnInit {

    constructor(private _router: Router, private _route: ActivatedRoute, private _mainService: MainService) {
    }

    ngOnInit() {
        this._route.url.subscribe(
            () => {
                console.log(this._route.children.length);
                if(this._route.children.length == 0){
                    this._router.navigate(["dashboard"]);
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    closeIFrame(){
        this._mainService.closeIFrame();
    }
}
export class PastOcularHistory {
    // Past Ocular History
    // Past Family Ocular History

    pastOcularHistory: string;
    pastFamilyOcularHistory: string;
    public constructor(){
        this.pastOcularHistory = "";
        this.pastFamilyOcularHistory = "";
    }
}

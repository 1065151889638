import {Component, Input} from '@angular/core';

@Component({
  selector: 'ui-button',
  templateUrl: './button.element.html'
})
export class UiButton {
  @Input() label: string = '';
  @Input() fontColor: string = '#000';
  @Input() backgroundColor: string = '#009751';
  @Input() outlineColor: string = '#009751';
  @Input() insidePadding: string = '8px';
  @Input() sidePadding: string = '8px';
  @Input() borderRadius: string = '5px';
  @Input() cardNotification: number = 0;
  @Input() letterSpacing: string = '0.3px';
  @Input() paddingTop: string = '5px';
  @Input() paddingBottom: string = '5px';

  calculatePaddingRight() {
    if (this.cardNotification > 0)
      return '0px';
    else
      return this.insidePadding;
  }
}

export class FundusImageInterpretationExam {
    testName = "FundusImageInterpretation";

    descriptionOD: string;
    descriptionOS: string;
    discOD: string;
    maculaOD: string;
    vesselsOD: string;
    peripheryOD: string;
    discOS: string;
    maculaOS: string;
    vesselsOS: string;
    peripheryOS: string;

    OpticDiscOD: string;
    CupToDiscRatioOD: string;
    DiabeticRetinopathyOD: string;
    AgeRelatedMacularDegenerationOD: string;
    NonDiabeticRetinalAnomaliesOD: string;
    NonDiabeticMacularAnomaliesOD: string;

    OpticDiscOS: string;
    CupToDiscRatioOS: string;
    DiabeticRetinopathyOS: string;
    AgeRelatedMacularDegenerationOS: string;
    NonDiabeticRetinalAnomaliesOS: string;
    NonDiabeticMacularAnomaliesOS: string;

    public constructor(){
        this.descriptionOD = null;
        this.descriptionOS = null;
        this.discOD = null;
        this.maculaOD = null;
        this.vesselsOD = null;
        this.peripheryOD = null;
        this.discOS = null;
        this.maculaOS = null;
        this.vesselsOS = null;
        this.peripheryOS = null;

        this.OpticDiscOD = null;
        this.CupToDiscRatioOD = null;
        this.DiabeticRetinopathyOD = null;
        this.AgeRelatedMacularDegenerationOD = null;
        this.NonDiabeticRetinalAnomaliesOD = null;
        this.NonDiabeticMacularAnomaliesOD = null;

        this.OpticDiscOS = null;
        this.CupToDiscRatioOS = null;
        this.DiabeticRetinopathyOS = null;
        this.AgeRelatedMacularDegenerationOS = null;
        this.NonDiabeticRetinalAnomaliesOS = null;
        this.NonDiabeticMacularAnomaliesOS = null;
    }
}

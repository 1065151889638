import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UploadState} from '../../components/referral-image/referral-image.element.component';

@Component({
    selector: 'diagnosis-overlay',
    templateUrl: './diagnosis-overlay.element.html'
})
export class DiagnosisOverlayElement {
    @Input() responseState: UploadState = UploadState.empty;
    @Input() diagnose: string;
    @Input() diagnoseAccepted: string = "";
    @Output() clicked = new EventEmitter();

    private _responseStateEnum = UploadState;

    constructor(){}

    onClick(value) {
        this.clicked.emit(value);
    }
}
import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../../services/auth.service";
import {RootShareService} from "../../../../../services/root.shared.service";
import {VisualField} from "../../../../../models/forms/referral/visual.field";
import {Refraction} from "../../../../../models/forms/referral/refraction";

@Component({
    selector: 'refraction-field-component',
    templateUrl: './refraction.field.html'
})
export class RefractionComponent {
    @Input() refraction: Refraction;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }

    jsonify(w) {
        return JSON.stringify(w);
    }
}

import {Component, EventEmitter, Input, Output} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {RootShareService} from "../../services/root.shared.service";
import {Moment} from "moment";
import * as moment from "moment";
import * as _ from "lodash";
import * as $ from "jquery";
import {TimelineElementType} from "../patient-timeline-element/patient-timeline-element.component";
import {ReferralForm} from "../../models/forms/referral/referral.form";
import {PatientHistory} from "../../models/forms/referral/patient.history";
import {PatientGeneralHistory} from "../../models/forms/referral/patient.general.history";
import {PatientFamilyHistory} from "../../models/forms/referral/patient.family.history";
import {PatientSocialHistory} from "../../models/forms/referral/patient.social.history";
import {PatientAllergy} from "../../models/forms/referral/patient.allergy";
import {ExternalExam} from "../../models/forms/referral/external.exam";
import {FundasExam} from "../../models/forms/referral/fundas.exam";
import {IOP} from "../../models/forms/referral/iop";
import {OcularMotilityAndMuscleBalance} from "../../models/forms/referral/ocular.motility.and.muscle.balance";
import {OpticCoherenceTomography} from "../../models/forms/referral/optic.coherence.tomography";
import {OpticNerveHead} from "../../models/forms/referral/optic.nerve.head";
import {PupilExam} from "../../models/forms/referral/pupil.exam";
import {SlitLamp} from "../../models/forms/referral/slit.lamp";
import {VisualAcuity} from "../../models/forms/referral/visual.acuity";
import {VisualField} from "../../models/forms/referral/visual.field";
import {PatientService} from "../../services/patient.service";
import {CustomFormsService} from "../../services/custom.forms.service";
import {Medications} from "../../models/forms/referral/medications";
import {Allergies} from "../../models/forms/referral/allergies";
import {PastOcularHistory} from "../../models/forms/referral/past.ocular.history";
//import any = jasmine.any;

@Component({
    selector: 'form-element-component',
    templateUrl: './form.element.component.html',
    styleUrls: ['./form.element.component.scss']
})
export class FormElementComponent {
    @Input() type: TimelineElementType = TimelineElementType.vitals;
    @Input() title: string = "Prescriptions";
    @Input() firstIcon: string = "";
    @Input() secondIcon: string = "";

    @Input() parent: any;

    @Input() userThumbnail: boolean = false;
    @Input() showDateBalloon: boolean = false;
    @Input() userThumbnailName: string = "John Doe";
    @Input() userThumbnailTitle: string = "5 hours ago";
    @Input() userThumbnailImage: string = "../../assets/img/users_photo/male/christopher.png";

    //Clinical Image
    @Input() patient: any;
    @Input() patientDocument: any;

    @Output() firstIconAction = new EventEmitter();
    @Output() secondIconAction = new EventEmitter();
    @Output() refreshVitalsGraph = new EventEmitter();

    @Input() referralForm: ReferralForm = new ReferralForm();

    expandedFlags: any = {
        _parent: null, //FormElementComponent

        _patientDetails: false,
        get patientDetails(): boolean { return this._patientDetails; },
        set patientDetails(v: boolean) { this._patientDetails = v; },

        _presentingSymptom: false,
        get presentingSymptom(): boolean { return this._presentingSymptom; },
        set presentingSymptom(v: boolean) { this._presentingSymptom = v; },
        _historyOfPresentingSymptom: false,
        get historyOfPresentingSymptom(): boolean { return this._historyOfPresentingSymptom; },
        set historyOfPresentingSymptom(v: boolean) { this._historyOfPresentingSymptom = v; },
        _diagnose: false,
        get diagnose(): boolean { return this._diagnose; },
        // get diagnose(): boolean { return this._parent.isReadOnly() || this._diagnose; },
        set diagnose(v: boolean) { this._diagnose = v; },
        _carePathway: false,
        get carePathway(): boolean { return this._carePathway; },
        // get carePathway(): boolean { return this._parent.isReadOnly() || this._carePathway; },
        set carePathway(v: boolean) { this._carePathway = v; },

        _adultsDetails: false,
        // get adultsDetails(): boolean { return this._parent.isReadOnly() || this._adultsDetails; },
        get adultsDetails(): boolean { return this._adultsDetails; },
        set adultsDetails(v: boolean) { this._adultsDetails = v; },
        _childrenDetails: false,
        get childrenDetails(): boolean { return this._childrenDetails; },
        set childrenDetails(v: boolean) { this._childrenDetails = v; },
        _gpActionRequired: false,
        get gpActionRequired(): boolean { return this._gpActionRequired; },
        set gpActionRequired(v: boolean) { this._gpActionRequired = v; },
        _patientGeneralHistory: false,
        get patientGeneralHistory(): boolean { return this._patientGeneralHistory; },
        set patientGeneralHistory(v: boolean) { this._patientGeneralHistory = v; },
        _patientHistory: false,
        get patientHistory(): boolean { return this._patientHistory; },
        set patientHistory(v: boolean) { this._patientHistory = v; },
        _patientFamilyHistory: false,
        get patientFamilyHistory(): boolean { return this._patientFamilyHistory; },
        set patientFamilyHistory(v: boolean) { this._patientFamilyHistory = v; },
        _patientSocialHistory: false,
        get patientSocialHistory(): boolean { return this._patientSocialHistory; },
        set patientSocialHistory(v: boolean) { this._patientSocialHistory = v; },
        _medicationsOcular: false,
        get medicationsOcular(): boolean { return this._medicationsOcular; },
        set medicationsOcular(v: boolean) { this._medicationsOcular = v; },
        _medicationsSystemic: false,
        get medicationsSystemic(): boolean { return this._medicationsSystemic; },
        set medicationsSystemic(v: boolean) { this._medicationsSystemic = v; },
        _patientAllergy: false,
        get patientAllergy(): boolean { return this._patientAllergy; },
        set patientAllergy(v: boolean) { this._patientAllergy = v; },
        _optomDetails: false,
        get optomDetails(): boolean { return this._optomDetails; },
        set optomDetails(v: boolean) { this._optomDetails = v; },
        _gpDetails: false,
        get gpDetails(): boolean { return this._gpDetails; },
        set gpDetails(v: boolean) { this._gpDetails = v; },
        _tests: false,
        get tests(): boolean { return this._tests; },
        set tests(v: boolean) { this._tests = v; },

        _displayDocument: false,
        get displayDocument(): boolean { return this._displayDocument; },
        set displayDocument(v: boolean) { this._displayDocument = v; }
    };

    addMenuVisible = false;

    constructor(private _rootShareService: RootShareService, private _customFormsService: CustomFormsService, private _auth: AuthService) {
        this.expandedFlags._parent = this;
    }

    getClasses(icon) {
        let classes: Array<string> = [];

        classes.push("unity-icon");
        classes.push("timeline-icon");

        if (icon == 'first')
            classes.push(this.firstIcon);
        else if (icon == 'second')
            classes.push(this.secondIcon);

        return classes;
    }

    userThumbnailDate() {
        let d = moment(this.referralForm.when);
        let day = d.format('DD');
        let month = d.format('MMM');
        let year = d.format('Y');

        return day + " " + month + " " + year;
    }

    getMonth() {
        // noinspection all
        let month = moment(this.referralForm.when).format('MMM').toUpperCase();
        return month;
    }

    getYear() {
        // noinspection all
        let year = moment(this.referralForm.when).format('Y');
        return year;
    }

    /////

    addExternalExam(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new ExternalExam());
    }
    addFundasExam(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new FundasExam());
    }
    addIOP(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new IOP());
    }
    addOcular(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new OcularMotilityAndMuscleBalance());
    }
    addOpticCoherence(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new OpticCoherenceTomography());
    }
    addOpticNerve(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new OpticNerveHead());
    }
    addPupil(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new PupilExam());
    }
    addSlit(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new SlitLamp());
    }
    addVisualAcuity(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new VisualAcuity());
    }
    addVisualField(e) {
        e.stopPropagation(); e.preventDefault();
        this.addMenuVisible = false;
        this.expandedFlags.tests = true;
        this.referralForm.tests.push(new VisualField());
    }

    removeTest(e, test) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.tests, (v) => { return v === test; });
    }

    /////

    addPatientHistory(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.patientHistory.push(new PatientHistory());
        this.expandedFlags.patientHistory = true;
    }
    removePatientHistory(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.patientHistory, (v) => {
            return v === element;
        });
    }

    addPatientGeneralHistory(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.patientGeneralHistory.push(new PastOcularHistory());
        this.expandedFlags.patientGeneralHistory = true;
    }
    removePatientGeneralHistory(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.patientGeneralHistory, (v) => { return v === element; });
    }

    addPatientFamilyHistory(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.patientFamilyHistory.push(new PatientFamilyHistory());
        this.expandedFlags.patientFamilyHistory = true;
    }
    removePatientFamilyHistory(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.patientFamilyHistory, (v) => { return v === element; });
    }

    addPatientSocialHistory(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.patientSocialHistory.push(new PatientSocialHistory());
        this.expandedFlags.patientSocialHistory = true;
    }
    removePatientSocialHistory(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.patientSocialHistory, (v) => { return v === element; });
    }

    addMedicationsOcular(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.medicationsOcular.push(new Medications());
        this.expandedFlags.medicationsOcular = true;
    }
    removeMedicationsOcular(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.medicationsOcular, (v) => { return v === element; });
    }
    addMedicationsSystemic(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.medicationsSystemic.push(new Medications());
        this.expandedFlags.medicationsSystemic = true;
    }
    removeMedicationsSystemic(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.medicationsSystemic, (v) => { return v === element; });
    }

    addPatientAllergy(e) {
        e.stopPropagation(); e.preventDefault();
        this.referralForm.patientAllergy.push(new Allergies());
        this.expandedFlags.patientAllergy = true;
    }
    removePatientAllergy(e, element) {
        e.stopPropagation(); e.preventDefault();
        _.remove(this.referralForm.patientAllergy, (v) => { return v === element; });
    }

    //

    isReadOnly() {
        return !(this.referralForm.status === 'new' || this.referralForm.status === 'edit');
    }

    saveElement() {
        // noinspection all
        // let formToSave = _.cloneDeep(this.referralForm);
        // // FIXME: !!!!!
        // formToSave.status = "save";
        this.referralForm.status = "save";

        // TODO: PUBLISH at some point !!!
        // this._customFormsService.saveForm(formToSave).then((v) => {
        //     // alert(v);
        // });
        this.refreshVitalsGraph.emit(1);
        console.log(this.referralForm);
        this._customFormsService.saveForm(this.referralForm).then((v:any) => {
            this.referralForm._id = v._id;
            if (this.parent) {
                // this.parent.updateElements();
            }
        });
    }

    editElement() {
        this.referralForm.status = "edit";
        if (this.parent) {
            // this.parent.updateElements();
        }
    }

    cancelElement() {
        if (this.parent) {
            this.parent.cancelElement(this.referralForm);
        }
    }

    removeElement() {
        if (this.parent) {
            this.parent.removeElement(this.referralForm);
        }
    }

    createElementDOR() {
        // noinspection all
        let formToSave = _.cloneDeep(this.referralForm);
        delete formToSave._id;
        formToSave.status = "closed_dor";
        formToSave.workflow = "disabled";

        this._customFormsService.saveForm(formToSave).then((v) => {
            if (this.parent) {
                this.parent.addElement(formToSave);
                // this.parent.updateElements();
            }
            this.referralForm.when = moment().toDate();
            this.saveElement();
        });
    }

    taskTextV: string;
    createTask() {
        let $modal = $(".modal-background");
        let $task = $(".new-task-form");
        let $body = $('body');

        $modal.css('display', 'block');
        $body.css('overflow', 'hidden');
        $task.css('display', 'block');
    }
    cancelTask() {
        let $modal = $(".modal-background");
        let $task = $(".new-task-form");
        let $body = $('body');

        $modal.css('display', 'none');
        $body.css('overflow', '');
        $task.css('display', 'none');
    }
    createNewTask() {
        let form = new ReferralForm();
        form.formName = 'task';
        form.status = 'new';
        form.taskUser = "58b9777c05825b14978d980f";
        form.taskText = this.taskTextV;
        if (this.patient) {
            form.patientId = this.patient.hospitalNumber;
            form.patientDetails.familyName = this.patient.familyName;
            form.patientDetails.firstName = this.patient.firstName;
            form.patientDetails.title = this.patient.title;
            form.patientDetails.patientID = this.patient.hospitalNumber;
            form.patientDetails.nhsNumber = this.patient.nhsNumber;
            form.patientDetails.dob = moment(this.patient.dob).toDate();
            form.patientDetails.sex = this.patient.gender;
            form.patientDetails.verificationStatus = this.patient.nhsNumberVerifiedStatus;
            form.patientDetails.homeTelephone = this.patient.homePhone;
            form.patientDetails.workTelephone = this.patient.mobilePhone;
        }

        let that = this;
        this._customFormsService.saveForm(form).then((v:any) => {
            // // this.referralForm._id = v._id;
            // // if (this.parent) {
            // //     this.parent.updateElements();
            // // }
            // _.remove(that.referralForms, (fe) => {
            //     return f === fe;
            // });
            that.cancelTask();
        });
    }

    createElementDORAndClose() {
        // FIXME: !!!!!
        this.referralForm.status = "closed";
        this.referralForm.workflow = "disabled";

        // TODO: PUBLISH at some point !!!
        this._customFormsService.saveForm(this.referralForm).then((v) => {
            if (this.parent) {
                // this.parent.updateElements();
            }
        });
    }

    //

    ignoreDiagnose() {
        this.referralForm.diagnose.isIgnored = true;
        this.saveElement();
    }
    confirmDiagnose() {
        this.referralForm.diagnose.isConfirmed = true;
        this.referralForm.diagnose.diagnosis = this.referralForm.diagnose.watsonDiagnosis;
        this.saveElement();
    }

    ignoreCP() {
        this.referralForm.carePathway.isIgnored = true;
        this.saveElement();
    }
    confirmCP() {
        this.referralForm.carePathway.isConfirmed = true;
        this.referralForm.carePathway.carePathway = this.referralForm.carePathway.watsonRecommendedTreatment;
        this.saveElement();
    }
}

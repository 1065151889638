import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ui-search',
  templateUrl: './search.element.html',
  styleUrls: ['./search.element.scss']
})
export class UiSearch {

  private searchText: string = '';
  public inputValue: string = '';

  @Output() search = new EventEmitter();

  @Input() keyup: boolean = true;

  @Input('base-input-value')
  set baseInputValue(baseInputValue: string) {
    if (typeof(baseInputValue) == "string") {
      if (baseInputValue && baseInputValue.length > 0) {
        this.inputValue = baseInputValue;
      }
    }
  }

  newSearch(e?) {
    if (e) {
      if(this.keyup)
        this.search.emit(this.inputValue);
      else if(e.keyCode == 13) {
        this.search.emit(this.inputValue);
      }
    } else
      this.search.emit(this.inputValue);
  }

}


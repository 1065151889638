import * as moment from "moment";

export class Medications {
    drug: string;
    dose: string;
    route: string;
    frequency: string;
    sinceWhen: Date;
    validTru: Date;
    when: Date;
    isRevers: boolean;
    public constructor() {
        this.drug = "";
        this.dose = "";
        this.route = "";
        this.frequency = "";
        this.sinceWhen = moment().subtract(1, 'd').toDate();
        this.validTru = moment().add(7, 'd').toDate();
        this.when = null;
        this.isRevers = false;
    }
}

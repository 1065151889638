import { Component } from '@angular/core';
import { UiButton } from '../button/button.element';
import { UserThumbnailComponent } from '../../components/user-thumbnail/user-thumbnail.component';
import { UsersService } from "../../services/user.service";
import { UserData } from "../../models/user.model";
import { AuthService } from "../../services/auth.service";
import { RootShareService } from "../../services/root.shared.service";

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.element.html'
})
export class TopBarElement {

  constructor(public userService: UsersService, public authService: AuthService,
              public rootShared: RootShareService){
  }

}

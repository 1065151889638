import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {PatientAllergy} from "../../../../models/forms/referral/patient.allergy";
import {PatientFamilyHistory} from "../../../../models/forms/referral/patient.family.history";
import {Allergies} from "../../../../models/forms/referral/allergies";

@Component({
    selector: 'patient-allergy-component',
    templateUrl: './patient.allergy.html'
})
export class PatientAllergyComponent {
    @Input() patientAllergy: Allergies;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    allergyTypeItems = PatientAllergy.allergyTypeChoices;
    riskLevelItems = PatientAllergy.riskLevelChoices;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

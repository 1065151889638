import moment = require("moment");
import {Moment} from "moment";

export class NoteForm {
    _id: any;
    status: string;
    workflow: string = 'open';

    version: number;
    when: Date = moment().toDate();
    expanded: boolean;

    formName: string = 'note';
    userId: string = null;
    patientId: string = null;

    users: Array<string>;

    note: string;

    public constructor(){
        this.status = 'new';
        this.version = 1;
        this.expanded = false;

        this.users = [];

        this.note = "";
    }
}

import {BaseClass} from "./BaseClass";
import {ApiService} from "../../services/api.service";
import {dictionaryFilter, DictRequestOptions} from "../../models/dict.utils.model";
import * as _ from "lodash";
import {MockService} from "../../services/mock.service";
import {DictionaryService} from "../../services/dict.service";

export interface DictionaryInterface {
    getDisplayName(): string;

    getDictId(): string;

    getDictCode(): string;
}

export class DictionaryItem implements DictionaryInterface {
    /**
     * This is item name - will and should be used for display.
     * Simply whatever this dictionary uses as an display item.
     */
    name: string;
    /**
     * Item unique identifier.
     */
    id: any;
    /**
     * code for showing user usable identifier
     */
    code: string;
    /**
     * Any data we want to include inside this item, in most cases
     * this will be used to store full data record.
     * In case of services with data we are using for filtering like names for doctors
     * data returned from API should use this construct as well
     */
    data: any;

    check: boolean = false;

    /**
     * Create item with initialization and data object attached
     * @param name Name
     * @param id Unique id
     * @param data Data object
     * @returns {DictionaryItem} Item created
     */
    static createWithData(name: string, id: any, data: any): DictionaryItem {
        let item = new DictionaryItem();
        item.name = name;
        item.id = id;
        if (data)
            item.data = data;
        if (item.data.code) {
            item.code = item.data.code;
        }
        return item;
    }

    /**
     * Create item only with name and unique id
     * @param name Name
     * @param id Unique id
     * @returns {DictionaryItem} Item created
     */
    static create(name: string, id: any): DictionaryItem {
        let item = new DictionaryItem();
        item.name = name;
        item.id = id;
        return item;
    }

    /**
     * Create item only with name and unique id
     * @param name Name
     * @param id Unique id
     * @returns {DictionaryItem} Item created
     */
    static createFromObject(object: DictionaryInterface): DictionaryItem {
        let item = new DictionaryItem();
        item.name = object.getDisplayName();
        item.id = object.getDictId();
        item.code = object.getDictCode();
        item.data = object;
        return item;
    }

    getDisplayName(): string {
        return this.name;
    };

    getDictId(): string {
        return this.id;
    }

    getDictCode(): string {
        if (this.code) {
            return this.code;
        } else {
            return this.id;
        }
    }
}

/**
 * Common class for Dictionaries.
 * Should be widely used across all elements that uses Dictionary or Dictionary like elements.
 */
export class Dictionary extends BaseClass {
    /**
     * Dictionary items collection.
     * This is private all necessary accessors are provided
     */
    private _items: Array<DictionaryItem> = [];
    public _totalItemsCount: number = 0;
    public _pageSize: number = 100;
    private _pageCount: number;
    public _lastGetPage: number = 1;
    public _lastSort: string;
    private _fetchedName: string;
    private _filtered: boolean;

    constructor(private dictionaryService: DictionaryService, apiService: ApiService, private _mockService?: MockService) {
        super(apiService);
    }

    /**
     * Convert this dictionary to json. May be used to duplicate dictionary or to update one
     * This method is also automatically used by JSON.stringify - now you can simply do:
     * JSON.stringify(dictionary) -> json
     * @returns {object} Json ready Dictionary (could be easily now encoded by JSON.stringify)
     */
    toJSON() {
        return {_items: this._items};
    }

    /**
     * This method retrieve all items from dictionary
     * @returns {Array<DictionaryItem>} Array with all items
     */
    items() {
        return this._items;
    }

    removeItems(){
        this._items = [];
    }
    /**
     * Optional dictionary name
     */
    name: string;

    /**
     * Add item to dictionary
     * @param item Item to add
     * @returns {Dictionary} This dictionary for allowing of chaining
     */
    add(item: DictionaryItem): Dictionary {
        this._items.push(item);
        return this;
    }

    setItems(items: Array<DictionaryItem>) {
        this._items = items;
    }
    /**
     * Returns dictionary size
     * @returns {number} dictionary size
     */
    actualSize(): number {
        return this._items.length;
    }

    /**
     * Returns dictionary size
     * @returns {number} dictionary size
     */
    size(): number {
        return this._totalItemsCount;
    }

    /**
     * Get element at position
     * @param position Position where we should look for item. If position is greater than size you will get error.
     * @returns {DictionaryItem} Founded item or null in case nothing was found
     */
    get (position: number): DictionaryItem {
        if (position < this.size())
            return this._items[position];
        return null;
    }

    get haveMorePages() {
        return this._lastGetPage < this._pageCount;
    }

    get haveAllItems() {
        return !this.haveMorePages && !this._filtered;
    }

    /**
     * This method fetches dictionary from URL
     * @param name Dictionary name
     */
    fetch(name: string, page?: number, sort?: string): Promise<boolean> {
        this._items = [];
        this._fetchedName = name;
        if (name.startsWith("mock_")) {
            return this.fetchMock(name.replace("mock_", ""));
        } else {
            return this.fetchDict(name, this.getOptions(page, sort));
        }
    }

    fetchMock(name: string): Promise<boolean> {
        //this._items = [];
        return new Promise((resolve) => {
            this._fetchedName = name;
            this.dictionaryService.fetchMockDictionaryByName(name).then(
                res => {
                    this._items.splice(0, this._items.length);
                    _.forEach(res, (dictItem) => {
                        this._items.push(DictionaryItem.createFromObject(dictItem));
                    });
                    this._totalItemsCount = this._items.length;
                    this._pageSize = this._items.length;
                    this._pageCount = 1;
                    this._lastGetPage = 1;

                    this._filtered = false;
                    resolve(true);
                },
                err => {
                    // alert('err');
                    // FIXME: ...
                    resolve(false);
                }
            );
        });
    }

    findClinicsByConsultant(filter: dictionaryFilter): Promise<boolean> {
        //this._items = [];
        return new Promise((resolve) => {
            // this._fetchedName = name;
            this.dictionaryService.findClinicsByConsultant(filter).then(
                res => {
                    console.log(res);
                    this._items.splice(0, this._items.length);
                    this._totalItemsCount = res.itemsCount;
                    this._pageSize = res.pageSize;
                    this._pageCount = res.pageCount;
                    this._lastGetPage = res.page;
                    _.forEach(res.data, (dictItem) => {
                        dictItem['baseDictName'] = name;
                        this._items.push(DictionaryItem.createFromObject(dictItem));
                    });
                    //this._filtered = searchFilter && searchFilter.length > 0;
                    resolve(true);
                },
                err => {
                    // alert('err');
                    // FIXME: ...
                    resolve(false);
                }
            );
        });
    }

    findConsultantsByClinic(filter: dictionaryFilter): Promise<boolean> {
        //this._items = [];
        return new Promise((resolve) => {
            //this._fetchedName = name;
            this.dictionaryService.findConsultantsByClinic(filter).then(
                res => {
                    console.log(res);
                    this._items.splice(0, this._items.length);
                    this._totalItemsCount = res.itemsCount;
                    this._pageSize = res.pageSize;
                    this._pageCount = res.pageCount;
                    this._lastGetPage = res.page;
                    _.forEach(res.data, (dictItem) => {
                        dictItem['baseDictName'] = name;
                        this._items.push(DictionaryItem.createFromObject(dictItem));
                    });
                    //this._filtered = searchFilter && searchFilter.length > 0;
                    resolve(true);
                },
                err => {
                    // alert('err');
                    // FIXME: ...
                    resolve(false);
                }
            );
        });
    }

    nextPage(): Promise<boolean> {
        if (this._lastGetPage < this._pageCount) {
            this._lastGetPage++;
            return this.fetchDict(this._fetchedName, this.getOptions());
        } else {
            return new Promise((resolve) => {
                resolve(true);
            })
        }

    }

    addPage(search: string): Promise<boolean> {
        if (this._lastGetPage < this._pageCount) {
            this._lastGetPage++;
            return this.addfetchDict(this._fetchedName, this.getOptions(), search);
        } else {
            return new Promise((resolve) => {
                resolve(true);
            })
        }

    }

    filter(search: string, page?: number, sort?: string): Promise<boolean> {
        search = search.replace('\'', '\\\'')
        this._items = [];
        this._lastGetPage = 1;
        return this.fetchDict(this._fetchedName, this.getOptions(page, sort), search);
    }

    private fetchDict(name: string, options: DictRequestOptions, searchFilter?: string): Promise<boolean> {
        return new Promise((resolve) => {
            this.dictionaryService.fetchPageDictionary(name, options, searchFilter).then(
                res => {
                    this._items.splice(0, this._items.length);
                    this._totalItemsCount = res.itemsCount;
                    this._pageSize = res.pageSize;
                    this._pageCount = res.pageCount;
                    this._lastGetPage = res.page;
                    _.forEach(res.data, (dictItem) => {
                        dictItem['baseDictName'] = name;
                        this._items.push(DictionaryItem.createFromObject(dictItem));
                    });
                    this._filtered = searchFilter && searchFilter.length > 0;
                    resolve(true);
                },
                err => {
                    resolve(false);
                    // alert('err');
                    // FIXME: ...
                }
            );
        })
    }

    private addfetchDict(name: string, options: DictRequestOptions, searchFilter?: string): Promise<boolean> {
        return new Promise((resolve) => {
            this.dictionaryService.fetchPageDictionary(name, options, searchFilter).then(
                res => {
                    //this._items.splice(0, this._items.length);
                    this._totalItemsCount = res.itemsCount;
                    this._pageSize = res.pageSize;
                    this._pageCount = res.pageCount;
                    this._lastGetPage = res.page;
                    _.forEach(res.data, (dictItem) => {
                        dictItem['baseDictName'] = name;
                        this._items.push(DictionaryItem.createFromObject(dictItem));
                    });
                    this._filtered = searchFilter && searchFilter.length > 0;
                    resolve(true);
                },
                err => {
                    resolve(false);
                    // alert('err');
                    // FIXME: ...
                }
            );
        })
    }

    private getOptions(page?: number, sort?: string): DictRequestOptions {
        let options = new DictRequestOptions();
        options.$pageSize = 100;
        options.$page = page ? page : this._lastGetPage;
        options.$sort = sort ? sort : this._lastSort;
        if(sort && sort != this._lastSort) {
            this._lastSort = sort;
        }
        return options;
    }

    public static getFilterString(): string {
        return "description regex '###.*' OR code regex '###.*' ";
    }
}




/**
 * Created by mstolarczyk on 2016-11-29.
 */

export enum LoggerLevel {
    ERROR = 0,
    WARNING = 1,
    INFO = 2,
    DEBUG = 3,
    LOG = 4,
    ALL = 100
}

export interface LoggerInterface {
    loggerName: string;
    loggerVersion?: string;

    log(message:string, errorNumber?: number):void;
    error(message: string, errorNumber?: number): void;
    debug(message: string, errorNumber?: number): void;
    info(message: string, errorNumber?: number): void;
    warning(message: string, errorNumber?: number): void;
    //audit ?
}
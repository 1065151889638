import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User, UsersService} from "../../services/user.service";
import {UserData} from "../../models/user.model";
import {RootShareService} from "../../services/root.shared.service";

@Component({
  selector: 'conversation-details',
  templateUrl: './user-chat.component.html'
})
export class ConversationDetailsComponent {

  private user: UserData;
  private _chatDetails: any;
  static openChatDetails: any;

  @Input() set chatDetails (chatDetails: any) {
    this._chatDetails = chatDetails;
    if (chatDetails.userId == '5acb514214969132a0fad927') {
      this.user = this.usersService.getCurrentUser();
    } else {
      this.user = this.usersService.getUser(chatDetails.userId);
    }
  }

  @Output() openChatEmitter = new EventEmitter();

  constructor(private usersService: UsersService, public rootShared: RootShareService) {}

  getPhoto(authorID) {
    if (authorID == '5acb514214969132a0fad927')
      return this.usersService.getCurrentUser().photo;

    let user = this.usersService.getUser(authorID);
    return user.photo? '../../assets/img/'+user.photo:'../../assets/img/no-photo.jpg'
  }

  chatIsOpen (): boolean {
    if(ConversationDetailsComponent.openChatDetails == this._chatDetails)
      return true;
    else
      return false;
  }

  openChat() {
    ConversationDetailsComponent.openChatDetails = this._chatDetails;

    //clear unread messages and pass to rootSharedService
/*
    this.rootShared.unreadNotifications.messages -= this._chatDetails.newMessage;
    this._chatDetails.newMessage = 0;
*/

    this.openChatEmitter.emit(this._chatDetails);
  }
}

import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../../services/auth.service";
import {RootShareService} from "../../../../../services/root.shared.service";
import {ExternalExam} from "../../../../../models/forms/referral/external.exam";

@Component({
    selector: 'external-exam-component',
    templateUrl: './external.exam.html'
})
export class ExternalExamComponent {
    @Input() externalExam: ExternalExam;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

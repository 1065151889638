import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import  { UsersService} from "../../services/user.service";
import {MessageService} from "../../services/messages.service";
import * as d3 from "d3";
import * as $ from "jquery"
import * as _ from "lodash"
import {PatientService} from "../../services/patient.service";
import * as moment from "moment";

class Point {
    xValue: any;
    yValue: number;
    yValueDisplay: string;

    public tooltip: any;
    public name: string;
    public range: any;

    public unit;
    public type: Eye;

    public graphType: GraphType;
    constructor (date,y,unit, name, range,typeEye, graphType, display) {
        this.unit = unit;
        this.xValue = date;
        this.yValue = y;
        this.yValueDisplay = display;
        this.name = name;
        this.range = range;
        this.type = typeEye;
        this.graphType = graphType;
    }

    public tooltipTop: number;
    public tooltipLeft: number;
    public tooltipBottom: number;
    public tooltipRight: number;

}


export class Graph {
    type: GraphType;
    name: string;
    code: string;
    isVisibleOS: boolean;
    isVisibleOD: boolean;
    maxValue: number;
    minValue: number;

    ODcolor: string;
    OScolor: string;

    constructor(type, name,code, min, max,OScolor, ODcolor){
        this.type = type;
        this.name = name;
        this.code = code;
        this.isVisibleOS = false;
        this.isVisibleOD = false;
        this.maxValue = max;
        this.minValue = min;
        this.OScolor = OScolor;
        this.ODcolor = ODcolor;
    }

    visibleAll() {
        this.isVisibleOS = true;
        this.isVisibleOD = true;
    }

    invisibleAll() {
        this.isVisibleOS = false;
        this.isVisibleOD = false;
    }
}

export enum GraphType {
    visualAcuity,
    IntraocularPressure,
    VisualField,
    GHT,
    CupToDiscRatio
}

export enum Eye {
    OS,
    OD
}

@Component({
    selector: 'vitals-graph',
    templateUrl: './vitals-graph.component.html'
})
export class VitalsGraphComponent implements AfterViewInit, OnChanges {
    @Input() title: string = "Prescriptions";
    @Input() firstIcon: string = "";
    @Input() secondIcon: string = "";
    @Input() refresh: boolean;


    @Output() firstIconAction = new EventEmitter();
    @Output() secondIconAction = new EventEmitter();


    selectGraph: Graph;
    graph = GraphType;

    svgContainer: any;
    minValueX = new Date('10-01-2015');
    maxValueX = new Date('10-01-2018');
    widthofChart;
    chart: any;
    margin = 30;

    heightOfChart;

    tooltipName: any;
    tooltipValue: any;
    tooltipUnit: any;
    tooltipDate: any;
    tooltipRange: any;

    monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    height = 272;

    graphToSelect: Array<Graph> = [];
    graphToDraw: Array<Graph> = [];

    marginTOP = 5;
    marginRight = 50;

    changeOS(graph: Graph) {
        graph.isVisibleOS = !graph.isVisibleOS;
        this.redrawCharts();
    }

    addToBigGraphOS(graph: Graph) {
        graph.isVisibleOS = !graph.isVisibleOS;
        let index = _.findIndex(this.graphToDraw, graph);
        if (index < 0 && graph.isVisibleOS)
            this.graphToDraw.push(graph);
        else if(index > 0 && !graph.isVisibleOS && !graph.isVisibleOD)
            this.graphToDraw.splice(index,1);
        if (graph.type == GraphType.visualAcuity || graph.type == GraphType.IntraocularPressure) {
            this.redrawCharts();
        } else {
            let testName = graph.code;
            testName += 'OS';
            this.patientService.fetchSingleInvestigationTests(this.patientService.currentPatientId,testName)
                .then( (value) => {
                    if (value) {
                        this.dataForNewGraph = value;
                        this.redrawCharts();
                    }
                });
        }
    }

    dataForNewGraph;
    addToBigGraphOD(graph: Graph) {
        graph.isVisibleOD = !graph.isVisibleOD;
        let index = _.findIndex(this.graphToDraw, graph);
        if (index < 0 && graph.isVisibleOD)
            this.graphToDraw.push(graph);
        else if (index > 0 && !graph.isVisibleOD && !graph.isVisibleOS)
            this.graphToDraw.splice(index, 1);

        if (graph.type == GraphType.visualAcuity || graph.type == GraphType.IntraocularPressure) {
            this.redrawCharts();
        } else {
            let testName = graph.code;
            testName += 'OD';
            this.patientService.fetchSingleInvestigationTests(this.patientService.currentPatientId,testName)
                .then( (value) => {
                    if (value) {
                        this.dataForNewGraph = value;
                        this.redrawCharts();
                    }
                });
        }
    }

    changeOD(graph: Graph) {
        graph.isVisibleOD = !graph.isVisibleOD;
        this.redrawCharts();
    }

    constructor(private patientService: PatientService) {

        this.heightOfChart = this.height - this.margin;

        this.graphToSelect.push(new Graph(GraphType.visualAcuity, 'Visual Acuity','VA',-0.5,1,'#c631e4','#50e3c2'));
        this.graphToSelect.push(new Graph(GraphType.IntraocularPressure, 'Intraocular Pressure','IP',0,90,'#fac364','#b6d957'));
        // this.graphToSelect.push(new Graph(GraphType.GHT, 'GHT', 'GHT',0,70,'#93b9c6','#ccc5a8'));
        this.graphToSelect.push(new Graph(GraphType.CupToDiscRatio,'CDR', 'CTDR',0,2,'#6f6b16','#009915'));

        this.selectGraph = this.graphToSelect[0];
        this.selectGraph.visibleAll();
        this.graphToDraw.push(this.selectGraph);
    }

    ngAfterViewInit(){
        if (this.patientService.vitalsAreReady) {
            let countAxis = this.graphToDraw.length == 1? 0 : this.graphToDraw.length-1 ;
            this.widthofChart = $("#chart").innerWidth() - ((countAxis) * 35) ;
            this.selectDateRange();
            this.graphBaseDraw();

            _.forEach(this.graphToDraw, (graph, index)=> {
                this.drawCharts(graph,index);
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['refresh']) {
            if (changes['refresh'].currentValue) {
                this.redrawCharts();
            }
        }
    }

    addDays(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    selectDateRange() {
        this.minValueX = new Date(this.patientService.VAOS[this.patientService.VAOS.length-1].observationDate);
        this.maxValueX = new Date(this.patientService.VAOS[0].observationDate);

        if (this.patientService.VAOS.length > 1) {
            let timeDiff = Math.abs(this.maxValueX.getTime() - this.minValueX.getTime());
            let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

            let d = 8*diffDays / this.widthofChart;
            this.minValueX = this.addDays(this.minValueX,-d);
            this.maxValueX = this.addDays(this.maxValueX,d);
        } else {
            this.minValueX = this.addDays(this.minValueX,-1);
            this.maxValueX = this.addDays(this.maxValueX,1);
        }

    }

    getClasses(icon) {
        let classes: Array<string> = [];

        classes.push("unity-icon");
        classes.push("timeline-icon");

        if (icon == 'first')
            classes.push(this.firstIcon);
        else if (icon == 'second')
            classes.push(this.secondIcon);

        return classes;
    }

    firstIconClick(e) {
        this.firstIconAction.emit(e);
    }

    secondIconClick(e){
        this.resizeModalElement();
       // this.secondIconAction.emit(e);
    }

    redrawCharts() {
        if (this.patientService.vitalsAreReady) {

            let countAxis = this.graphToDraw.length == 1? 0: this.graphToDraw.length - 1 ;
            this.widthofChart = $("#chart").innerWidth() - (countAxis * 20);

            d3.select(".toChange").remove();

            this.graphBaseDraw();
            _.forEach(this.graphToDraw, (graph, index)=> {
                this.drawCharts(graph,index);
            });
        }
    }


    graphBaseDraw() {
        this.svgContainer = d3.select("svg")
            .attr("width", $("#chart").innerWidth())
            .attr("height", this.height);

        this.svgContainer = this.svgContainer.append('svg')
            .attr('class','toChange')
            .attr("width", $("#chart").innerWidth())
            .attr("height", this.height)
            .style("fill", "red");
    }

    goToTime(date) {
        if (date) {
            let n = moment(date).format("YYYYMMDD");
            let f = $("div[data-when='" + n + "']");
            if (f.length > 0) {
                let where = f.first();
                let o = where.offset();
                let pScroll = $('.patient-timeline-scroll.scroll-right');
                let st = pScroll.scrollTop();
                let nu = o.top + st - 195;
                nu = nu < 0 ? 0 : nu;
                pScroll.animate({ scrollTop: (nu) }, 500);
            }
        }
    }

    formatDate(s) {
        return s ? moment(s).format('DD/MM/YYYY') : "";
    }

    selectedMed: any = null;
    selectedProc: any = null;
    drawCharts(graph: Graph, i) {
        let that = this;

        let xScale = d3.scaleTime()
            .domain([this.minValueX, this.maxValueX])
            .range([0, this.widthofChart - this.marginRight]);

        let xValue = function (d) {
            return d.xValue;
        };
        let xMap = function (d) {
            return xScale(xValue(d));
        };
        let xMapDate = function (d) {
            return xScale(new Date(d));
        };

        let rangeY = graph.maxValue - graph.minValue;
        // let scaleY = this.heightOfChart / rangeY;
        let scaleY = (this.heightOfChart-7) / rangeY;

        let yScale = d3.scaleLinear()
            .domain([graph.minValue, graph.maxValue])
            .range([(graph.maxValue - graph.minValue) * scaleY, 0]);


        let yValue = function (d) {
            return d.yValue;
        };
        let yMap =function (d) {
            return yScale(yValue(d));
        };

        let yMapDate = function (d) {
            return yScale(d);
        };

        let xAxis = d3.axisTop(xScale)
            .tickSizeInner(5)
            .tickSizeOuter(0);

        let yAxis = d3.axisLeft(yScale)
            .tickSizeInner(5)
            .tickSizeOuter(0);

        let yAxisRight = d3.axisRight(yScale)
            .tickSizeInner(5)
            .tickSizeOuter(0);


        let xAxisBottom = d3.axisBottom(xScale)
            .tickSizeInner(5)
            .tickSizeOuter(0)
            .ticks(7)
            .tickFormat(function(d){
                let x = new Date(d);
                return that.monthNames[x.getMonth()];
            });

        let xAxisBottom2 = d3.axisBottom(xScale)
            .tickSizeInner(5)
            .tickSizeOuter(0)
            .ticks(7)
            .tickFormat(d3.timeFormat("%Y"));

        let name = 'vital'+i;
        let color = graph.OScolor;

        let style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = '.axis'+name+' .domain { stroke: '+color+' !important}'+ '.axis'+name+' text { fill: '+color+' !important}';
        document.getElementsByTagName('head')[0].appendChild(style);

        if (i == 0) {
            // MEDICATIONS !!!!!!
            // let medicationsGroup = this.svgContainer.append('g')
            //     .attr('transform', 'translate(' + this.margin + ',' + (scaleY * (graph.maxValue - graph.minValue) + this.marginTOP ) + ')')
            //     .classed('x axis', true);
            // medicationsGroup.append("rect")
            //     .style('fill', 'rgba(255,0,0,0.5)')
            //     .attr("width", 100)
            //     .attr("height", 35);

            let colors1 = [
                'rgba(255, 179, 186, 0.4)',
                'rgba(255, 223, 186, 0.4)',
                'rgba(255, 255, 186, 0.4)',
                'rgba(186, 255, 201, 0.4)',
                'rgba(186, 225, 255, 0.4)'
            ];
            let colors2 = [
                'rgba(224, 187, 228, 0.2)',
                'rgba(149, 125, 173, 0.2)',
                'rgba(210, 145, 188, 0.2)',
                'rgba(254, 200, 216, 0.2)',
                'rgba(255, 223, 211, 0.2)'
            ];

            // FIXME: I know it should be .data !!! :)
            let that = this;
            let meds = this.patientService.MEDICATIONS;
            let idx = 0;
            _.forEach(meds, (m) => {
                let start = moment(m.sinceWhen);
                let finish = moment(m.validTru);

                let v1 = xScale(start.toDate());
                let v2 = xScale(finish.toDate());

                let xS = this.margin + v1;
                let medicationsGroup = this.svgContainer.append('g')
                    .attr('transform', 'translate(' + xS + ',' + (scaleY * (graph.maxValue - graph.minValue) + this.marginTOP ) + ')')
                    .classed('x axis', true);
                medicationsGroup.append("rect")
                    .style('fill', colors1[idx])
                    .style("stroke", "#444")
                    .style("cursor", "pointer")
                    .style("stroke-opacity", ".2")
                    .attr("width", v2 - v1)
                    .attr("height", 30)
                    .attr("rx", 10)
                    .attr("ry", 10)
                    .on("mouseout", function (point) {
                        that.selectedMed = null;
                        let ttp = $(".tooltip-for-medication");
                        ttp.css({ display: "none" });
                    })
                    .on("mouseover", function (point, a, b, c, d) {
                        let mp = d3.mouse(this);
                        // console.log( mp );
                        // console.log( d3.event.clientX, d3.event.clientY );

                        let ttp = $(".tooltip-for-medication");
                        ttp.css({ left: d3.event.clientX + 5, top: d3.event.clientY + 15, display: "block" });
                        that.selectedMed = m;
                    })
                    .on("click", function() {
                        that.goToTime(m.when);
                    });
                ;

                idx++;
                if (colors1.length === idx)
                    idx=0;
            });

            // PROCEDURES !!!!!!
            let procs = this.patientService.PROCEDURES;
            idx = 0;
            _.forEach(procs, (p) => {
                console.log(p);

                let start = moment(p.when);
                let v1 = xScale(start.toDate());
                let xS = this.margin + v1;
                let proceduresGroup = this.svgContainer.append('g')
                    .attr('transform', 'translate(' + xS + ',' + this.marginTOP + ')')
                    .classed('x axis', true);
                proceduresGroup.append("rect")
                    .style('fill', colors2[idx])
                    .style("stroke", "#444")
                    .style("cursor", "pointer")
                    .style("stroke-opacity", ".2")
                    .attr("width", 10)
                    .attr("rx", 2)
                    .attr("ry", 2)
                    .attr("height", scaleY * (graph.maxValue - graph.minValue))
                    .on("mouseout", function (point) {
                        that.selectedProc = null;
                        let ttp = $(".tooltip-for-procedure");
                        ttp.css({ display: "none" });
                    })
                    .on("mouseover", function (point, a, b, c, d) {
                        let mp = d3.mouse(this);
                        // console.log( mp );
                        // console.log( d3.event.clientX, d3.event.clientY );

                        let ttp = $(".tooltip-for-procedure");
                        ttp.css({ left: d3.event.clientX + 5, top: d3.event.clientY + 15, display: "block" });
                        that.selectedProc = p;
                    })
                    .on("click", function() {
                        that.goToTime(p.when);
                    });
                ;

                /*
                let start = moment(m.sinceWhen);
                let finish = moment(m.validTru);

                let v1 = xScale(start.toDate());
                let v2 = xScale(finish.toDate());

                let xS = this.margin + v1;
                let medicationsGroup = this.svgContainer.append('g')
                    .attr('transform', 'translate(' + xS + ',' + this.marginTOP + ')')
                    .classed('x axis', true);
                medicationsGroup.append("rect")
                    .style('fill', colors1[idx])
                    .attr("width", v2 - v1)
                    .attr("height", scaleY * (graph.maxValue - graph.minValue))
                    .on("mouseout", function (point) {
                        that.selectedMed = null;
                        let ttp = $(".tooltip-for-medication");
                        ttp.css({ display: "none" });
                    })
                    .on("mouseover", function (point, a, b, c, d) {
                        let mp = d3.mouse(this);
                        // console.log( mp );
                        // console.log( d3.event.clientX, d3.event.clientY );

                        let ttp = $(".tooltip-for-medication");
                        ttp.css({ left: d3.event.clientX + 5, top: d3.event.clientY + 15, display: "block" });
                        that.selectedMed = m;
                    })
                ;
                */

                idx++;
                if (colors1.length === idx)
                    idx=0;
            });

            //
            this.chart = this.svgContainer.append('g')
                .attr('transform', 'translate(' + this.margin + ',' + this.marginTOP + ')')
                .classed('y axis' + name, true)
                .call(yAxis);

            this.svgContainer.append('g')
                .attr('transform', 'translate(' + this.margin + ',' + (scaleY * (graph.maxValue - graph.minValue) + this.marginTOP ) + ')')
                .classed('x axis', true)
                .call(xAxisBottom);
            this.svgContainer.append('g')
                .attr('transform', 'translate(' + this.margin + ',' + (scaleY * (graph.maxValue - graph.minValue) + this.marginTOP + 10) + ')')
                // .attr('transform', 'translate(' + this.margin + ',' + (scaleY * (graph.maxValue - graph.minValue) + this.marginTOP + 20) + ')')
                .classed('x axis', true)
                .call(xAxisBottom2);

            this.chart.selectAll(".yGrids")
                .data(yScale.ticks())
                .enter().append("svg:line")
                .attr("class", "yGrids")
                .attr("y1", function (d) {
                    return yScale(d);
                })
                .attr("x1", xScale(this.minValueX))
                .attr("y2", function (d) {
                    return yScale(d);
                })
                .attr("x2", xScale(this.maxValueX));

        } else {

            this.svgContainer.append('g')
                .attr('transform', 'translate(' + (this.widthofChart  + (i*25) - this.margin - 10) +',' + this.marginTOP + ')')
                .classed('y axis' + name, true)
                .call(yAxisRight);
        }

        if(graph.isVisibleOD) this.drawPointsFor(Eye.OD,graph);
        if(graph.isVisibleOS) this.drawPointsFor(Eye.OS,graph);
    }

    private drawPointsFor(eye: Eye,graph: Graph){

        let name = 'vital'+eye+graph.type;

        let xScale = d3.scaleTime()
            .domain([this.minValueX, this.maxValueX])
            .range([0, this.widthofChart-this.marginRight]);

        let xValue = function (d) {
            return d.xValue;
        };
        let xMap = function (d) {
            return xScale(xValue(d));
        };
        let xMapDate = function (d) {
            return xScale(new Date(d));
        };

        let rangeY = graph.maxValue - graph.minValue;
        let scaleY = this.heightOfChart / rangeY;

        let yScale = d3.scaleLinear()
            .domain([graph.minValue, graph.maxValue])
            .range([(graph.maxValue - graph.minValue) * scaleY, 0]);


        let yValue = function (d) {
            return d.yValue;
        };
        let yMapTest = function (d) {
            return yScale(yValue(d));
        };

        let that = this;

        let lineFunctionLength2 = d3.line()
            .x(function (d) {
                return xMap(d);
            })
            .y(function (d) {
                return yMapTest(d);
            })
            .curve(d3.curveLinear);

        let points = that.selectPoints(eye,graph);
        if (points) {
            this.chart.append("path")
                .attr("d", lineFunctionLength2(points))
                .attr("class","clinical-cut-off-line2")
                .attr("stroke", eye == Eye.OD? graph.ODcolor: graph.OScolor)
                .attr("stroke-width",'2px')
                .attr("clip-path", "url(#clip)")
                .attr("fill", "none");

            this.chart.selectAll(".dot"+name).remove();
            let enterSelection = this.chart.selectAll(".dot"+name).data(points).enter();

            this.chart.append("defs").append("clipPath")
                .attr("id", "clip")
                .append("rect")
                .attr("width", this.widthofChart - this.marginRight)
                .attr("height", 350);


            enterSelection.append("circle")
                .attr("class", "dot"+name)
                .attr("clip-path", "url(#clip)")
                .attr("r", 4)
                .attr("on", function (d) {

                    d.yPoint = yMapTest(d);
                    d.xPoint = xMap(d);

                    d.tooltipTop = d.yPoint - 85;
                    d.tooltipLeft = d.xPoint - 25;
                    d.tooltipBottom = d.yPoint - 25;
                    d.tooltipRight = d.xPoint + 25 ;
                })
                .on("mouseout", function (point) {

                    let tooltipBody = document.getElementById('tooltipBody');

                    let tooltipTriangle = document.getElementById('tooltipTriangle');

                    tooltipBody.style.display = 'none';
                    tooltipTriangle.style.display = 'none';

                    that.redrawCharts();
                })
                .on("mouseover", function (point) {

                    point.tooltip = that.chart.append("g")
                        .style("opacity", 0);

                    let type = point.type == Eye.OD? 'OD': 'OS';
                    switch (point.graphType) {
                        case GraphType.visualAcuity:
                            that.tooltipValue = type + ' ' + point.yValueDisplay;
                            break;
                        default:
                            that.tooltipValue = type + ' ' + point.yValueDisplay;

                    }
                    that.tooltipName = point.name;
                    that.tooltipRange = point.range;
                    that.tooltipUnit = point.unit;
                    that.tooltipDate = point.xValue;

                    let tooltipBody = document.getElementById('tooltipBody');

                    let tooltipTriangle = document.getElementById('tooltipTriangle');


                    let offsetLeftChart =  document.getElementById('chart').offsetLeft;
                    let offsetTopChart =  document.getElementById('chart').offsetTop;



                    tooltipBody.style.backgroundColor = eye == Eye.OD? graph.ODcolor: graph.OScolor;
                    tooltipBody.style.top = point.tooltipTop  + offsetTopChart + "px";
                    tooltipBody.style.left = point.tooltipLeft + offsetLeftChart + "px";
                    tooltipBody.style.display = 'block';

                    tooltipTriangle.style.top = point.tooltipTop  + offsetTopChart + 80 + "px";
                    tooltipTriangle.style.left = point.tooltipLeft + offsetLeftChart + 50 + "px";
                    tooltipTriangle.style.borderTopColor = eye == Eye.OD? graph.ODcolor: graph.OScolor;
                    tooltipTriangle.style.display = 'block';

                })
                .attr("cx", function (d) {
                    return xMap(d)
                })
                .attr("cy", function (d) {
                    return yMapTest(d)
                })
                .style("stroke", eye == Eye.OD? graph.ODcolor: graph.OScolor)
                .style('fill', 'white')
                .style('stroke-width','2px');
        }
    }

    selectPoints(eye,graph) {
        let testToDraw;
        let points: Array<Point> = [];
        switch (graph.type) {
            case GraphType.visualAcuity:
                testToDraw = eye == Eye.OD ? this.patientService.VAOD: this.patientService.VAOS;
                points = this.preparePoint(testToDraw,graph,eye);
                return points;
                // break;
            case GraphType.IntraocularPressure:
                testToDraw = eye == Eye.OD ? this.patientService.IOPOD: this.patientService.IOPOS;
                points = this.preparePoint(testToDraw,graph,eye);
                return points;
                // break;
            case GraphType.VisualField:
                testToDraw = eye == Eye.OD ? this.patientService.VFOD: this.patientService.VFOS;
                points = this.preparePoint(testToDraw,graph,eye);
                return points;
                // break;
            case GraphType.CupToDiscRatio:
                testToDraw = eye == Eye.OD ? this.patientService.CDROD: this.patientService.CDROS;
                points = this.preparePoint(testToDraw,graph,eye);
                return points;
            default:
                points = this.preparePoint(this.dataForNewGraph, graph, eye);
                return points;

        }
    }

    preparePoint(testToDraw, graph, eye) {
        let points: Array<Point> = [];

        for(let test of testToDraw) {
            if (!test.value[0].noResult)
                points.push(new Point(new Date(test.observationDate),test.value[0].value,' ',
                    graph.name, ' ',eye, graph.type, test.value[0].displayValue));
        }

        return points;
    }
    changeGraph(graph) {
        this.graphToSelect.forEach(function(graph) {
            graph.invisibleAll()
        });
        this.graphToDraw = [];
        this.graphToDraw.push(graph);

        this.selectGraph = graph;
        this.selectGraph.visibleAll();

        this.redrawCharts();
    }

    modalExpand: boolean = false;
    modalExpanded: boolean = false;

    elementPositionTop;
    elementPositionLeft;
    elementWeight;
    elementHeight;

    resizeModalElement(){
        let windowWidth = window.innerWidth;
        let windowScroll = window.scrollY;
        let modalWidth = 900;
        let modalHeight = 330;
        let topMargin = 80;

        this.modalExpand = !this.modalExpand;

        let that = this;

        let interval = setInterval(function() {
            that.redrawCharts()
        }, 10);

        if(this.modalExpand) {
            this.elementPositionTop = $('#graph-to-expand').offset().top;
            this.elementPositionLeft = $('#graph-to-expand').offset().left;
            this.elementWeight = $('#graph-to-expand').innerWidth();
            this.elementHeight = $('#graph-to-expand').innerHeight();

            d3.select("#graph-to-expand")
                .style("position", "fixed")
                .style("left", this.elementPositionLeft+'px')
                .style("top", this.elementPositionTop+'px')
                .style("width", this.elementWeight+'px')
                .style("height", this.elementHeight+'px')
                .transition()
                .duration(1000)
                .style("left", ((windowWidth-modalWidth)/2)+'px')
                .style("top", (windowScroll+topMargin)+'px')
                .style("width", modalWidth +'px')
                .style("height", modalHeight +"px")
                .on('start', function () {
                    that.modalExpanded = true;
                    document.getElementById('graph-modal-background').style.top = windowScroll+'px';
                    document.body.style.overflow = "hidden";
                    document.getElementById('chart').style.width = '100%';
                    document.getElementById('graph-to-expand').style.boxShadow = '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)';
                });
        } else {
            that.graphToDraw = [];
            that.graphToDraw.push(that.selectGraph);

            document.getElementById('graph-to-expand').style.boxShadow = 'none';

            d3.select("#graph-to-expand")
                .transition()
                .duration(1000)
                .style("left", this.elementPositionLeft +'px')
                .style("top", this.elementPositionTop+'px')
                .style("width", this.elementWeight+'px')
                .style("height", this.elementHeight+'px')
                .transition()
                .duration(2)
                .style("position", "relative")
                .style("left", '0px')
                .style("top", '0px')
                .style("width", 'inherit')
                .style("height", 'inherit')
                .on('start', function () {
                    document.getElementById('chart').style.width = '95%';
                })
                .on('end', function () {
                    that.modalExpanded = false;
                    that.redrawCharts();
                    document.body.style.overflow = "auto";
                });

            this.graphToSelect.forEach(function(graph) {
                if (graph != that.selectGraph)
                    graph.invisibleAll()
            });
        }
        setTimeout(function(){
            clearInterval(interval);
        },1000);

    }
}

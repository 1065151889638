export class OpticNerveHead {
    testName = "OpticNerveHead";

    Image_Right_URL: string;
    Image_Left_URL: string;

    Summary_Right: string;
    Summary_Left: string;

    public constructor() {
        // TODO: PUT URL HERE !?
        this.Image_Right_URL = null;
        this.Image_Left_URL = null;

        this.Summary_Right = "";
        this.Summary_Left = "";
    }
}

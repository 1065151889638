import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {PatientGeneralHistory} from "../../../../models/forms/referral/patient.general.history";
import {ReferralPresentingSymptom} from "../../../../models/forms/referral/referral.presenting.symptom";
import {HistoryOfPresentingSymptom} from "../../../../models/forms/referral/history.of.presenting.symptom";
import {CarePathway} from "../../../../models/forms/referral/care.pathway";

@Component({
    selector: 'diagnose-care-pathway-component',
    templateUrl: './diagnose.care.pathway.html'
})
export class DiagnoseCarePathwayComponent {
    @Input() carePathway: CarePathway;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

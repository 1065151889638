import { Http } from "@angular/http";
import { Injectable } from "@angular/core";

@Injectable()
export class MockService {
    constructor (public http: Http) {
    }

    filesData: any = {};
    public getData(getFunctionName:string, id:string): Promise<any> {
        let fileName = "mock/" + getFunctionName + "_" + id + ".json";
        return new Promise((resolve, reject) => {
            if (this.filesData[fileName]) {
                resolve(this.filesData[fileName]);
                return;
            }
            try {
                this.http.get(fileName)
                    .subscribe(
                        data => {
                            if(data && data["_body"]) {
                                let resObj:any = {};
                                try {
                                    resObj = JSON.parse(data['_body']);
                                    this.filesData[fileName] = resObj;
                                    resolve(resObj);
                                } catch(ex) {
                                    console.log("JSON parse error in file " + fileName);
                                    reject(ex);
                                }
                            }
                        },
                        err => {
                            console.log(err);
                            reject(err);
                        },
                        () => {
                        }
                    );
            }
            catch (e) {
                console.log('Exception:' + (<Error>e).message)
            }
        });
    }
}
export class ReferralPresentingSymptom {
    // Primary Consultant
    // Route of Referral

    primaryConsultant: string;
    routeOfReferral: string;
    public constructor(){
        this.primaryConsultant = "";
        this.routeOfReferral = "";
    }
}

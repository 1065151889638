import { Injectable } from "@angular/core";
import moment = require("moment");
import {ApiService} from "./api.service";
import {RootShareService} from "./root.shared.service";

export class SearchResult {
    //public data: Array<Patient>;
    public data: Array<any>;
    public page: number;
    public pageSize: number;
    public pageCount: number;
    public resultCount: number;
    public sortType: string;
}

class LastSearch {
    fraze: string;
    timePoint: moment.Moment;
    page: number;
    pageSize: number;
    path: string;
    options: any;
    sort: string;
    results: SearchResult;
    //should be some timeout for resolving that
}

@Injectable()
export class SearchService {
    private last : LastSearch;

    constructor(private apiService: ApiService, private rootShare: RootShareService) {}

    search(path: string, query: any, page?: number, pageSize?: number, options?: any, sort?: string): Promise<SearchResult> {
        if (!page) {
            page = 1;
        }
        if (!pageSize) {
            //pageSize = this.rootShare.environment.pageSize;
            pageSize = 10;
        }
        if (!options) {
            options = {extendedSearch: "false"}
        }
        let collectionPath = '$search';
        let data = {
            resourceUri: path,
            text: query,
            page: page,
            pageSize: pageSize,
            options: options
        };
        if(sort)
        {
            data['sort'] = sort;
        }
        return new Promise((resolve, reject) => {
            if (this.isItSameAsLast(query, page, pageSize, path, options, sort)){
                resolve(this.last.results);
            } else {
                this.apiService.post(collectionPath, data).then(
                    (result) => {
                        let sr = new SearchResult();
                        sr.data = [];
                        if(result && result['collection']) {
                            sr.data = result['collection']['__data'];
                            sr.page = result['collection']['__pageNumber'];
                            sr.pageCount = result['collection']['__pageCount'];
                            sr.pageSize = result['collection']['__pageSize'];
                            sr.resultCount = result['collection']['__size'];
                        } else {
                            sr.page = page;
                            sr.pageCount = 1;
                            sr.pageSize = pageSize;
                            sr.resultCount = 0;
                        }
                        this.saveLastResult(query, page, pageSize, path, options, sort, sr);
                        resolve(sr);
                    },
                    (error) => {
                        let result: string;
                        //result = this._translate.instant("Search service error") + " " +
                        //    error.status.toString() + "\n" +
                        //    this._translate.instant("Response_Error_" + error.status.toString());
                        reject([result, error.status]);
                    }
                );
            }
        })
    }

    private saveLastResult(query:string, page:number, pageSize:number, path:string, options:any, sort:string, results:SearchResult):void {
        this.last = new LastSearch();
        this.last.fraze = query;
        this.last.page = page;
        this.last.pageSize = pageSize;
        this.last.path = path;
        this.last.options = options;
        this.last.sort = sort;
        this.last.results = results;
        this.last.timePoint = moment();
    }

    private isItSameAsLast(query:string, page:number, pageSize:number, path:string, options:any, sort:string): boolean {
        //maybe should check is it to long ?
        if (this.last && this.last.fraze) {
            if(this.last.timePoint.isAfter(moment().subtract(360, 'seconds'))) {
                return false && (this.last.fraze == query && this.last.page == page
                    && this.last.pageSize == pageSize && this.last.path == path
                    && this.last.options == options && this.last.sort == sort)
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}
import {Moment} from "moment";
import * as moment from "moment";

export class VteStage2 {
    // Active cancer or cancer treatment
    // Age > 60
    // Dehydration
    // Known thrombophilias
    // Obesity (BMI >30 kg/m2)
    // One or more significant medical comorbidities
    // (eg heart disease;metabolic,endocrine or respiratory pathologies;acute infectious diseases; inflammatory conditions)
    // Personal history or first-degree relative with a history of VTE
    // Use of hormone replacement therapy
    // Use of oestrogen-containing contraceptive therapy
    // Varicose veins with phlebitis
    // Pregnancy or < 6 weeks post partum (see NICE guidance for specific risk factors)
    //
    // ///////////////////////////////////
    //
    // Significantly reduced mobility for 3 days or more
    // Hip or knee replacement
    // Hip fracture
    // Total anaesthetic + surgical time > 90 minutes
    // Surgery involving pelvis or lower limb with a total anaesthetic + surgical time > 60 minutes
    // Acute surgical admission with inflammatory or intra-abdominal condition
    // Critical care admission
    // Surgery with significant reduction in mobility
    q1: boolean;
    q2: boolean;
    q3: boolean;
    q4: boolean;
    q5: boolean;
    q6: boolean;
    q7: boolean;
    q8: boolean;
    q9: boolean;
    q10: boolean;
    q11: boolean;
    q12: boolean;
    q13: boolean;
    q14: boolean;
    q15: boolean;
    q16: boolean;
    q17: boolean;
    q18: boolean;
    q19: boolean;
    q20: boolean;

    public constructor(){
        this.q1 = false;
        this.q2 = false;
        this.q3 = false;
        this.q4 = false;
        this.q5 = false;
        this.q6 = false;
        this.q7 = false;
        this.q8 = false;
        this.q9 = false;
        this.q10 = false;
        this.q11 = false;
        this.q12 = false;
        this.q13 = false;
        this.q14 = false;
        this.q15 = false;
        this.q16 = false;
        this.q17 = false;
        this.q18 = false;
        this.q19 = false;
        this.q20 = false;
    }
}

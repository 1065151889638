import {ApiService} from "./api.service";
import {Injectable} from "@angular/core";
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {Subject} from "rxjs/Subject";
import {Observable} from "rxjs";
import * as _ from "lodash";
import * as moment from "moment";
import {MyGroups, TaskDescription, TaskRenderRequest} from "../models/task.description.model";
import {Http} from "@angular/http";

@Injectable()
export class TasksService {


    private _taskList: Array<TaskDescription>;
    private _tasks: {};

    public activeTask:Subject<TaskDescription> = new Subject<TaskDescription>();

    constructor(private _apiService: ApiService) {}

    getGroups() : Promise<Array<MyGroups>>{
        return new Promise((resolve, reject) => {
            this._apiService.get("forms/groups").then(
                (response) => {
                    if (response && response.__data) {
                        let groups = [];
                        _.forEach(response.__data,(data)=> {
                            let g = new MyGroups();
                            g.fromJson(data);
                            g.name = 'My groups';
                            groups.push(g);
                        });
                        resolve(groups);
                    }
                },
                (failed) => {
                    console.log(failed);
                    reject(failed);
                }
            )
        });
    }

    getTasksList() : Promise<Array<TaskDescription>> {
        return new Promise((resolve, reject) => {
            this._apiService.get("forms/workflow/list").then(
                (response) => {
                    if (response && response.__data) {
                        this._taskList = <Array<TaskDescription>> response.__data;
                        this._tasks = {};
                        this._taskList.forEach( task => {
                           this._tasks[task.workItemID] = task;
                        });
                        resolve(this._taskList);
                    }
                    resolve([]);
                },
                (failed) => {
                    console.log(failed);
                    reject(failed);
                }
            )
        });
    }

    getTaskById(taskId:string):TaskDescription {
        if(this._tasks && this._tasks[taskId]) {
            return this._tasks[taskId];
        }else {
            return null;
        }
    }

    render(taskId:string,  urlOnly:boolean): Promise<string>  {
        return new Promise((resolve,reject) => {
            let taskRenderRequest:TaskRenderRequest = new TaskRenderRequest();
            taskRenderRequest.workflowItemID = taskId;
            taskRenderRequest.urlOnly = urlOnly;
            this._apiService.post("forms/workflow/render", taskRenderRequest).then(
                (result) => { resolve(result); },
                (fail) => {
                    console.log(fail);
                    reject(false);
                })
        })
    }

    refreshAll() {
        let result = {
            tasks: [],
            groups: []
        };

        return new Promise((resolve, reject) => {
            this.getTasksList().then((success) => {

                this.getGroups().then((groups)=> {

                    _.forEach(groups,(group)=> {
                        _.forEach(group.groups, (g) => {
                            _.forEach(success, (task)=>{
                                if (g.groupID == task.assignee) {
                                    if (g.taskCount) g.taskCount++;
                                    else g.taskCount = 1;
                                    group.countTask++;
                                }
                            });
                        });
                    });

                    result['tasks'] = success;
                    result['groups'] = groups;

                    resolve(result);

                }, (failed) => {
                    if (failed.status == 401) {
                        //logout
                    } else {
                        result['tasks'] = success;
                        resolve(result);
                    }
                    reject(failed);
                });
            }, (failed) => {
                if (failed.status == 401) {
                    //logout
                } else {
                    console.log(failed);
                }
                reject(failed);
            })
        });
    }

    private claimRetreatTask(workitemID: string, claimUnclaim:string ,dueTime?:moment.Moment, priority?:number) : Promise<boolean> {
        return new Promise<boolean>((resolve, reject)=>{
            let data:any = {};
            data['type'] = claimUnclaim;
            data['workItemID'] = workitemID;
            if(claimUnclaim == "claim" && dueTime) data['dueTime'] = dueTime.format("YYYY-MM-DD");
            if(claimUnclaim == "claim" && priority) data['priority'] = priority;
            this._apiService.post("forms/workflow/task", data).then(
                (response) => {
                    console.log(response);
                    if(response && response["success"]){
                        resolve(true);
                    }
                    resolve(false);
                },
                (failed) => {
                    if(failed.status == 401){
                        //unautorized
                    }
                    console.log(failed);
                    resolve(false);
                }
            )
        })
    }

    claimTask(workitemID: string, dueTime?:moment.Moment, priority?:number) : Promise<boolean> {
        return this.claimRetreatTask(workitemID,  "claim", dueTime, priority);
    }

    retreatTask(workitemID) : Promise<boolean> {
        return this.claimRetreatTask(workitemID,  "unclaim");
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>|Promise<any> {
        let result = {
            tasks: [],
            groups: []
        };

        return new Promise((resolve, reject) => {
            this.getTasksList().then((success) => {
                this.getGroups().then((groups)=> {
                        _.forEach(groups,(group)=> {
                            _.forEach(group.groups, (g) => {
                                _.forEach(success, (task)=>{
                                    if (g.groupID == task.assignee) {
                                        if (g.taskCount) g.taskCount++;
                                        else g.taskCount = 1;
                                        group.countTask++;
                                    }
                                });
                            });
                        });

                        result['tasks'] = success;
                        result['groups'] = groups;
                        resolve(result);

                    }, (failed) => {
                        if (failed.status == 401) {
                            //logout
                        } else {
                            result['tasks'] = success;
                            resolve(result);

                            console.log(failed);
                        }
                        reject(failed);
                    });
            }, (failed) => {
                if (failed.status == 401) {
                    //logout
                } else {
                    console.log(failed);
                }
                reject(failed);
            })
        });
    }
}

@Injectable()
export class TaskResolveService implements Resolve<any> {
    constructor(private taskService: TasksService){}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        let result = {
            tasks: [],
            groups: []
        };

        return new Promise((resolve, reject) => {
            this.taskService.getTasksList().then((success) => {
                this.taskService.getGroups().then((groups)=> {
                    _.forEach(groups,(group)=> {
                        _.forEach(group.groups, (g) => {
                            _.forEach(success, (task)=>{
                                if (g.groupID == task.assignee) {
                                    if (g.taskCount) g.taskCount++;
                                    else g.taskCount = 1;
                                    group.countTask++;
                                }
                            });
                        });
                    });

                    result['tasks'] = success;
                    result['groups'] = groups;
                    resolve(result);

                }, (failed) => {
                    if (failed.status == 401) {
                        //logout
                    } else {
                        result['tasks'] = success;
                        resolve(result);

                        console.log(failed);
                    }
                    reject(failed);
                });
            }, (failed) => {
                if (failed.status == 401) {
                    //logout
                } else {
                    console.log(failed);
                }
                reject(failed);
            })
        });
    }
}

@Injectable()
export class ReferalsResolveService implements Resolve<any> {
    constructor(private taskService: TasksService){}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        let result = {
            tasks: [],
            groups: []
        };

        return new Promise((resolve, reject) => {
            this.taskService.getTasksList().then((success) => {
                this.taskService.getGroups().then((groups)=> {
                    _.forEach(groups,(group)=> {
                        _.forEach(group.groups, (g) => {
                            _.forEach(success, (task)=>{
                                if (g.groupID == task.assignee) {
                                    if (g.taskCount) g.taskCount++;
                                    else g.taskCount = 1;
                                    group.countTask++;
                                }
                            });
                        });
                    });

                    result['tasks'] = success;
                    result['groups'] = groups;
                    resolve(result);

                }, (failed) => {
                    if (failed.status == 401) {
                        //logout
                    } else {
                        result['tasks'] = success;
                        resolve(result);

                        console.log(failed);
                    }
                    reject(failed);
                });
            }, (failed) => {
                if (failed.status == 401) {
                    //logout
                } else {
                    console.log(failed);
                }
                reject(failed);
            })
        });
    }
}

@Injectable()
export class WorkbasketColumnsConfig implements Resolve<any> {
    constructor(private http: Http){}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return new Promise((resolve, reject) => {
            let v = Date.now();
            let result:any;
            // read json config file,
            this.http.get('../config/workbasket.json?v=' + v)
                .map(res => res.json())
                .subscribe(
                    data => {
                        result = data;
                    },
                    err => {
                        console.log(err);
                        result = {};
                    },
                    () => {
                        resolve(result);
                    }
                );
        })
    }
}
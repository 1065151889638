import {Component, EventEmitter, Input, Output} from "@angular/core";
import {AuthService} from "../../../services/auth.service";
import {RootShareService} from "../../../services/root.shared.service";
import {Moment} from "moment";
import * as moment from "moment";
import * as _ from "lodash";
import {ReferralForm} from "../../../models/forms/referral/referral.form";
import {PatientHistory} from "../../../models/forms/referral/patient.history";
import {PatientGeneralHistory} from "../../../models/forms/referral/patient.general.history";
import {PatientFamilyHistory} from "../../../models/forms/referral/patient.family.history";
import {PatientSocialHistory} from "../../../models/forms/referral/patient.social.history";
import {PatientAllergy} from "../../../models/forms/referral/patient.allergy";
import {ExternalExam} from "../../../models/forms/referral/external.exam";
import {FundasExam} from "../../../models/forms/referral/fundas.exam";
import {IOP} from "../../../models/forms/referral/iop";
import {OcularMotilityAndMuscleBalance} from "../../../models/forms/referral/ocular.motility.and.muscle.balance";
import {OpticCoherenceTomography} from "../../../models/forms/referral/optic.coherence.tomography";
import {OpticNerveHead} from "../../../models/forms/referral/optic.nerve.head";
import {PupilExam} from "../../../models/forms/referral/pupil.exam";
import {SlitLamp} from "../../../models/forms/referral/slit.lamp";
import {VisualAcuity} from "../../../models/forms/referral/visual.acuity";
import {VisualField} from "../../../models/forms/referral/visual.field";
import {PatientService} from "../../../services/patient.service";
import {CustomFormsService} from "../../../services/custom.forms.service";
import {NoteForm} from "../../../models/forms/note/note.form";
import {ProcedureForm} from "../../../models/forms/procedure/procedure.form";
//import any = jasmine.any;

@Component({
    selector: 'procedure-note-component',
    templateUrl: './procedure.note.component.html',
    styleUrls: ['../form.element.component.scss', './procedure.note.component.scss']
})
export class ProcedureNoteComponent {
    @Input() title: string = "Prescriptions";
    @Input() firstIcon: string = "";
    @Input() secondIcon: string = "";

    @Input() userThumbnail: boolean = false;
    @Input() showDateBalloon: boolean = false;
    @Input() userThumbnailName: string = "John Doe";
    @Input() userThumbnailTitle: string = "5 hours ago";
    @Input() userThumbnailImage: string = "../../assets/img/users_photo/male/christopher.png";

    @Input() parent: any;

    @Input() patient: any;
    @Input() patientDocument: any;

    @Output() firstIconAction = new EventEmitter();
    @Output() secondIconAction = new EventEmitter();
    @Output() refreshVitalsGraph = new EventEmitter();

    @Input() procedureForm: ProcedureForm = new ProcedureForm();

    constructor(private _rootShareService: RootShareService, private _customFormsService: CustomFormsService, private _auth: AuthService) {
    }

    isReadOnly() {
        return !(this.procedureForm.status === 'new' || this.procedureForm.status === 'edit');
    }

    getClasses(icon) {
        let classes: Array<string> = [];

        classes.push("unity-icon");
        classes.push("timeline-icon");

        if (icon == 'first')
            classes.push(this.firstIcon);
        else if (icon == 'second')
            classes.push(this.secondIcon);

        return classes;
    }

    userThumbnailDate() {
        let d = moment(this.procedureForm.when);
        let day = d.format('DD');
        let month = d.format('MMM');
        let year = d.format('Y');

        return day + " " + month + " " + year;
    }

    getMonth() {
        // noinspection all
        let month = moment(this.procedureForm.when).format('MMM').toUpperCase();
        return month;
    }

    getYear() {
        // noinspection all
        let year = moment(this.procedureForm.when).format('Y');
        return year;
    }

    // noinspection all
    saveNote() {
        this.procedureForm.status = "save";
        this._customFormsService.saveForm(this.procedureForm).then((v:any) => {
            this.procedureForm._id = v._id;
            if (this.parent) {
                this.parent.updateElements();
                this.refreshVitalsGraph.emit(1);
            }
        });
    }

    cancelNote() {
        if (this.parent) {
            this.parent.removeProcedure(this.procedureForm);
        }
    }

    formatDate(s) {
        return moment(s).format('DD/MM/YYYY');
    }

    editElement() {
        this.procedureForm.status = "edit";
        if (this.parent) {
            // this.parent.updateElements();
        }
    }
}

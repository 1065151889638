import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {PatientSocialHistory} from "../../../../models/forms/referral/patient.social.history";

@Component({
    selector: 'patient-social-history-component',
    templateUrl: './patient.social.history.html'
})
export class PatientSocialHistoryComponent {
    @Input() patientSocialHistory: PatientSocialHistory;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

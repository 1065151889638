import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {GPDetailsType} from "../../../../models/forms/referral/gp.details.type";
import {GpActionRequiredComponent} from "../gp-action-required/gp-action-required";
import {Medications} from "../../../../models/forms/referral/medications";
import * as _ from 'lodash';
import * as moment from "moment";

@Component({
    selector: 'medications-systemic-component',
    templateUrl: './medications-systemic.html'
})
export class MedicationsSystemicComponent {
    @Input() medications: Medications;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }

    formatDate(s) {
        return moment(s).format('DD/MM/YYYY');
    }
}

import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../../services/auth.service";
import {RootShareService} from "../../../../../services/root.shared.service";
import {IOP} from "../../../../../models/forms/referral/iop";

@Component({
    selector: 'iop-component',
    templateUrl: './iop.html'
})
export class IOPComponent {
    @Input() iop: IOP;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

import { Component } from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {PatientService} from "../../services/patient.service";
import { Patient } from "../../utils/definitions/Patient";
import { UiSpinner } from "../../elements/spinner/spinner.element";
import moment = require("moment");

@Component({
  templateUrl: './patients.module.html'
})
export class PatientsModule {
  // private patientsList: Array<number> = [2357892735,
  //     9663871123, 2287704112, 9401421388,
  //     2209120989, 2288774498, 2951938255,
  //     1006228304, 3421112893, 8664092100,
  //     2203084496
  // ];

  private patientsList: Array<number> = [1111111111,
    2222222222, 3333333333, 4444444444, 5555555555,
    6666666666, 7777777777, 8888888888, 9999999999,
    1010101010, 1313131313
  ];


  private patients: Array<Patient> = [];
  public searchedValue: Array<string> = [];
  private _queryParams : any = {};
  private _searchText:string = "";
  private _resultSet: Array<Patient> = [];
  private _loading: boolean;
  private _resultTime: number;
  private _resultCount: number;
  private _page: number;
  private _pageSize: number;
  private _searchFraze: string;
  private _sortType: string;
  private includePastAtten: boolean = false;

  constructor(private router: Router, private _patientService: PatientService, private _route: ActivatedRoute) {
    this._loading = false;
    this._pageSize = 10;
    this._page = 1;
    this._sortType = null;
  }

  ngOnInit(){
    this._route.queryParams.subscribe((params: Params) => {
      let fraze = params['fraze'];
      let page = params['page'];
      let sort = params['sort'];
      let specialty = params['specialty'];
      let consultant = params['consultant'];
      let gp = params['gp'];
      let past = params['past'];

      if(page) {
        this._queryParams['page'] = page;
        this._page = page;
      }
      if(sort) {
        this._queryParams['sort'] = sort;
        this._sortType= sort;
      }
      let options = {};
      if(specialty){
        this._queryParams['specialty'] = specialty;
        options['specialty'] = specialty;
      }
      if(consultant){
        this._queryParams['consultant'] = consultant;
        options['consultant'] = consultant;
      }
      if(gp){
        this._queryParams['gp'] = gp;
        options['gp'] = gp;
      }
      if (past) {
        this._queryParams['past'] = past;
        this.includePastAtten =  past == 'true'? true: false;
        options['includePastAttendances'] = past;
      }
      if(fraze) {
        this._queryParams['fraze'] = fraze;
        this._searchFraze = fraze;
        this.search(fraze, page, options, sort);
      } else {
        //by default- if there is no params- search for NHSScotland users (from 1111111111 to 7777777777)
        this._resultSet = this._patientService.getPatients(this.patientsList);
      }

    });
    // let tmpColumnsConfig = this.initDefaultPatientTableColumns();
    // this._preferencesService.getPreference(this._defaultOpenPatientPath).then(
    //     (preference) => {
    //       if(preference == null){
    //         this._defaultOpenPatient = DefaultOpenPatient[DefaultOpenPatient.timeline];
    //       } else {
    //         if(preference.value in DefaultOpenPatient) {
    //           this._defaultOpenPatient = preference.value;
    //         } else {
    //           this._defaultOpenPatient = DefaultOpenPatient[DefaultOpenPatient.timeline];
    //         }
    //       }
    //     }
    // );
    // this._preferencesService.getPreference(this._configColumnsOrderPath).then(
    //     (preference) => {
    //       this._columnsConfig = [];
    //       if(preference != null && preference.hasOwnProperty('value') && preference.value != null){
    //         try {
    //           JSON.parse(preference.value).forEach((element) => {
    //             if(tmpColumnsConfig.hasOwnProperty(element)) {
    //               this._columnsConfig.push(tmpColumnsConfig[element]);
    //             }
    //           })
    //         } catch(e) {
    //           this._logger.error("Problem with prepare columns order: " + e.toString());
    //           this._columnsConfig = null;
    //         }
    //       } else {
    //         this._logger.error("No property for columns order. Loading default order");
    //         Object.keys(tmpColumnsConfig).forEach( (key)=> {
    //           this._columnsConfig.push(tmpColumnsConfig[key]);
    //         });
    //       }
    //     }
    // );
    //this.setLists();
  }


  searchPatient(value, searchNumber) {
    let searchValue:string = value.id ? value.id : value.name ? value.name : value;
    this.searchedValue[searchNumber] = searchValue;

    this._queryParams['fraze'] = searchValue;
    delete this._queryParams['page'];
    this.router.navigate([], { queryParams:  this._queryParams });
  }

  rowOpen(patient: Patient) {
    this.router.navigate((['patient', patient.patientID, 'timeline']));
  }

  getRowClass(i) {
    if (i%2 == 0)
      return "grey-row";
  }

  uppercaseFirstLetter(text): string {
    return text.substr(0, 1).toUpperCase() + text.substr(1);
  }

  search(searchText: string, page?: number, filterOptions?: any, sort?:string):void{
    let _startSearchTime = moment();
    this._loading = true;
    this._patientService.patientSearch(searchText, page, null, filterOptions, sort).then(
        (results)=>{
          this._resultSet = results.data;
          console.log(results.data);
          this._resultCount = results.resultCount;
          this._page = results.page;
          this._pageSize = results.pageSize;
          this._loading = false;
          this._resultTime = moment().diff(_startSearchTime)/1000;
        }, (error) =>{
          this._loading = false;
          if (error[1] == 401) {
            this.router.navigate(['patients'])
          }
        }
    )
  }
}

export class VisualFieldInterpretationExam {
    testName = "VisualFieldInterpretation";

    descriptionOD: string;
    descriptionOS: string;
    discOD: string;
    maculaOD: string;
    vesselsOD: string;
    peripheryOD: string;
    discOS: string;
    maculaOS: string;
    vesselsOS: string;
    peripheryOS: string;

    FixationLossesOD: string;
    FalsePositiveErrorsOD: string;
    FalseNegativeErrorsOD: string;
    VFIOD: string;
    MeanDefectOD: string;
    PatternStandardDeviationOD: string;
    GHTOD: string;

    FixationLossesOS: string;
    FalsePositiveErrorsOS: string;
    FalseNegativeErrorsOS: string;
    VFIOS: string;
    MeanDefectOS: string;
    PatternStandardDeviationOS: string;
    GHTOS: string;

    public constructor(){
        this.descriptionOD = null;
        this.descriptionOS = null;
        this.discOD = null;
        this.maculaOD = null;
        this.vesselsOD = null;
        this.peripheryOD = null;
        this.discOS = null;
        this.maculaOS = null;
        this.vesselsOS = null;
        this.peripheryOS = null;
    }
}

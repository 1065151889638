import moment = require("moment");
import {VteStage1} from "./vte.stage1";
import {VteStage2} from "./vte.stage2";
import {VteStage3} from "./vte.stage3";

export class VteForm {
    _id: any;
    status: string;
    workflow: string = 'open';

    version: number;
    when: Date = moment().toDate();
    expanded: boolean;

    formName: string = 'vte';
    // TODO: !
    userId: string = null;
    patientId: string = null;

    users: Array<string>;

    stage1: VteStage1;
    stage2: VteStage2;
    stage3: VteStage3;

    public constructor() {
        this.status = 'new';
        this.version = 1;
        this.expanded = false;

        this.users = [];

        this.stage1 = new VteStage1();
        this.stage2 = new VteStage2();
        this.stage3 = new VteStage3();
    }
}

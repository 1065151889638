import {Moment} from "moment";
import * as moment from "moment";

export class ChecklistStage1 {
    // Has the patient confirmed his/her identity,
    // site, procedure, and consent?
    // Yes
    // Is the site marked?
    // Yes
    // Not applicable
    // Is the anaesthesia machine and medication
    // check complete?
    // Yes
    // Is the pulse oximeter on the patient and
    // functioning?
    // Yes
    // Does the patient have a:
    // Known allergy?
    // No
    // Yes
    // Difficult airway or aspiration risk?
    // No
    // Yes, and equipment/assistance available
    // Risk of >500ml blood loss (7ml/kg in children)?
    // No
    // Yes, and two IVs/central access and fluids
    // planned
    q1: boolean;
    q2: boolean;
    q3: boolean;
    q4: boolean;
    q5: boolean;
    q6: boolean;
    q7: boolean;
    q8: boolean;
    q9: boolean;
    q10: boolean;
    q11: boolean;

    public constructor(){
        this.q1 = false;
        this.q2 = false;
        this.q3 = false;
        this.q4 = false;
        this.q5 = false;
        this.q6 = false;
        this.q7 = false;
        this.q8 = false;
        this.q9 = false;
        this.q10 = false;
        this.q11 = false;
    }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User, UsersService} from "../../services/user.service";
import {Conversation, Message, MessageService, MessageStatus} from "../../services/messages.service";
import {Data} from "@angular/router";
import {PatientService} from "../../services/patient.service";
import * as _ from "lodash";
import {RootShareService} from "../../services/root.shared.service";

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html'
})
export class ChatComponent {

  public conversation: any;
  public chatDetails: any;
  public myId: string;

  newMessageText: string = '';
  static openChatDetails: any;


  /*
   'id': '1',
   'userId': '9',
   'patientID': '2222222222',
   'newMessage': '0',
   'viewByGroupChat': false,
   'countAllNewMessage': '10'
   'userConversation' : [
   {
   'patientID' : '2222222222',
   'newMessage': 1,
   'convId' : 1
   },
   {
   'patientID' : '1111111111',
   'newMessage': 9,
   'convId': 3
   }
   ]
   */

  @Input() set details (chatDetails: any) {
    this.newMessageText = '';
    this.chatDetails = chatDetails;

    let chat =   document.getElementById('chat-box');
    if (chat) chat.style.visibility ='hidden';

    let conToOpen;

    if (localStorage.getItem('chatPatientID')) {
      let patientToOpen = localStorage.getItem('chatPatientID');

      for (let i = 0 ; i < chatDetails.userConversation.length; i++) {
        if(chatDetails.userConversation[i].patientID == patientToOpen) {
          conToOpen = chatDetails.userConversation[i];
          localStorage.removeItem('chatPatientID');
          break;
        }
      }
    } else {
      for (let i = 0 ; i < chatDetails.userConversation.length; i++) {
        if(chatDetails.userConversation[i].newMessage == 0) {
          conToOpen = chatDetails.userConversation[i];
          break;
        }
      }
    }


    if (!conToOpen) conToOpen = chatDetails.userConversation[0];

    _.forEach(chatDetails.userConversation, (conv)=> {
      this.patientService.getById(conv.patientID).then((patient)=> {
        conv['patient'] = patient;
        if(conToOpen.patientID == patient.__id)
          this.openPatientId = conToOpen.patientID;

        this.openNewPatientEmitter.emit(conToOpen.patient);
      })
    });

    this.messageService.getMessagesForChat(conToOpen.convId).then((con) => {
      this.conversation = con;
      this.chatDetails.countAllNewMessage -= conToOpen.newMessage;
      this.messageService.resetNewMessageFor(conToOpen.convId);
      this._rootShared.unreadNotifications.messages -= conToOpen.newMessage;
      conToOpen.newMessage = 0;
    });



    setTimeout(() => {
      if(document.getElementById('chat-box')) {
        document.getElementById('chat-box').scrollTop = document.getElementById('chat-box').scrollHeight;
        document.getElementById('chat-box').style.visibility = 'visible';
      }
    }, 100);
  }

  @Input() users: Array<User>;
  @Input() photo: string = '';

  @Output() openNewPatientEmitter = new EventEmitter();

  constructor(private messageService: MessageService, private usersService: UsersService,
              private patientService: PatientService,  private _rootShared: RootShareService) {
    this.myId = this.usersService.getCurrentUser().id;
  }

  ngAfterViewInit(){
    setTimeout(() => {
      if(document.getElementById('chat-box')) {
        document.getElementById('chat-box').scrollTop = document.getElementById('chat-box').scrollHeight;
        document.getElementById('chat-box').style.visibility = 'visible';
      }
    }, 100);

    // document.getElementById('chat-box').scrollTop = 100;
  }

  sendMessage(e){
    if (e.keyCode == 13) {
      let message = new Message(this.newMessageText,new Date(),MessageStatus.send,'5acb514214969132a0fad927');
      let conId;

      for(let i = 0; i <this.chatDetails.userConversation.length; i++) {
        if(this.chatDetails.userConversation[i].patientID == this.openPatientId)
          conId = this.chatDetails.userConversation[i].convId;

      }
      this.messageService.addMessage(message, conId);
      this.newMessageText = '';

      setTimeout(() => {
        document.getElementById('chat-box').scrollTop = 99999;
      }, 100);
    }
  }

  getPhoto(isMine, authorID) {
    if (authorID == '5acb514214969132a0fad927') return this.usersService.getCurrentUser().photo;
    else {
      if (this.chatDetails.isGroupChat) {
        for(let i = 0 ; i<this.users.length; i++) {
          if(this.users[i].id == authorID)
            return this.users[i].photo? '../../assets/img/' + this.users[i].photo : '../../assets/img/no-photo.jpg';
        }

      } else {
        let user = this.usersService.getUser(this.chatDetails.userId);
        return user.photo ? '../../assets/img/' + user.photo : '../../assets/img/no-photo.jpg'
      }
    }
    return '../../assets/img/no-photo.jpg';
  }

  input(){
    document.getElementById('myFile').click();
  }

  newMessage(newMsg){
      let message = new Message(newMsg,new Date(),MessageStatus.send,this.myId);
      this.conversation.messages.push(message);
      this.newMessageText = '';

      setTimeout(() => {
          document.getElementById('chat-box').scrollTop = 99999;
      }, 100);
  }

private openPatientId;
  openConversationOnPatient(userConversation) {

    this.openNewPatientEmitter.emit(userConversation.patient);

    this.openPatientId = userConversation.patientID;

    let chat =   document.getElementById('chat-box');
    if (chat) chat.style.visibility ='hidden';

    this.messageService.getMessagesForChat(userConversation.convId).then((con) => {
      this.conversation = con;
      this.messageService.resetNewMessageFor(userConversation.convId);
      this.chatDetails.countAllNewMessage -= userConversation.newMessage;
      this._rootShared.unreadNotifications.messages -= userConversation.newMessage;
      userConversation.newMessage = 0;
    });

    setTimeout(() => {
      document.getElementById('chat-box').scrollTop = document.getElementById('chat-box').scrollHeight;
      document.getElementById('chat-box').style.visibility ='visible';
    }, 100);

  }

  getConversationColor(userConversation): string{
    if (this.conversation && this.conversation.conversationId == userConversation.convId)
      return 'black';
    else if (userConversation.newMessage == 0)
      return '#999999';
    else if (userConversation.newMessage > 0)
      return '#00a0e4';
  }
}

import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {PatientGeneralHistory} from "../../../../models/forms/referral/patient.general.history";
import {VteStage1} from "../../../../models/forms/vte/vte.stage1";
import * as _ from 'lodash';

@Component({
    selector: 'vte-stage1-component',
    templateUrl: './vte.stage1.html'
})
export class VteStage1Component {
    @Input() stage1: VteStage1;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }

    clickQ(qn) {
        if (!this.readOnly)
            this.stage1[qn] = !this.stage1[qn];
    }
}

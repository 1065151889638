export class ChildClinicTypeSuggest {
    StrabiusmusAndAmblyolpia: boolean;
    PaediatricNonStrabismus: boolean;
    OrthopticOnly: boolean;

    public constructor(){
        this.StrabiusmusAndAmblyolpia = false;
        this.PaediatricNonStrabismus = false;
        this.OrthopticOnly = false;
    }
}

import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {OptomDetails} from "../../../../models/forms/referral/optom.details";

@Component({
    selector: 'optom-details-component',
    templateUrl: './optom.details.html'
})
export class OptomDetailsComponent {
    @Input() optomDetails: OptomDetails;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {

    }
}

import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DocumentsService} from "../../services/documents.service";
import {DomSanitizer} from "@angular/platform-browser";
import {FormsService} from "../../services/forms.service";
import {PatientService} from "../../services/patient.service";
import {TasksService} from "../../services/tasks.service";
import {ReferralForm} from "../../models/forms/referral/referral.form";
import * as _ from "lodash";
import * as $ from "jquery"
import moment = require("moment");
import {AuthService} from "../../services/auth.service";
import {CustomFormsService} from "../../services/custom.forms.service";

@Component({
  templateUrl: './tasks.module.html',
    styleUrls: ['./tasks.module.scss']
})
export class TasksModule {
    constructor(private _router: Router, private _route: ActivatedRoute, private _documentsService: DocumentsService, private _sanitizer: DomSanitizer,
                private _authService: AuthService, private _customFormsService: CustomFormsService,
                private _patientService: PatientService,  private _formsService: FormsService, private _taskService: TasksService) {}

    referralForms: Array<ReferralForm> = [ ];
    filterName: string = null;

    ngOnInit() {
        this._route.data.subscribe(data => {
            this.referralForms = [];
            this._authService.getUser().then((v) => {
                _.forEach(data.forms, (f) => {
                    switch (f.formName) {
                        case 'task':
                            if (f.taskUser === v.id && f.status !== "done") {
                                f.expanded = true;
                                this.referralForms.push(f);
                            }
                            break;
                    }
                });
            });
        });
    }

    filteredForms: Array<ReferralForm> = [ ];
    getFilteredForms() {
        let filteredForms: Array<ReferralForm> = [ ];

        let that = this;
        _.forEach(this.referralForms, (form) => {
            let formGroup = that.getReferralFormGroup(form);
            if (that.filterName === null || that.filterName === formGroup)
                filteredForms.push(form);
        });

        return filteredForms;
    }

    // noinspection all
    searchSomething(value, searchNumber) {
        let searchValue:string = value.id ? value.id : value.name ? value.name : value;
        // this.searchedValue[searchNumber] = searchValue;
        alert(searchValue);
    }

    // noinspection all
    getDOB(form: ReferralForm) {
        return moment(form.patientDetails.dob).format("DD/MM/YYYY");
    }

    // noinspection all
    expandDetails(e, form: ReferralForm) {
        e.stopPropagation(); e.preventDefault();
        form.expanded = !form.expanded;
    }

    openPatient(form: ReferralForm) {
        this._router.navigate((['patient', form.patientDetails.patientID, 'timeline']));
    }

    // noinspection all
    getReferralFormGroup(form: ReferralForm) {
        if (form && form.referralGroup && form.referralGroup.length > 0) {
            return form.referralGroup;
        }

        return "General Referral";
    }

    filterGroup(filterName) {
        this.filterName = filterName;
    }

    // noinspection all
    getDaysFrom18WeeksRule(form: ReferralForm) {
        let w = moment(form.when);
        let w18 = w.add(18, 'weeks');

        return Math.floor(moment.duration(w18.diff(moment())).asDays());
    }

    // noinspection all
    complete(e, f) {
        e.stopPropagation(); e.preventDefault();

        f.status = "done";
        // TODO: PUBLISH at some point !!!
        // this._customFormsService.saveForm(formToSave).then((v) => {
        //     // alert(v);
        // });
        let that = this;
        this._customFormsService.saveForm(f).then((v:any) => {
            // this.referralForm._id = v._id;
            // if (this.parent) {
            //     this.parent.updateElements();
            // }
            _.remove(that.referralForms, (fe) => {
                return f === fe;
            });
        });
    }

    // noinspection all
    cancel(e, f) {
        e.stopPropagation(); e.preventDefault();
        this.complete(e, f);
    }
}

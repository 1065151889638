import {Moment} from "moment";
import * as moment from "moment";

export class ChecklistStage3 {
    // Active bleeding
    // Acquired bleeding disorders (such as acute liver failure)
    // Concurrent use of anticoagulants known to increase the risk of bleeding (such as warfarin with INR >2)
    // Acute stroke
    // Thrombocytopaenia (platelets< 75x109/1)
    // Uncontrolled systolic hypertension (230/120 mmHg or higher)
    // Untreated inherited bleeding disorders (such as haemophilia and von Willebrand's disease)
    //
    // //////
    //
    // Neurosurgery, spinal surgery or eye surgery
    // Other procedure with high bleeding risk
    // Lumbar puncture/epidural/spinal anaesthesia expected within the next 12 hours
    // Lumbar puncture/epidural/spinal anaesthesia within the previous 4 hours

    q1: boolean;
    q2: boolean;
    q3: boolean;
    q4: boolean;
    q5: boolean;

    public constructor(){
        this.q1 = false;
        this.q2 = false;
        this.q3 = false;
        this.q4 = false;
        this.q5 = false;
    }
}

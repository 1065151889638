export class VisualField {
    testName = "VisualField";

    verticalCupToDiscRatioOD: string;
    verticalCupToDiscRatioOS: string;
    visualFieldAnalysisOD: string;
    visualFieldAnalysisOS: string;

    public constructor(){
        this.verticalCupToDiscRatioOD = null;
        this.visualFieldAnalysisOD = null;
        this.verticalCupToDiscRatioOS = null;
        this.visualFieldAnalysisOS = null;
    }
}

import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {ChildClinicTypeSuggest} from "../../../../models/forms/referral/child.clinic.type.suggest";
import {GpAction} from "../../../../models/forms/referral/gp.action";
import * as _ from 'lodash';

@Component({
    selector: 'gp-action-required-component',
    templateUrl: './gp-action-required.html'
})
export class GpActionRequiredComponent {
    @Input() gpAction: GpAction;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }

    clickQ(qn) {
        if (!this.readOnly)
            this.gpAction[qn] = !this.gpAction[qn];
    }
}

import {Address} from "./address.model";
import {JsonProperty} from "./mapUtil";

export class GpData {
    id: string;
    title: string;
    firstName: string;
    lastName: string;
    officePhone: string;
    mobile: string;
    email: string;
    fax: string;
    @JsonProperty('address')
    addresses: Array<Address>;

    public constructor(){
        this.id = undefined;
        this.title = undefined;
        this.firstName = undefined;
        this.lastName = undefined;
        this.officePhone = undefined;
        this.mobile = undefined;
        this.email = undefined;
        this.fax = undefined;
        this.addresses = undefined;
    }

    get fullName() {
        return this.title + ". " + this.firstName + " " + this.lastName;
    }
}
/**
 * Created by mstolarczyk on 2017-12-07.
 */
import {DictionaryInterface} from "./Dictionary";
import * as _ from "lodash";

export class InvestigationDepartment implements DictionaryInterface {
    __id: any;
    color: string;
    name: string;
    code: string;
    description: string;
    parentDepartment: string;

    constructor(obj:any){
        this.__id = obj['__id'] ? obj['__id'] : _.uniqueId("InvestigationDepartment");
        this.color = obj['color'] ? obj['color'] : null;
        this.name = obj['name'] ? obj['name'] : null;
        this.code = obj['__code'] ? obj['__code'] : null;
        this.description = obj['__description'] ? obj['__description'] : null;
        this.parentDepartment = obj['parentDepartmentCode'] ? obj['parentDepartmentCode'] : null;
    }

    getDisplayName(): string {
        return this.description;
    };

    getDictId(): string {
        return this.__id;
    }

    getDictCode(): string {
        if (this.code) {
            return this.code;
        } else {
            return this.__id;
        }
    }
}

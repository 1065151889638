import * as moment from "moment";

export class PatientAllergy {
    allergyType: string;
    // <!-- https://pathways.nice.org.uk/pathways/drug-allergy#path=view%3A/pathways/drug-allergy/documenting-drug-allergy-and-sharing-information-with-other-healthcare-professionals.xml&content=view-node%3Anodes-recording-drug-allergy-status -->
    // <!-- drug allergy | none known | unable to ascertain -->
    drugName: string;
    documentation: string;
    riskLevel: string;
    // <!-- Mortality | Repeat Reaction | Extended Care | Addiction | Anaphylaxis -->
    dateIdentified: Date;

    static allergyTypeChoices = ['Drug allergy', 'None known', 'Unable to ascertain'];
    static riskLevelChoices = ['Mortality', 'Repeat Reaction', 'Extended Care', 'Addiction', 'Anaphylaxis'];

    public constructor() {
        this.allergyType = "";
        // <!-- https://pathways.nice.org.uk/pathways/drug-allergy#path=view%3A/pathways/drug-allergy/documenting-drug-allergy-and-sharing-information-with-other-healthcare-professionals.xml&content=view-node%3Anodes-recording-drug-allergy-status -->
        // <!-- drug allergy | none known | unable to ascertain -->
        this.drugName = "";
        this.documentation = "";
        this.riskLevel = "";
        // <!-- Mortality | Repeat Reaction | Extended Care | Addiction | Anaphylaxis -->
        this.dateIdentified = moment().toDate();
    }
}

import { Component } from '@angular/core';

@Component({
    templateUrl: './audits.module.html',
    styleUrls: ['./audits.module.scss']
})
export class AuditsModule {
    public tbl:boolean = true;

    public showMedical(){
        this.tbl = true;
    }

    public showReferral(){
        this.tbl = false;
    }
}
export class PupilExam {
    testName = "PupilExam";

    descriptionOD: string;
    descriptionOS: string;

    public constructor() {
        this.descriptionOD = null;
        this.descriptionOS = null;
    }
}

import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {PatientGeneralHistory} from "../../../../models/forms/referral/patient.general.history";
import {PastOcularHistory} from "../../../../models/forms/referral/past.ocular.history";

@Component({
    selector: 'patient-general-history-component',
    templateUrl: './patient.general.history.html'
})
export class PatientGeneralHistoryComponent {
    @Input() patientGeneralHistory: PastOcularHistory;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

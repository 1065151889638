export class CarePathway {
    isConfirmed: boolean;
    isIgnored: boolean; // ????

    // Care Pathway (selection ?)
    //
    // Watson Recommended Treatment
    // SNOMED - Watson Recommended Treatment
    // Watson Treatment Evidence

    carePathway: string;
    watsonRecommendedTreatment: string;
    snomedWatsonRecommendedTreatment: string;
    watsonTreatmentEvidence: string;

    public constructor(){
        this.isConfirmed = false;
        this.isIgnored = false;

        this.carePathway = "";
        this.watsonRecommendedTreatment = "";
        this.snomedWatsonRecommendedTreatment = "";
        this.watsonTreatmentEvidence = "";
    }
}

import {Hospital} from "./Hospital";
import {BaseClass} from "./BaseClass";
import {ApiService} from "../../services/api.service";

export class Clinic extends BaseClass {
    clinic_id: number;
    specialtyCode: string;
    isActive: boolean;
    clinicCode: string;
    description: string;
    hospital: Hospital;

    constructor(apiService: ApiService){
        super(apiService);
    }

    getDisplayName(): string {
        return  this.description;
    }

    getDictId(): string {
        return this.__id;
    }

    getDictCode(): string {
        return this.clinicCode;
    }

    static getFilterString() {
        return "description regex '###.*' OR _id regex '###.*' ";
    }

    static getDefaultSort(): string {
        return "description";
    }
}
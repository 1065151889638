export class PatientDetails {
    patientID: string;
    nhsNumber: string;
    title: string;
    firstName: string;
    middleName: string;
    familyName: string;
    dob: Date;
    age: string;
    sex: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    postcode: string;
    homeTelephone: string;
    workTelephone: string;
    emailAddress: string;
    verificationStatus: string;
    barcodeData: string;

    public constructor(){
        this.patientID = "";
        this.nhsNumber = "";
        this.title = "";
        this.firstName = "";
        this.middleName = "";
        this.familyName = "";
        this.dob = null;
        this.age = "";
        this.sex = "";
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.addressLine3 = "";
        this.addressLine4 = "";
        this.postcode = "";
        this.homeTelephone = "";
        this.workTelephone = "";
        this.emailAddress = "";
        this.verificationStatus = "";
        this.barcodeData = "";
    }
}

export class DaysFilter {
    firstDay: string;
    lastDay: string;
    isSet: boolean;

    constructor() {
        this.firstDay = null;
        this.lastDay = null;
        this.isSet = false;
    }

    setValue(firstDay: string, lastDay: string) {
        this.firstDay = firstDay;
        this.lastDay = lastDay;
        this.isSet = true;
    }
    isCorrectFirstDay (start: string): boolean{
        if (start < this.lastDay) return true;
        else return false;
    }

    isCorrectLastDay (end: string): boolean{
        if (end > this.firstDay) return true;
        else return false;
    }

    dayInRange(date: string): boolean {
        if (this.firstDay <= date && this.lastDay >= date)
            return true;
        else return false;
    }
}
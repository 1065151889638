import {BaseClass} from "./BaseClass";
import {ApiService} from "../../services/api.service";
import {DictionaryInterface} from "./Dictionary";

export class Consultant extends BaseClass implements DictionaryInterface {
    consultantCode: string;
    specialtyCode: string;
    title: string;
    familyName: string;
    firstName: string;
    middleNames: string;
    intials: string;
    pasCode: string;
    inactive: string;

    constructor(apiService:ApiService){
        super(apiService);
    }

    getDisplayName(): string {
        return (this.familyName ? this.familyName : "") + ' ' + (this.firstName ? this.firstName : "");
    }

    getFullDisplayName(): string {
        return  (this.title ? this.title : "")  + ' ' + (this.familyName ? this.familyName : "") + ' ' + (this.firstName ? this.firstName : "");
    }

    getDictId(): string {
        return this.__id;
    }

    getDictCode(): string {
        return this.consultantCode;
    }

    static getInactiveFilter():string {
        return "inactive ne true";
    }

    static getFilterString():string {
        return "inactive ne true AND (familyName regex '###.*' OR firstName regex '###.*' OR _id regex '###.*')";
    }

    static getFullNameFilterString():string {
        return "inactive ne true AND familyName regex '###1.*' AND firstName regex '###2.*'";
    }

    static getFullNameAndCodeFilterString():string {
        return "inactive ne true AND familyName regex '###1.*' AND firstName regex '###2.*' AND _id regex '###3.*'";
    }

    static getDefaultSort():string {
        return "familyName,firstName";
    }

}
import {BaseClass} from "./BaseClass";
import {ApiService} from "../../services/api.service";
import {DictionaryInterface} from "./Dictionary";

export class GPAddress extends BaseClass {
    clinicalCareGroup: string;
    type: string;
    lines: Array<string>;    // containing 4 elements
    postalCode: string;

    constructor(apiService:ApiService){
        super(apiService);
    }
}

export class GP extends BaseClass implements DictionaryInterface{
    names: string;
    title: string;
    initials: string;
    firstName: string;
    familyName: string;
    middleNames: string;
    dob: Date;
    phone: string;
    description: string;
    genderCode: string;
    gpCode: string;
    emailAddress: string;
    address: GPAddress;

    private addressesStr:string = null;

    constructor(private apiService: ApiService) {
        super(apiService);
    }

    get gpId() {
        return this.__id;
    }

    get fullGPName():string {
        return (this.title ? this.title + ' ' : '') +
            (this.firstName ? this.firstName + ' ' : '') +
            (this.middleNames ? this.middleNames + ' ' : '') +
            (this.familyName ? this.familyName : '');
    }

    get gpAddress():string {
        if(!this.addressesStr && this.address){
            this.addressesStr = "";

            let address = this.address;
            // if(address.type) {
            //     this.addressesStr += "(" + address.type + ") <br>";
            // }
            let needComma = false;
            if(address.lines) {
                for (let _i = 0; _i < address.lines.length; _i++)
                    if(address.lines[_i]) {
                        if (needComma)
                            this.addressesStr += ", ";
                        this.addressesStr += address.lines[_i];
                        needComma = true;
                    }
            }
            if(address.postalCode) {
                this.addressesStr += ", " + address.postalCode;
            }
            return this.addressesStr;
        } else {
            return this.addressesStr == null ? "" : this.addressesStr ;
        }
    }

    getDisplayName(): string {
        return this.firstName + ' ' + this.familyName;
    }

    getDictId(): string {
        return this.__id;
    }

    getDictCode(): string {
        return this.gpCode;
    }

    static getFilterString() {
        return "familyName regex '###.*' OR firstName regex '###.*' OR _id regex '###.*'";
    }

    static getDefaultSort(): string {
        return "familyName,firstName";
    }
}
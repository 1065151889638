import { Component, HostListener} from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { MainService } from "../../services/main.service";
import { Router } from "@angular/router";

@Component({
    selector: 'auth-module',
    templateUrl: './auth.module.html'
})
export class AuthModule {
    username: string;
    password: string;
    rememberMe: boolean;

    @HostListener('window:keydown', ['$event'])
    hotKeysEvent(event) {
        if (event.keyCode == 13){
            this.login();
        }
    }

    constructor(public authService: AuthService, public mainService: MainService, private _router: Router){
        // this.username = "Gordon";
        // this.password = "demo.demo";
    }

    login() {
        this.mainService.showLoading();

        this.authService.basicLogin(this.username, this.password).then(
            (response) => {
                if (response) {
                    this.mainService.hideLoading();
                    this._router.navigate(["","dashboard"]);
                } else {
                    this.mainService.hideLoading();
                    alert("Wrong Username or Password");
                }
            }, (error) => {
                console.log(error);
            }
        )
    }
}
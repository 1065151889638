import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import { PatientTimelineElementComponent, TimelineElementType } from '../../components/patient-timeline-element/patient-timeline-element.component';
import { PatientTimelineProfile } from '../../components/patient-timeline-profile/patient-timeline-profile.component';
import { UiFilter } from '../../elements/filter/filter.element';
import {ActivatedRoute, Router} from "@angular/router";
import {DocumentsService} from "../../services/documents.service";
import {PatientService} from "../../services/patient.service";
import moment = require("moment");
import * as _ from "lodash";
import * as $ from "jquery"
import {FormsService} from "../../services/forms.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {TasksService} from "../../services/tasks.service";
import {Patient} from "../../utils/definitions/Patient";
import {ReferralForm} from "../../models/forms/referral/referral.form";
import {WatsonMessage} from "../../components/patient-timeline-watson/patient-timeline-watson.component";
import {AuthService} from "../../services/auth.service";
import {MockService} from "../../services/mock.service";
import {NoteForm} from "../../models/forms/note/note.form";
import {VteForm} from "../../models/forms/vte/vte.form";
import {ChecklistForm} from "../../models/forms/checkilst/checklist.form";
import {Medications} from "../../models/forms/referral/medications";
import {GP} from "../../utils/definitions/GP";
import {ApiService} from "../../services/api.service";
import {CustomFormsService} from "../../services/custom.forms.service";

@Component({
    templateUrl: './patient-timeline.module.html'
})
export class PatientTimelineModule implements OnInit, OnDestroy {
    TimelineElementType = TimelineElementType;

    public showDates: boolean = false;

    public patient: Patient;
    public _patientDocuments: any;
    public showWatson: boolean;
    public _tmpDate: moment.Moment = moment('2016-04-12');
    public _formUrl: SafeResourceUrl;
    public conversation: Array<WatsonMessage>;

    dateValues: Array<string> = ["Date", "All", "2018", "Nov", "Mar", "2017", "2016", "Feb"];
    dateValue: string = "All";
    filterValues: Array<string> = ["All"];
    filterValue: string = "All";
    // filterValues: Array<string> = ["Filter", "All", "Events", "Clinical Images", "Examinations",
    // "Prescriptions", "Refferals", "Documents", "Visual Fields"];

    vitalsViewByGraph: boolean = false;
    workbasketID : any = null;

    conversationIdToOpen: any = null;

    constructor(private _router: Router, private _route: ActivatedRoute, private _documentsService: DocumentsService, private _sanitizer: DomSanitizer,
                private _authService: AuthService, private _mockService: MockService, private _apiService: ApiService,
                private _customFormsService: CustomFormsService,
                public _patientService: PatientService,  private _formsService: FormsService, private _taskService: TasksService) {}

    datesPresent = [];
    ngOnInit() {
        $("#patients-top-bar-nav").addClass('active');
        this._route.data.subscribe(data => {
            this.patient = data.patient;
            console.log(data);
            this.assignCustomForms(data.forms);
        });

        this._route.queryParams
            .subscribe(params => {
                this.showDates = params.showDates && params.showDates === "1";

                // console.log(params);
                if (params.workbasket && this.workbasketID !== params.workbasket) {
                    this.workbasketID = params.workbasket;
                    // TODO!: open it first !
                }

                if (params.con) {
                    this.conversationIdToOpen = params.con
                }
        });

        this.getAllPatientDocuments();
        this.conversation = [];

        //get patientGP values from mock
        this._patientService.getPatientMockGP(this.patient.patientID).then(
            (response) => {
                if (response) {
                    this.patient.gp = new GP(this._apiService);

                    Object.keys(response).forEach((key)=> {
                        this.patient.gp[key] = response[key];
                    });
                }
            }
        )
    }
    assignCustomForms(data) {
        this.referralForms = [];
        this.testsForms = [];
        this.vteForms = [];
        this.noteForms = [];
        this.tocForms = [];
        this.referralImageForms = [];
        _.forEach(data, (f) => {
            switch (f.formName) {
                case 'note':
                    this.noteForms.push(f);
                    break;
                case 'procedure-surgery':
                    this.procedureSurgeryForms.push(f);
                    break;
                case 'toc':
                    this.tocForms.push(f);
                    break;
                case 'referralImage':
                    this.referralImageForms.push(f);
                    break;
                case 'referral':
                    this.referralForms.push(f);
                    break;
                case 'tests':
                case 'test':
                    this.testsForms.push(f);
                    break;
                case 'eye-exam':
                    this.eyeExamForms.push(f);
                    break;
                case 'vte':
                    this.vteForms.push(f);
                    break;
                case 'checklist':
                    this.checklistForms.push(f);
                    break;
            }
        });

        let forms = [];
        forms = forms.concat(this.testsForms);
        forms = forms.concat(this.eyeExamForms);
        forms = forms.concat(this.referralForms);
        forms = forms.concat(this.procedureSurgeryForms);
        this._patientService.testForms = forms;
        // this._patientService.testForms = this.testsForms;

        this.noteForms = _.sortBy(this.noteForms, [(o) => {
            return moment(o.when).toDate();
        }]);
    }

    ngOnDestroy() {
        $("#patients-top-bar-nav").removeClass('active');
    }

    getAllPatientDocuments(){
        let that = this;

        this._documentsService.getAllPatientDocuments(this.patient.patientID).then(
            (documents)=> {
                console.log(documents);
                this._patientDocuments = documents;
                this.elementsRequireUpdate = true;
                _.forEach(documents, (patientDocument) => {
                    //console.log(patientDocument);
                    patientDocument.isFirstOfMonthDate = false;
                    if (patientDocument.startDate) {
                        let m = moment(patientDocument.startDate);
                        let yearAndMonth = m.format('Y MMM');
                        patientDocument.isFirstOfMonthDate = true;
                        _.forEach(that.datesPresent, (d) => {
                            if (d === yearAndMonth) {
                                patientDocument.isFirstOfMonthDate = false;
                            }
                        });
                        if (patientDocument.isFirstOfMonthDate) {
                            that.datesPresent.push(yearAndMonth);
                        }
                    }
                });
                console.log('Documents loaded');
                //console.log(this._patientDocuments);
            },
            (error) => {
                console.log("No documents for this patient");
            }
        );
    }

    deleteDocument(document) {
        if (confirm('are you sure?')) {
            this._customFormsService.deleteForm(document.data._id).then((v) => {
                location.reload();
            });
        }
    }

    tableViewOfVitals() {
        this.vitalsViewByGraph = false;
    }

    graphViewOfVitals() {
        this.vitalsViewByGraph = true;
    }

    watsonWidgetEvent = new EventEmitter();
    showWatsonWidget() {
        this.showWatson = !this.showWatson;
        if (this.showWatson) {
            // send hello
            setTimeout(() => {
                this.watsonWidgetEvent.emit();
            }, 1);
        }
    }

    closeWatson() {
        this.showWatson = false;
    }

    public messageReceived$ = new EventEmitter();
    messageReceived(messageWatson: any) {
        console.log('messageReceived', messageWatson);

        let reason = '';
        if (messageWatson.intents && messageWatson.intents[0]) {
            reason = messageWatson.intents[0].intent;
        }

        switch (reason) {
            case "Eye_Exam":
            case "Reason_for_referral":
                // STEP 1
                // create eye exam
                this.addEyeExam();
                break;
            default:
                this.messageReceived$.emit(messageWatson);
                break;
        }
    }

    // noinspection all
    documentDateProp(document) {
        // http://localhost:4200/#/patient/7777777777/timeline?showDates=1
        return {
            get when(): string {
                return document.data.when.toISOString();
            },
            set when(w) {
                let mm = moment(w);
                if (mm.isValid()) {
                    document.data.when = mm;
                }
            }
        }
    }

    formPrevious = { left: 0, top: 0 };
    formOpened = false;
    animateFormOpen() {
        // zaleznie od sytuacji - albo otwieramy nowa albo jezeli mamy task_id to otwieramy TASK
        //

        let data = {
            "afData": {
                "afBoundData": {
                    "data": {
                        "PatientDetails": {
                            "patientID": this.patient.__id,
                            "title": this.patient.title,
                            "firstName": this.patient.firstName,
                            "middleName": this.patient.middleNames,
                            "familyName": this.patient.familyName,
                            "nhsNumber": this.patient.nhsNumber,
                            "dob": this.patient.dob,
                            "sex": this.patient.gender
                        }
                    }
                }
            }
        };

        // TODO: !!!
        // <iframe [src]="_formUrl"></iframe>
        this._formsService.render('/FortrusForms/Forms/0_2_0_Alerts.html', JSON.stringify(data), true).then(
            (results) => {
                this._formUrl = this._sanitizer.bypassSecurityTrustResourceUrl(results);
            },
            (failed) => {
                this._formUrl = this._sanitizer.bypassSecurityTrustResourceUrl("EformLoadFailed.html");
            }
        );

        /*
        this._taskService.render(this.task.workItemID, true).then(
            (results) => {
                this._formUrl = this._sanitizer.bypassSecurityTrustResourceUrl(results);
            },
            (failed) => {
                this._formUrl = this._sanitizer.bypassSecurityTrustResourceUrl("EformLoadFailed.html");
            }
        );
        */

        //

        let that = this;
        let transitionTime = 1500;

        let $formMain = $('.form-inside');
        let $form1 = $('.form-inside .button-object');
        let $form2 = $('.form-inside .form-object');
        let $modal = $(".modal-background");
        let $body = $('body');

        let shadow = "0px 0px 5px 1px";

        if (this.formOpened) {
            $formMain.animate({
                left: that.formPrevious.left,
                top: that.formPrevious.top,
                width: 34,
                height: 34,
                'border-width': 0,
                'border-radius': 0
            }, {
                duration: transitionTime,
                complete: () => {
                    $formMain.css('z-index', '');

                    $formMain.css('left', '');
                    $formMain.css('top', '');
                    $formMain.css('width', '');
                    $formMain.css('height', '');
                    $formMain.css('box-shadow', '');

                    $body.css('overflow', '');
                    that.formOpened = false;
                },
                step: function(now, fx) {
                    if (fx.prop === "borderRadius") {
                        let v = (now / 10.0) * 0.25;
                        $formMain.css({
                            boxShadow: shadow + ' rgba(0,0,0,' + v + ')'
                        })
                    }
                }
            });

            $modal.animate({
                opacity: 0
            }, transitionTime, () => {
                $modal.css('display', 'none');
            });
            $form1.css('display', 'block');
            $form1.animate({
                opacity: 1
            }, transitionTime, () => {
            });
            $form2.css('display', 'block');
            $form2.animate({
                opacity: 0
            }, transitionTime, () => {
                $form2.css('display', 'none');
            });
        } else {
            $modal.css('display', 'block');
            $body.css('overflow', 'hidden');

            let o = $formMain.offset();
            that.formPrevious = {
                left: o.left,
                top: o.top
            };

            let $win = $(window);
            let bw = $win.width();
            let bh = $win.height();

            $formMain.css('z-index', 10);
            $formMain.animate({
                left: 20,
                top: 20 + $(window).scrollTop(),
                width: bw - 40,
                height: bh - 40,
                // 'border-width': 1,
                'border-radius': 10
            }, {
                duration: transitionTime,
                complete: () => {
                    that.formOpened = true;
                    $formMain.css({
                        boxShadow: shadow + ' rgba(0,0,0,0.25)'
                    })
                },
                step: function(now, fx) {
                    if (fx.prop === "borderRadius") {
                        let v = (now / 10.0) * 0.25;
                        $formMain.css({
                            boxShadow: shadow + ' rgba(0,0,0,' + v + ')'
                        })
                    }
                }
            });

            $modal.animate({
                opacity: 1
            }, transitionTime, () => {
            });
            $form1.css('display', 'block');
            $form1.animate({
                opacity: 0
            }, transitionTime, () => {
                $form1.css('display', 'none');
            });
            $form2.css('display', 'block');
            $form2.animate({
                opacity: 1
            }, transitionTime, () => {
            });
        }
    }

    // referralForms: Array<ReferralForm> = [ new ReferralForm() ];
    referralForms: Array<ReferralForm> = [ ];
    testsForms: Array<ReferralForm> = [ ];
    eyeExamForms: Array<ReferralForm> = [ ];
    vteForms: Array<VteForm> = [ ];
    checklistForms: Array<ChecklistForm> = [ ];
    noteForms: Array<NoteForm> = [ ];
    referralImageForms: Array<NoteForm> = [ ];
    tocForms: Array<NoteForm> = [ ];
    procedureSurgeryForms: Array<NoteForm> = [ ];

    documentAddMenuVisible = false;

    // noinspection all
    getMedicationsOcular(reverse=false) : Array<Medications> {
        let now = moment();
        let medications = [];
        _.forEach(this.referralForms, (form: ReferralForm) => {
            if (form.status !== "closed_dor") {
                _.forEach(form.medicationsOcular, (med: Medications) => {
                    let condition = now.isAfter(moment(med.sinceWhen)) && now.isBefore(moment(med.validTru));
                    if (reverse)
                        condition = !condition;
                    if (condition) {
                        // noinspection all
                        let medClone = _.cloneDeep(med);
                        medClone.when = form.when;
                        medClone.isRevers = reverse;
                        medications.push(medClone);
                    }
                });
            }
        });
        return medications;
    }
    // noinspection all
    getMedicationsSystemic(reverse=false) : Array<Medications> {
        let now = moment();
        let medications = [];
        _.forEach(this.referralForms, (form: ReferralForm) => {
            if (form.status !== "closed_dor") {
                _.forEach(form.medicationsSystemic, (med: Medications) => {
                    let condition = now.isAfter(moment(med.sinceWhen)) && now.isBefore(moment(med.validTru));
                    if (reverse)
                        condition = !condition;
                    if (condition) {
                        // noinspection all
                        let medClone = _.cloneDeep(med);
                        medClone.when = form.when;
                        medClone.isRevers = reverse;
                        medications.push(medClone);
                    }
                });
            }
        });
        return medications;
    }

    addPhoto() {
        if (this.patient) {
            // https://emeaimmd.merge.com/?name=Mackenzie%2cLeslie&dob=19380210&mrn=8888888888&mode=XC#!
            let url = "https://emeaimmd.merge.com/?name=" + this.patient.familyName + "%2c" + this.patient.firstName + "&dob=" + moment(this.patient.dob).format("YYYYMMDD") + "&mrn=" + this.patient.hospitalNumber + "&mode=XC#!"
            window.open(url);
            this.documentAddMenuVisible = false;
        }
    }

    addMedications() {
        let form = new ReferralForm();
        form.mode = 'medications';
        if (this.patient) {
            form.patientId = this.patient.hospitalNumber;
            form.patientDetails.familyName = this.patient.familyName;
            form.patientDetails.firstName = this.patient.firstName;
            form.patientDetails.title = this.patient.title;
            form.patientDetails.patientID = this.patient.hospitalNumber;
            form.patientDetails.nhsNumber = this.patient.nhsNumber;
            form.patientDetails.dob = moment(this.patient.dob).toDate();
            form.patientDetails.sex = this.patient.gender;
            form.patientDetails.verificationStatus = this.patient.nhsNumberVerifiedStatus;
            form.patientDetails.homeTelephone = this.patient.homePhone;
            form.patientDetails.workTelephone = this.patient.mobilePhone;
        }
        this._authService.getUser().then((v) => {
            form.userId = v.id;
            this.referralForms.push(form);
            this.documentAddMenuVisible = false;
            this.elementsRequireUpdate = true;
        });
    }
    addProcedure() {
        let procedure = new NoteForm();
        procedure.formName = 'procedure-surgery';
        if (this.patient) {
            procedure.patientId = this.patient.hospitalNumber;
        }
        this._authService.getUser().then((v) => {
            procedure.userId = v.id;
            this.procedureSurgeryForms.push(procedure);
            this.documentAddMenuVisible = false;
            this.elementsRequireUpdate = true;
        });
    }

    addReferral() {
        let form = new ReferralForm();
        if (this.patient) {
            form.patientId = this.patient.hospitalNumber;
            form.patientDetails.familyName = this.patient.familyName;
            form.patientDetails.firstName = this.patient.firstName;
            form.patientDetails.title = this.patient.title;
            form.patientDetails.patientID = this.patient.hospitalNumber;
            form.patientDetails.nhsNumber = this.patient.nhsNumber;
            form.patientDetails.dob = moment(this.patient.dob).toDate();
            form.patientDetails.sex = this.patient.gender;
            form.patientDetails.verificationStatus = this.patient.nhsNumberVerifiedStatus;
            form.patientDetails.homeTelephone = this.patient.homePhone;
            form.patientDetails.workTelephone = this.patient.mobilePhone;
        }
        this._authService.getUser().then((v) => {
            form.userId = v.id;
            this.referralForms.push(form);
            this.documentAddMenuVisible = false;
            this.elementsRequireUpdate = true;
        });
    }
    addEyeExam() {
        let form = new ReferralForm();
        form.formName = 'eye-exam';
        if (this.patient) {
            form.patientId = this.patient.hospitalNumber;
            form.patientDetails.familyName = this.patient.familyName;
            form.patientDetails.firstName = this.patient.firstName;
            form.patientDetails.title = this.patient.title;
            form.patientDetails.patientID = this.patient.hospitalNumber;
            form.patientDetails.nhsNumber = this.patient.nhsNumber;
            form.patientDetails.dob = moment(this.patient.dob).toDate();
            form.patientDetails.sex = this.patient.gender;
            form.patientDetails.verificationStatus = this.patient.nhsNumberVerifiedStatus;
            form.patientDetails.homeTelephone = this.patient.homePhone;
            form.patientDetails.workTelephone = this.patient.mobilePhone;
        }
        this._authService.getUser().then((v) => {
            form.userId = v.id;
            this.eyeExamForms.push(form);
            this.documentAddMenuVisible = false;
            this.elementsRequireUpdate = true;
        });

        return form;
    }
    addTests() {
        let tests = new ReferralForm();
        tests.formName = 'test';
        if (this.patient) {
            tests.patientId = this.patient.hospitalNumber;
        }
        this._authService.getUser().then((v) => {
            tests.userId = v.id;
            this.testsForms.push(tests);
            this.documentAddMenuVisible = false;
            this.elementsRequireUpdate = true;
        });
    }
    addReferralImage() {
        //clear previous Image Uploads
        this.referralImageForms = [];

        let note = new NoteForm();
        note.formName = 'referralImage';
        if (this.patient) {
            note.patientId = this.patient.hospitalNumber;
        }
        this._authService.getUser().then((v) => {
            note.userId = v.id;
            this.referralImageForms.push(note);
            this.documentAddMenuVisible = false;
            this.elementsRequireUpdate = true;
        });
    }
    cancelImageUpload() {
        this.referralImageForms = [];
        this.updateElements();
    }
    addClinicalNote() {
        let note = new NoteForm();
        if (this.patient) {
            note.patientId = this.patient.hospitalNumber;
        }
        this._authService.getUser().then((v) => {
            note.userId = v.id;
            this.noteForms.push(note);
            this.documentAddMenuVisible = false;
            this.elementsRequireUpdate = true;
        });
    }
    addTransferOfCare() {
        let note = new NoteForm();
        note.formName = 'toc';
        if (this.patient) {
            note.patientId = this.patient.hospitalNumber;
        }
        this._authService.getUser().then((v) => {
            note.userId = v.id;
            this.tocForms.push(note);
            this.documentAddMenuVisible = false;
            this.elementsRequireUpdate = true;
        });
    }
    addVTE() {
        let vte = new VteForm();
        if (this.patient) {
            vte.patientId = this.patient.hospitalNumber;
        }
        this._authService.getUser().then((v) => {
            vte.userId = v.id;
            this.vteForms.push(vte);
            this.documentAddMenuVisible = false;
            this.elementsRequireUpdate = true;
        });
    }
    addSafetyChecklist() {
        let checklist = new ChecklistForm();
        if (this.patient) {
            checklist.patientId = this.patient.hospitalNumber;
        }
        this._authService.getUser().then((v) => {
            checklist.userId = v.id;
            this.checklistForms.push(checklist);
            this.documentAddMenuVisible = false;
            this.elementsRequireUpdate = true;
        });
    }

    isNoteVisible(noteForm: NoteForm) {
        if (this.showDates)
            return true;
        return !/^%SUMMARY%$/gm.test(noteForm.note);
    }

    elements = null;
    elementsCache = [];
    elementsRequireUpdate = false;
    getAllDocuments() {
        if (!this.elementsRequireUpdate && this.elements)
            return this.elementsCache;

        this.elementsRequireUpdate = false;
        this.elements = [];

        let forms = [];
        forms = forms.concat(this.testsForms);
        forms = forms.concat(this.eyeExamForms);
        forms = forms.concat(this.referralForms);
        forms = forms.concat(this.procedureSurgeryForms);
        this._patientService.testForms = forms;
        // this._patientService.testForms = this.testsForms;

        _.forEach(this._patientDocuments, (p) => {
            this.elements.push({
                type: 'image',
                data: p,
                date: moment(p.startDate),
                title: '',
                imageUrl: (p.imageUrl),
                userThumbnailName: 'Mark', // FIXME: !!!!
                userThumbnailImage: '../../assets/img/users_photo/male/4.jpg' // FIXME: !!!!
            });
        });
        let dataToGetCount = this.referralForms.length;
        _.forEach(this.referralForms, (p) => {
            p.when = moment(p.when);

            let title = 'Referral';
            switch (p.status) {
                case "closed":
                    title = 'DOR: Referral';
                    break;
                case "closed_dor":
                    title = 'DOR: Referral';
                    break;
            }
            if (p.mode === 'medications') {
                title = 'Medications';
            }

            this._mockService.getData("currentUserMissingFields", p.userId).then((mockData) => {
                this.elements.push({
                    type: 'form',
                    data: p,
                    date: moment(p.when),
                    title: title,
                    userThumbnailName: mockData.firstName, // FIXME: !!!!
                    userThumbnailImage: '../../' + mockData.photo
                });
                dataToGetCount--;
                if (dataToGetCount === 0) {
                    // noinspection all
                    this.elements = _.sortBy(this.elements, [function (o) {
                        return o.date.valueOf();
                    }]);
                    _.reverse(this.elements);
                    this.setFilters();
                }
            });
        });

        let dataToGetCount8 = this.eyeExamForms.length;
        _.forEach(this.eyeExamForms, (p) => {
            p.when = moment(p.when);

            let title = 'Eye exam';
            switch (p.status) {
                case "closed":
                    title = 'DOR: Eye exam';
                    break;
                case "closed_dor":
                    title = 'DOR: Eye exam';
                    break;
            }

            this._mockService.getData("currentUserMissingFields", p.userId).then((mockData) => {
                this.elements.push({
                    type: 'eye-exam',
                    data: p,
                    date: moment(p.when),
                    title: title,
                    userThumbnailName: mockData.firstName, // FIXME: !!!!
                    userThumbnailImage: '../../' + mockData.photo
                });
                dataToGetCount8--;
                if (dataToGetCount8 === 0) {
                    // noinspection all
                    this.elements = _.sortBy(this.elements, [function (o) {
                        return o.date.valueOf();
                    }]);
                    _.reverse(this.elements);
                    this.setFilters();
                }
            });
        });

        let dataToGetCount7 = this.testsForms.length;
        _.forEach(this.testsForms, (p) => {
            p.when = moment(p.when);

            let title = 'Diagnostic Testing';
            switch (p.status) {
                case "closed":
                    title = 'DOR: Diagnostic Testing';
                    break;
                case "closed_dor":
                    title = 'DOR: Diagnostic Testing';
                    break;
            }

            this._mockService.getData("currentUserMissingFields", p.userId).then((mockData) => {
                this.elements.push({
                    type: 'tests',
                    data: p,
                    date: moment(p.when),
                    title: title,
                    userThumbnailName: mockData.firstName, // FIXME: !!!!
                    userThumbnailImage: '../../' + mockData.photo
                });
                dataToGetCount7--;
                if (dataToGetCount7 === 0) {
                    // noinspection all
                    this.elements = _.sortBy(this.elements, [function (o) {
                        return o.date.valueOf();
                    }]);
                    _.reverse(this.elements);
                    this.setFilters();
                }
            });
        });

        let dataToGetCount2 = this.noteForms.length;
        _.forEach(this.noteForms, (p) => {
            p.when = moment(p.when);

            let title = 'Clinical note';
            // switch (p.status) {
            // }

            this._mockService.getData("currentUserMissingFields", p.userId).then((mockData) => {
                this.elements.push({
                    type: 'note',
                    data: p,
                    date: moment(p.when),
                    title: title,
                    userThumbnailName: mockData.firstName, // FIXME: !!!!
                    userThumbnailImage: '../../' + mockData.photo
                });
                dataToGetCount2--;
                if (dataToGetCount2 === 0) {
                    // noinspection all
                    this.elements = _.sortBy(this.elements, [function (o) {
                        return o.date.valueOf();
                    }]);
                    _.reverse(this.elements);
                    this.setFilters();
                }
            });
        });

        let dataToGetCountReferralImage = this.referralImageForms.length;
        _.forEach(this.referralImageForms, (p) => {
            p.when = moment(p.when);

            let title = 'Fundus Image';
            // switch (p.status) {
            // }

            this._mockService.getData("currentUserMissingFields", p.userId).then((mockData) => {
                console.log(mockData);
                this.elements.push({
                    type: 'referralImage',
                    data: p,
                    date: moment(p.when),
                    title: title,
                    userThumbnailName: mockData.firstName, // FIXME: !!!!
                    userThumbnailImage: '../../' + mockData.photo
                });
                dataToGetCountReferralImage--;
                if (dataToGetCountReferralImage === 0) {
                    // noinspection all
                    this.elements = _.sortBy(this.elements, [function (o) {
                        return o.date.valueOf();
                    }]);
                    _.reverse(this.elements);
                    this.setFilters();
                }
            });
        });

        let dataToGetCount12 = this.procedureSurgeryForms.length;
        _.forEach(this.procedureSurgeryForms, (p) => {
            p.when = moment(p.when);

            let title = 'Procedure';
            // switch (p.status) {
            // }

            this._mockService.getData("currentUserMissingFields", p.userId).then((mockData) => {
                this.elements.push({
                    type: 'procedure-surgery',
                    data: p,
                    date: moment(p.when),
                    title: title,
                    userThumbnailName: mockData.firstName, // FIXME: !!!!
                    userThumbnailImage: '../../' + mockData.photo
                });
                dataToGetCount12--;
                if (dataToGetCount12 === 0) {
                    // noinspection all
                    this.elements = _.sortBy(this.elements, [function (o) {
                        return o.date.valueOf();
                    }]);
                    _.reverse(this.elements);
                    this.setFilters();
                }
            });
        });

        let dataToGetCountTOC = this.tocForms.length;
        _.forEach(this.tocForms, (p) => {
            p.when = moment(p.when);

            let title = 'Transfer of care';
            // switch (p.status) {
            // }

            this._mockService.getData("currentUserMissingFields", p.userId).then((mockData) => {
                this.elements.push({
                    type: 'toc',
                    data: p,
                    date: moment(p.when),
                    title: title,
                    userThumbnailName: mockData.firstName, // FIXME: !!!!
                    userThumbnailImage: '../../' + mockData.photo
                });
                dataToGetCountTOC--;
                if (dataToGetCountTOC === 0) {
                    // noinspection all
                    this.elements = _.sortBy(this.elements, [function (o) {
                        return o.date.valueOf();
                    }]);
                    _.reverse(this.elements);
                    this.setFilters();
                }
            });
        });

        let dataToGetCount3 = this.vteForms.length;
        _.forEach(this.vteForms, (p) => {
            p.when = moment(p.when);

            let title = 'VTE';
            // switch (p.status) {
            // }

            this._mockService.getData("currentUserMissingFields", p.userId).then((mockData) => {
                this.elements.push({
                    type: 'vte',
                    data: p,
                    date: moment(p.when),
                    title: title,
                    userThumbnailName: mockData.firstName, // FIXME: !!!!
                    userThumbnailImage: '../../' + mockData.photo
                });
                dataToGetCount3--;
                if (dataToGetCount3 === 0) {
                    // noinspection all
                    this.elements = _.sortBy(this.elements, [function (o) {
                        return o.date.valueOf();
                    }]);
                    _.reverse(this.elements);
                    this.setFilters();
                }
            });
        });

        let dataToGetCount4 = this.checklistForms.length;
        _.forEach(this.checklistForms, (p) => {
            p.when = moment(p.when);

            let title = 'Surgical Safety Checklist';
            // switch (p.status) {
            // }

            this._mockService.getData("currentUserMissingFields", p.userId).then((mockData) => {
                this.elements.push({
                    type: 'checklist',
                    data: p,
                    date: moment(p.when),
                    title: title,
                    userThumbnailName: mockData.firstName, // FIXME: !!!!
                    userThumbnailImage: '../../' + mockData.photo
                });
                dataToGetCount4--;
                if (dataToGetCount4 === 0) {
                    // noinspection all
                    this.elements = _.sortBy(this.elements, [function (o) {
                        return o.date.valueOf();
                    }]);
                    _.reverse(this.elements);
                    this.setFilters();
                }
            });
        });

        return this.elementsCache;
    }

    filterElements(e) {
        this.filterValue = e;
    }

    // noinspection all
    getElementId(element) {
        if (element.type === "image") {
            return element.data.id;
        } else {
            if (element.data._id)
                return element.data._id;
            else {
                if (!element.temp_id) {
                    element.temp_id = _.uniqueId('temp_id');
                }
                return element.temp_id;
            }
        }
    }
    findElementById(elements, element) {
        let founded = null;
        let eId = this.getElementId(element);
        _.forEach(elements, (e) => {
            if (this.getElementId(e) === eId) {
                founded = e;
            }
        });

        return founded;
    }

    invalidateCache() {
        // this.elementsCache = this.elements;

        if (this.elementsCache && this.elements) {
            // first remove all from cache which are not present in elements !
            let elementsToRemove = [];
            _.forEach(this.elementsCache, (element) => {
                let foundedElement = this.findElementById(this.elements, element);
                if (!foundedElement) {
                    elementsToRemove.push(element);
                }
            });
            _.forEach(elementsToRemove, (element) => {
                _.remove(this.elementsCache, function(n) {
                    return element === n;
                });
            });

            let elementsPlus = [];
            // rest need simply to be updated or added!
            _.forEach(this.elements, (element) => {
                let foundedElement = this.findElementById(this.elementsCache, element);
                if (!foundedElement) {
                    elementsPlus.push(element);
                } else {
                    foundedElement.data = element.data;
                    elementsPlus.push(foundedElement);
                }
            });

            //If there is Image Upload- move it to the top
            let i = elementsPlus.findIndex(e => e.type == 'referralImage');
            if (i >= 0) {
                let imageElement = elementsPlus.splice(i, 1)[0];
                elementsPlus.splice(0, 0, imageElement);
            }

            this.elementsCache = elementsPlus;
        }
    }

    setFilters() {
        let filter = ["All"];
        _.forEach(this.elements, (element) => {
            let t = this.getTitle(element);
            if (_.indexOf(filter, t) === -1) {
                filter.push(t);
            }
        });
        this.filterValues = filter;

        this.invalidateCache();
    }

    private getTitle(element) {
        return element.title && element.title.length > 0 ? element.title : 'Image';
    }

    addElement(w) {
        this.referralForms.push(w);
        this.updateElements();
    }

    addTestsElement(w) {
        this.testsForms.push(w);
        this.updateElements();
    }

    addEyeExamElement(w) {
        this.eyeExamForms.push(w);
        this.updateElements();
    }

    addVteElement(w) {
        this.vteForms.push(w);
        this.updateElements();
    }

    formatDateToJUMP(d) {
        if (d) {
            return moment(d).format("YYYYMMDD");
        }
        return '';
    }

    updateElements() {
        this.elementsRequireUpdate = true;
    }

    cancelElement(element) {
        this.updateElements();
    }

    removeElement(element) {
        _.remove(this.referralForms, (v) => {
            return v === element;
        });
        _.remove(this.eyeExamForms, (v) => {
            return v === element;
        });
        this.updateElements();
    }

    removeTestElement(element) {
        _.remove(this.testsForms, (v) => {
            return v === element;
        });
        this.updateElements();
    }

    removeVte(element) {
        _.remove(this.vteForms, (v) => {
            return v === element;
        });
        this.updateElements();
    }

    removeCL(element) {
        _.remove(this.checklistForms, (v) => {
            return v === element;
        });
        this.updateElements();
    }

    removeNote(note) {
        _.remove(this.noteForms, (v) => {
            return v === note;
        });
        _.remove(this.tocForms, (v) => {
            return v === note;
        });
        this.updateElements();
    }

    removeProcedure(note) {
        _.remove(this.procedureSurgeryForms, (v) => {
            return v === note;
        });
        this.updateElements();
    }

    getLatestDiagnosis() {
        let diagnose = undefined;

        _.forEach(this.referralForms, (f: ReferralForm) => {
            if (f.diagnose.diagnosis && f.diagnose.diagnosis.length > 0) {
                if (!diagnose) {
                    diagnose = f.diagnose.diagnosis;
                } else {
                    diagnose += ", " + f.diagnose.diagnosis;
                }
            }
        });

        return diagnose;
    }

    refresher : boolean = false;

    refreshGraphComponent() {
        this.refresher = !this.refresher;
    }

    updateDiagnoseElements(e, document) {
        //Fetch and update customForms
        if (e == 'referrals') {
            let filter = {
                patientId: this.patient.patientID
            };
            this._apiService.post('customdata/filter?collectionName=customForms', filter).then(
                (response) => {
                    //Fetch recently created Referral and append to array
                    if (response[response.length-1]) {
                        this.referralForms.push(response[response.length-1]);
                        this.updateElements();
                    }
                }, (err) => {
                    console.log(err);
                }
            );
        //Fetch changed document and update it's data
        } else if (e == 'document') {
            this._documentsService.getDocumentByID(this.patient.patientID, document.data.id).then(
                (response)=> {
                    //Search for this document and if exists- update it's value
                    let foundIndex = this._patientDocuments.findIndex(item => item.id == document.data.id);
                    if (foundIndex >= 0) {
                        this._patientDocuments[foundIndex] = response;
                        this.updateElements();
                    }
                }, (err) => {
                    console.log(err);
                }
            );
        }
    }
}

import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../services/auth.service";
import {RootShareService} from "../../../../services/root.shared.service";
import {GPDetailsType} from "../../../../models/forms/referral/gp.details.type";
import {PatientHistory} from "../../../../models/forms/referral/patient.history";
import {PatientDetails} from "../../../../models/forms/referral/patient.details";

@Component({
    selector: 'patient-history-component',
    templateUrl: './patient.history.html'
})
export class PatientHistoryComponent {
    @Input() patientHistory: PatientHistory;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

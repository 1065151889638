import {ApiService} from "./api.service";
import {Injectable} from "@angular/core";
/**
 * Created by Jacek on 02.03.2017.
 */


class FormRenderRequest  {
    formName:string;
    formData:string;
    urlOnly:boolean;
}

@Injectable()
export class FormsService {

    constructor(private _apiService: ApiService) {
    }

    render(formName:string, formData:string, urlOnly:boolean): Promise<string>  {
        return new Promise((resolve,reject) => {
            let formRenderRequest:FormRenderRequest = new FormRenderRequest();
            formRenderRequest.formName = formName;
            formRenderRequest.formData = formData;
            formRenderRequest.urlOnly = urlOnly;
            this._apiService.post("forms/render", formRenderRequest).then(
                (result) => { resolve(result); },
                (fail) => {
                    reject(false);
                })
        })
    }
}
export class ExternalExam {
    testName = "ExternalExam";

    descriptionOD: string;
    descriptionOS: string;
    faceOD: string;
    faceOS: string;
    lidsLashesOD: string;
    lidsLashesOS: string;
    lacrimalOD: string;
    lacrimalOS: string;
    orbitOD: string;
    orbitOS: string;

    public constructor() {
        this.descriptionOD = null;
        this.descriptionOS = null;
        this.faceOD = null;
        this.faceOS = null;
        this.lidsLashesOD = null;
        this.lidsLashesOS = null;
        this.lacrimalOD = null;
        this.lacrimalOS = null;
        this.orbitOD = null;
        this.orbitOS = null;
    }
}

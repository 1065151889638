import {ChangeDetectorRef, Component} from '@angular/core';
import * as moment from "moment";
import * as $ from "jquery";
import * as _ from "lodash";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Consultant} from "../../utils/definitions/Consultant";
import {Clinic} from "../../utils/definitions/Clinic";
import {ClinicAppointmentInfo, Patient, Place} from "../../utils/definitions/Patient";
import {DaysFilter} from "../../models/daysFilter.model";
import {AttendanceService} from "../../services/attendances.service";
import {DictionaryService} from "../../services/dict.service";
import {SearchDictionary, dictionaryFilterType, dictionaryFilter} from "../../models/dict.utils.model";
import {DictionaryItem} from "../../utils/definitions/Dictionary";
import {MainService} from "../../services/main.service";
import {ApiService} from "../../services/api.service";
import {MainGroup, SubGroup, DropdownSearchItem, FetchAttendancesFilter} from "../clinics/clinics.module";
import {Moment} from "moment";
import {start} from "repl";

export class AvTimes {
    public datetime: moment.Moment;
    public available: boolean;
    public clinicName: string;
    public selected: boolean;

    constructor(dt: moment.Moment, av: boolean, cn:string){
        this.datetime = dt;
        this.available = av;
        this.clinicName = cn;
    }
}

@Component({
    templateUrl: './appointments.module.html',
    styleUrls: ['./appointments.module.scss']
})
export class AppointmentsModule {
    public _selectDictionary: Array<any> = [];
    public _consultantsDictionary: Array<any> = [];
    public _selectDictionaryLabel: string = "";
    public _selectDictionaryDefaultText: string = "";
    public _selectDictionaryName: string;
    public _selectDictionaryConsultant: string;
    public _listHeaderName: string;
    public _noResults: string;
    public _loadingText: string;
    public _pickCriteria: string;
    public _propertyOrderName: string;
    public _propertyOrderNameAll: string;
    public _propertyView: string;
    public _propertySortName: string;
    public startDate: moment.Moment;
    public selectedDate: string;
    public selectedClinicsList: MainGroup = new MainGroup();
    public selectedClinic: string = "private";

    // public tableName: string;
    // public resultsTime: number = 0.06;
    // public resultsCount: number = 0;
    // public results: Array<any> = [];
    // public loading: boolean = false;
    // public activePage: number = 1;
    //
    public _attendances: any = {};
    // public totalResultsCount: number = -1;
    // public resultTime: number = 0;
    // public columnsConfig: Array<DDItems>;
    // public columnsConfigAll: Array<DDItems>;

    public _mainGroups: Array<MainGroup> = [];

    public _listType: string;
    public _additionalFilterOptions: FetchAttendancesFilter = new FetchAttendancesFilter();
    public _defaultOpenPatient: string;
    public _defaultOpenPatientPath: string = "patientRecord.defaultView";
    public _queryParams : any = {};
    public daysFilter: DaysFilter = new DaysFilter();

    public dictionaryItemConsultant: DictionaryItem = new DictionaryItem ();

    public _columnsOrder: Array<number>;
    public _columnsOrderAll: Array<number>;

    public listConfigs: any;

    public viewBySpecialty: boolean = true;
    public subGroupsHeader: boolean = true;
    public isParams: boolean = false;


    public dictionaryItemClinic: DictionaryItem = new DictionaryItem ();
    public activeDates: Array<moment.Moment> = [];

    public actualMonthInLeftPosition: boolean = false;
    public showAll: boolean = false;

    public showCalendar: boolean = true;

    constructor(private _router:Router, private _route: ActivatedRoute, private _attendanceService: AttendanceService,
                private _dictionaryService:DictionaryService, private _mainService:MainService, private _api: ApiService){
        this._listType = "O";
        this.selectedClinicsList = new MainGroup();
        this.selectedClinicsList.subGroups = [];
    }

    ngOnInit() {
        this.startDate = moment.utc(moment().format('YYYY-MM'));
        let _startSearchTime = moment();

        let tmpSearchDictConsultants = new SearchDictionary();
        tmpSearchDictConsultants.name = 'Consultants';
        tmpSearchDictConsultants.displayName = 'Consultants';
        tmpSearchDictConsultants.sort = Consultant.getDefaultSort();
        this._consultantsDictionary.push(tmpSearchDictConsultants);

        let tmpSearchDictClinics = new SearchDictionary();
        tmpSearchDictClinics.name = 'Clinics';
        tmpSearchDictClinics.displayName = 'Clinics';
        tmpSearchDictClinics.sort = Clinic.getDefaultSort();
        this._selectDictionary.push(tmpSearchDictClinics);

        this._route.params.subscribe((params:Params) => {
            this.selectedClinic = params.kind;
            //this.buildActiveDates(params.kind=='private');
          this.bildForActualMonth(params.kind=='private');
        });

        this._route.data.subscribe(data => {
            this.patient = data.patient;
        });

        // this._route.queryParams.subscribe((params: Params) => {
        //     let consultantFilter = params['consultant'];
        //     let unitFilter = params['unit'];
        //     let dataFilter = params['date'];
        //     let fromFilter = params['from'];
        //     let toFilter = params['to'];
        //     let all = params['all'];
        //
        //     this._queryParams = {};
        //
        //     if (dataFilter) {
        //         this.startDate =  moment(dataFilter, 'YYYY-MM-DD');
        //     }
        //
        //     if (fromFilter) {
        //         this._queryParams.from = fromFilter;
        //         this._queryParams.to = toFilter;
        //
        //         this.daysFilter.setValue(fromFilter, toFilter);
        //     }
        //
        //     if (consultantFilter) {
        //         this._queryParams.consultant = consultantFilter;
        //         this._additionalFilterOptions.consultantId = consultantFilter;
        //         this._dictionaryService.getConsult(consultantFilter).then((response) => {
        //                 this.dictionaryItemConsultant = response;
        //
        //                 this._selectDictionary =[];
        //
        //                 let tmpSearchDictClinics = new SearchDictionary();
        //                 tmpSearchDictClinics.name = 'Clinics filtered by: '+ this.dictionaryItemConsultant.name;
        //                 tmpSearchDictClinics.displayName = 'Clinics';
        //                 tmpSearchDictClinics.sort = Clinic.getDefaultSort();
        //
        //                 let _startDay =  this.daysFilter.firstDay < this.startDate.format("YYYY-MM-DD")? moment(this.daysFilter.firstDay):moment(this.startDate);
        //                 let _endDay =  this.daysFilter.lastDay > moment(this.startDate).add(3, 'month').format("YYYY-MM-DD")? moment(this.daysFilter.lastDay).add(1, 'days'):moment(this.startDate).add(3, 'month');
        //
        //                 tmpSearchDictClinics.filter =
        //                     new dictionaryFilter(dictionaryFilterType.consultant,consultantFilter,
        //                         _startDay.format("YYYY-MM-DD"),_endDay.format("YYYY-MM-DD"));
        //                 this._selectDictionary.push(tmpSearchDictClinics);
        //             }
        //         );
        //
        //
        //     } else {
        //         this._selectDictionary =[];
        //         this._additionalFilterOptions.consultantId = null;
        //         let tmpSearchDictClinics = new SearchDictionary();
        //         tmpSearchDictClinics.name = 'Clinics';
        //         tmpSearchDictClinics.displayName = 'Clinics';
        //         tmpSearchDictClinics.sort = Clinic.getDefaultSort();
        //         this._selectDictionary.push(tmpSearchDictClinics);
        //     }
        //
        //     if (unitFilter) {
        //         this._queryParams.unit = unitFilter;
        //         this._additionalFilterOptions.clinicCode = unitFilter;
        //         this._dictionaryService.getClinic(unitFilter).then((response) => {
        //                 this.dictionaryItemClinic = response;
        //
        //                 this._consultantsDictionary =[];
        //                 let tmpSearchDictConsultants = new SearchDictionary();
        //                 tmpSearchDictConsultants.name = 'Consultants filtered by: '+ this.dictionaryItemClinic.name;
        //                 tmpSearchDictConsultants.displayName = 'Consultants';
        //                 tmpSearchDictConsultants.sort = Consultant.getDefaultSort();
        //
        //                 let _startDay =  this.daysFilter.firstDay < this.startDate.format("YYYY-MM-DD")? moment(this.daysFilter.firstDay):moment(this.startDate);
        //                 let _endDay =  this.daysFilter.lastDay > moment(this.startDate).add(3, 'month').format("YYYY-MM-DD")? moment(this.daysFilter.lastDay).add(1, 'days'):moment(this.startDate).add(3, 'month');
        //
        //
        //                 tmpSearchDictConsultants.filter =
        //                     new dictionaryFilter(dictionaryFilterType.clinic, unitFilter,
        //                         _startDay.format("YYYY-MM-DD"),_endDay.format("YYYY-MM-DD"));
        //
        //                 this._consultantsDictionary.push(tmpSearchDictConsultants);
        //             }
        //         );
        //
        //     } else {
        //         this._consultantsDictionary =[];
        //         let tmpSearchDictConsultants = new SearchDictionary();
        //         tmpSearchDictConsultants.name = 'Consultants';
        //         tmpSearchDictConsultants.displayName = 'Consultants';
        //         tmpSearchDictConsultants.sort = Consultant.getDefaultSort();
        //         this._consultantsDictionary.push(tmpSearchDictConsultants);
        //
        //         this._additionalFilterOptions.clinicCode = null;
        //     }
        //     if (all) {
        //         this._queryParams.all = all;
        //         if (all == 'yes') this.showAll = true;
        //         else this.showAll = false;
        //     }
        //
        //
        //     if (!this.daysFilter.isSet && this.startDate.format("YYYY-MM") ==  moment().format("YYYY-MM"))
        //         this.actualMonthInLeftPosition = true;
        //     else
        //         this.actualMonthInLeftPosition = false;
        //
        //     this._queryParams.date = this.startDate.format("YYYY-MM-DD");
        //
        //     if (this._additionalFilterOptions.clinicCode == null && this._additionalFilterOptions.consultantId == null) {
        //         this.daysFilter = new DaysFilter();
        //         this._queryParams.from = null;
        //         this._queryParams.to = null;
        //         this._queryParams.date = null;
        //         this.startDate = moment.utc(moment().format('YYYY-MM'));
        //     }
        //     this.searchedByValue();
        // });
    }

    buildActiveDates(priv:boolean) {
        this.activeDates = [];
        if(priv) {
            // demo was 2018-07-04 / 2018-07-05 !!!
            this.activeDates.push(moment('2018-10-09'));
            this.activeDates.push(moment('2018-10-11'));
            this.activeDates.push(moment('2018-10-12'));
            this.activeDates.push(moment('2018-10-13'));
            this.activeDates.push(moment('2018-10-19'));
            this.activeDates.push(moment('2018-10-20'));
            this.activeDates.push(moment('2018-10-21'));
            this.activeDates.push(moment('2018-10-23'));
            this.activeDates.push(moment('2018-10-25'));
            this.activeDates.push(moment('2018-10-27'));
            this.activeDates.push(moment('2018-10-28'));
            this.activeDates.push(moment('2018-10-30'));

            this.activeDates.push(moment('2018-11-01'));
            this.activeDates.push(moment('2018-11-02'));
            this.activeDates.push(moment('2018-11-03'));
            this.activeDates.push(moment('2018-11-06'));
            this.activeDates.push(moment('2018-11-07'));
            this.activeDates.push(moment('2018-11-09'));
            this.activeDates.push(moment('2018-11-10'));
            this.activeDates.push(moment('2018-11-13'));
            this.activeDates.push(moment('2018-11-16'));
            this.activeDates.push(moment('2018-11-17'));
            this.activeDates.push(moment('2018-11-20'));
        }
        this.activeDates.push(moment('2018-11-21'));
        this.activeDates.push(moment('2018-11-22'));
        this.activeDates.push(moment('2018-11-24'));
        this.activeDates.push(moment('2018-11-27'));
        this.activeDates.push(moment('2018-11-29'));
        this.activeDates.push(moment('2018-11-30'));

        this.activeDates.push(moment('2018-12-04'));
        this.activeDates.push(moment('2018-12-05'));
        this.activeDates.push(moment('2018-12-06'));
        this.activeDates.push(moment('2018-12-07'));
        this.activeDates.push(moment('2018-12-10'));
        this.activeDates.push(moment('2018-12-11'));
        this.activeDates.push(moment('2018-12-12'));
        this.activeDates.push(moment('2018-12-13'));
        this.activeDates.push(moment('2018-12-17'));
        this.activeDates.push(moment('2018-12-18'));
        this.activeDates.push(moment('2018-12-20'));
        this.activeDates.push(moment('2018-12-21'));
        this.activeDates.push(moment('2018-12-24'));
        this.activeDates.push(moment('2018-12-26'));
        this.activeDates.push(moment('2018-12-27'));
        this.activeDates.push(moment('2018-12-28'));
        if(!priv){
            this.calendarChooseDate(moment('2018-11-21'));
        } else {
            this.calendarChooseDate(moment('2018-10-09'));
        }
    }

    bildForActualMonth(isPriv: boolean){
      let today = moment();
      let actMonth = moment({day: 1 , month:today.month(), year:today.year()});
      this.activeDates = [];

      if(isPriv) {
        //for private add 4 days and find first not free day
        today.add(3, "days");
      } else {
        //for not private add 24 days and find first not free day
        today.add(24, "days");
      }
      today = this.ommitFreeDayes(today);
      if(actMonth.month() == today.month()){
        this.activeDates = this.activeDates.concat(this.bildForNextMonth(isPriv, moment(actMonth).add(1, "month")));
      }
      this.activeDates = this.activeDates.concat(this.bildForNextMonth(isPriv, moment(today)));
      this.activeDates = this.activeDates.concat(this.bildForNextMonth(isPriv,  moment(actMonth).add(2, "month")));
      //set first possible
      this.calendarChooseDate(today);
    }

    bildForNextMonth(priv: boolean, startDate: moment.Moment) {
      let nextDays = [];
      let resultsDates = [];
      let month = startDate.month();
      let nextDayIterator = 0;
      if (priv) {
        nextDays = [2, 1, 1, 3, 2, 2, 1, 2, 1, 4, 2, 1, 3, 2, 2, 3, 1];
      } else {
        nextDays = [6, 4, 5, 7, 8, 7];
      }
      startDate = this.ommitFreeDayes(startDate);
      // we get first day, at least first monday
      while(startDate.month() == month) {
        resultsDates.push(moment(startDate));
        if(nextDayIterator < nextDays.length){
          startDate.add(nextDays[nextDayIterator], "days");
          nextDayIterator++;
          startDate = this.ommitFreeDayes(startDate);
        } else {
          month = 13;
        }
      }
      return resultsDates;
    }

    ommitFreeDayes(date:moment.Moment): moment.Moment {
      if (date.weekday() == 6) {
        date.add(1, "days");
      }
      if (date.weekday() == 5) {
        date.add(1, "days");
      }
      return date;
    }

    showHideCalendar() {
        this.showCalendar = !this.showCalendar;
    }

    calendarChooseDate(date: moment.Moment) {
        this.selectedDate = date.format("YYYY-MM-DD");
        //this.selectByDate();
        let dateActive = false;
        this.activeDates.forEach((ad) => {
            if(ad.isSame(date, "day")){
                dateActive= true;
            }
        });
        this.avTimes1 = [];
        this.avTimes2 = [];
        this.avTimes3 = [];
        let d = moment(date.format("YYYY-MM-DD"));
        d.hours(9).minutes(0);
        for(let i=0; i<19; i++){
            let active1 = dateActive;
            let active2 = dateActive;
            let active3 = dateActive;
            if (i == 2 || i == 8 || i == 9 || i == 10 || i == 17 || i == 18) {
                active1 = false;
            }
            this.avTimes1.push(new AvTimes(moment(d), active1, "Southampton General Hospital   OPHTHALMOLOGY GA ASSESSMENT CLINIC 0PCOPHGA"));
            if (i == 6 || i == 13 || i == 16 || i == 17 || i == 18) {
                active2 = false;
            }
            this.avTimes2.push(new AvTimes(moment(d), active2, "The Royal Bournemouth and Christchurch Hospitals   NEWMEDICA OPHTHALMOLOGY GA ASSESSMENT CLINIC NMCABH1A"));
            if (i == 1 || i == 2 || i == 13) {
                active3 = false;
            }
            this.avTimes3.push(new AvTimes(moment(d), active3, "Newmedica Eye Health Clinic Portsmouth NMA123"));
            d.add(30, "minutes");
        }
    }

    getDate(additionalMonth?: number): string {
        let date = moment(this.startDate);
        if (additionalMonth) {
            date.add(additionalMonth, 'month');
        }
        return date.format('YYYY-MM');

    }

    changeStartMonth(change: number) {
        this.startDate.add(change, 'month');

        let o : any = {};
        _.extend(o, this._queryParams);

        o['date'] = this.startDate.format('YYYY-MM-DD');

        this._router.navigate([], { queryParams: o });

    }

    setStartDay(day: moment.Moment){

        let pickedDay = day.format("YYYY-MM-DD");
        let defaultDay = day.add(2, 'days').format("YYYY-MM-DD");
        let firstDay = this.daysFilter.firstDay;
        let lastDay = this.daysFilter.lastDay;

        if (this.daysFilter.isSet) {
            if(this.daysFilter.isCorrectFirstDay(pickedDay)){
                firstDay = pickedDay;
            } else {
                console.log("Incorrect selected date range");
            }
        } else {
            firstDay = pickedDay;
            lastDay = defaultDay;
        }

        let o : any = {};
        _.extend(o, this._queryParams);

        o['from'] = firstDay;
        o['to'] = lastDay;

        this._router.navigate([], { queryParams: o });
    }

    setEndDay(day: moment.Moment){
        let pickedDay = day.format("YYYY-MM-DD");
        let defaultDay = day.add(-2, 'days').format("YYYY-MM-DD");
        let firstDay = this.daysFilter.firstDay;
        let lastDay = this.daysFilter.lastDay;

        if (this.daysFilter.isSet) {
            if(this.daysFilter.isCorrectLastDay(pickedDay)){
                lastDay = pickedDay;
            } else {
                console.log("Incorrect selected date range");
            }
        } else {
            firstDay = defaultDay;
            lastDay = pickedDay;
        }

        let o : any = {};
        _.extend(o, this._queryParams);

        o['from'] = firstDay;
        o['to'] = lastDay;

        this._router.navigate([], { queryParams: o });
    }

    dayMatchesToFilter(data: string): boolean{
        if (this.actualMonthInLeftPosition && !this.showAll ) {
            if (data < moment().format("YYYY-MM-DD")) return false;
            else return true;
        } else {
            return true;
        }
    }

    removeCalendarFilter(){
        this.daysFilter = new DaysFilter();
        this._queryParams.from = null;
        this._queryParams.to = null;
        this._router.navigate([], { queryParams: this._queryParams });
    }

    public sortAction(sortedColumn) {
        //this.saveSortedColumnsToStorage(ClinicsModule.sortColumnsStorageKey);
    }

    getPropertyName() {
        return this._propertyOrderName;
    }

    // selectByDate() {
    //     if(!this.selectedDate){
    //         this.selectedDate = moment().format("YYYY-MM-DD");
    //     }
    //     this.selectedClinicsList = _.find(this._mainGroups, {"id": this.selectedDate});
    //     if(this.selectedClinicsList == undefined){
    //         this.selectedClinicsList = new MainGroup();
    //         this.selectedClinicsList.subGroups = [];
    //     }
    // }

    setSearchedValue(item: any, searchTypeName: string):void {
        let paramName = "unit";
        let paramValue = null;
        switch (searchTypeName) {
            case 'Select':
                paramValue = item ? item.code : null;
                break;
            case 'Clinic':
                paramValue = item ? item.code : null;
                break;
            case 'Consultant':
                paramName = "consultant";
                paramValue = item ? item.id : null;
                break;
        }
        let o : any = {};
        _.extend(o, this._queryParams);

        if (paramValue)
            o[paramName] = paramValue;
        else
            delete o[paramName];
        this._router.navigate([], { queryParams: o })
    }

    public getBaseValueConsultant():any {
        if (this._additionalFilterOptions.consultantId != null) {
            return this.dictionaryItemConsultant;
        }else{
            return "";
        }
    }

    public getBaseValueClinic():any {
        if (this._additionalFilterOptions.clinicCode != null) {
            return this.dictionaryItemClinic;
        }else{
            return "";
        }
    }

    getDateString(date:string):string {
        if(date){
            let dateIn = moment(date);
            return dateIn.format('LT');
        } else {
            return "";
        }
    }

    getHours(date:string):string {
        if(date){
            let dateIn = moment(date);
            return dateIn.format('DD MMM YYYY');
        } else {
            return "";
        }
    }

    goToPatient(patientHospitalNumber: string) {
        this._router.navigate((['patient', patientHospitalNumber, 'timeline']));
    }

    getFormattedTime(t:AvTimes):string {
        if(t.datetime) {
            return t.datetime.format("HH:mm a, ddd D MMM YYYY");
        } else {
            "";
        }
    }

    changeKind(k:string) {
        this._router.navigate((['appointments', this.patient.__id,k]));
    }

    chooseTime(t: AvTimes, k:number){
        if(t.available) {
            this.avTimes1.forEach((el) => {
                if (el == t) {
                    el.selected = true;
                } else {
                    el.selected = false;
                }
            });
            this.avTimes2.forEach((el) => {
                if (el == t) {
                    el.selected = true;
                } else {
                    el.selected = false;
                }
            });
            this.avTimes3.forEach((el) => {
                if (el == t) {
                    el.selected = true;
                } else {
                    el.selected = false;
                }
            });
            this.selectedTime = t;
        } else {
            this.selectedTime = null;
        }

        this.bookActive1 = false;
        this.bookActive2 = false;
        this.bookActive3 = false;
        this['bookActive'+k] = t.available;
    }

    bookTime(){
        if(this.selectedTime != null){
            this.showModal1 = true;
        }
    }

    closeModal1(){
        this.showModal1 = false;
        this.showModal2 = false;
    }

    confirmBtnClick(){
        this.showModal1 = false;
        this.showModal2 = true;
    }

    printClick() {
        //get pdf document
        window.open("../../../assets/eyecare.pdf", 'Download');
        this.closeModal1();
    }

    emailClick() {
        //send email action;
        this.closeModal1();
    }

    public showModal1: boolean = false;
    public showModal2: boolean = false;

    public selectedTime: AvTimes;

    public bookActive1: boolean = false;
    public bookActive2: boolean = false;
    public bookActive3: boolean = false;
    public avTimes1: Array<AvTimes> = [];
    public avTimes2: Array<AvTimes> = [];
    public avTimes3: Array<AvTimes> = [];

    public patient: Patient;
}
import {Component, Input} from '@angular/core';

@Component({
  selector: 'user-thumbnail',
  templateUrl: './user-thumbnail.component.html'
})
export class UserThumbnailComponent {
  @Input() name: string = '';
  @Input() title: string = '';
  @Input() image: string = '';
  @Input() imageSize: string = '40px';
  @Input() imageSide: string = 'right';
}

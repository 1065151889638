import {AfterViewInit, Component, EventEmitter, Input, Output, NgZone} from '@angular/core';
import {RootShareService} from "../../services/root.shared.service";
import {Http, Headers} from "@angular/http";
import {WatsonService} from "../../services/watson.service";
const watson = require('../../../js/watson-speech.js');

@Component({
    selector: 'watson-mic',
    templateUrl: './speech_to_text_element.html',
    styleUrls: ['./speech_to_text.element.scss']

})
export class STTElement {
    private micReady: boolean = false;
    private newMessageText: string;
    private stream:any;
    @Output() newMessage: EventEmitter<string> = new EventEmitter();

    constructor(private _rootShareService: RootShareService, private _ngZone:NgZone, private _watsonService: WatsonService) {
    }

    onMicClick(event){
        this._watsonService.getToken().then((token)=>{
            try {
                this.stream = watson.SpeechToText.recognizeMicrophone({
                    access_token: token,
                    outputElement: false, // may use CSS selector or DOM Element
                    url: "https://gateway-wdc.watsonplatform.net/speech-to-text/api"
                });
                this.stream.setEncoding('utf8'); // get text instead of Buffers for on data events

                this.micReady = true;
                this.stream.on('data', (data) => {
                    console.log("WS data");
                    this.stream.stop();
                    this._ngZone.run(() => {
                        this.micReady = false;
                        this.stream = null;
                        if (data) {
                            this.newMessage.emit(data);
                        }
                    });
                });

                this.stream.on('error', (err) => {
                    console.log("WS error");
                    console.error(err);
                    this._ngZone.run(() => {
                        this.micReady = false;
                        this.stream = null;
                    });
                });

            } catch (ex) {
                console.error(ex);
                this.stream = null;
                this._ngZone.run(()=>{
                    this.micReady = false;
                });
            }
        });
    }
}

import * as moment from "moment";

export class OptomDetails {
    title: string;
    initials: string;
    familyName: string;
    GOCNumber: string;
    practiceCode: string;
    address: string;
    telephone: string;
    email: string;
    sectionFilled: string;
    dateTimeFilled: Date;

    public constructor(){
        this.title = "";
        this.initials = "";
        this.familyName = "";
        this.GOCNumber = "";
        this.practiceCode = "";
        this.address = "";
        this.telephone = "";
        this.email = "";
        this.sectionFilled = "";
        this.dateTimeFilled = moment().toDate();
    }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ui-filter',
    templateUrl: './filter.element.html'
})
export class UiFilter {
    @Input() label: string = "Filter";
    @Input() values: Array<string> = [];

    @Input() val: string;

    @Output() filter = new EventEmitter();

    valueChanged(e) {
        this.filter.emit(this.val);
    }
}

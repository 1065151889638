import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../../services/auth.service";
import {RootShareService} from "../../../../../services/root.shared.service";
import {VisualField} from "../../../../../models/forms/referral/visual.field";

@Component({
    selector: 'visual-field-component',
    templateUrl: './visual.field.html'
})
export class VisualFieldComponent {
    @Input() visualField: VisualField;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }

    jsonify(w) {
        return JSON.stringify(w);
    }
}

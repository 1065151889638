import {JsonProperty} from "./mapUtil";

export class Address {
    @JsonProperty('addressLines')
    addressLines: any;
    // @JsonProperty('second-line')
    // secondLine: string;
    city: string;
    postalCode: string;

    constructor() {
        this.addressLines = undefined;
        this.city = undefined;
        this.postalCode = undefined;
    }
}
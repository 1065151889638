import {Component, Input} from "@angular/core";
import {AuthService} from "../../../../../services/auth.service";
import {RootShareService} from "../../../../../services/root.shared.service";
import {IOP} from "../../../../../models/forms/referral/iop";
import {OpticCoherenceTomography} from "../../../../../models/forms/referral/optic.coherence.tomography";

@Component({
    selector: 'optic-coherence-tomography-component',
    templateUrl: './optic.coherence.tomography.html'
})
export class OpticCoherenceTomographyComponent {
    @Input() opticCoherenceTomography: OpticCoherenceTomography;

    @Input() patient: any;
    @Input() patientDocument: any;
    @Input() readOnly: Boolean = false;

    constructor(private _rootShareService: RootShareService, private _auth: AuthService) {
    }
}

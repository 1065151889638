import {ChangeDetectorRef, Component} from '@angular/core';
import * as moment from "moment";
import * as $ from "jquery";
import * as _ from "lodash";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Consultant} from "../../utils/definitions/Consultant";
import {Clinic} from "../../utils/definitions/Clinic";
import {ClinicAppointmentInfo, Patient, Place} from "../../utils/definitions/Patient";
import {DaysFilter} from "../../models/daysFilter.model";
import {AttendanceService} from "../../services/attendances.service";
import {DictionaryService} from "../../services/dict.service";
import {SearchDictionary, dictionaryFilterType, dictionaryFilter} from "../../models/dict.utils.model";
import {DictionaryItem} from "../../utils/definitions/Dictionary";
import {MainService} from "../../services/main.service";
import {ApiService} from "../../services/api.service";

export class MainGroup{
    id: string; //date in format YYYY-MM-DD
    displayName: string;
    date: moment.Moment;
    subGroupsDict:any;
    subGroups: Array<SubGroup>;
}

export class SubGroup{
    id: string;
    displayName: string;
    patients: Array<Patient>;
}

export class DropdownSearchItem{
    name: string;
    code: string;
    data: any;
    id: string;

    constructor(name:string, code?:string, id?:string, data?: any){
        this.name = name;
        if(code){
            this.code = code;
        }
        if(id) {
            this.id = id;
        }
        if(data) {
            this.data = data;
        }
    }

    getItemId():string {
        if(this.id){
            return this.id;
        } else if(this.code) {
            return this.code
        } else {
            return this.name;
        }
    }

    getDisplayCode():string {
        if(this.code) {
            return this.code
        } else {
            return "";
        }
    }

    getDisplayName():string {
        return this.name;
    }
}

export class FetchAttendancesFilter {
    wardCodeOrSpecialtyId: string;
    clinicCode: string;
    theaterCode: string;
    consultantId: string;

    constructor() {
        this.consultantId = null;
        this.wardCodeOrSpecialtyId = null;
        this.clinicCode = null;
        this.theaterCode = null;
    }
}

@Component({
    templateUrl: './clinics.module.html',
    styleUrls: ['./clinics.module.scss']
})
export class ClinicsModule {
    public _selectDictionary: Array<any> = [];
    public _consultantsDictionary: Array<any> = [];
    public _selectDictionaryLabel: string = "";
    public _selectDictionaryDefaultText: string = "";
    public _selectDictionaryName: string;
    public _selectDictionaryConsultant: string;
    public _listHeaderName: string;
    public _noResults: string;
    public _loadingText: string;
    public _pickCriteria: string;
    public _propertyOrderName: string;
    public _propertyOrderNameAll: string;
    public _propertyView: string;
    public _propertySortName: string;
    public startDate: moment.Moment;
    public selectedDate: string;
    public selectedClinicsList: MainGroup = new MainGroup();

    // public tableName: string;
    // public resultsTime: number = 0.06;
    // public resultsCount: number = 0;
    // public results: Array<any> = [];
    // public loading: boolean = false;
    // public activePage: number = 1;
    //
    public _attendances: any = {};
    // public totalResultsCount: number = -1;
    // public resultTime: number = 0;
    // public columnsConfig: Array<DDItems>;
    // public columnsConfigAll: Array<DDItems>;

    public _mainGroups: Array<MainGroup> = [];

    public _listType: string;
    public _additionalFilterOptions: FetchAttendancesFilter = new FetchAttendancesFilter();
    public _defaultOpenPatient: string;
    public _defaultOpenPatientPath: string = "patientRecord.defaultView";
    public _queryParams : any = {};
    public daysFilter: DaysFilter = new DaysFilter();

    public dictionaryItemConsultant: DictionaryItem = new DictionaryItem ();

    public _columnsOrder: Array<number>;
    public _columnsOrderAll: Array<number>;

    public listConfigs: any;

    public viewBySpecialty: boolean = true;
    public subGroupsHeader: boolean = true;
    public isParams: boolean = false;


    public dictionaryItemClinic: DictionaryItem = new DictionaryItem ();
    public activeDates: Array<moment.Moment> = [];

    public actualMonthInLeftPosition: boolean = false;
    public showAll: boolean = false;

    public showCalendar: boolean = true;

    constructor(private _router:Router, private _route: ActivatedRoute, private _attendanceService: AttendanceService,
                private _dictionaryService:DictionaryService, private _mainService:MainService, private _api: ApiService){
        this._listType = "O";
        this.selectedClinicsList = new MainGroup();
        this.selectedClinicsList.subGroups = [];
    }

    ngOnInit() {
        this.startDate = moment.utc(moment().format('YYYY-MM'));
        let _startSearchTime = moment();

        let tmpSearchDictConsultants = new SearchDictionary();
        tmpSearchDictConsultants.name = 'Consultants';
        tmpSearchDictConsultants.displayName = 'Consultants';
        tmpSearchDictConsultants.sort = Consultant.getDefaultSort();
        this._consultantsDictionary.push(tmpSearchDictConsultants);

        let tmpSearchDictClinics = new SearchDictionary();
        tmpSearchDictClinics.name = 'Clinics';
        tmpSearchDictClinics.displayName = 'Clinics';
        tmpSearchDictClinics.sort = Clinic.getDefaultSort();
        this._selectDictionary.push(tmpSearchDictClinics);


        this._route.queryParams.subscribe((params: Params) => {
            let consultantFilter = params['consultant'];
            let unitFilter = params['unit'];
            let dataFilter = params['date'];
            let fromFilter = params['from'];
            let toFilter = params['to'];
            let all = params['all'];

            this._queryParams = {};

            if (dataFilter) {
                this.startDate =  moment(dataFilter, 'YYYY-MM-DD');
            }

            if (fromFilter) {
                this._queryParams.from = fromFilter;
                this._queryParams.to = toFilter;

                this.daysFilter.setValue(fromFilter, toFilter);
            }

            if (consultantFilter) {
                this._queryParams.consultant = consultantFilter;
                this._additionalFilterOptions.consultantId = consultantFilter;
                this._dictionaryService.getConsult(consultantFilter).then((response) => {
                        this.dictionaryItemConsultant = response;

                        this._selectDictionary =[];

                        let tmpSearchDictClinics = new SearchDictionary();
                        tmpSearchDictClinics.name = 'Clinics filtered by: '+ this.dictionaryItemConsultant.name;
                        tmpSearchDictClinics.displayName = 'Clinics';
                        tmpSearchDictClinics.sort = Clinic.getDefaultSort();

                        let _startDay =  this.daysFilter.firstDay < this.startDate.format("YYYY-MM-DD")? moment(this.daysFilter.firstDay):moment(this.startDate);
                        let _endDay =  this.daysFilter.lastDay > moment(this.startDate).add(3, 'month').format("YYYY-MM-DD")? moment(this.daysFilter.lastDay).add(1, 'days'):moment(this.startDate).add(3, 'month');

                        tmpSearchDictClinics.filter =
                            new dictionaryFilter(dictionaryFilterType.consultant,consultantFilter,
                                _startDay.format("YYYY-MM-DD"),_endDay.format("YYYY-MM-DD"));
                        this._selectDictionary.push(tmpSearchDictClinics);
                    }
                );


            } else {
                this._selectDictionary =[];
                this._additionalFilterOptions.consultantId = null;
                let tmpSearchDictClinics = new SearchDictionary();
                tmpSearchDictClinics.name = 'Clinics';
                tmpSearchDictClinics.displayName = 'Clinics';
                tmpSearchDictClinics.sort = Clinic.getDefaultSort();
                this._selectDictionary.push(tmpSearchDictClinics);
            }

            if (unitFilter) {
                this._queryParams.unit = unitFilter;
                this._additionalFilterOptions.clinicCode = unitFilter;
                this._dictionaryService.getClinic(unitFilter).then((response) => {
                        this.dictionaryItemClinic = response;

                        this._consultantsDictionary =[];
                        let tmpSearchDictConsultants = new SearchDictionary();
                        tmpSearchDictConsultants.name = 'Consultants filtered by: '+ this.dictionaryItemClinic.name;
                        tmpSearchDictConsultants.displayName = 'Consultants';
                        tmpSearchDictConsultants.sort = Consultant.getDefaultSort();

                        let _startDay =  this.daysFilter.firstDay < this.startDate.format("YYYY-MM-DD")? moment(this.daysFilter.firstDay):moment(this.startDate);
                        let _endDay =  this.daysFilter.lastDay > moment(this.startDate).add(3, 'month').format("YYYY-MM-DD")? moment(this.daysFilter.lastDay).add(1, 'days'):moment(this.startDate).add(3, 'month');


                        tmpSearchDictConsultants.filter =
                            new dictionaryFilter(dictionaryFilterType.clinic, unitFilter,
                                _startDay.format("YYYY-MM-DD"),_endDay.format("YYYY-MM-DD"));

                        this._consultantsDictionary.push(tmpSearchDictConsultants);
                    }
                );

            } else {
                this._consultantsDictionary =[];
                let tmpSearchDictConsultants = new SearchDictionary();
                tmpSearchDictConsultants.name = 'Consultants';
                tmpSearchDictConsultants.displayName = 'Consultants';
                tmpSearchDictConsultants.sort = Consultant.getDefaultSort();
                this._consultantsDictionary.push(tmpSearchDictConsultants);

                this._additionalFilterOptions.clinicCode = null;
            }
            if (all) {
                this._queryParams.all = all;
                if (all == 'yes') this.showAll = true;
                else this.showAll = false;
            }


            if (!this.daysFilter.isSet && this.startDate.format("YYYY-MM") ==  moment().format("YYYY-MM"))
                this.actualMonthInLeftPosition = true;
            else
                this.actualMonthInLeftPosition = false;

            this._queryParams.date = this.startDate.format("YYYY-MM-DD");

            if (this._additionalFilterOptions.clinicCode == null && this._additionalFilterOptions.consultantId == null) {
                this.daysFilter = new DaysFilter();
                this._queryParams.from = null;
                this._queryParams.to = null;
                this._queryParams.date = null;
                this.startDate = moment.utc(moment().format('YYYY-MM'));
            }
            this.searchedByValue();
        });
    }

    showHideCalendar() {
        this.showCalendar = !this.showCalendar;
    }

    calendarChooseDate(date: moment.Moment) {
        this.selectedDate = date.format("YYYY-MM-DD");
        this.selectByDate();

        // if(!this.daysFilter.isSet) {
        //     if(this.dayMatchesToFilter(date.format("YYYY-MM-DD"))) {
        //         let tagPositionTop = $("#" + date.format('YYYY-MM-DD')).offset().top;
        //         $(window).scrollTop(tagPositionTop - 60);
        //     } else {
        //         console.log("This date is not included in your current filter");
        //     }
        //
        // } else {
        //     if(this.daysFilter.dayInRange(date.format("YYYY-MM-DD"))) {
        //         let tagPositionTop = $("#" + date.format('YYYY-MM-DD')).offset().top;
        //         $(window).scrollTop(tagPositionTop - 60);
        //     } else {
        //         console.log("This date is not included in your current filter");
        //     }
        // }
    }

    getDate(additionalMonth?: number): string {
        let date = moment(this.startDate);
        if (additionalMonth) {
            date.add(additionalMonth, 'month');
        }
        return date.format('YYYY-MM');

    }

    changeStartMonth(change: number) {
        this.startDate.add(change, 'month');

        let o : any = {};
        _.extend(o, this._queryParams);

        o['date'] = this.startDate.format('YYYY-MM-DD');

        this._router.navigate([], { queryParams: o });

    }

    setStartDay(day: moment.Moment){

        let pickedDay = day.format("YYYY-MM-DD");
        let defaultDay = day.add(2, 'days').format("YYYY-MM-DD");
        let firstDay = this.daysFilter.firstDay;
        let lastDay = this.daysFilter.lastDay;

        if (this.daysFilter.isSet) {
            if(this.daysFilter.isCorrectFirstDay(pickedDay)){
                firstDay = pickedDay;
            } else {
                console.log("Incorrect selected date range");
            }
        } else {
            firstDay = pickedDay;
            lastDay = defaultDay;
        }

        let o : any = {};
        _.extend(o, this._queryParams);

        o['from'] = firstDay;
        o['to'] = lastDay;

        this._router.navigate([], { queryParams: o });
    }

    setEndDay(day: moment.Moment){
        let pickedDay = day.format("YYYY-MM-DD");
        let defaultDay = day.add(-2, 'days').format("YYYY-MM-DD");
        let firstDay = this.daysFilter.firstDay;
        let lastDay = this.daysFilter.lastDay;

        if (this.daysFilter.isSet) {
            if(this.daysFilter.isCorrectLastDay(pickedDay)){
                lastDay = pickedDay;
            } else {
                console.log("Incorrect selected date range");
            }
        } else {
            firstDay = defaultDay;
            lastDay = pickedDay;
        }

        let o : any = {};
        _.extend(o, this._queryParams);

        o['from'] = firstDay;
        o['to'] = lastDay;

        this._router.navigate([], { queryParams: o });
    }

    dayMatchesToFilter(data: string): boolean{
        if (this.actualMonthInLeftPosition && !this.showAll ) {
            if (data < moment().format("YYYY-MM-DD")) return false;
            else return true;
        } else {
            return true;
        }
    }

    removeCalendarFilter(){
        this.daysFilter = new DaysFilter();
        this._queryParams.from = null;
        this._queryParams.to = null;
        this._router.navigate([], { queryParams: this._queryParams });
    }

    public sortAction(sortedColumn) {
        //this.saveSortedColumnsToStorage(ClinicsModule.sortColumnsStorageKey);
    }

    getPropertyName() {
        return this._propertyOrderName;
    }

    private searchedByValue():void {
        //if(this._additionalFilterOptions.clinicCode != null || this._additionalFilterOptions.consultantId != null) {
            this._mainService.showLoading();
            let _startSearchTime = moment();

            let _startDay =  this.daysFilter.firstDay < this.startDate.format("YYYY-MM-DD")? moment(this.daysFilter.firstDay):moment(this.startDate);
            let _endDay =  this.daysFilter.lastDay > moment(this.startDate).add(3, 'month').format("YYYY-MM-DD")? moment(this.daysFilter.lastDay).add(1, 'days'):moment(this.startDate).add(3, 'month');

            this._attendanceService.fetchAllAttendance(this._listType, _startDay, _endDay, this._additionalFilterOptions).then((response) => {
                this._mainService.hideLoading();
                this._attendances = response;
                this.buildMainGroupObject();
                //this.resultTime = moment().diff(_startSearchTime) / 1000;

                let shest = sessionStorage.getItem('localHistoryEntryScrollTop');
                if (shest) {
                    setTimeout(() => {
                        $(window).scrollTop(parseInt(shest));
                        sessionStorage.setItem('localHistoryEntryScrollTop', null);
                    }, 100);
                }
            }, (err) => {
                this._mainService.hideLoading();
            });
        //}
    }

    private buildMainGroupObject():void {
        let mainGroupDict: any = {};
        let mainGroup: MainGroup = new MainGroup();
        let subGroup: SubGroup = new SubGroup();
        let patient: Patient;
        if(this.listConfigs == null){
            this.listConfigs = {};
        }
        this._mainGroups.splice(0, this._mainGroups.length);
        _.forEach(this._attendances, (attendance) => {
            try {
                let dateString: string = attendance.startDate.slice(0, 10);

                if (mainGroupDict.hasOwnProperty(dateString)) {
                    mainGroup = mainGroupDict[dateString];
                } else {
                    mainGroup = new MainGroup();
                    mainGroup.id = dateString;
                    mainGroup.date = moment(dateString);
                    mainGroup.displayName = mainGroup.date.format('DD MMMM YYYY');
                    mainGroup.subGroupsDict = {};
                    mainGroup.subGroups = [];
                    mainGroupDict[dateString] = mainGroup;
                }
                if (attendance.clinic == null) {
                    if (mainGroup.subGroupsDict.hasOwnProperty('noClinic')) {
                        subGroup = mainGroup.subGroupsDict['noClinic']
                    } else {
                        subGroup = new SubGroup();
                        subGroup.id = "noClinic";
                        subGroup.displayName = 'noClinic';
                        subGroup.patients = [];
                        mainGroup.subGroupsDict['noClinic'] = subGroup;
                    }
                } else {
                    //if attendance in the same clinic already exist
                    if (mainGroup.subGroupsDict.hasOwnProperty(attendance.clinic.clinicCode)) {
                        if (attendance.facility) {
                            //if attendance to the same facility already exists- append to the same SubGroup
                            if (mainGroup.subGroupsDict.hasOwnProperty(attendance.facility)) {
                                subGroup = mainGroup.subGroupsDict[attendance.facility];
                            }
                            //if attendance to this facility doesn't exist- create new subGroup and append this attendance to newly created subGroup
                            else {
                                subGroup = new SubGroup();
                                subGroup.id = attendance.facility;
                                subGroup.displayName = attendance.clinic.description + " - " + attendance.facility + ' (' + attendance.clinic.clinicCode + ')';
                                subGroup.patients = [];
                                mainGroup.subGroupsDict[attendance.facility] = subGroup;
                            }
                        }
                        //if attendance is not connected with any facility- manage subGroup by clinic code
                        else {
                            subGroup = mainGroup.subGroupsDict[attendance.clinic.clinicCode];
                        }
                    } else {
                        subGroup = new SubGroup();
                        subGroup.id = attendance.clinic.clinicCode;
                        subGroup.displayName = attendance.clinic.description;

                        //if attendance has 'facility' param, we are adding it to name, to look like this:
                        // SAMPLE CLINIC NAME - Facility One (AAA9999)
                        if (attendance.facility)
                            subGroup.displayName += ' - ' + attendance.facility + ' (' + attendance.clinic.clinicCode + ')';
                        else
                            subGroup.displayName += ' (' + attendance.clinic.clinicCode + ')';

                        subGroup.patients = [];
                        mainGroup.subGroupsDict[attendance.clinic.clinicCode] = subGroup;
                    }
                }

                patient = Patient.fromJSON2(attendance.patient);
                patient.place = new Place();
                patient.place.icon = "unity-icon-clinics";
                patient.place.description = moment(attendance.startDate).format('HH:mm');
                patient.clinicAppointmentInfo = new ClinicAppointmentInfo(this._api);
                patient.clinicAppointmentInfo.appointmentDate = attendance.patient && attendance.patient.clinicAppointmentInfo && attendance.patient.clinicAppointmentInfo.appointmentDate ? attendance.patient.clinicAppointmentInfo.appointmentDate : "";
                patient.clinicAppointmentInfo.appointmentType = attendance.patient && attendance.patient.clinicAppointmentInfo && attendance.patient.clinicAppointmentInfo.appointmentType ? attendance.patient.clinicAppointmentInfo.appointmentType : "";
                patient.clinicAppointmentInfo.attendanceStatus = attendance.patient && attendance.patient.clinicAppointmentInfo && attendance.patient.clinicAppointmentInfo.attendanceStatus ? attendance.patient.clinicAppointmentInfo.attendanceStatus : "";
                patient.clinicAppointmentInfo.clinicObj = Clinic.fromJSON(attendance.clinic);
                patient.clinicAppointmentInfo.appointmentHour = moment(attendance.startDate).format('LT');
                patient.clinicAppointmentInfo.appointmentDay = moment(attendance.startDate).format('DD MMM YYYY');
                patient.clinicAppointmentInfo.consultant = Consultant.fromJSON(attendance.consultant);
                patient.clinicAppointmentInfo.consultantName = patient.clinicAppointmentInfo.consultant.getFullDisplayName();
                subGroup.patients.push(patient);
            } catch (ex) {
                console.error(ex);
                //do nothing;
            }
        });
        this.activeDates = [];
        for (let mainKey in mainGroupDict) {
            this.activeDates.push(mainGroupDict[mainKey].date);
            for (let subKey in mainGroupDict[mainKey].subGroupsDict) {
                mainGroupDict[mainKey].subGroups.push(mainGroupDict[mainKey].subGroupsDict[subKey]);
            }
            delete mainGroupDict[mainKey].subGroupsDict;
            this._mainGroups.push(mainGroupDict[mainKey]);
        }
        this.selectByDate();
    }

    selectByDate() {
        if(!this.selectedDate){
            this.selectedDate = moment().format("YYYY-MM-DD");
        }
        this.selectedClinicsList = _.find(this._mainGroups, {"id": this.selectedDate});
        if(this.selectedClinicsList == undefined){
            this.selectedClinicsList = new MainGroup();
            this.selectedClinicsList.subGroups = [];
        }
    }

    setSearchedValue(item: any, searchTypeName: string):void {
        let paramName = "unit";
        let paramValue = null;
        switch (searchTypeName) {
            case 'Select':
                paramValue = item ? item.code : null;
                break;
            case 'Clinic':
                paramValue = item ? item.code : null;
                break;
            case 'Consultant':
                paramName = "consultant";
                paramValue = item ? item.id : null;
                break;
        }
        let o : any = {};
        _.extend(o, this._queryParams);

        if (paramValue)
            o[paramName] = paramValue;
        else
            delete o[paramName];
        this._router.navigate([], { queryParams: o })
    }

    public getBaseValueConsultant():any {
        if (this._additionalFilterOptions.consultantId != null) {
            return this.dictionaryItemConsultant;
        }else{
            return "";
        }
    }

    public getBaseValueClinic():any {
        if (this._additionalFilterOptions.clinicCode != null) {
            return this.dictionaryItemClinic;
        }else{
            return "";
        }
    }

    getDateString(date:string):string {
        if(date){
            let dateIn = moment(date);
            return dateIn.format('LT');
        } else {
            return "";
        }
    }

    getHours(date:string):string {
        if(date){
            let dateIn = moment(date);
            return dateIn.format('DD MMM YYYY');
        } else {
            return "";
        }
    }

    goToPatient(patientHospitalNumber: string) {
        this._router.navigate((['patient', patientHospitalNumber, 'timeline']));
    }

}